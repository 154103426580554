import { Injectable, ViewContainerRef } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";

import { ResendEmailConfirmDialogComponent } from "./resend-email-confirm-dialog.component";
import { EmailMetadata } from "@app/modules/support/components/support/reducers/support.reducer";

@Injectable()
export class ResendEmailConfirmDialogService {
  constructor(private dialog: MatDialog) {}

  public display(viewContainerRef: ViewContainerRef, metadata: EmailMetadata, user: any): Observable<any> {
    let dialogRef: MatDialogRef<ResendEmailConfirmDialogComponent>;
    const config = new MatDialogConfig();
    config.panelClass = ["cg-dialog", "middle"];
    config.backdropClass = "cg-dialog-backdrop";
    config.disableClose = true;
    config.viewContainerRef = viewContainerRef;
    config.data = { metadata: metadata, user: user };
    dialogRef = this.dialog.open(ResendEmailConfirmDialogComponent, config);
    return dialogRef.afterClosed();
  }
}
