import { Component, OnInit, Inject, OnDestroy, ViewContainerRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { selectorLanguage } from "@app/core/i18n/i18n.reducer";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import * as _ from "lodash";

import {
  selectorChoiceQuestionState,
  ChoiceQuestionGet
} from "@shared/modules/questions-module-shared/components/choice-question/reducers";
import {
  CountryQuestionGet,
  Country,
  selectorCountries
} from "@shared/modules/questions-module-shared/components/country-question/reducers";
import { Question, Flag } from "@app/shared/models";
import { environment } from "@env/environment";
import { RejectAndConfirmDialogService } from "@shared/dialogs/reject-confirm-dialog/reject-confirm-dialog.service";
import { FormsModule } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgIf, NgFor } from "@angular/common";

@Component({
    selector: "ddd-show-options-dialog",
    templateUrl: "./show-options-dialog.component.html",
    styleUrls: ["./show-options-dialog.component.scss"],
    standalone: true,
    imports: [NgIf, NgFor, MatTooltipModule, FormsModule, TranslateModule]
})
export class ShowOptionsDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public dialog: any;
  public options: any;
  public flags: any = [];
  public columnFlags: any = [];
  public fieldFlags: any = [];
  public columnOptions: any;
  public fieldOptions: any;
  public columnOptionsCount: number;
  public fieldOptionsCount: number;
  public fieldOptionsCountOnSetFlags: number;
  public question: Question;

  constructor(
    private readonly translate: TranslateService,
    public dialogRef: MatDialogRef<ShowOptionsDialogComponent>,
    private readonly store: Store<any>,
    private confirmDialogService: RejectAndConfirmDialogService,
    private viewContainerRef: ViewContainerRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.store.pipe(select(selectorLanguage), takeUntil(this.unsubscribe$)).subscribe((lang) => {
      this.translate.setDefaultLang(lang.currentLanguage);
    });
  }

  ngOnInit() {
    this.question = this.data.question;
    if (this.question) {
      if (this.question.type === "ChoiceQuestion" && this.question.optionSet.key) {
        this.store.dispatch(
          new ChoiceQuestionGet({
            key: this.question.optionSet.key
          })
        );

        this.store.pipe(select(selectorChoiceQuestionState), takeUntil(this.unsubscribe$)).subscribe((optionsArray) => {
          if (optionsArray) {
            const optionSetKey = this.question.optionSet.key;
            const currentQuestionOptions = optionsArray.filter((option) => {
              return option.key === optionSetKey;
            });
            if (optionsArray && optionsArray.length && currentQuestionOptions.length) {
              const questionFlags = this.question.flags ? this.question.flags : [];
              this.options = currentQuestionOptions[0].options[0].key
                ? currentQuestionOptions[0].options.filter((option) => option && !option.isDisabled)
                : currentQuestionOptions[0].options[0].filter((option) => option && !option.isDisabled);
              this.setFlags(this.options, questionFlags);
            }
          }
        });
      }

      if (this.question.type === "TableQuestion" && this.question.isStatic) {
        this.columnOptionsCount = this.question.columns.length;
        _(this.question.columns).each((column: any, i) => {
          this.store.dispatch(
            new ChoiceQuestionGet({
              key: column.optionSet.key
            })
          );
          if (this.columnFlags.length <= i) {
            this.columnFlags.push({
              flags: [],
              options: [],
              optionKey: column.optionSet.key
            });
          }
        });

        this.store.pipe(select(selectorChoiceQuestionState), takeUntil(this.unsubscribe$)).subscribe((optionsArray) => {
          if (optionsArray) {
            if (optionsArray && optionsArray.length) {
              _(this.question.columns).each((column: any, i) => {
                if (this.columnFlags[i].options.length === 0) {
                  const currentQuestionOptions = optionsArray.filter((option) => {
                    return option.key === column.optionSet.key;
                  });
                  if (currentQuestionOptions.length) {
                    this.columnFlags[i].options = currentQuestionOptions[0].options[0].key
                      ? currentQuestionOptions[0].options.filter((option) => option && !option.isDisabled)
                      : currentQuestionOptions[0].options[0].filter((option) => option && !option.isDisabled);
                    --this.columnOptionsCount;
                  }
                }
              });
              if (this.columnOptionsCount === 0) {
                this.setColumnsFlags(this.question.flags);
              }
            }
          }
        });
      }

      if (this.question.type === "MultiFieldQuestion") {
        this.fieldOptionsCount = this.question.fields.length;
        this.fieldOptionsCountOnSetFlags  = this.question.fields.length;
        let gotCountriesList: boolean;
        _(this.question.fields).each((field: any, i) => {
          if (field.type === "ChoiceField") {
            this.store.dispatch(
              new ChoiceQuestionGet({
                key: field.optionSet.key
              })
            );
          } else if (field.type === "CountryField" && !gotCountriesList) {
            this.store.dispatch(new CountryQuestionGet({ base_url: environment.client.base_url }));
            gotCountriesList = true;
          }
          if (this.fieldFlags.length <= i) {
            this.fieldFlags.push({
              flags: [],
              options: [],
              optionKey: field.optionSet ? field.optionSet.key : "country_key" + i
            });
          }
        });

        this.store.pipe(select(selectorChoiceQuestionState), takeUntil(this.unsubscribe$)).subscribe((optionsArray) => {
          if (optionsArray) {
            if (optionsArray && optionsArray.length) {
              _(this.question.fields).each((field: any, i) => {
                if (this.fieldFlags[i].options.length === 0 && field.type === "ChoiceField") {
                  const currentQuestionOptions = optionsArray.filter((option) => {
                    return option.key === field.optionSet.key;
                  });
                  if (currentQuestionOptions.length) {
                    this.fieldFlags[i].options = currentQuestionOptions[0].options[0].key
                      ? currentQuestionOptions[0].options.filter((option) => option && !option.isDisabled)
                      : currentQuestionOptions[0].options[0].filter((option) => option && !option.isDisabled);
                    --this.fieldOptionsCount;
                  }
                }
              });
              if (this.fieldOptionsCount === 0) {
                this.question.fields.forEach((field) => {
                  this.setFieldFlags();
                })
              }
            }
          }
        });
        
        this.store.pipe(select(selectorCountries), takeUntil(this.unsubscribe$)).subscribe((countries: Country[]) => {
          if (countries && countries.length) {
            _(this.question.fields).each((field: any, i) => {
              if (this.fieldFlags[i].options.length === 0 && field.type === "CountryField") {
                if (countries) {
                  this.fieldFlags[i].options = countries;
                  --this.fieldOptionsCount;
                }
              }
            });
            if (this.fieldOptionsCount === 0) {
              this.question.fields.forEach((field) => {
                this.setFieldFlags();
              })
            }
          }
        });
      }


      if (
        this.question.type === "CountryQuestion" ||
        (this.question.type === "TableQuestion" && this.question.key === "SI40")
      ) {
        this.store.dispatch(new CountryQuestionGet({ base_url: environment.client.base_url }));

        this.store.pipe(select(selectorCountries), takeUntil(this.unsubscribe$)).subscribe((countries: Country[]) => {
          if (countries) {
            // const questionFlags = this.question.flags ? this.question.flags : [];
            const questionFlags = this.question.key === "SI40" ? 
            (
              this.question.flags.length === 0 
              ?
                this.question.columns.length > 0 &&
                this.question.columns[0].flags 
                  ? 
                  this.question.columns[0].flags : []
              :
              this.question.flags
            ) 
            : 
            (this.question.flags ? this.question.flags : []);
            this.options = countries;
            this.setFlags(countries, questionFlags);
          }
        });
      }
    }
  }

  /**
   * Update flag
   */
  public flagUpdate(name: string, flagIndex: number) {
    this.flags[flagIndex].selectedFlag = name;
  }

  /**
   * Update columns flag
   */
  public columnFlagUpdate(name: string, flagIndex: number, columnIndex: number) {
    this.columnFlags[columnIndex].flags[flagIndex].selectedFlag = name;
  }

  /**
   * Update columns flag
   */
     public fieldFlagUpdate(name: string, flagIndex: number, fieldIndex: number) {
      this.fieldFlags[fieldIndex].flags[flagIndex].selectedFlag = name;
    }

  /**
   * Set flags
   */
  public setFlags(options, questionFlags) {
    _(options).each((option: any) => {
      let questionFlag;
      _(questionFlags).each((flag: Flag) => {
        if (option.key === flag.selectedOptionKey && flag.isActive) {
          questionFlag = flag;
        }
      });

      if (questionFlag) {
        this.flags.push({
          column: this.question.key === "SI40" ? questionFlag.column : null,
          optionKey: option.key,
          selectedFlag: questionFlag.riskLevel
        });
      } else {
        this.flags.push({
          column: this.question.key === "SI40" ? this.question.columns[0].key : null,
          optionKey: option.key,
          selectedFlag: "Green"
        });
      }
    });
  }

  /**
   * Set flags for table columns
   */
  public setColumnsFlags(questionFlags) {
    _(this.question.columns).each((column: any, i) => {
      _(this.columnFlags[i].options).each((option: any) => {
        let questionFlag;
        _(questionFlags).each((flag: Flag) => {
          if (option.key === flag.selectedOptionKey && flag.isActive && column.key === flag.column) {
            questionFlag = flag;
          }
        });
        if (questionFlag) {
          this.columnFlags[i].flags.push({
            optionKey: option.key,
            selectedFlag: questionFlag.riskLevel,
            column: column.key
          });
        } else {
          this.columnFlags[i].flags.push({
            optionKey: option.key,
            selectedFlag: "Green",
            column: column.key
          });
        }
      });
    });
  }

   /**
   * Set flags for multifield field
   */
    public setFieldFlags(questionFlags?: any, index?: number) {
      if (this.fieldOptionsCountOnSetFlags > 0) {
        --this.fieldOptionsCountOnSetFlags;
      }
      if (this.fieldOptionsCountOnSetFlags === 0) {
        _(this.question.fields).each((field: any, i) => {
          if (index !== undefined && index !== i) {
            return;
          }
          _(this.fieldFlags[i].options).each((option: any) => {
            let questionFlag;
            _(questionFlags || field.flags).each((flag: Flag) => {
              if (option.key === flag.selectedOptionKey && flag.isActive && field.key === flag.column) {
                questionFlag = flag;
              }
            });
            const filteredFlag = _(this.fieldFlags[i].flags).find(
              (flag) => flag.optionKey === option.key
            );
            if (!filteredFlag) {
              if (questionFlag) {
                this.fieldFlags[i].flags.push({
                  optionKey: option.key,
                  selectedFlag: questionFlag.riskLevel,
                  column: field.key
                });
              } else if (!questionFlag ) {
                this.fieldFlags[i].flags.push({
                  optionKey: option.key,
                  selectedFlag: "Green",
                  column: field.key
                });
              }
            }
          });
        });
      }
    }

  /**
   * Reset flags dialog
   */
  public showResetFlagDialog() {
    const confirmDialog = {
      title: "Reset flags",
      message: "Are you sure that you want to reset flags for this question?",
      cancel: "Cancel",
      okBtn: "Ok"
    };
    this.confirmDialogService.confirm(confirmDialog, this.viewContainerRef).subscribe((res) => {
      if (res) {
        if (this.question.type === "TableQuestion" && this.question.isStatic) {
          this.resetColumnsFlags();
        } else if (this.question.type === "MultiFieldQuestion") {
          this.resetFieldFlags();
        } else {
          this.resetFlags();
        }
      }
    });
  }

  /**
   * Reset flags
   */
  public resetFlags(isSI40?: boolean) {
    const resetFlags = [];
    const questionFlags =  isSI40 ? (this.question.columns.length > 0 && this.question.columns[0].flags ? this.question.columns[0].flags : []) : (this.question.flags ? this.question.flags : []);
    _(questionFlags).each((flag: Flag) => {
      if (flag.type === "System") {
        resetFlags.push({
          isActive: true,
          riskLevel: flag.riskLevel,
          selectedOptionKey: flag.selectedOptionKey,
          type: "System"
        });
      }
    });
    this.flags = [];
    this.setFlags(this.options, resetFlags);
  }

  /**
   * Reset flags for multifield field
   */
  public resetFieldFlags() {
    this.question.fields.forEach((field, k) => {
      const questionFlags = field.flags ? field.flags : [];
      const resetFlags = [];
      this.fieldFlags[k].flags = [];
      _(questionFlags).each((flag: Flag) => {
        if (flag.type === "System") {
          resetFlags.push({
            isActive: true,
            riskLevel: flag.riskLevel,
            selectedOptionKey: flag.selectedOptionKey,
            type: "System",
            column: flag.column
          });
        }
      });
      this.setFieldFlags(resetFlags, k);
    })
  }

  /**
   * Reset flags for table columns
   */
   public resetColumnsFlags() {
    const questionFlags = this.question.flags ? this.question.flags : [];
    const resetFlags = [];
    _(this.question.columns).each((column: any, i) => {
      this.columnFlags[i].flags = [];
    });
    _(questionFlags).each((flag: Flag) => {
      if (flag.type === "System") {
        resetFlags.push({
          isActive: true,
          riskLevel: flag.riskLevel,
          selectedOptionKey: flag.selectedOptionKey,
          type: "System",
          column: flag.column
        });
      }
    })
    this.setColumnsFlags(resetFlags);
  }

  public getAllColumnsFlags() {
    const allFlags = [];
    _(this.question.columns).each((column: any, i) => {
      _(this.columnFlags[i].flags).each((flag: any) => {
        allFlags.push(flag);
      });
    });
    return allFlags;
  }

  public getAllFieldsFlags() {
    const allFlags = [];
    _(this.question.fields).each((column: any, i) => {
      _(this.fieldFlags[i].flags).each((flag: any) => {
        allFlags.push(flag);
      });
    });
    return allFlags;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
