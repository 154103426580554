import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Dialog } from "@shared/models/questionnaire.interfaces";
import { select, Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

import {
  ActionChangeColleagueActiveStatus,
  ActionColleaguesRetrieve,
  ActionImportListRetrieve,
  ActionImportListSend,
  Colleague,
  selectorColleagueImportList,
  selectorColleagueImportResult
} from "@modules/qc-complete/components/qc-complete/reducers";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { FormsModule } from "@angular/forms";
import { NgIf, NgFor } from "@angular/common";

@Component({
    selector: "ddd-import-colleagues-dialog",
    templateUrl: "./import-colleagues-dialog.component.html",
    styleUrls: ["./import-colleagues-dialog.component.scss"],
    standalone: true,
    imports: [NgIf, NgFor, FormsModule, MatProgressBarModule]
})
export class ImportColleaguesDialogComponent implements OnInit, OnDestroy {
  public importColleaguesDialog: Dialog;
  public colleaguesList: Colleague[];
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<ImportColleaguesDialogComponent>,
    private readonly translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly store: Store<any>
  ) {
    this.store.dispatch(new ActionImportListRetrieve());
  }

  ngOnInit() {
    this.translate.get("IMPORT_COLLEAGUES_DIALOG").subscribe((res: object) => {
      this.importColleaguesDialog = {
        title: res["TITLE"],
        message: null,
        cancel: res["CANCEL_BUTTON"],
        okBtn: res["OK_BUTTON"]
      };
    });

    this.store.pipe(select(selectorColleagueImportList), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        this.colleaguesList = data;
      }
    });

    this.store.pipe(select(selectorColleagueImportResult), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        this.store.dispatch(new ActionColleaguesRetrieve());
        this.dialogRef.close();
      }
    });
  }

  public importColleagues() {
    const importIdList = [];
    this.colleaguesList.forEach(function (colleague) {
      if (colleague.active) {
        importIdList.push(colleague.id);
      }
    });
    this.store.dispatch(
      new ActionImportListSend({
        importList: importIdList
      })
    );
  }

  /**
   * colleague active status
   */
  private colleagueActiveChanged(email: string) {
    this.store.dispatch(
      new ActionChangeColleagueActiveStatus({
        email: email
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
