import { enableProdMode, isDevMode, importProvidersFrom } from "@angular/core";
import {} from "@angular/platform-browser";


import { environment } from "@env/environment";
import { environmentLoader } from "@env/environmentLoader";
import { init, instrumentAngularRouting } from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/browser';
import { AppComponent } from "./app/app.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { MultiTranslateHttpLoader } from "@app/core/utils/customTranslateLoader";
import { HttpClient } from "@angular/common/http";
import { CoreModule } from "@app/core";
import { ProfileModule } from "@modules/profile/profile.module";
import { SharedModule } from "@app/shared";
import { SettingsModule } from "@app/settings";
import { AppRoutingModule } from "@app/app-routing.module";
import { LogoutModule } from "@modules/logout/logout.module";
import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { OAuthStorage, OAuthModule } from "angular-oauth2-oidc";
import { TranslateService, TranslateModule, TranslateLoader } from "@ngx-translate/core";

export function translateHttpLoader(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: "./assets/i18n/app/", suffix: ".json" },
    { prefix: "./assets/i18n/shared-dialogs/", suffix: ".json" }
  ]);
}

environmentLoader.then((env) => {
  if (env.production) {
    enableProdMode();
    environment.production = env.production;
  }

  // HF Testing demo CI
  environment.client.main_site_url = env.client.main_site_url;
  environment.client.base_url = env.client.base_url;
  environment.client.identity_url = env.client.identity_url;
  environment.client.reporting_url = env.client.reporting_url;
  environment.client.power_bi_url = env.client.power_bi_url;
  environment.es_host = env.es_host;
  environment.es_index = env.es_index;
  environment.es_loglevel = env.es_loglevel;
  environment.sc_dns = env.sc_dns;
  environment.sc_loglevel = env.sc_loglevel;
  environment.sc_environment = env.sc_environment;

  /* init Sentry browser SDK */
  init({
    dsn: environment.sc_dns,
    environment: environment.sc_environment,
    integrations: [
      new BrowserTracing({
        tracingOrigins: [env.client.main_site_url, "/^//"],
        routingInstrumentation: instrumentAngularRouting
      })
    ],
    tracesSampleRate: 1.0
  });

  bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, LogoutModule, AppRoutingModule, SettingsModule, SharedModule, ProfileModule, 
        // OAuthModule.forRoot({
        //   resourceServer: {
        //     allowedUrls: ['http://localhost:4200'],
        //     sendAccessToken: true
        //   }
        // }),
        CoreModule.forRoot(),
        OAuthModule.forRoot(),
        TranslateModule.forRoot({
          loader: {
              provide: TranslateLoader,
              useFactory: translateHttpLoader,
              deps: [HttpClient]
          },
          isolate: true
        }), ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })),
        TranslateService, { provide: OAuthStorage, useValue: localStorage }
    ]
})
    .catch((err) => console.log(err));
});
