import { Component, Inject, OnInit, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";

import {
  selectorColleagues,
  ActionColleaguesRetrieve,
  ActionColleagueCheckExist,
  ActionColleagueAdd,
  ActionColleagueClearExistStatus,
  selectorColleagueExist
} from "@app/modules/qc-complete/components/qc-complete/reducers";
import { environment } from "@env/environment";
import {
  CountryQuestionGet,
  selectorCountries,
  Country
} from "@app/shared/modules/questions-module-shared/components/country-question/reducers";
import { FundManagerAdminService } from "@app/modules/fm-admin/component/fund-manager-admin/service";
import {
  ActionSupportGetCompanyUsers,
  ActionSupportAddUser,
  supportCompanyUsersSelector,
  ActionSupportFindUser,
  ActionSupportUpdateKeyContacts
} from "@app/modules/support/components/support/reducers/support.reducer";
import { PhoneType } from "@app/core/auth/identity/user.reducer";
import { PhoneNumberHelper } from "@app/core/utils/phone-number-helper";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FieldErrorShowComponent } from "../../components/field-error-show/field-error-show.component";
import { AppCapitalizeDirective } from "../../directives/app-capitalize.directive";
import { NgIf, NgFor, NgClass } from "@angular/common";

@Component({
    selector: "ddd-change-review-group-approvers-dialog",
    templateUrl: "./change-review-group-approvers-dialog.component.html",
    styleUrls: ["./change-review-group-approvers-dialog.component.scss"],
    standalone: true,
    imports: [NgIf, NgFor, FormsModule, ReactiveFormsModule, AppCapitalizeDirective, FieldErrorShowComponent, MatTooltipModule, NgClass, MatProgressBarModule]
})
export class ChangeReviewGroupApproversDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject();

  public approvers;
  public colleagues;
  public colleaguesCount;
  public templateId;
  public form: UntypedFormGroup;
  public countriesSelect;
  public showColleagueForm = false;
  public allowSubmit = false;
  public keyContactsSearch = new UntypedFormControl("");
  public needAutoSelectKC = false;

  @ViewChild("mobilePhone") mobilePhone: ElementRef;
  @ViewChild("landline") landline: ElementRef;
  @ViewChild("mobilePhoneCountryCode") mobilePhoneCountryCode: ElementRef;
  @ViewChild("countryCodeLandline") countryCodeLandline: ElementRef;

  constructor(
    private store: Store<any>,
    private formBuilder: UntypedFormBuilder,
    private adminService: FundManagerAdminService,
    public dialogRef: MatDialogRef<ChangeReviewGroupApproversDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.approvers = [...this.data.approvers];
    this.templateId = this.data.id;
    this.storeActions();

    this.form = this.formBuilder.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [
        null,
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ]
      ],
      phone: [null],
      landline: this.data.component === "adminCompanies" ? [null] : [null, Validators.required]
    });
  }

  public findUsers() {
    if (this.keyContactsSearch.value !== "") {
      this.store.dispatch(new ActionSupportFindUser({ email: this.keyContactsSearch.value }));
    } else {
      this.store.dispatch(new ActionSupportGetCompanyUsers({ companyId: this.templateId }));
    }
  }

  public addApprover(newApprover) {
    const currentApprovers = this.approvers.map((item) => {
      return item.id;
    });
    const index = currentApprovers.indexOf(newApprover.id);
    if (currentApprovers.indexOf(newApprover.id) < 0) {
      this.approvers.push(newApprover);
    } else {
      this.approvers.splice(index, 1);
    }
  }

  public checkApprovers(newApprover) {
    const currentApprovers = this.approvers.map((approver) => {
      return approver.id;
    });
    if (currentApprovers.indexOf(newApprover.id) >= 0) {
      return true;
    } else {
      return false;
    }
  }

  public showForm() {
    this.showColleagueForm = true;
  }

  public hideForm() {
    this.showColleagueForm = false;
    this.form.reset();
  }

  public getFormData() {
    if (this.data.component === "relationship-info") {
      return {
        id: null,
        companyId: this.templateId,
        email: this.form.getRawValue().email,
        firstName: this.form.getRawValue().firstName,
        lastName: this.form.getRawValue().lastName,
        phones: [
          {
            type: PhoneType.Mobile,
            number: PhoneNumberHelper.getFormattedPhoneNumber(this.mobilePhone, this.mobilePhoneCountryCode)
          },
          {
            type: PhoneType.LandLine,
            number: PhoneNumberHelper.getFormattedPhoneNumber(this.landline, this.countryCodeLandline)
          }
        ]
      };
    } else {
      return {
        id: null,
        email: this.form.getRawValue().email,
        firstName: this.form.getRawValue().firstName,
        lastName: this.form.getRawValue().lastName,
        phones: [
          {
            type: PhoneType.Mobile,
            number: PhoneNumberHelper.getFormattedPhoneNumber(this.mobilePhone, this.mobilePhoneCountryCode)
          },
          {
            type: PhoneType.LandLine,
            number: PhoneNumberHelper.getFormattedPhoneNumber(this.landline, this.countryCodeLandline)
          }
        ]
      };
    }
  }

  public isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }

  public validatePhoneNumber() {
    return PhoneNumberHelper.validatePhoneNumber(this.mobilePhone, this.mobilePhoneCountryCode);
  }

  public validateLandline() {
    return PhoneNumberHelper.validatePhoneNumber(this.landline, this.countryCodeLandline);
  }

  public validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public onSubmit() {
    if (!this.validatePhoneNumber() || !this.validateLandline()) {
      return false;
    }
    if (this.form.valid) {
      this.store.dispatch(
        new ActionColleagueCheckExist({
          email: this.form.getRawValue().email
        })
      );
    } else if (!this.form.valid) {
      this.validateAllFormFields(this.form);
    }
  }

  public changeOwner() {
    if (this.data.component === "FMAdmin") {
      const approvers = {
        rgTemplateId: this.templateId,
        approvers: this.approvers
      };
      this.adminService.changeTemplateApprovers(environment.client.base_url, approvers);
    } else if (this.data.component !== "relationship-info") {
      const ids = this.approvers.map((item) => {
        return item.id;
      });
      const keyContacts = {
        userIds: ids
      };
      this.store.dispatch(
        new ActionSupportUpdateKeyContacts({
          companyId: this.templateId,
          keyContacts: keyContacts
        })
      );
    }
  }

  public setAutoSelectKC() {
    const colleagueWithoutPopulators = [];
    this.colleagues.forEach((user) => {
      if (user.roles.indexOf("Populator") === -1) {
        colleagueWithoutPopulators.push(user);
      }
    });
    if (colleagueWithoutPopulators.length === 1) {
      this.addApprover(colleagueWithoutPopulators[0]);
      this.needAutoSelectKC = false;
    }
  }

  storeActions() {
    this.store.dispatch(new CountryQuestionGet({ base_url: environment.client.base_url }));
    if (this.data.component === "adminCompanies") {
      this.store.dispatch(new ActionSupportGetCompanyUsers({ companyId: this.templateId }));
    } else {
      this.store.dispatch(new ActionColleaguesRetrieve());
    }

    this.store
      .select(selectorColleagueExist)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (res && this.data.component === "relationship-info") {
          this.store.dispatch(
            new ActionColleagueAdd({
              colleague: this.getFormData()
            })
          );
          this.form.reset();
          this.showColleagueForm = false;
          this.store.dispatch(new ActionColleagueClearExistStatus());
          if (res.error) {
            return;
          }
        } else if (res && this.data.component === "FMAdmin") {
          this.store.dispatch(
            new ActionColleagueAdd({
              colleague: this.getFormData()
            })
          );
          this.form.reset();
          this.showColleagueForm = false;
          this.store.dispatch(new ActionColleagueClearExistStatus());
          if (res.error) {
            return;
          }
        } else if (res && this.data.component === "adminCompanies") {
          this.store.dispatch(
            new ActionSupportAddUser({
              companyId: this.templateId,
              colleague: this.getFormData()
            })
          );
          this.form.reset();
          this.showColleagueForm = false;
          if (this.colleaguesCount === 0) {
            this.needAutoSelectKC = true;
          }
          this.store.dispatch(new ActionColleagueClearExistStatus());
          if (res.error) {
            this.needAutoSelectKC = false;
            return;
          }
        }
      });

    this.store
      .select(selectorCountries)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((countries: Country[]) => {
        if (countries) {
          this.countriesSelect = countries;
        }
      });

    if (this.data.component === "FMAdmin" || this.data.component === "relationship-info") {
      this.store
        .select(selectorColleagues)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res) => {
          if (res && res.colleagueStatus) {
            const colleagueStatus = res.colleagueStatus;
            if (colleagueStatus.result && colleagueStatus.action === "add") {
              this.store.dispatch(new ActionColleaguesRetrieve());
            }
          }
          if (res && res.colleagues) {
            this.colleagues = res.colleagues;
          }
        });
    } else {
      this.store
        .select(supportCompanyUsersSelector)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res) => {
          if (res) {
            this.colleagues = res.result;
            this.colleaguesCount = res.count;
            if (this.needAutoSelectKC) {
              this.setAutoSelectKC();
            }
          }
        });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
