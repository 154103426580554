import { Role, CompanyType } from "@app/core/auth/identity/user.reducer";

export interface SupportTimeRangeObject {
  from?: Date;
  until?: Date;
}

export interface SupportNumRangeObject {
  from?: number;
  until?: number;
}

export interface SupportCompanyFilter {
  dsc: boolean;
  sort: string;
  search: {
    company: string;
    country: string[];
    status: string[];
    keyContact: string;
    companyType?: CompanyType[];
  };
}

export interface IFmType {
  type: CompanyType;
  label: string;
}

export interface SupportEmailsFilter {
  dsc: boolean;
  sort: string;
  search: {
    name: string;
  };
}

export enum TimelineType {
  Clarification,
  FinalRelease 
}

export interface ICompanyBrand {
  value: Brand;
  label: string;
}

export enum Brand {
  DDD,
  AllFunds,
  FCC,
  KYD
}

export enum ValidateResult {
  None,
  InProgress,
  TargetFinalized,
  AuditorFinalized,
  PendingReview
}

export interface IValidatedCycleItemModel {
  cycleState: ValidateResult;  
  disabled?: boolean; 
  id: string;
  name: string;
}

export interface IFMCompanyData {
  name: string;
  legalEntityId: string;
  firstAddressLine: string;
  secondAddressLine: string;
  postCode: string;
  city: string;
  countryKey: string;
  fmTemplates: Array<IFMTemplate>;
  contractingEntities: Array<IContractingEntityData>;
  brand: Brand;
  curatorId: string;
}

export interface IFMTemplate {
  name: string;
  label: string;
  reviewGroups: Array<IReviewGroupData>;
}

export interface IFMTemplateData {
  name: string;
  label: string;
}

export interface IReviewGroupData {
  name: string;
  label: string;
}

export interface IContractingEntityData {
  name: string;
  legalEntityId: string;
  firstAddressLine: string;
  secondAddressLine: string;
  postCode: string;
  city: string;
  countryKey: string;
}

export const COMPANY_BRANDS_LIST: Array<ICompanyBrand> = [
  {
    value: Brand.DDD,
    label: "DDD"
  },
  {
    value: Brand.FCC,
    label: "FCC"
  },
  {
    value: Brand.KYD,
    label: "KYD"
  } 
];



export interface IManageTimelinesData {
  companyId: string;
  date: Date;
  timelineType: string[TimelineType];
}

export interface SupportCompanyListResponse {
  count: number;
  result: any[];
}

export interface SupportEmailsListResponse {
  count: number;
  result: any[];
}

export interface IUpdateUserRoles {
  userId: string;
  companyId: string;
  roles: Role[];
}

export interface ITargetFM {
  targetFmName?: string;
  targetFmId: string;
  cycles: string[];
  enableFutureAccess: boolean;
}

export const FM_TYPES: IFmType[] = [
  {
    type: CompanyType.FundManager,
    label: "Core Fund Manager"
  },
  {
    type: CompanyType.Intermediary,
    label: "External Fund Manager"
  }
];

export const ACTIONS_COLUMN_SETTING = {
  name: "Actions",
  dataType: "Actions",
  dataField: "Actions"
};

const PROGRESS_RANGE_FILTER_ITEMS = ["< 5%", "5-25%", "26-50%", "51-75%", "76-90%", "91-100%"];

const DISTRIBUTOR_STATUS_FILTER_ITEMS = [
  "Invitation Sent",
  "Invitation Opened",
  "Key Contact Verified",
  "Company Details Verified",
  "Preliminary Questions Answered",
  "Completers Assigned",
  "In Progress",
  "Complete",
  "In Clarification Period",
  "In Independent Review",
  "In Final Release Period",
  "Released",
  "Company Invitation Rejected",
  "User Invitation Rejected",
  "No Response Provided",
  "Not Invited"
];

const PROGRESS_STATUS_FILTER_ITEMS = [
  "None",
  "Far Behind",
  "Overdue",
  "Behind Schedule",
  "On Track",
  "Pending Completion Release",
  "Released",
  "No Response Provided for Current Cycle",
  "Not Invited"
];

export const COLUMNS_HEADER_FILTER = [
  {
    key: "DDQCompletionProgress",
    filterItems: PROGRESS_RANGE_FILTER_ITEMS
  },
  {
    key: "DDQReviewProgress",
    filterItems: PROGRESS_RANGE_FILTER_ITEMS
  },
  {
    key: "DDQSubmittedProgress",
    filterItems: PROGRESS_RANGE_FILTER_ITEMS
  },
  {
    key: "DistributorStatus",
    filterItems: DISTRIBUTOR_STATUS_FILTER_ITEMS
  },
  {
    key: "DistributorActualStatus",
    filterItems: DISTRIBUTOR_STATUS_FILTER_ITEMS
  },
  {
    key: "DDQCompletionProgressStatus",
    filterItems: PROGRESS_STATUS_FILTER_ITEMS
  }
];

export const ACTIONS_ITEMS = [
  "Manage Key Contacts",
  "Interactions",
  "View Progress",
  "Pause Reminder Emails",
  "Resume Reminder Emails",
  "Create Interaction",
  "Create a Task",
  "Invite to a New DDQ Cycle",
  "Manage Internal Ownership",
  "Manage Relationship",
  "Audit Log",
  "Populate DDQ Answers",
  "Manage Release Timelines",
  "Allow External Document Sharing",
  "Disable External Document Sharing",
  "Show All Tasks",
  "Export Missing/Incorrect Answers",
  "Clone DDQ"
];

export const GRID_COLUMNS_SETTING = {
  Actions: {
    width: "74px",
    fixedPosition: "right",
    fixed: true,
    cellTemplate: "actionsTemplate",
    allowFiltering: false,
    allowSorting: false,
    allowHeaderFiltering: false,
    allowExporting: false,
    allowGrouping: false
  },
  Approvers: {
    dataType: "string",
    allowSorting: false,
    allowHeaderFiltering: false,
    allowSearch: true
  },
  ClarificationPeriodExpirationDate: {
    dataType: "date",
    visible: false
  },
  CompanyId: {
    dataType: "string",
    showInColumnChooser: false,
    visible: false
  },
  CompanyLEI: {
    dataType: "string"
  },
  CompanyName: {
    dataType: "string",
    showInColumnChooser: false,
    fixed: true,
    cellTemplate: "distributorCompanyTemplate",
    groupCellTemplate: "distributorCompanyGroupTemplate"
  },
  Country: {
    dataType: "string"
  },
  CriticalTimeline: {
    dataType: "string",
    cellTemplate: "criticalTimelineTemplate"
  },
  DDQCycleInvitationYear: {
    dataType: "string",
    visible: false
  },
  DDQCycleCount: {
    dataType: "number",
    alignment: "left"
  },
  DDQCycleInvitationDate: {
    dataType: "date"
  },
  DDQDate: {
    dataType: "date"
  },
  DDQCompletionProgress: {
    dataType: "number",
    cellTemplate: "ddqProgressTemplate",
    alignment: "left",
    visible: false
  },
  DDQReviewProgress: {
    dataType: "number",
    cellTemplate: "ddqReviewProgressTemplate",
    alignment: "left"
  },
  DDQSubmittedProgress: {
    dataType: "number",
    cellTemplate: "ddqSubmittedProgressTemplate",
    alignment: "left"
  },
  DistributorStatus: {
    dataType: "string"
  },
  DistributorActualStatus: {
    dataType: "string",
    visible: false
  },
  FMCompanyId: {
    dataType: "string",
    showInColumnChooser: false,
    visible: false
  },
  FinalReleasePeriodExpirationDate: {
    dataType: "date",
    showInColumnChooser: false,
    visible: false
  },
  FundManager: {
    dataType: "string"
  },
  Id: {
    dataType: "string",
    showInColumnChooser: false,
    visible: false
  },
  InitialDeadLineDate: {
    dataType: "date",
    showInColumnChooser: false,
    visible: false
  },
  SupportContact: {
    dataType: "string",
    visible: false
  },
  ReferredBackOwner: {
    dataType: "string"
  },
  IsDeadLineDateChangeEnabled: {
    dataType: "boolean",
    showInColumnChooser: false,
    visible: false
  },
  IsMuted: {
    dataType: "boolean",
    showInColumnChooser: false,
    visible: false
  },
  KeyContacts: {
    dataType: "string",
    allowSorting: false,
    allowHeaderFiltering: false,
    allowSearch: true
  },
  KeyContactsData: {
    dataType: "object",
    showInColumnChooser: false,
    visible: false
  },
  LastInteractionDate: {
    dataType: "date"
  },
  DateCompanyLastActive: {
    dataType: "date"
  },
  ManagementRegion: {
    dataType: "string"
  },
  NumberOfReferrals: {
    dataType: "number",
    alignment: "left"
  },
  NumberOfFMRelationships: {
    dataType: "number",
    alignment: "left"
  },
  DDQCompletionProgressStatus: {
    dataType: "string",
    visible: false
  },
  ReferredBackOwnership: {
    dataType: "string"
  },
  RelationshipManagers: {
    dataType: "string",
    allowSorting: false,
    allowHeaderFiltering: false,
    allowSearch: true
  },
  ReleaseDate: {
    dataType: "date"
  },
  DateOfLastRelease: {
    dataType: "date"
  },
  DDQResponseStatus: {
    dataType: "string",
    showInColumnChooser: false,
    visible: false
  },
  RelationshipStatus: {
    dataType: "string"
  },
  SummaryComment: {
    dataType: "string",
    allowSorting: false,
    allowHeaderFiltering: false,
    allowSearch: true
  },
  CompanyRegistrationDate: {
    dataType: "date",
    visible: false
  },
  IsExternalGuestAllowDocs: {
    dataType: "boolean",
    showInColumnChooser: false,
    visible: false
  },
  RelationshipAcceptanceDate: {
    dataType: "date"
  },
  DaysLeftInCurrentStatus: {
    dataType: "number",
    alignment: "left"
  },
  NumberOfClarifications: {
    dataType: "number",
    alignment: "left"
  },
  InitialReleaseDate: {
    dataType: "date",
    visible: false
  },
  ReviewCycleYear: {
    dataType: "string",
    visible: false
  },
  CalculatedOverallRiskPriority: {
    dataType: "string",
    visible: false
  },
  AssignedOverallRiskPriority: {
    dataType: "string",
    visible: false
  },
  NextDDQCycleInvitationYear: {
    dataType: "string",
    visible: false
  },
  LastRiskModelReviewFrequency: {
    dataType: "number",
    alignment: "left",
    visible: false
  },
  RelationshipCreatedDate: {
    dataType: "date",
    visible: false
  },
  ReviewFinalisationDate: {
    dataType: "date",
    visible: false
  },
  LastReviewFinalisationDate: {
    dataType: "date",
    visible: false
  },
  SalesRegion: {
    dataType: "string",
    visible: false
  },
  ManagementClassification: {
    dataType: "string",
    visible: false
  },
  FundGroup: {
    dataType: "string",
    visible: false
  },
  NewContract: {
    dataType: "string",
    visible: false
  }
};
