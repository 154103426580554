import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";

import { environment } from "@env/environment";
import { ActionUserResolveGuide } from "@app/core/auth/identity/user.reducer";
import { FMDashboardFirstVisitComponent } from "./components/fm-dashboard-first-visit/fm-dashboard-first-visit.component";
import { CompleteNewCycleFirstVisitComponent } from "./components/complete-new-cycle-first-visit/complete-new-cycle-first-visit.component";
import { UpdateTermsFirstVisitComponent } from "./components/update-terms-first-visit/update-terms-first-visit.component";

@Injectable()
export class FirstVisitService {
  constructor(private dialog: MatDialog, private readonly store: Store<any>) {}

  public FMDashboardPageFirstVisit(userRoles): Observable<string> {
    let dialogRef: MatDialogRef<FMDashboardFirstVisitComponent>;
    const config = new MatDialogConfig();
    config.panelClass = ["cg-dialog", "middle"];
    config.backdropClass = "cg-dialog-backdrop";
    config.disableClose = true;
    config.data = { userRoles: userRoles };

    dialogRef = this.dialog.open(FMDashboardFirstVisitComponent, config);
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.store.dispatch(
          new ActionUserResolveGuide({
            guidename: res,
            userGuidename: "fmKeyContactBridgingScreen",
            base_url: environment.client.base_url,
            dialog: true
          })
        );
      }
    });
    return dialogRef.afterClosed();
  }

  public completePageNewCycleFirstVisit(): Observable<string> {
    let dialogRef: MatDialogRef<CompleteNewCycleFirstVisitComponent>;
    const config = new MatDialogConfig();
    config.panelClass = ["cg-dialog", "middle"];
    config.backdropClass = "cg-dialog-backdrop";
    config.disableClose = true;

    dialogRef = this.dialog.open(CompleteNewCycleFirstVisitComponent, config);
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.store.dispatch(
          new ActionUserResolveGuide({
            guidename: res,
            userGuidename: "newCycleCompleterInformation",
            base_url: environment.client.base_url,
            dialog: true
          })
        );
      }
    });
    return dialogRef.afterClosed();
  }

  public updatedTermsFirstVisit(isKeycloakProvider: boolean): Observable<string> {
    let dialogRef: MatDialogRef<UpdateTermsFirstVisitComponent>;
    const config = new MatDialogConfig();
    config.panelClass = ["cg-dialog", "middle"];
    config.backdropClass = "cg-dialog-backdrop";
    config.disableClose = true;
    config.data = { isKeycloakProvider };

    dialogRef = this.dialog.open(UpdateTermsFirstVisitComponent, config);
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.store.dispatch(
          new ActionUserResolveGuide({
            guidename: res,
            userGuidename: "processedUpdatedTerms",
            base_url: environment.client.base_url,
            dialog: true
          })
        );
      }
    });
    return dialogRef.afterClosed();
  }
}
