import { Action } from "@ngrx/store";

export enum ToastrActionTypes {
  SHOW_SUCCESS = "[Toastr] Success",
  SHOW_ERROR = "[Toastr] Error",
  SHOW_WARNING = "[Toastr] Warning",
  SHOW_INFO = "[Toastr] Info"
}

export class ActionToastrSuccess implements Action {
  readonly type = ToastrActionTypes.SHOW_SUCCESS;
  constructor(public payload: { message: string }) {}
}

export class ActionToastrError implements Action {
  readonly type = ToastrActionTypes.SHOW_ERROR;
  constructor(public payload: { error }) {}
}

export class ActionToastrWarning implements Action {
  readonly type = ToastrActionTypes.SHOW_WARNING;
  constructor(public payload: { message: string }) {}
}
export class ActionToastrInfo implements Action {
  readonly type = ToastrActionTypes.SHOW_INFO;
  constructor(public payload: { message: string, isExportInfoMessage?: boolean}) {}
}

export type ToastrActions = ActionToastrSuccess | ActionToastrError | ActionToastrWarning | ActionToastrInfo;

export const initialState: ToastrState = {
  message: ""
};

export function toastrReducer(state: ToastrState = initialState, action: ToastrActions): ToastrState {
  switch (action.type) {
    case ToastrActionTypes.SHOW_SUCCESS:
      return { ...state, message: action.payload.message };
    case ToastrActionTypes.SHOW_ERROR:
      return { ...state, message: action.payload.error };
    case ToastrActionTypes.SHOW_WARNING:
      return { ...state, message: action.payload.message };
    case ToastrActionTypes.SHOW_INFO:
        return { ...state, message: action.payload.message };
    default:
      return state;
  }
}

export interface ToastrState {
  message: string;
}

export const toastrSuccessConfig = {
  timeOut: 5700,
  toastClass: 'cg-toastr cg-success-toastr ngx-toastr'
};

export const toastrErrorConfig = {
  timeOut: 5700,
  toastClass: 'cg-toastr cg-error-toastr ngx-toastr'
};

export const toastrWarningConfig = {
  timeOut: 5700,
  toastClass: 'cg-toastr cg-warning-toastr ngx-toastr'
};

export const toastrInfoConfig = {
  timeOut: 10000,
  toastClass: 'cg-toastr cg-info-toastr ngx-toastr'
};
