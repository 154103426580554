import { AuthConfig } from "angular-oauth2-oidc";

/**
 * !!!! NOTE: issuer and postLogoutRedirectUri is set dynamically in app.component, based on environment.json
 */
export const authConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: "",
  requireHttps: true,

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin,

  // URL of the SPA to redirect the user after silent refresh
  silentRefreshRedirectUri: window.location.origin + '/assets/silent-refresh.html',
  // 2592000

  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: "curator/distributordd",

  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: "distributordd.read profile curator.responses.read curator.documents.read",

  showDebugInformation: true,

  sessionChecksEnabled: false,
  postLogoutRedirectUri: "",
  strictDiscoveryDocumentValidation: false,
  skipIssuerCheck: true,
  preserveRequestedRoute: true
};
