import { Action, createSelector } from "@ngrx/store";
import { IUser } from "@app/core/auth/identity/user.reducer";
import { HttpErrorResponse } from "@angular/common/http";
import * as _ from "lodash";
import { IInteractionFormModel } from "@shared/models/interaction.interfaces";
import { IAuditTrail } from "@app/shared/models";
import { IManageTimelinesData, IValidatedCycleItemModel, ValidateResult } from "@modules/support/models";

export const distributorCompaniesStoreName = "distributorCompany";
const localState = (state) => state.support[distributorCompaniesStoreName] || supportDistributorCompanyInitialState;

export enum SupportDistributorCompanyActionTypes {
  DISTRIBUTOR_PAUSE_REMINDERS = "[Support] Update pause reminders",
  DISTRIBUTOR_PAUSE_REMINDERS_SUCCESS = "[Support] Update pause reminders success",
  DISTRIBUTOR_PAUSE_REMINDERS_ERROR = "[Support] Update pause reminders error",
  DISTRIBUTOR_NOTHING_HAPPENDS = "[Support] Empty method",
  DISTRIBUTOR_SET_KEY_CONTACTS = "[Support] Set key contacts",
  DISTRIBUTOR_COMPANY_USERS_GET = "[Support] Get distributor company user",
  DISTRIBUTOR_COMPANY_USERS_GET_SUCCESS = "[Support] Get distributor company user success",
  DISTRIBUTOR_COMPANY_USERS_GET_ERROR = "[Support] Get distributor company user error",
  DISTRIBUTOR_COMPANY_INTERACTION_ADD = "[Support] Add interaction",
  DISTRIBUTOR_COMPANY_INTERACTION_ADD_SUCCESS = "[Support] Add interaction Success",
  DISTRIBUTOR_COMPANY_TASK_ADD = "[Support] Add task",
  DISTRIBUTOR_ALL_USERS_GET = "[Support] Get All Users",
  DISTRIBUTOR_ALL_USERS_GET_SUCCESS = "[Support] Get All Users Success",
  DISTRIBUTOR_ALL_USERS_GET_ERROR = "[Support] Get All Users Error",
  DISTRIBUTOR_TRIGGER_COMPANY_EVENT = "[Support] Trigger Company Event",
  DISTRIBUTOR_TRIGGER_COMPANY_EVENT_SUCCESS = "[Support] Trigger Company Event Success",
  DISTRIBUTOR_TRIGGER_COMPANY_EVENT_ERROR = "[Support] Trigger Company Event Error",
  DISTRIBUTOR_INVITE_DM_USER = "[Support] Invite Document Management User",
  DISTRIBUTOR_INVITE_DM_USER_SUCCESS = "[Support] Invite Document Management User Success",
  DISTRIBUTOR_INVITE_DM_USER_ERROR = "[Support] Invite Document Management User Error",
  DISTRIBUTOR_TAKE_OWNERSHIP = "[Support] Take Distributor Ownership",
  DISTRIBUTOR_TAKE_OWNERSHIP_SUCCESS = "[Support] Take Distributor Ownership Success",
  DISTRIBUTOR_TAKE_OWNERSHIP_ERROR = "[Support] Take Distributor Ownership Error",
  DISTRIBUTOR_ADD_COMPANY = "[Support] Add Distributor Company",
  DISTRIBUTOR_ADD_COMPANY_SUCCESS = "[Support] Add Distributor Company Success",
  DISTRIBUTOR_UPDATE_COMPANY = "[Support] Update Distributor Company",
  DISTRIBUTOR_UPDATE_COMPANY_SUCCESS = "[Support] Update Distributor Company Success",
  DISTRIBUTOR_UPDATE_COMPANY_ERROR = "[Support] Update Distributor Company Error",
  DISTRIBUTOR_MANAGE_RELEASE_TIMELINES = "[Support] Manage Release Timelines",
  DISTRIBUTOR_MANAGE_RELEASE_TIMELINES_SUCCESS = "[Support] Manage Release Timelines Success",
  DISTRIBUTOR_MANAGE_RELEASE_TIMELINES_ERROR = "[Support] Manage Release Timelines Error",
  DISTRIBUTOR_EXTERNAL_DOCUMENTS_SHARING = "[Support] External Document Sharing",
  DISTRIBUTOR_EXTERNAL_DOCUMENTS_SHARING_SUCCESS = "[Support] External Document Sharing Success",
  DISTRIBUTOR_EXTERNAL_DOCUMENTS_SHARING_ERROR = "[Support] External Document Sharing Error",
  DISTRIBUTOR_GET_VALIDATION_FILE = "[Support] Get Validation File",
  UPDATE_LOADING_STATE = "[Support] Update Loading State",
  CLONE_DDQ = "[Support] Clone DDQ",
  CLONE_DDQ_SUCCESS = "[Support] Clone DDQ Success",
  CLONE_DDQ_ERROR = "[Support] Clone DDQ Error",

  // Relationship actions
  GET_RELATIONSHIP_DATA = "[Support] Get Relationship Data",
  GET_RELATIONSHIP_DATA_SUCCESS = "[Support] Get Relationship Data Success",
  GET_RELATIONSHIP_DATA_ERROR = "[Support] Get Relationship Data Error",
  RELATIONSHIP_ADD = "[Support] Add Relationship",
  RELATIONSHIP_SAVE = "[Support] Save Relationship",
  RELATIONSHIP_DELETE = "[Support] Delete Relationship",
  RELATIONSHIP_DELETE_SUCCESS = "[Support] Delete Success Relationship",
  RELATIONSHIPS_GET_HISTORY = "[Support] Relationship Get History",
  RELATIONSHIPS_UPDATE_HISTORY = "[Support] Relationship Update History",
  INIT_RELATIONSHIPS_LIST = "[Support] Init Relations List",
  UPDATE_RELATIONSHIPS_LIST = "[Support] Update Relations List",
  RELATIONSHIPS_LIST_ADD = "[Support] Relationship List Add",
  UPDATE_AUDIT_TARGET_RELATION = "[Support] Update Audit Target Relation",
  GET_FM_CYCLES = "[Support] Get FM Cycles",
  GET_FM_CYCLES_SUCCESS = "[Support] Get FM Cycles Success",
  GET_FM_CYCLES_ERROR = "[Support] Get FM Cycles Error"
}

export class ActionSupportDistributorCompanyPauseReminders implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_PAUSE_REMINDERS;
  constructor(public payload: { companyId: string; newState: boolean }) {}
}

export class ActionSupportDistributorCompanyPauseRemindersSuccess implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_PAUSE_REMINDERS_SUCCESS;
  constructor(public payload: { companyId: string }) {}
}

export class ActionSupportDistributorCompanyPauseRemindersError implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_PAUSE_REMINDERS_ERROR;
  constructor(public payload: { error: any }) {}
}

export class ActionSupportNothingHappends implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_NOTHING_HAPPENDS;
  constructor() {}
}

export class ActionSupportDistributorSetKeyContacts implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_SET_KEY_CONTACTS;
  constructor(public payload: { list: any; companyId: string }) {}
}

export class ActionSupportDistributorCompanyGetUsers implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_COMPANY_USERS_GET;
  constructor(public payload: { companyId: string }) {}
}

export class ActionSupportDistributorCompanyGetUsersSuccess implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_COMPANY_USERS_GET_SUCCESS;
  constructor(public payload: { companyUsers: IUser[] }) {}
}

export class ActionSupportDistributorCompanyGetUsersError implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_COMPANY_USERS_GET_ERROR;
  constructor(public payload: { error: any }) {}
}

export class ActionSupportDistributorCompanyInteractionAdd implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_COMPANY_INTERACTION_ADD;
  constructor(
    public payload: {
      interaction: IInteractionFormModel;
      companyId: string;
    }
  ) {}
}

export class ActionSupportDistributorCompanyInteractionAddSuccess implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_COMPANY_INTERACTION_ADD_SUCCESS;
  constructor(public payload: { companyId: string; date: string }) {}
}

export class ActionSupportDistributorAllUsersGet implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_ALL_USERS_GET;
  constructor() {}
}

export class ActionSupportDistributorAllUsersGetSuccess implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_ALL_USERS_GET_SUCCESS;
  constructor(public payload: { allUsers: any }) {}
}

export class ActionSupportDistributorAllUsersGetError implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_ALL_USERS_GET_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionSupportDistributorCompanyTaskAdd implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_COMPANY_TASK_ADD;
  constructor(public payload: { task: any }) {}
}

export class ActionSupportDistributorTriggerCompanyEvent implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_TRIGGER_COMPANY_EVENT;
  constructor(
    public payload: {
      companyId: string;
      startNewCycle: boolean;
      refreshCompletionDates: boolean;
      refreshDDQStructure: boolean;
    }
  ) {}
}

export class ActionSupportDistributorTriggerCompanyEventSuccess implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_TRIGGER_COMPANY_EVENT_SUCCESS;
  constructor() {}
}

export class ActionSupportDistributorTriggerCompanyEventError implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_TRIGGER_COMPANY_EVENT_ERROR;
  constructor(public payload: { error: any }) {}
}

export class ActionSupportDistributorInviteDMUser implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_INVITE_DM_USER;
  constructor(public payload: { dmUser: any }) {}
}

export class ActionSupportDistributorInviteDMUserSuccess implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_INVITE_DM_USER_SUCCESS;
  constructor() {}
}

export class ActionSupportDistributorInviteDMUserError implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_INVITE_DM_USER_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionSupportDistributorManageReleseTimelines implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_MANAGE_RELEASE_TIMELINES;
  constructor(public payload: { manageData: IManageTimelinesData }) {}
}

export class ActionSupportDistributorManageReleseTimelinesSuccess implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_MANAGE_RELEASE_TIMELINES_SUCCESS;
  constructor() {}
}

export class ActionSupportDistributorManageReleseTimelinesError implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_MANAGE_RELEASE_TIMELINES_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionSupportDistributorExternalDocumentsSharing implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_EXTERNAL_DOCUMENTS_SHARING;
  constructor(public payload: { companyId: string; newState: boolean }) {}
}

export class ActionSupportDistributorExternalDocumentsSharingSuccess implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_EXTERNAL_DOCUMENTS_SHARING_SUCCESS;
  constructor(public payload: { companyId: string }) {}
}

export class ActionSupportDistributorExternalDocumentsSharingError implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_EXTERNAL_DOCUMENTS_SHARING_ERROR;
  constructor(public payload: { error: any }) {}
}

export class ActionSupportDistributorManageOwnership implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_TAKE_OWNERSHIP;
  constructor(
    public payload: {
      distributorId: string;
      internalOwnerId: string;
    }
  ) {}
}

export class ActionSupportDistributorManageOwnershipSuccess implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_TAKE_OWNERSHIP_SUCCESS;
  constructor() {}
}

export class ActionSupportDistributorManageOwnershipError implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_TAKE_OWNERSHIP_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionDistributorAddCompany implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_ADD_COMPANY;
  constructor(
    public payload?: {
      distributorCompanyData: any;
    }
  ) {}
}

export class ActionDistributorAddCompanySuccess implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_ADD_COMPANY_SUCCESS;
  constructor() {}
}

export class ActionDistributorUpdateCompany implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_UPDATE_COMPANY;
  constructor(
    public payload?: {
      distributorCompanyData: any;
    }
  ) {}
}

export class ActionDistributorUpdateCompanySuccess implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_UPDATE_COMPANY_SUCCESS;
  constructor(
    public payload?: {
      response: any;
    }
  ) {}
}

export class ActionDistributorUpdateCompanyError implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_UPDATE_COMPANY_ERROR;
  constructor(public payload: { error: any }) {}
}

export class ActionGetRelationshipData implements Action {
  readonly type = SupportDistributorCompanyActionTypes.GET_RELATIONSHIP_DATA;
  constructor(public payload: { companyId: string; distributorId: string }) {}
}

export class ActionGetRelationshipDataSuccess implements Action {
  readonly type = SupportDistributorCompanyActionTypes.GET_RELATIONSHIP_DATA_SUCCESS;
  constructor(public payload: { list: any }) {}
}

export class ActionGetRelationshipDataError implements Action {
  readonly type = SupportDistributorCompanyActionTypes.GET_RELATIONSHIP_DATA_ERROR;
  constructor(public payload: { error: any }) {}
}

export class ActionRelationshipAdd implements Action {
  readonly type = SupportDistributorCompanyActionTypes.RELATIONSHIP_ADD;
  constructor(
    public payload?: {
      relationship: any;
      relationshipData: any;
    }
  ) {}
}

export class ActionRelationshipSave implements Action {
  readonly type = SupportDistributorCompanyActionTypes.RELATIONSHIP_SAVE;
  constructor(
    public payload?: {
      relationship: any;
      relationshipData: any;
    }
  ) {}
}

export class ActionRelationshipDelete implements Action {
  readonly type = SupportDistributorCompanyActionTypes.RELATIONSHIP_DELETE;
  constructor(
    public payload: {
      distributorId: string;
      fmCompanyId: string;
      index: number;
    }
  ) {}
}

export class ActionRelationshipDeleteSuccess implements Action {
  readonly type = SupportDistributorCompanyActionTypes.RELATIONSHIP_DELETE_SUCCESS;
  constructor(public payload: { index: number }) {}
}

export class ActionRelationshipGetHistory implements Action {
  readonly type = SupportDistributorCompanyActionTypes.RELATIONSHIPS_GET_HISTORY;
  public constructor(
    public payload: {
      distributorId?: string;
    }
  ) {}
}

export class ActionRelationshipUpdateHistory implements Action {
  readonly type = SupportDistributorCompanyActionTypes.RELATIONSHIPS_UPDATE_HISTORY;
  constructor(public payload: { actions: IAuditTrail[] }) {}
}

export class ActionInitRelationshipsList implements Action {
  readonly type = SupportDistributorCompanyActionTypes.INIT_RELATIONSHIPS_LIST;
  constructor(public payload: { relationshipsList: any }) {}
}

export class ActionUpdateRelationshipsList implements Action {
  readonly type = SupportDistributorCompanyActionTypes.UPDATE_RELATIONSHIPS_LIST;
  constructor(public payload: { relationshipData: any }) {}
}

export class ActionRelationshipsListAdd implements Action {
  readonly type = SupportDistributorCompanyActionTypes.RELATIONSHIPS_LIST_ADD;
  constructor(public payload: { relationshipData: any }) {}
}

export class ActionUpdateAuditTargetRelation implements Action {
  readonly type = SupportDistributorCompanyActionTypes.UPDATE_AUDIT_TARGET_RELATION;
  constructor(public payload: { relation: any }) {}
}

export class ActionGetValidationFile implements Action {
  readonly type = SupportDistributorCompanyActionTypes.DISTRIBUTOR_GET_VALIDATION_FILE;
  constructor(public payload: { companyId: string }) {}
}

export class ActionUpdateLoadingState implements Action {
  readonly type = SupportDistributorCompanyActionTypes.UPDATE_LOADING_STATE;
  constructor(public payload: { loadingStatus: boolean }) {}
}

export class ActionCloneDDQ implements Action {
  readonly type = SupportDistributorCompanyActionTypes.CLONE_DDQ;
  constructor(
    public payload: {
      readyCompanyId: string;
      pendingCompanyId: string;
    }
  ) {}
}

export class ActionCloneDDQSuccess implements Action {
  readonly type = SupportDistributorCompanyActionTypes.CLONE_DDQ_SUCCESS;
  constructor() {}
}

export class ActionCloneDDQError implements Action {
  readonly type = SupportDistributorCompanyActionTypes.CLONE_DDQ_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionGetFMCycles implements Action {
  readonly type = SupportDistributorCompanyActionTypes.GET_FM_CYCLES;
  constructor(public payload: { distributorId: string; targetFmId: string; auditorFmId: string; }) {}
}

export class ActionGetFMCyclesSuccess implements Action {
  readonly type = SupportDistributorCompanyActionTypes.GET_FM_CYCLES_SUCCESS;
  constructor(public payload: { list: any }) {}
}

export class ActionGetFMCyclesError implements Action {
  readonly type = SupportDistributorCompanyActionTypes.GET_FM_CYCLES_ERROR;
  constructor(public payload: { error: any }) {}
}

export type SupportDistributorCompanyAction =
  | ActionSupportDistributorCompanyPauseReminders
  | ActionSupportDistributorCompanyPauseRemindersSuccess
  | ActionSupportDistributorCompanyPauseRemindersError
  | ActionSupportNothingHappends
  | ActionSupportDistributorSetKeyContacts
  | ActionSupportDistributorCompanyGetUsers
  | ActionSupportDistributorCompanyGetUsersSuccess
  | ActionSupportDistributorCompanyGetUsersError
  | ActionSupportDistributorCompanyInteractionAdd
  | ActionSupportDistributorCompanyInteractionAddSuccess
  | ActionSupportDistributorAllUsersGet
  | ActionSupportDistributorAllUsersGetSuccess
  | ActionSupportDistributorAllUsersGetError
  | ActionSupportDistributorCompanyTaskAdd
  | ActionSupportDistributorTriggerCompanyEvent
  | ActionSupportDistributorTriggerCompanyEventSuccess
  | ActionSupportDistributorTriggerCompanyEventError
  | ActionSupportDistributorInviteDMUser
  | ActionSupportDistributorInviteDMUserSuccess
  | ActionSupportDistributorInviteDMUserError
  | ActionSupportDistributorManageReleseTimelines
  | ActionSupportDistributorManageReleseTimelinesSuccess
  | ActionSupportDistributorManageReleseTimelinesError
  | ActionSupportDistributorExternalDocumentsSharing
  | ActionSupportDistributorExternalDocumentsSharingSuccess
  | ActionSupportDistributorExternalDocumentsSharingError
  | ActionSupportDistributorManageOwnership
  | ActionSupportDistributorManageOwnershipSuccess
  | ActionSupportDistributorManageOwnershipError
  | ActionDistributorAddCompany
  | ActionDistributorAddCompanySuccess
  | ActionDistributorUpdateCompany
  | ActionDistributorUpdateCompanySuccess
  | ActionDistributorUpdateCompanyError
  | ActionGetRelationshipData
  | ActionGetRelationshipDataSuccess
  | ActionGetRelationshipDataError
  | ActionRelationshipAdd
  | ActionRelationshipSave
  | ActionRelationshipDelete
  | ActionRelationshipDeleteSuccess
  | ActionRelationshipGetHistory
  | ActionRelationshipUpdateHistory
  | ActionInitRelationshipsList
  | ActionUpdateRelationshipsList
  | ActionRelationshipsListAdd
  | ActionGetValidationFile
  | ActionUpdateLoadingState
  | ActionCloneDDQ
  | ActionCloneDDQSuccess
  | ActionCloneDDQError
  | ActionUpdateAuditTargetRelation
  | ActionGetFMCycles
  | ActionGetFMCyclesSuccess
  | ActionGetFMCyclesError;

export const supportDistributorCompanyLoading = (state) => localState(state).loading;
export const supportDistributorCompanyList = (state) => localState(state).list;
export const supportDistributorCompanyPage = (state) => localState(state).page;
export const supportDistributorCompanySize = (state) => localState(state).size;
export const supportDistributorCompanyTotal = (state) => localState(state).total;
export const supportDistributorCompanyUsers = (state) => localState(state).companyUsers;
export const supportDistributorAllAdminUsers = (state) => localState(state).allAdminUsers;
export const supportRelationshipsSelector = (state) => localState(state).relationshipsData;
export const supportRelationshipsListSelector = (state) => localState(state).relationshipsList;
export const supportRelationshipsHistoryActionsSelector = (state) => localState(state).relationshipsHistoryActions;
export const supportReloadDistributorListSelector = (state) => localState(state).reloadDistributorList;
export const supportFMCyclesSelector = (state) => localState(state).fmCyclesData;

export const supportDistributorCompanyInitialState: SupportDistributorCompanyState = {
  loading: true,
  companyUsers: null,
  allAdminUsers: null,
  relationshipsList: [],
  relationshipsData: {
    fmTemplates: [],
    rgTemplates: []
  },
  relationshipsHistoryActions: [],
  reloadDistributorList: false,
  fmCyclesData: []
};

export function supportDistributorCopmanyReducer(
  state: SupportDistributorCompanyState = supportDistributorCompanyInitialState,
  action: SupportDistributorCompanyAction
) {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_PAUSE_REMINDERS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_PAUSE_REMINDERS_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_PAUSE_REMINDERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_SET_KEY_CONTACTS:
      return {
        ...state,
        loading: false
      };
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_COMPANY_USERS_GET:
      return {
        ...state,
        loading: true,
        companyUsers: null,
        error: null
      };
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_COMPANY_USERS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        companyUsers: action.payload.companyUsers,
        error: null
      };
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_COMPANY_USERS_GET_ERROR:
      return {
        ...state,
        loading: false,
        companyUsers: null,
        error: action.payload.error
      };
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_ALL_USERS_GET:
      return {
        ...state,
        allAdminUsers: null,
        error: null
      };
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_ALL_USERS_GET_SUCCESS:
      return {
        ...state,
        allAdminUsers: action.payload.allUsers,
        error: null,
        loading: false
      };
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_ALL_USERS_GET_ERROR:
      return {
        ...state,
        allAdminUsers: null,
        error: action.payload.error
      };
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_COMPANY_INTERACTION_ADD_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_TRIGGER_COMPANY_EVENT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_TRIGGER_COMPANY_EVENT_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_TRIGGER_COMPANY_EVENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_INVITE_DM_USER:
      return {
        ...state,
        loading: true,
        error: null
      };
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_INVITE_DM_USER_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_INVITE_DM_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_EXTERNAL_DOCUMENTS_SHARING:
      return {
        ...state,
        loading: true,
        error: null
      };
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_EXTERNAL_DOCUMENTS_SHARING_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_EXTERNAL_DOCUMENTS_SHARING_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_TAKE_OWNERSHIP:
      return {
        ...state,
        loading: true,
        error: null
      };
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_TAKE_OWNERSHIP_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_TAKE_OWNERSHIP_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_ADD_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_UPDATE_COMPANY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case SupportDistributorCompanyActionTypes.GET_RELATIONSHIP_DATA:
      return {
        ...state,
        relationshipsData: {
          fmTemplates: [],
          rgTemplates: []
        },
        loading: true,
        error: null
      };
    case SupportDistributorCompanyActionTypes.GET_RELATIONSHIP_DATA_SUCCESS:
      return {
        ...state,
        relationshipsData: action.payload.list,
        loading: false,
        error: null
      };
    case SupportDistributorCompanyActionTypes.GET_RELATIONSHIP_DATA_ERROR:
      return {
        ...state,
        relationshipsData: {
          fmTemplates: [],
          rgTemplates: []
        },
        loading: false,
        error: action.payload.error
      };
    case SupportDistributorCompanyActionTypes.RELATIONSHIPS_GET_HISTORY:
      return {
        ...state,
        loading: false,
        relationshipsHistoryActions: null
      };
    case SupportDistributorCompanyActionTypes.RELATIONSHIPS_UPDATE_HISTORY:
      return {
        ...state,
        loading: false,
        relationshipsHistoryActions: action.payload.actions
      };
    case SupportDistributorCompanyActionTypes.INIT_RELATIONSHIPS_LIST:
      return {
        ...state,
        loading: false,
        relationshipsList: [].concat(action.payload.relationshipsList),
        reloadDistributorList: false
      };
    case SupportDistributorCompanyActionTypes.UPDATE_RELATIONSHIPS_LIST:
      return {
        ...state,
        loading: false,
        relationshipsList: state.relationshipsList.map((item: any) => {
          if (item.fmCompanyId !== action.payload.relationshipData.fmCompanyId) {
            return item;
          }
          return {
            ...item,
            relationshipStatus: action.payload.relationshipData.relationshipStatus,
            targetFundManagerIds: action.payload.relationshipData.targetFundManagerIds,
            fmTemplateId: action.payload.relationshipData.fmTemplateId,
            rgTemplateId: action.payload.relationshipData.rgTemplateId
          };
        }),
        reloadDistributorList: true
      };
    case SupportDistributorCompanyActionTypes.RELATIONSHIPS_LIST_ADD:
      return {
        ...state,
        loading: false,
        relationshipsList: state.relationshipsList.concat(action.payload.relationshipData),
        reloadDistributorList: true
      };
    case SupportDistributorCompanyActionTypes.RELATIONSHIP_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        relationshipsList: state.relationshipsList
          .slice(0, action.payload.index)
          .concat(state.relationshipsList.slice(action.payload.index + 1)),
        reloadDistributorList: true
      };
    case SupportDistributorCompanyActionTypes.UPDATE_AUDIT_TARGET_RELATION:
      return {
        ...state,
        relationshipsData: state.relationshipsList.map((item: any) => {
          if (item.id !== action.payload.relation.id) {
            return item;
          }
          return action.payload.relation;
        })
      };
    case SupportDistributorCompanyActionTypes.DISTRIBUTOR_GET_VALIDATION_FILE:
      return {
        ...state,
        loading: true
      };
    case SupportDistributorCompanyActionTypes.UPDATE_LOADING_STATE:
      return {
        ...state,
        loading: action.payload.loadingStatus
      };
    case SupportDistributorCompanyActionTypes.CLONE_DDQ:
      return {
        ...state,
        loading: true
      };
    case SupportDistributorCompanyActionTypes.CLONE_DDQ_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case SupportDistributorCompanyActionTypes.CLONE_DDQ_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case SupportDistributorCompanyActionTypes.GET_FM_CYCLES:
      return {
        ...state,
        fmCyclesData: [],
        loading: true,
        error: null
      };
    case SupportDistributorCompanyActionTypes.GET_FM_CYCLES_SUCCESS:
      return {
        ...state,
        fmCyclesData: action.payload.list.map((item: IValidatedCycleItemModel) => {
          if (
            item.cycleState === ValidateResult.TargetFinalized ||
            item.cycleState === ValidateResult.AuditorFinalized
          )
          {
            return {
              ...item,
              disabled: true
            };
          }
          return item;
        }),
        loading: false,
        error: null
      };
    case SupportDistributorCompanyActionTypes.GET_FM_CYCLES_ERROR:
      return {
        ...state,
        fmCyclesData: [],
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
}

export interface SupportDistributorCompanyState {
  loading: boolean;
  companyUsers?: IUser[];
  allAdminUsers?: Array<IUser>;
  relationshipsData?: any;
  relationshipsList?: any;
  relationshipsHistoryActions?: IAuditTrail[];
  reloadDistributorList?: boolean;
  fmCyclesData?: any;
}

export interface IInteraction {
  id: string;
  message: string;
  type?: Array<string>;
  taggedUsers?: IUser[];
  date?: string;
  createdBy?: IUser;
  taskId?: string;
}

export interface IDMUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  lei: string;
  companyName: string;
  share: boolean;
}
