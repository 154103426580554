import { NgZone } from '@angular/core';
import { Observable, OperatorFunction, Subscriber } from 'rxjs';

export function runInNgZone<T>(zone: NgZone): OperatorFunction<T, T> {
  return (source: Observable<T>): Observable<T> =>
    new Observable<T>((observer) => {
      const sourceSubscription = source.subscribe({
        next: (value) => zone.run(() => observer.next(value)),
        error: (err) => zone.run(() => observer.error(err)),
        complete: () => zone.run(() => observer.complete())
      });

      return () => sourceSubscription.unsubscribe();
    });
}