import { HttpErrorResponse } from "@angular/common/http";
import { Action, createSelector, createFeatureSelector } from "@ngrx/store";
import { IStateTablesData, IReassignReasonsData, IPriorityChartData } from "@modules/analytics/model/analytics.model";

/**
 * Interface for analytics state.
 *
 * @export
 * @interface AnalyticsState
 */
export interface AnalyticsState {
  /**
   * Loading state status.
   *
   * @type {boolean}
   * @memberof AnalyticsState
   */
  loading: boolean;

  /**
   * Stages tables data
   *
   * @type {IStateTablesData}
   * @memberof AnalyticsState
   */
  stateTablesData?: IStateTablesData;

  /**
   * Reassign reasons data
   *
   * @type {IReassignReasonsData}
   * @memberof AnalyticsState
   */
  reassignReasonsData?: IReassignReasonsData;

  /**
   * Priority chart data
   *
   * @type {IPriorityChartData}
   * @memberof AnalyticsState
   */
  priorityChartData?: IPriorityChartData;

  /**
   * Error status
   *
   * @type {HttpErrorResponse}
   * @memberof AnalyticsState
   */
  error?: HttpErrorResponse;
}

/**
 * Analytics initial state
 */
export const initialState: AnalyticsState = {
  loading: false
};

/**
 * Analytics feature selector
 */
const featureAnalyticsSelector = createFeatureSelector<any>("analyticsState");

/**
 * Analytics state selector
 */
export const selectorAnalyticsState = createSelector(featureAnalyticsSelector, (state) => {
  if (state) {
    return state.analytics;
  }
});

/**
 * Analytics stages table data selector
 */
export const selectorStateTablesData = createSelector(selectorAnalyticsState, (state) => {
  if (state) {
    return state.stateTablesData;
  }
});

/**
 * Analytics reassign reasons data selector
 */
export const selectorReassignReasonsData = createSelector(selectorAnalyticsState, (state) => {
  if (state) {
    return state.reassignReasonsData;
  }
});

/**
 * Analytics priority chart data selector
 */
export const selectorPriorityChartData = createSelector(selectorAnalyticsState, (state) => {
  if (state) {
    return state.priorityChartData;
  }
});

/**
 * Analytics loading status selector
 */
export const selectorAnalyticsLoading = createSelector(selectorAnalyticsState, (state) => {
  if (state) {
    return state.loading;
  }
});

/**
 * Analytics actions types
 *
 * @export
 * @enum {number}
 */
export enum AnalyticsActionTypes {
  ANALYTICS_STATES_DATA_GET = "[Analytics] Get States Data",
  ANALYTICS_STATES_DATA_GET_SUCCESS = "[Analytics] Get States Data Success",
  ANALYTICS_STATES_DATA_GET_ERROR = "[Analytics] Get States Data Error",
  ANALYTICS_REASSIGN_REASONS_DATA_GET = "[Analytics] Get Reassign Reasons Data",
  ANALYTICS_REASSIGN_REASONS_DATA_GET_SUCCESS = "[Analytics] Get Reassign Reasons Data Success",
  ANALYTICS_REASSIGN_REASONS_DATA_GET_ERROR = "[Analytics] Get Reassign Reasons Data Error",
  ANALYTICS_PRIORITY_CHART_DATA_GET = "[Analytics] Get Priority Chart Data",
  ANALYTICS_PRIORITY_CHART_DATA_GET_SUCCESS = "[Analytics] Get Priority Chart Data Success",
  ANALYTICS_PRIORITY_CHART_DATA_GET_ERROR = "[Analytics] Get Priority Chart Data Error",
  ANALYTICS_EXPORT = "[Analytics] Export",
  ANALYTICS_NOTHING_HAPPENDS = "[Analytics] empty method"
}

/**
 * Store action for retrive stages table data
 *
 * @export
 * @class ActionAnalyticsStateDataGet
 * @implements {Action}
 */
export class ActionAnalyticsStateDataGet implements Action {
  /**
   * Type of action
   *
   * @memberof ActionAnalyticsStateDataGet
   */
  readonly type = AnalyticsActionTypes.ANALYTICS_STATES_DATA_GET;

  /**
   * Creates an instance of ActionAnalyticsStateDataGet.
   * @param {{ type: string }} [payload]
   * @memberof ActionAnalyticsStateDataGet
   */
  constructor(public payload?: { type: string }) {}
}

/**
 * Store action for success retrive stages table data
 *
 * @export
 * @class ActionAnalyticsStateDataGetSuccess
 * @implements {Action}
 */
export class ActionAnalyticsStateDataGetSuccess implements Action {
  /**
   * Type of action
   *
   * @memberof ActionAnalyticsStateDataGetSuccess
   */
  readonly type = AnalyticsActionTypes.ANALYTICS_STATES_DATA_GET_SUCCESS;

  /**
   * Creates an instance of ActionAnalyticsStateDataGetSuccess.
   * @param {{ stateTablesData: IStateTablesData }} payload
   * @memberof ActionAnalyticsStateDataGetSuccess
   */
  constructor(public payload: { stateTablesData: IStateTablesData }) {}
}

/**
 *  Store action for get retrive stages table data
 *
 * @export
 * @class ActionAnalyticsStateDataGetError
 * @implements {Action}
 */
export class ActionAnalyticsStateDataGetError implements Action {
  /**
   * Type of action
   *
   * @memberof ActionAnalyticsStateDataGetError
   */
  readonly type = AnalyticsActionTypes.ANALYTICS_STATES_DATA_GET_ERROR;

  /**
   * Creates an instance of ActionAnalyticsStateDataGetError.
   * @param {{ error: HttpErrorResponse }} payload
   * @memberof ActionAnalyticsStateDataGetError
   */
  constructor(public payload: { error: HttpErrorResponse }) {}
}

/**
 * Store action for retrive reassign reasons data
 *
 * @export
 * @class ActionAnalyticsReassignReasonsDataGet
 * @implements {Action}
 */
export class ActionAnalyticsReassignReasonsDataGet implements Action {
  /**
   * Type of action
   *
   * @memberof ActionAnalyticsReassignReasonsDataGet
   */
  readonly type = AnalyticsActionTypes.ANALYTICS_REASSIGN_REASONS_DATA_GET;

  /**
   * Creates an instance of ActionAnalyticsReassignReasonsDataGet.
   * @memberof ActionAnalyticsReassignReasonsDataGet
   */
  constructor() {}
}

/**
 * Store action for success retrive reassign reasons data
 *
 * @export
 * @class ActionAnalyticsReassignReasonsDataGetSuccess
 * @implements {Action}
 */
export class ActionAnalyticsReassignReasonsDataGetSuccess implements Action {
  /**
   * Type of action
   *
   * @memberof ActionAnalyticsReassignReasonsDataGetSuccess
   */
  readonly type = AnalyticsActionTypes.ANALYTICS_REASSIGN_REASONS_DATA_GET_SUCCESS;

  /**
   * Creates an instance of ActionAnalyticsReassignReasonsDataGetSuccess.
   * @param {{ reassignReasonsData: IReassignReasonsData }} payload
   * @memberof ActionAnalyticsReassignReasonsDataGetSuccess
   */
  constructor(public payload: { reassignReasonsData: IReassignReasonsData }) {}
}

/**
 * Store action for error retrive reassign reasons data
 *
 * @export
 * @class ActionAnalyticsReassignReasonsDataGetError
 * @implements {Action}
 */
export class ActionAnalyticsReassignReasonsDataGetError implements Action {
  /**
   * Type of action
   *
   * @memberof ActionAnalyticsReassignReasonsDataGetError
   */
  readonly type = AnalyticsActionTypes.ANALYTICS_REASSIGN_REASONS_DATA_GET_ERROR;

  /**
   * Creates an instance of ActionAnalyticsReassignReasonsDataGetError.
   * @param {{ error: HttpErrorResponse }} payload
   * @memberof ActionAnalyticsReassignReasonsDataGetError
   */
  constructor(public payload: { error: HttpErrorResponse }) {}
}

/**
 * Store action for retrive priority chart data
 *
 * @export
 * @class ActionAnalyticsPriorityChartDataGet
 * @implements {Action}
 */
export class ActionAnalyticsPriorityChartDataGet implements Action {
  /**
   * Type of action
   *
   * @memberof ActionAnalyticsPriorityChartDataGet
   */
  readonly type = AnalyticsActionTypes.ANALYTICS_PRIORITY_CHART_DATA_GET;

  /**
   * Creates an instance of ActionAnalyticsPriorityChartDataGet.
   * @memberof ActionAnalyticsPriorityChartDataGet
   */
  constructor() {}
}

/**
 * Store action for success retrive priority chart data
 *
 * @export
 * @class ActionAnalyticsPriorityChartDataGetSuccess
 * @implements {Action}
 */
export class ActionAnalyticsPriorityChartDataGetSuccess implements Action {
  /**
   * Type of action
   *
   * @memberof ActionAnalyticsPriorityChartDataGetSuccess
   */
  readonly type = AnalyticsActionTypes.ANALYTICS_PRIORITY_CHART_DATA_GET_SUCCESS;

  /**
   * Creates an instance of ActionAnalyticsPriorityChartDataGetSuccess.
   * @param {{ priorityChartData: IPriorityChartData }} payload
   * @memberof ActionAnalyticsPriorityChartDataGetSuccess
   */
  constructor(public payload: { priorityChartData: IPriorityChartData }) {}
}

/**
 * Store action for error retrive priority chart data
 *
 * @export
 * @class ActionAnalyticsPriorityChartDataGetError
 * @implements {Action}
 */
export class ActionAnalyticsPriorityChartDataGetError implements Action {
  /**
   * Type of action
   *
   * @memberof ActionAnalyticsPriorityChartDataGetError
   */
  readonly type = AnalyticsActionTypes.ANALYTICS_PRIORITY_CHART_DATA_GET_ERROR;

  /**
   * Creates an instance of ActionAnalyticsPriorityChartDataGetError.
   * @param {{ error: HttpErrorResponse }} payload
   * @memberof ActionAnalyticsPriorityChartDataGetError
   */
  constructor(public payload: { error: HttpErrorResponse }) {}
}

/**
 * Store action for export analytics data
 *
 * @export
 * @class ActionAnalyticsExport
 * @implements {Action}
 */
export class ActionAnalyticsExport implements Action {
  /**
   * Type of action
   *
   * @memberof ActionAnalyticsExport
   */
  readonly type = AnalyticsActionTypes.ANALYTICS_EXPORT;

  /**
   * Creates an instance of ActionAnalyticsExport.
   * @param {{ analyticType: string; password: string; docName: string }} payload
   * @memberof ActionAnalyticsExport
   */
  constructor(public payload: { analyticType: string; password: string; docName: string }) {}
}

/**
 * Store action fo nothing Happens
 *
 * @export
 * @class ActionAnalyticNothingHappends
 * @implements {Action}
 */
export class ActionAnalyticNothingHappends implements Action {
  /**
   * Type of action
   *
   * @memberof ActionAnalyticNothingHappends
   */
  readonly type = AnalyticsActionTypes.ANALYTICS_NOTHING_HAPPENDS;

  /**
   * Creates an instance of ActionAnalyticNothingHappends.
   * @memberof ActionAnalyticNothingHappends
   */
  constructor() {}
}

/**
 * Analytics actions type.
 */
export type AnalyticsActions =
  | ActionAnalyticsStateDataGet
  | ActionAnalyticsStateDataGetSuccess
  | ActionAnalyticsStateDataGetError
  | ActionAnalyticsReassignReasonsDataGet
  | ActionAnalyticsReassignReasonsDataGetSuccess
  | ActionAnalyticsReassignReasonsDataGetError
  | ActionAnalyticsPriorityChartDataGet
  | ActionAnalyticsPriorityChartDataGetSuccess
  | ActionAnalyticsPriorityChartDataGetError
  | ActionAnalyticsExport
  | ActionAnalyticNothingHappends;

/**
 * Reducer function for analytics state.
 *
 * @export
 * @param {AnalyticsState} [state=initialState]
 * @param {AnalyticsActions} action
 * @return {*}  {AnalyticsState}
 */
export function analyticsReducer(state: AnalyticsState = initialState, action: AnalyticsActions): AnalyticsState {
  switch (action.type) {
    case AnalyticsActionTypes.ANALYTICS_STATES_DATA_GET:
      return {
        ...state,
        loading: true,
        stateTablesData: null,
        error: null
      };
    case AnalyticsActionTypes.ANALYTICS_STATES_DATA_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        stateTablesData: action.payload.stateTablesData,
        error: null
      };
    case AnalyticsActionTypes.ANALYTICS_STATES_DATA_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case AnalyticsActionTypes.ANALYTICS_REASSIGN_REASONS_DATA_GET:
      return {
        ...state,
        loading: true,
        reassignReasonsData: null,
        error: null
      };
    case AnalyticsActionTypes.ANALYTICS_REASSIGN_REASONS_DATA_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        reassignReasonsData: action.payload.reassignReasonsData,
        error: null
      };
    case AnalyticsActionTypes.ANALYTICS_REASSIGN_REASONS_DATA_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case AnalyticsActionTypes.ANALYTICS_PRIORITY_CHART_DATA_GET:
      return {
        ...state,
        loading: true,
        priorityChartData: null,
        error: null
      };
    case AnalyticsActionTypes.ANALYTICS_PRIORITY_CHART_DATA_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        priorityChartData: action.payload.priorityChartData,
        error: null
      };
    case AnalyticsActionTypes.ANALYTICS_PRIORITY_CHART_DATA_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
}
