import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { ProfileComponent } from "@app/modules/profile/components";

@NgModule({
  imports: [CommonModule, RouterModule.forChild([{ path: "", component: ProfileComponent }])],
  exports: [RouterModule]
})
export class ProfileRoutingModule {}
