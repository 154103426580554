import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  retry,
  throttleTime,
  tap
} from "rxjs/operators";
import { Router } from "@angular/router";

import { ActionToastrSuccess, ActionToastrError } from "@app/core/toastr/toastr.reducer";
import { ConfigurableBaseEffect } from "@shared/effects";
import { CustomAction } from "@app/core/utils/customAction";
import { UserService } from "@app/core/auth/identity/user.service";
import {
  ActionUserRetrieve,
  ActionUserRetrieveError,
  ActionUserRetrieveSuccess,
  ActionUserUpdate,
  ActionUserUpdateSuccess,
  ActionChangeCompany,
  ActionChangeCompanySuccess,
  ActionChangeCompanyError,
  ActionUserResolveGuide,
  ActionUserResolveGuideSuccess,
  ActionSsoUserLogout,
  ActionRejectResponsibility,
  ActionRejectResponsibilitySuccess,
  ActionUserApprove,
  ActionUserApproveSuccess,
  IUser,
  UserActionTypes,
  ActionClearUpdatedGuides,
  SignupStatus,
  Role,
  ActionChangePassword,
  ActionChangePasswordSuccess,
  ActionChangePasswordError
} from "@app/core/auth/identity/user.reducer";
import { TranslateService } from "@ngx-translate/core";

/**
 * Effects for user state
 *
 * @export
 * @class UserEffects
 * @extends {ConfigurableBaseEffect}
 */
@Injectable()
export class UserEffects extends ConfigurableBaseEffect {
  /**
   * Object of translation text
   * @private
   * @type {object}
   * @memberof UserEffects
   */
  private userMsg: object;

  /**
   * Creates an instance of UserEffects.
   * @param {Actions<CustomAction>} actions$
   * @param {UserService} service
   * @param {TranslateService} translate
   * @param {Store<any>} store
   * @param {Router} router
   * @memberof UserEffects
   */
  constructor(
    private actions$: Actions<CustomAction>,
    private service: UserService,
    public translate: TranslateService,
    public readonly store: Store<any>,
    private router: Router
  ) {
    super(translate, store);

    this.translate.get("USER_EFFECT_MESSAGE").subscribe((res: object) => {
      this.userMsg = res;
    });
  }

  /**
   * When USER_RETRIEVE action is dispatched, call the service to retrive user.
   * @memberof UserEffects
   */
  retrieveUser = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(UserActionTypes.USER_RETRIEVE),
      debounceTime(500),
      throttleTime(100),
      switchMap((action: ActionUserRetrieve) =>
        this.service.retrieveUserData(action.payload.base_url).pipe(
          map((user: IUser) => {
            return new ActionUserRetrieveSuccess({ user });
          }),
          catchError((error) => of(new ActionUserRetrieveError({ error })))
        )
      ),
      retry(3)
    );
  });

  /**
   * When USER_UPDATE action is dispatched, call the service to update user.
   * @memberof UserEffects
   */
  updateUser = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(UserActionTypes.USER_UPDATE),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionUserUpdate) =>
        this.service.updateUserData(action.payload.user, action.payload.base_url).pipe(
          switchMap((user) => [
            new ActionUserUpdateSuccess({
              user
            }),
            new ActionToastrSuccess({
              message: this.userMsg["SUCCESS"]
            })
          ]),
          catchError((error) =>
            of(
              new ActionToastrError({
                error: {
                  message: this.userMsg["ERROR"]
                }
              })
            )
          )
        )
      )
    );
  });


  approveUser = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(UserActionTypes.USER_APPROVE),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionUserApprove) =>
        this.service.approveUserData(action.payload.user, action.payload.base_url).pipe(
          switchMap((user) => [
            new ActionUserUpdateSuccess({
              user
            }),
            new ActionUserApproveSuccess({
              user
            }),
            new ActionToastrSuccess({
              message: this.userMsg["SUCCESS"]
            })
          ]),
          catchError((error) =>
            of(
              new ActionToastrError({
                error: {
                  message: this.userMsg["ERROR"]
                }
              })
            )
          )
        )
      )
    
    );
  });

  goToLogout = createEffect(
    (): Observable<Action> => {
      return this.actions$.pipe(
        ofType(UserActionTypes.REJECT_RESPONSIBILITY_SUCCESS),
        distinctUntilChanged(),
        debounceTime(500),
        tap((action: ActionRejectResponsibilitySuccess) => {
          this.router.navigate(["/logout"]);
        })
      );
    },
    { dispatch: false }
  );

  goToCompanySetup = createEffect(
    (): Observable<any> => {
      return this.actions$.pipe(
        ofType(UserActionTypes.USER_APPROVE_SUCCESS),
        distinctUntilChanged(),
        map((action: any) => {
          if (action && action.payload && action.payload.user) {
            let user: IUser = action.payload.user;
            if (user.signupStatus === SignupStatus.Completed) {
              this.router.navigate(["/dashboard"]);
            } else if (
                user &&
                (
                  (user.roles.indexOf(Role.KeyContact) > -1) ||
                  (user.roles.indexOf(Role.FMGuest) > -1) ||
                  (user.roles && user.roles.length === 1 && user.roles.indexOf(Role.DocumentManager) > -1)
                )
              )
            {
              this.router.navigate(["/setup/company-setup"]);
            } else {
              this.router.navigate(["/help"]);
            }
          }
        }) 
      );
    },
    { dispatch: false }
  );  

  rejectResponsibility = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(UserActionTypes.REJECT_RESPONSIBILITY),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionRejectResponsibility) =>
        this.service.rejectResponsibility(action.payload.rejectUser, action.payload.base_url).pipe(
          switchMap((user) => {return [
            new ActionRejectResponsibilitySuccess({
              user
            }),
            new ActionToastrSuccess({
              message: this.userMsg["SUCCESS"]
            })
          ]}),
          catchError((error) =>
            of(
              new ActionToastrError({
                error: {
                  message: this.userMsg["ERROR"]
                }
              })
            )
          )
        )
      )
    );
  });

  /**
   * When CHANGE_COMPANY action is dispatched, call the service to change company.
   * @memberof UserEffects
   */
  changeCompany = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(UserActionTypes.CHANGE_COMPANY),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionChangeCompany) =>
        this.service.changeCompany(action.payload.id, action.payload.base_url).pipe(
          switchMap((result) => [
            new ActionChangeCompanySuccess({
              result: result,
              actionLink: action.payload.actionLink,
              queryParams: action.payload.queryParams
            })
          ]),
          catchError((error) => of(new ActionChangeCompanyError({ error })))
        )
      )
    );
  });

  /**
   * When RESOLVE_GUIDE action is dispatched, call the service to resolve guide.
   * @memberof UserEffects
   */
  resolveGuide = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(UserActionTypes.RESOLVE_GUIDE),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionUserResolveGuide) =>
        this.service.resolveGuide(action.payload.guidename, action.payload.base_url).pipe(
          switchMap((result) =>
            (function () {
              const actions = [];
              if (action.payload.dialog) {
                actions.push(
                  new ActionClearUpdatedGuides({
                    userGuidename: action.payload.userGuidename
                  })
                );
              }
              return actions;
            })().concat([
              new ActionUserResolveGuideSuccess({
                userGuidename: action.payload.userGuidename
              })
            ])
          )
        )
      )
    );
  });

  /***
   * When SSO_USER_LOGOUT action is dispatched, call the router for resuming to logout screen.
   * @memberof UserEffects
   */
  goToLogoutScreen = createEffect(
    (): Observable<Action> => {
      return this.actions$.pipe(
        ofType(UserActionTypes.SSO_USER_LOGOUT),
        distinctUntilChanged(),
        debounceTime(500),
        tap((action: ActionSsoUserLogout) => {
          this.router.navigate(["/logout"]);
        })
      );
    },
    { dispatch: false }
  );

  /**
   * When CHANGE_PASSWORD action is dispatched, call the service to change password.
   * @memberof UserEffects
   */
   changePassword = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(UserActionTypes.USER_CHANGE_PASSWORD),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionChangePassword) =>
        this.service.changePassword().pipe(
          switchMap((result) => [
            new ActionChangePasswordSuccess({
              result: result
            })
          ]),
          catchError((error) => of(new ActionChangePasswordError({ error })))
        )
      )
    );
  });
}
