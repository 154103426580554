import { HttpErrorResponse } from "@angular/common/http";
import { Action, createFeatureSelector, createSelector } from "@ngrx/store";
import * as _ from "lodash";

import { Option } from "@shared/models/questionnaire.interfaces";
import { Colleague } from "@modules/qc-complete/components/qc-complete/reducers";
import { Observable } from "rxjs/internal/Observable";
import { IAppStateReviewGroups } from "@modules/review-groups/review-groups.module";
import { IDistributor } from "@shared/models";
// import { IReviewState } from '@app/modules/review-groups';

export enum ReviewGroupsActionTypes {
  REVIEW_GROUPS_GET = "[ReviewGroups] Get",
  REVIEW_GROUPS_GET_SUCCESS = "[ReviewGroups] Get Success",
  REVIEW_GROUPS_GET_ERROR = "[ReviewGroups] Get Error",
  REVIEW_GROUP_GET = "[ReviewGroup] Get",
  REVIEW_GROUP_GET_SUCCESS = "[ReviewGroup] Get Success",
  REVIEW_GROUP_GET_ERROR = "[ReviewGroup] Get Error",
  REVIEW_GROUP_UPDATE = "[ReviewGroup] Update",
  REVIEW_GROUP_UPDATE_SUCCESS = "[ReviewGroup] Update Success",
  REVIEW_GROUP_UPDATE_ERROR = "[ReviewGroup] Update Error",
  UPDATE_REVIEWER = "[ReviewGroup] Update Reviewer",
  DELETE_REVIEWER = "[ReviewGroup] Delete Reviewer",
  UPDATE_TEMPLATE_NAME = "[ReviewGroup] Update Template Name",
  SAVE_NEEDED = "[SaveNeeded] Set Boolean",
  ADD_APPROVER = "[ReviewGroup] Add Approver",
  UPDATE_APPROVER = "[ReviewGroup] Update Approver",
  DELETE_APPROVER = "[ReviewGroup] Delete Approver",
  CLEAR_NEW_USERS = "[ReviewGroup] Clear New Users",

  GET_ALL_DISTRIBUTORS = "[ReviewGroups] Get All Distributors",
  GET_ALL_DISTRIBUTORS_SUCCESS = "[ReviewGroups] Get All Distributors Success",
  GET_ALL_DISTRIBUTORS_ERROR = "[ReviewGroups] Get All Distributors Error"
}

export class ActionDistributorsGet implements Action {
  readonly type = ReviewGroupsActionTypes.GET_ALL_DISTRIBUTORS;
  constructor(public payload?: { id?: string; page: number; size: number }) {}
}

export class ActionDistributorsGetSuccess implements Action {
  readonly type = ReviewGroupsActionTypes.GET_ALL_DISTRIBUTORS_SUCCESS;
  constructor(public payload: { distributors: IDistributor[] }) {}
}

export class ActionDistributorsGetError implements Action {
  readonly type = ReviewGroupsActionTypes.GET_ALL_DISTRIBUTORS_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionReviewGroupsGet implements Action {
  readonly type = ReviewGroupsActionTypes.REVIEW_GROUPS_GET;
  constructor(public payload?: {}) {}
}

export class ActionReviewGroupsGetSuccess implements Action {
  readonly type = ReviewGroupsActionTypes.REVIEW_GROUPS_GET_SUCCESS;
  constructor(public payload: { groupsData: ReviewGroupsInfo }) {}
}

export class ActionReviewGroupsGetError implements Action {
  readonly type = ReviewGroupsActionTypes.REVIEW_GROUPS_GET_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionReviewGroupGet implements Action {
  readonly type = ReviewGroupsActionTypes.REVIEW_GROUP_GET;
  constructor(public payload?: { id: string }) {}
}

export class ActionReviewGroupGetSuccess implements Action {
  readonly type = ReviewGroupsActionTypes.REVIEW_GROUP_GET_SUCCESS;
  constructor(public payload: { template: TemplateReview }) {}
}

export class ActionReviewGroupGetError implements Action {
  readonly type = ReviewGroupsActionTypes.REVIEW_GROUP_GET_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionReviewGroupUpdate implements Action {
  readonly type = ReviewGroupsActionTypes.REVIEW_GROUP_UPDATE;
  constructor(public payload?: { template: TemplateReview }) {}
}

export class ActionReviewGroupUpdateSuccess implements Action {
  readonly type = ReviewGroupsActionTypes.REVIEW_GROUP_UPDATE_SUCCESS;
  constructor(public payload?: { template: TemplateReview; newUsers: Array<any> }) {}
}

export class ActionReviewGroupUpdateError implements Action {
  readonly type = ReviewGroupsActionTypes.REVIEW_GROUP_UPDATE_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionReviewGroupUpdateReviewer implements Action {
  readonly type = ReviewGroupsActionTypes.UPDATE_REVIEWER;
  constructor(
    public payload: {
      reviewer: Reviewer;
      sectionId: string | null;
      reviewerId?: string | null;
    }
  ) {}
}

export class ActionReviewGroupDeleteReviewer implements Action {
  readonly type = ReviewGroupsActionTypes.DELETE_REVIEWER;
  constructor(
    public payload: {
      sectionId: string;
      deleteIndex?: number;
    }
  ) {}
}

export class ActionReviewGroupUpdateTemplateName implements Action {
  readonly type = ReviewGroupsActionTypes.UPDATE_TEMPLATE_NAME;
  constructor(public payload: { name: string; id: string }) {}
}

export class SaveNeeded implements Action {
  readonly type = ReviewGroupsActionTypes.SAVE_NEEDED;
  constructor(public payload: { save: boolean }) {}
}

export class ActionReviewGroupAddApprover implements Action {
  readonly type = ReviewGroupsActionTypes.ADD_APPROVER;
  constructor(public payload: { approver: Colleague }) {}
}

export class ActionReviewGroupUpdateApprover implements Action {
  readonly type = ReviewGroupsActionTypes.UPDATE_APPROVER;
  constructor(public payload: { approver: Colleague }) {}
}

export class ActionReviewGroupDeleteApprover implements Action {
  readonly type = ReviewGroupsActionTypes.DELETE_APPROVER;
  constructor(
    public payload: {
      deleteIndex?: number;
      id?: string;
    }
  ) {}
}

export class ActionReviewGroupClearNewUsers implements Action {
  readonly type = ReviewGroupsActionTypes.CLEAR_NEW_USERS;
  constructor() {}
}

export type ReviewGroupsActions =
  | ActionReviewGroupsGet
  | ActionReviewGroupsGetSuccess
  | ActionReviewGroupsGetError
  | ActionReviewGroupGet
  | ActionReviewGroupGetSuccess
  | ActionReviewGroupGetError
  | ActionReviewGroupUpdate
  | ActionReviewGroupUpdateSuccess
  | ActionReviewGroupUpdateError
  | ActionReviewGroupUpdateReviewer
  | ActionReviewGroupDeleteReviewer
  | ActionReviewGroupUpdateTemplateName
  | SaveNeeded
  | ActionReviewGroupAddApprover
  | ActionReviewGroupUpdateApprover
  | ActionReviewGroupDeleteApprover
  | ActionDistributorsGet
  | ActionDistributorsGetSuccess
  | ActionDistributorsGetError
  | ActionReviewGroupClearNewUsers;

export const initialState: IReviewGroupsState = {
  loading: false,
  saveNeeded: false
};

const featureSelector = createFeatureSelector<IAppStateReviewGroups>("reviewGroupsState");
export const selectGroupsState = createSelector(
  featureSelector,
  (state: IAppStateReviewGroups) => state && state.reviewGroups
);
export const selectorListReviewGroups = createSelector(
  selectGroupsState,
  (state: IReviewGroupsState) => state && state.groups
);
export const selectorListReviewGroupId = createSelector(
  selectGroupsState,
  (state: IReviewGroupsState) =>
    state.groups && state.groups.list && state.groups.list.length && state.groups.list[0].id
);
export const selectorTemplateReviewGroups = createSelector(
  selectGroupsState,
  (state: IReviewGroupsState) => state && state.template
);
export const selectorNewUsersReviewGroups = createSelector(
  selectGroupsState,
  (state: IReviewGroupsState) => state && state.newUsers
);
export const selectorSaveNeeded = createSelector(
  selectGroupsState,
  (state: IReviewGroupsState) => state && state.saveNeeded
);
export const selectorDistributorsRG = createSelector(
  selectGroupsState,
  (state: IReviewGroupsState) => state.distributorsRG
);

export const selectorCurrentReviewGroupName = createSelector(
  selectGroupsState,
  (state: IReviewGroupsState) => state && state.currentTemplateName
);

export function reviewGroupsReducer(
  state: IReviewGroupsState = initialState,
  action: ReviewGroupsActions
): IReviewGroupsState {
  switch (action.type) {
    case ReviewGroupsActionTypes.GET_ALL_DISTRIBUTORS:
      return {
        ...state,
        loading: true,
        distributorsRG: null,
        error: null
      };
    case ReviewGroupsActionTypes.GET_ALL_DISTRIBUTORS_SUCCESS:
      return {
        ...state,
        loading: false,
        distributorsRG: action.payload.distributors,
        error: null
      };
    case ReviewGroupsActionTypes.GET_ALL_DISTRIBUTORS_ERROR:
      return {
        ...state,
        loading: false,
        distributorsRG: null,
        error: action.payload.error
      };
    case ReviewGroupsActionTypes.REVIEW_GROUPS_GET:
      return {
        ...state,
        loading: true,
        groups: {
          list: null,
          disableGetFirstTemplate: false,
          isFMTemplatePublished: null,
          isFMHasDistributors: null
        },
        error: null
      };
    case ReviewGroupsActionTypes.REVIEW_GROUPS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        groups: {
          list: action.payload.groupsData.rgTemplates,
          disableGetFirstTemplate: false,
          isFMTemplatePublished: action.payload.groupsData.isFMTemplatePublished,
          isFMHasDistributors: action.payload.groupsData.isFMHasDistributors
        },
        error: null
      };
    case ReviewGroupsActionTypes.REVIEW_GROUPS_GET_ERROR:
      return {
        ...state,
        loading: false,
        groups: {
          list: null,
          disableGetFirstTemplate: false,
          isFMTemplatePublished: null,
          isFMHasDistributors: null
        },
        error: action.payload.error
      };
    case ReviewGroupsActionTypes.REVIEW_GROUP_GET:
      return {
        ...state,
        loading: true,
        template: null,
        error: null
      };
    case ReviewGroupsActionTypes.REVIEW_GROUP_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        template: action.payload.template,
        currentTemplateName: action.payload.template.name,
        error: null
      };
    case ReviewGroupsActionTypes.REVIEW_GROUP_GET_ERROR:
      return {
        ...state,
        loading: false,
        template: null,
        error: action.payload.error
      };
    case ReviewGroupsActionTypes.REVIEW_GROUP_UPDATE:
      return {
        ...state,
        loading: true,
        template: null,
        error: null
      };
    case ReviewGroupsActionTypes.REVIEW_GROUP_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        template: {
          ...action.payload.template,
          hasNewUsers: action.payload.newUsers && action.payload.newUsers.length > 0 ? true : false
        },
        newUsers: action.payload.newUsers,
        currentTemplateName: action.payload.template.name,
        error: null
      };
    case ReviewGroupsActionTypes.REVIEW_GROUP_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        template: null,
        error: action.payload.error
      };
    case ReviewGroupsActionTypes.UPDATE_REVIEWER:
      return {
        ...state,
        template: {
          ...state.template,
          sections: state.template.sections.map((item) => {
            if (action.payload.sectionId !== null) {
              if (item.id !== action.payload.sectionId) {
                return item;
              }
              return {
                ...item,
                reviewers: _.find(item.reviewers, ["email", action.payload.reviewer.email])
                  ? item.reviewers
                  : item.reviewers.concat(action.payload.reviewer)
              };
            } else if (action.payload.reviewerId) {
              return {
                ...item,
                reviewers: item.reviewers.map((reviewer) => {
                  if (reviewer.id !== action.payload.reviewerId) {
                    return reviewer;
                  }
                  return action.payload.reviewer;
                })
              };
            }
          })
        }
      };
    case ReviewGroupsActionTypes.DELETE_REVIEWER:
      return {
        ...state,
        template: {
          ...state.template,
          sections: state.template.sections.map((section) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            return {
              ...section,
              reviewers: [...section.reviewers]
                .slice(0, action.payload.deleteIndex)
                .concat([...section.reviewers].slice(action.payload.deleteIndex + 1))
            };
          })
        }
      };
    case ReviewGroupsActionTypes.UPDATE_TEMPLATE_NAME:
      return {
        ...state,
        currentTemplateName: action.payload.name,
        groups: {
          list: state.groups.list.map((group) => {
            if (group.id !== action.payload.id) {
              return group;
            }
            return {
              ...group,
              name: action.payload.name
            };
          }),
          isFMTemplatePublished: state.groups.isFMTemplatePublished,
          isFMHasDistributors: state.groups.isFMHasDistributors,
          disableGetFirstTemplate: true
        }
      };
    case ReviewGroupsActionTypes.SAVE_NEEDED:
      return {
        ...state,
        saveNeeded: action.payload.save
      };

    case ReviewGroupsActionTypes.ADD_APPROVER:
      /**
       * Simply add approver to questionnaire approvers array
       */
      return {
        ...state,
        template: {
          ...state.template,
          approvers: [...state.template.approvers, action.payload.approver]
        }
      };
    case ReviewGroupsActionTypes.UPDATE_APPROVER:
      /**
       * Simply updates approver's data in the list in case of colleague's data change.
       */
      return {
        ...state,
        template: {
          ...state.template,
          approvers: state.template.approvers.map((approver) => {
            if (approver.id !== action.payload.approver.id) {
              return approver;
            }
            return action.payload.approver;
          })
        }
      };
    case ReviewGroupsActionTypes.DELETE_APPROVER:
      /**
       * @param {{deleteIndex?: number; id?: string}} payload
       *
       * If approver is deleted from the list directly:
       * payload would contain index from the list by which approver should be deleted;
       *
       * If approver is deleted during colleague deleting process:
       * payload would contain colleague ID, by which we get approver index from the list.
       */
      const deleteApproverIndex: number = action.payload.id
        ? _.findIndex(state.template.approvers, (approver) => approver.id === action.payload.id)
        : action.payload.deleteIndex;
      /**
       * If found index === -1, which means such colleague is not present in the list:
       * using bitwise operator which change value to 0 (false) to return current state.
       * Otherwise, if index >= 0:
       * bitwise operator would return non-zero value (true) - to remove approver by index.
       */
      return ~deleteApproverIndex
        ? {
            ...state,
            template: {
              ...state.template,
              approvers: [...state.template.approvers]
                .slice(0, deleteApproverIndex)
                .concat([...state.template.approvers].slice(deleteApproverIndex + 1))
            }
          }
        : state;

    case ReviewGroupsActionTypes.CLEAR_NEW_USERS:
      return {
        ...state,
        newUsers: null
      };

    default:
      return state;
  }
}

export interface IReviewGroupsState {
  loading?: boolean;
  groups?: ReviewGroupsData;
  distributorsRG?: any;
  template?: TemplateReview;
  currentTemplateName?: string;
  saveNeeded?: boolean;
  error?: HttpErrorResponse;
  newUsers?: Array<any>;
}

export interface ReviewGroupsData {
  list: ReviewGroupItem[];
  disableGetFirstTemplate: boolean;
  isFMTemplatePublished: boolean;
  isFMHasDistributors: boolean;
}

export interface ReviewGroupsInfo {
  rgTemplates: ReviewGroupItem[];
  isFMTemplatePublished: boolean;
  isFMHasDistributors: boolean;
}

export interface ReviewGroupItem {
  id: string;
  name: string;
}

export interface TemplateReview {
  id: string;
  name: string;
  dirty?: boolean;
  approvers?: Colleague[];
  sections: TemplateSection[];
  hasNewUsers?: boolean;
}

export interface TemplateSection {
  id: string;
  label: string;
  isActive: boolean;
  reviewers: Reviewer[];
  groups: TemplateReviewGroup[];
}

export interface Reviewer {
  id: string;
  title?: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface TemplateReviewGroup {
  label: string;
  isActive: boolean;
  questions: TemplateReviewQuestions[];
}

export interface TemplateReviewQuestions {
  key: string;
  label: string;
  text: string;
  helpText: string;
}
