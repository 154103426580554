<div class="content">
  <div class="title">{{ importColleaguesDialog.title }}</div>
  <div class="colleagues-box">
    <div *ngIf="colleaguesList; else loading" class="colleagues_list">
      <ng-container *ngIf="colleaguesList.length > 0">
        <div>Select one or more existing colleagues that you have already created to add them to this company.</div>
        <div class="colleague-row-item"  *ngFor="let colleague of colleaguesList">
          <div class="checkbox-container">
            <input type="checkbox" [ngModel]="colleague.active" (click)="colleagueActiveChanged(colleague.email)" />
            <span class="checkmark"></span>
          </div>
          <span class="checkmark-label">{{ colleague.firstName }} {{ colleague.lastName }}, {{ colleague.email }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="colleaguesList.length === 0">
        <div>There are no colleagues to import.</div>
      </ng-container>
    </div>
    <ng-template #loading>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </ng-template>
  </div>
  <div *ngIf="colleaguesList" class="actions">
    <button class="ddd-button" (click)="dialogRef.close(false)">
      {{ importColleaguesDialog.cancel }}
    </button>
    <button *ngIf="colleaguesList.length > 0" class="ddd-button raised" (click)="importColleagues()">
      {{ importColleaguesDialog.okBtn }}
    </button>
  </div>
</div>
