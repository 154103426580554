import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Action, Store, select } from "@ngrx/store";
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  ActionDistributorUpdateCompany,
  SupportDistributorCompanyActionTypes
} from "@app/modules/support/components/support/reducers/distributor-companies.reducer";
import { Subject } from "rxjs";
import { Actions, ofType } from "@ngrx/effects";
import { map, takeUntil } from "rxjs/operators";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { selectorLanguage } from "@app/core/i18n/i18n.reducer";
import { FieldErrorShowComponent } from "../../components/field-error-show/field-error-show.component";

@Component({
    selector: "ddd-edit-distributor-dialog",
    templateUrl: "./edit-distributor-dialog.component.html",
    styleUrls: ["./edit-distributor-dialog.component.scss"],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, FieldErrorShowComponent, TranslateModule]
})
export class EditDistributorDialogComponent implements OnInit {
  public dialog: any;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public form: UntypedFormGroup;
  private newLei: string;

  constructor(
    public dialogRef: MatDialogRef<EditDistributorDialogComponent>,
    private readonly store: Store<any>,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private actions$: Actions<Action>
  ) {
    this.store.pipe(select(selectorLanguage), takeUntil(this.unsubscribe$)).subscribe((lang) => {
      this.translate.setDefaultLang(lang.currentLanguage);
    });
  }

  ngOnInit() {
    this.actions$
      .pipe(
        ofType(SupportDistributorCompanyActionTypes.DISTRIBUTOR_UPDATE_COMPANY_SUCCESS),
        takeUntil(this.unsubscribe$),
        map(() => {
          this.dialogRef.close(this.newLei);
        })
      )
      .subscribe();

    this.form = this.formBuilder.group({
      name: [this.data.distributor.name, Validators.required],
      legalEntityId: [this.data.distributor.legalEntityId, Validators.required]
    });
  }

  onSubmit() {
    const distributorCompanyData = this.getFormData();
    this.newLei = distributorCompanyData.legalEntityId;
    if (this.form.valid) {
      this.store.dispatch(new ActionDistributorUpdateCompany({ distributorCompanyData }));
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  isFieldValid(field: string) {
    return !this.form.get(field).valid && (this.form.get(field).touched || this.form.get(field).dirty);
  }

  private getFormData() {
    return {
      id: this.data.distributor.id,
      name: this.form.get("name").value,
      legalEntityId: this.form.get("legalEntityId").value
    };
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
