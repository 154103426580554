import { Injectable, ViewContainerRef } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";

import { IUser } from "@app/core/auth/identity/user.reducer";
import { SaveInteractionDialogComponent } from "./save-interaction-dialog.component";
import { IDataInteractionsGet, IInteraction } from "@shared/models/interaction.interfaces";

@Injectable({
  providedIn: 'root'
})
export class SaveInteractionDialogService {
  constructor(private dialog: MatDialog) {}

  public open(
    viewContainerRef: ViewContainerRef,
    interaction?: IInteraction,
    interactionsGetData?: IDataInteractionsGet,
    currentUser?: IUser,
    fromAllCompanies?: boolean,
    companyName?: string,
    taskId?: string,
    companyId?: string,
    companyUsers?: any,
    isFM?: any
  ): Observable<any> {
    let dialogRef: MatDialogRef<SaveInteractionDialogComponent>;
    const config = new MatDialogConfig();
    config.panelClass = ["cg-dialog", "middle"];
    config.backdropClass = "cg-dialog-backdrop";
    config.disableClose = true;
    config.disableClose = true;
    config.data = {
      interaction: interaction,
      interactionsGetData: interactionsGetData,
      currentUser: currentUser,
      fromAllCompanies: fromAllCompanies,
      companyName: companyName,
      taskId: taskId,
      companyId: companyId,
      companyUsers: companyUsers,
      isFM: isFM
    };
    config.viewContainerRef = viewContainerRef;

    dialogRef = this.dialog.open(SaveInteractionDialogComponent, config);
    return dialogRef.afterClosed();
  }
}
