<div class="content">
  <div class="title">{{ data.title }}</div>
  <div class="approvers" *ngIf="colleagues; else loading">
    <div class="current-approvers">
      <div class="subtitle">{{ data.subtitleCurrent }}</div>
      <div class="inner-container">
        <div *ngIf="approvers.length === 0" class="no-present-message">No users present.</div>
        <div class="colleague-item current" *ngFor="let approver of approvers">
          <p class="name">{{ approver.firstName }} {{ approver.lastName }}</p>
          <p class="email">{{ approver.email }}</p>
        </div>
      </div>
    </div>
    <div class="colleagues">
      <div class="subtitle" *ngIf="data.component === 'FMAdmin'">
        {{ data.subtitleAvaliable }}
      </div>
      <div class="find-users-header custom-form" *ngIf="data.component === 'adminCompanies'">
        <div class="control-box">
          <input
            class="input-form"
            placeholder="Find Key Contact By Email"
            (click)="$event.stopPropagation()"
            [formControl]="keyContactsSearch"
          />
        </div>
        <button class="ddd-button raised" (click)="findUsers()">Search</button>
      </div>
      <div class="inner-container" *ngIf="colleagues">
        <div class="colleague-item" *ngFor="let colleague of colleagues">
          <button
            class="checkbox-container"
            (click)="addApprover(colleague)"
            [disabled]="
              checkApprovers(colleague) &&
              approvers.length === 1 &&
              data.component !== 'relationship-info' &&
              data.component !== 'adminCompanies'
            "
          >
            <input type="checkbox" [checked]="checkApprovers(colleague)" />
            <span class="checkmark"></span>
          </button>
          <div class="colleague-inner">
            <p class="name">{{ colleague.firstName }} {{ colleague.lastName }}</p>
            <p class="email">{{ colleague.email }}</p>
          </div>
        </div>
      </div>
      <div class="colleague-item no-colleagues" *ngIf="!colleagues">You don't have any colleagues yet</div>
      <button (click)="showForm()" class="ddd-button ddd-text-icon-button add-button">
        <i class="tabler-icon tabler-icon--plus"></i>
        {{ data.addButtonName }}
      </button>

      <div class="form-content" *ngIf="showColleagueForm">
        <form class="custom-form setup-form" [formGroup]="form">
          <div class="label">First Name</div>
          <div class="control-group">
            <div class="control-box">
              <input
                class="input-form"
                type="text"
                id="firstName"
                formControlName="firstName"
                placeholder="first name"
                dddAppCapitalize
              />
              <ddd-field-error-show [displayError]="isFieldValid('firstName')" errorMsg="Please enter first name">
              </ddd-field-error-show>
            </div>
          </div>
          <div class="label">Last Name</div>
          <div class="control-group">
            <div class="control-box">
              <input
                class="input-form"
                type="text"
                id="lastName"
                formControlName="lastName"
                placeholder="last name"
                dddAppCapitalize
              />
              <ddd-field-error-show [displayError]="isFieldValid('lastName')" errorMsg="Please enter last name">
              </ddd-field-error-show>
            </div>
          </div>
          <div class="label">Email</div>
          <div class="control-group">
            <div class="control-box">
              <input class="input-form" type="email" id="email" formControlName="email" placeholder="email" />
              <ddd-field-error-show [displayError]="isFieldValid('email')" errorMsg="Please enter valid email">
              </ddd-field-error-show>
            </div>
          </div>
          <div class="label">
            Landline
            <div
              class="info-icon"
              matTooltip="In case your company uses the extensions, please insert it in the following format: +000000000000 ext. 0000"
              [matTooltipPosition]="'right'"
            ></div>
          </div>
          <div class="control-group">
            <div class="control-box">
              <div class="phone-number-input-container">
                <div class="c-select">
                  <select #countryCodeLandline>
                    <option *ngIf="!landlineCountryAlphaCode" disabled selected value></option>
                    <option
                      value="{{ country.alpha2Code }}"
                      *ngFor="let country of countriesSelect"
                      [selected]="country.alpha2Code === landlineCountryAlphaCode"
                    >
                      {{ country.name }} ({{ country.phonePrefix }})
                    </option>
                  </select>
                </div>
                <input
                  class="input-form"
                  type="text"
                  id="landline"
                  formControlName="landline"
                  placeholder="landline phone"
                  (blur)="validateLandline()"
                  #landline
                  [ngClass]="{ 'empty-error': !validateLandline() }"
                />
              </div>
              <div class="error-msg" *ngIf="!validateLandline()">Please enter a valid landline number.</div>
              <ddd-field-error-show [displayError]="isFieldValid('landline')" errorMsg="Please enter landline">
              </ddd-field-error-show>
            </div>
          </div>
          <div class="label">Mobile Phone</div>
          <div class="control-group">
            <div class="control-box">
              <div class="phone-number-input-container">
                <div class="c-select">
                  <select #mobilePhoneCountryCode>
                    <option *ngIf="!phoneCountryAlphaCode" disabled selected value></option>
                    <option
                      value="{{ country.alpha2Code }}"
                      *ngFor="let country of countriesSelect"
                      [selected]="country.alpha2Code === phoneCountryAlphaCode"
                    >
                      {{ country.name }} ({{ country.phonePrefix }})
                    </option>
                  </select>
                </div>
                <input
                  class="input-form"
                  type="text"
                  id="phone"
                  formControlName="phone"
                  placeholder="mobile phone"
                  (blur)="validatePhoneNumber()"
                  #mobilePhone
                  [ngClass]="{ 'empty-error': !validatePhoneNumber() }"
                />
              </div>
              <div class="error-msg" *ngIf="!validatePhoneNumber()">Please enter a valid phone number.</div>
            </div>
          </div>
        </form>

        <div class="actions">
          <button class="ddd-button" (click)="hideForm()">Cancel</button>
          <button class="ddd-button raised" (click)="onSubmit()">
            {{ data.addButtonName }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="actions">
    <button class="ddd-button" (click)="dialogRef.close(false)">Cancel</button>
    <button class="ddd-button raised" (click)="changeOwner(); dialogRef.close(approvers)">
      <span> {{ data.saveButtonName }}</span>
    </button>
  </div>
</div>

<ng-template #loading>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
