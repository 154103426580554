import { Action } from "@ngrx/store";

export const LANGUAGE_KEY = "LANG";

export enum LanguageActionTypes {
  SET = "[Language] Set",
  ADD = "[Language] Add",
  PERSIST = "[Language] Persist"
}

export class ActionSetLanguage implements Action {
  readonly type = LanguageActionTypes.SET;
  constructor(public payload: { currentLanguage }) {}
}

export class ActionPersistLanguage implements Action {
  readonly type = LanguageActionTypes.PERSIST;
  constructor(public payload: { currentLanguage }) {}
}

export class ActionAddLanguage implements Action {
  readonly type = LanguageActionTypes.ADD;
  constructor(public payload: { listOfLanguages }) {}
}

export type LanguageActions = ActionSetLanguage | ActionPersistLanguage | ActionAddLanguage;

export const initialState: LanguageState = {
  currentLanguage: "en"
};

export const selectorLanguage = (state) => state.lang;

export function languageReducer(state: LanguageState = initialState, action: LanguageActions): LanguageState {
  switch (action.type) {
    case LanguageActionTypes.SET:
      return { ...state, currentLanguage: action.payload.currentLanguage };
    default:
      return state;
  }
}

export interface LanguageState {
  currentLanguage: string;
}
