<div class="footer" *ngIf="showFooter && user">
  <div class="footer-section" *ngIf="!isKYDBrand">
    <div class="footer-img-container"></div>
  </div>
  <div class="footer-section" *ngIf="!isKYDBrand">
    <a href="https://www.carnegroup.com/" target="_blank" rel="noopener" class="footer-section">
      <span> ©{{ footerCopyrightDate }} Carne Group </span>
      <span> | </span>
      <span> All Rights Reserved </span>
      <span> | </span>
      <span>{{ buildVersion }}</span>
    </a>
  </div>
  <div class="footer-section">
    <a *ngIf="isKYDBrand" href="http://www.knowyourdistributor.com.au/" target="_blank" rel="noopener">
      Know Your Distributor
    </a>
    <ng-container *ngIf="isKYDBrand"> | </ng-container>
    <a href="{{ TERMS_AND_CONDITIONS_URL }}" target="_blank" rel="noopener">
      {{ "FOOTER.TERMS_OF_USE" | translate }}
    </a>
    |
    <a href="{{ PRIVACY_POLICY_URL }}" target="_blank" rel="noopener">
      {{ "FOOTER.PRIVACY_POLICY" | translate }}
    </a>
    |
    <a routerLink="/help">
      {{ "FOOTER.SUPPORT" | translate }}
    </a>
  </div>
  <div class="footer-section" *ngIf="isKYDBrand">
    <a href="https://www.carnegroup.com/" target="_blank" rel="noopener" class="footer-section">
      <span> ©{{ footerCopyrightDate }} Carne Group </span>
      <span> | </span>
      <span> All Rights Reserved </span>
      <span> | </span>
      <span>{{ buildVersion }}</span>
    </a>
  </div>
  <div class="footer-section" *ngIf="isKYDBrand">
    <div class="footer-img-container"></div>
  </div>
</div>
