import { Injectable, ViewContainerRef } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";

import { EditDialogComponent } from "./edit-dialog.component";

@Injectable()
export class EditDialogService {
  constructor(private dialog: MatDialog) {}

  public edit(viewContainerRef: ViewContainerRef, name: string, existingNames?: Array<string>): Observable<string> {
    let dialogRef: MatDialogRef<EditDialogComponent>;
    const config = new MatDialogConfig();
    config.panelClass = ["cg-dialog", "middle"];
    config.backdropClass = "cg-dialog-backdrop";
    config.disableClose = true;
    config.data = { name: name, existingNames: existingNames };
    config.viewContainerRef = viewContainerRef;

    dialogRef = this.dialog.open(EditDialogComponent, config);
    return dialogRef.afterClosed();
  }
}
