import { Injectable } from "@angular/core";

import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IUser, IRejectUser} from "@app/core/auth/identity/user.reducer";
import { environment } from "@env/environment";

/**
 * User information service
 *
 * @export
 * @class UserService
 */
@Injectable()
export class UserService {
  /**
   * Base url for API
   *
   * @private
   * @memberof UserService
   */
  private API_URL = environment.client.base_url;

  /**
   * Creates an instance of UserService.
   *
   * @param {HttpClient} httpClient
   * @memberof UserService
   */
  constructor(private httpClient: HttpClient) {}

  /**
   * Retrieve the user information
   *
   * @param {string} baseUrl
   * @return {*}  {Observable<IUser>}
   * @memberof UserService
   */
  retrieveUserData(baseUrl: string): Observable<IUser> {
    return this.httpClient
      .get((baseUrl ? baseUrl : this.API_URL) + "/api/user/info", {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Update the user information
   *
   * @param {IUser} user
   * @param {string} baseUrl
   * @return {*}  {Observable<any>}
   * @memberof UserService
   */
  updateUserData(user: IUser, baseUrl: string): Observable<any> {
    return this.httpClient
      .put((baseUrl ? baseUrl : this.API_URL) + `/api/user`, user, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

    /**
   * Update the user information
   *
   * @param {IUser} user
   * @param {string} baseUrl
   * @return {*}  {Observable<any>}
   * @memberof UserService
   */
     approveUserData(user: IUser, baseUrl: string): Observable<any> {
      return this.httpClient
        .put((baseUrl ? baseUrl : this.API_URL) + `/api/signUp/user/approve`, user, {
          responseType: "text"
        })
        .pipe(map((res: string) => JSON.parse(res)));
    }

  /**
   * Update the user information
   *
   * @param {IRejectUser} user
   * @param {string} baseUrl
   * @return {*}  {Observable<any>}
   * @memberof UserService
   */
   rejectResponsibility(rejectUser: IRejectUser, baseUrl: string): Observable<any> {
    return this.httpClient
      .put((baseUrl ? baseUrl : this.API_URL) + `/api/signUp/user/reject`, rejectUser, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }  

  /**
   * Change user company
   *
   * @param {string} id
   * @param {string} baseUrl
   * @return {*}  {Observable<any>}
   * @memberof UserService
   */
  changeCompany(id: string, baseUrl: string): Observable<any> {
    return this.httpClient
      .put((baseUrl ? baseUrl : this.API_URL) + `/api/user/currentcompany/${id}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Resolve guide
   *
   * @param {string} guidename
   * @param {string} baseUrl
   * @return {*}  {Observable<any>}
   * @memberof UserService
   */
  resolveGuide(guidename: string, baseUrl: string): Observable<any> {
    return this.httpClient
      .put((baseUrl ? baseUrl : this.API_URL) + `/api/user/resolveguide/${guidename}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }


  /**
   * Change Password
   *
   * @return {*}  {Observable<any>}
   * @memberof UserService
   */
   changePassword(): Observable<any> {
    return this.httpClient
      .get(this.API_URL + `/api/user/changepassword`, {
        responseType: "text"
      })
      .pipe(map((res: string) => res));
  }
}
