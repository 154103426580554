import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "ddd-complete-new-cycle-first-visit",
    templateUrl: "./complete-new-cycle-first-visit.component.html",
    styleUrls: ["./complete-new-cycle-first-visit.component.scss"],
    standalone: true
})
export class CompleteNewCycleFirstVisitComponent implements OnInit {
  public dialog: any;

  constructor(
    public dialogRef: MatDialogRef<CompleteNewCycleFirstVisitComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}
}
