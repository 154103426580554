<div class="content">
  <div class="title">Important information for updating your due diligence questionnaire</div>
  <div class="message">
    <div class="step">
      Every question is pre-populated with last year's answers, so you only need to update answers which
      <span>are no longer correct</span>. You don't need to do anything with answers which
      <span>are still correct</span>. Please also check that all attachments are still correct!
    </div>
    <div class="step">
      Once you have checked all of last year's answers (and updated them where needed), you click "Save" and "Send for
      release" at the bottom of each section of the questionnaire - the same process as last year.
    </div>
    <div class="step">
      Please remember that you only have a few weeks to complete this process, so please start reviewing now.
    </div>
  </div>
  <div class="actions">
    <button class="ddd-button raised increased-indents" (click)="dialogRef.close('NewCycleCompleterInformation')">Ok</button>
  </div>
</div>
