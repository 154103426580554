import { Component, ViewContainerRef, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TooltipDialogOptions, TooltipDialogContentType } from "./tooltip-dialog.model";
import { NgIf, NgFor } from "@angular/common";

@Component({
    selector: "ddd-tooltip-dialog",
    templateUrl: "./tooltip-dialog.component.html",
    styleUrls: ["./tooltip-dialog.component.scss"],
    standalone: true,
    imports: [NgIf, NgFor]
})
export class TooltipDialogComponent {
  public options: TooltipDialogOptions;
  public grid: any[];
  constructor(public dialogRef: MatDialogRef<TooltipDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.options = data.options;
    if (this.options.type == TooltipDialogContentType.GRID) {
      this.grid = [];
      this.options.content.forEach((contentRow) => {
        const row = [];
        for (let colIndex = 0; colIndex < contentRow.length; colIndex++) {
          row.push({
            flex: this.options.flex[colIndex],
            content: contentRow[colIndex]
          });
        }
        this.grid.push(row);
      });
    }
  }

  public close() {
    this.dialogRef.close();
  }
}
