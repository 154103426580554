import { HttpErrorResponse } from "@angular/common/http";
import { Action, createFeatureSelector, createSelector } from "@ngrx/store";
import { Questionnaire, IGroup, Field, IAuditTrail } from "@app/shared/models";
import { IAppStateQCReleaser } from "@modules/qc-release/qc-release.module";

/**
 * Releaser actions types
 */
export enum ReleaserActionTypes {
  QUESTIONNAIRE_RELEASER_GET = "[QuestionnaireReleaser] Get",
  QUESTIONNAIRE_RELEASER_GET_SUCCESS = "[QuestionnaireReleaser] Get Success",
  QUESTIONNAIRE_RELEASER_GET_ERROR = "[QuestionnaireReleaser] Get Error",
  QUESTIONNAIRE_UPDATE_STATE = "[QuestionnaireReleaser] Update State",
  QUESTIONNAIRE_UPDATE_ACTIVE_TAB = "[QuestionnaireReleaser] Update Active Tab",
  QUESTIONNAIRE_UPDATE_GROUP = "[QuestionnaireReleaser] Update IGroup in Section",
  QUESTIONNAIRE_APPROVED_GROUP = "[QuestionnaireReleaser] IGroup Was Approved",
  QUESTIONNAIRE_RELEASED_GROUP = "[QuestionnaireReleaser] IGroup Was Released",
  UPDATE_FILTER_MESSAGE = "[QuestionnaireReleaser] Update Filter Message",
  QUESTIONNAIRE_RELEASE_SET_TRIGGERS = "[QuestionnaireReleaser] Set Triggers",
  QUESTIONNAIRE_INIT_PERSON_QUESTION_FIELDS_ANSWERS_RELEASER = "[QuestionnaireReleaser] Init Person Question Fields Answers",
  QC_RELEASER_ADD_APPROVED_GROUP = "[QuestionnaireReleaser] Add Approved Group",
  QC_RELEASER_RECALL_GROUP = "[QuestionnaireReleaser] Recall Group",
  QC_RELEASER_EXPORT_PDF = "[QuestionnaireReleaser] Export Pdf",
  QC_RELEASER_EXPORT_PDF_SUCCESS = "[QuestionnaireReleaser] Export Pdf Success",
  QC_RELEASER_EXPORT_PDF_ERROR = "[QuestionnaireReleaser] Export Pdf Error",
  QUESTIONNAIRE_RELEASE_GET_GROUP_HISTORY = "[QuestionnaireReleaser] Get Group History",
  QUESTIONNAIRE_RELEASE_UPDATE_GROUP_HISTORY = "[QuestionnaireReleaser] Update Group History",
  QC_RELEASER_RECALL_DDQ = "[QuestionnaireReleaser] Recall DDQ",
  QC_RELEASER_CLEAR_DIALOG_STATUS = "[QuestionnaireReleaser] Clear Status For Showing Release Dialog",
  QUESTIONNAIRE_RELEASE_GET_HISTORY = "[QuestionnaireReleaser] Get History",
  QUESTIONNAIRE_RELEASE_UPDATE_HISTORY = "[QuestionnaireReleaser] Update History",
  QUESTIONNAIRE_RELEASE_GET_CYCLES = "[QuestionnaireReleaser] Get Cycles",
  QUESTIONNAIRE_RELEASE_UPDATE_CYCLES = "[QuestionnaireReleaser] Update Cycles"
}

export class ActionExportPdfRetrieve implements Action {
  readonly type = ReleaserActionTypes.QC_RELEASER_EXPORT_PDF;
  constructor(public payload?: { password: string; docName: string; ddqId?: string }) {}
}

export class ActionExportPdfRetrieveSuccess implements Action {
  readonly type = ReleaserActionTypes.QC_RELEASER_EXPORT_PDF_SUCCESS;
  constructor(public payload: { pdf: any }) {}
}

export class ActionExportPdfRetrieveError implements Action {
  readonly type = ReleaserActionTypes.QC_RELEASER_EXPORT_PDF_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionAddApprovedGroup implements Action {
  readonly type = ReleaserActionTypes.QC_RELEASER_ADD_APPROVED_GROUP;
  constructor(public payload?: { sectionId: string }) {}
}

export class ActionRecallGroup implements Action {
  readonly type = ReleaserActionTypes.QC_RELEASER_RECALL_GROUP;
  constructor(public payload?: { sectionId: string }) {}
}

export class ActionQuestionnaireReleaserGet implements Action {
  readonly type = ReleaserActionTypes.QUESTIONNAIRE_RELEASER_GET;
  constructor(public payload?: {}) {}
}

export class ActionQuestionnaireReleaserGetSuccess implements Action {
  readonly type = ReleaserActionTypes.QUESTIONNAIRE_RELEASER_GET_SUCCESS;
  constructor(public payload: { questionnaire: Questionnaire }) {}
}

export class ActionQuestionnaireReleaserGetError implements Action {
  readonly type = ReleaserActionTypes.QUESTIONNAIRE_RELEASER_GET_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionQRUpdateActiveTab implements Action {
  readonly type = ReleaserActionTypes.QUESTIONNAIRE_UPDATE_ACTIVE_TAB;
  constructor(public payload: { tabId: string }) {}
}

export class ActionQRUpdateGroup implements Action {
  readonly type = ReleaserActionTypes.QUESTIONNAIRE_UPDATE_GROUP;
  constructor(public payload: { group: IGroup }) {}
}

export class ActionQRGroupWasApproved implements Action {
  readonly type = ReleaserActionTypes.QUESTIONNAIRE_APPROVED_GROUP;
  constructor(public payload: { group: IGroup; isRecall?: boolean }) {}
}

export class ActionQRGroupWasReleased implements Action {
  readonly type = ReleaserActionTypes.QUESTIONNAIRE_RELEASED_GROUP;
  constructor(public payload: { sections: IGroup[] }) {}
}

export class ActionQRUpdateFilterMessage implements Action {
  readonly type = ReleaserActionTypes.UPDATE_FILTER_MESSAGE;
  constructor(public payload: { filterMessage: boolean }) {}
}

export class ActionQRSetTriggers implements Action {
  readonly type = ReleaserActionTypes.QUESTIONNAIRE_RELEASE_SET_TRIGGERS;
  constructor(public payload: { groupId?: string; sectionId?: string }) {}
}

export class ActionQRGetHistoryGroup implements Action {
  readonly type = ReleaserActionTypes.QUESTIONNAIRE_RELEASE_GET_GROUP_HISTORY;
  constructor(public payload: { group: IGroup; questionnaireId: string; sectionId: string; }) {}
}

export class ActionQRUpdateHistoryGroup implements Action {
  readonly type = ReleaserActionTypes.QUESTIONNAIRE_RELEASE_UPDATE_GROUP_HISTORY;
  constructor(public payload: { group: IGroup; actions: IAuditTrail[] }) {}
}

export class ActionQCInitPersonQuestionFieldsAnswersReleaser implements Action {
  readonly type = ReleaserActionTypes.QUESTIONNAIRE_INIT_PERSON_QUESTION_FIELDS_ANSWERS_RELEASER;
  constructor(
    public payload: {
      sectionId: string;
      questionKey: string;
      groupId: string;
      fields: Field[];
    }
  ) {}
}

export class ActionReleaserRecallDDQ implements Action {
  readonly type = ReleaserActionTypes.QC_RELEASER_RECALL_DDQ;
  constructor(public payload?: {}) {}
}

export class ActionQRClearDialogStatus implements Action {
  readonly type = ReleaserActionTypes.QC_RELEASER_CLEAR_DIALOG_STATUS;
  constructor(public payload?: {}) {}
}

export class ActionQRGetHistory implements Action {
  readonly type = ReleaserActionTypes.QUESTIONNAIRE_RELEASE_GET_HISTORY;
  public constructor(
    public payload: {
      questionnaireId?: string;
    }
  ) {}
}

export class ActionQRUpdateHistory implements Action {
  readonly type = ReleaserActionTypes.QUESTIONNAIRE_RELEASE_UPDATE_HISTORY;
  constructor(public payload: { actions: IAuditTrail[] }) {}
}

export class ActionQRGetCycles implements Action {
  readonly type = ReleaserActionTypes.QUESTIONNAIRE_RELEASE_GET_CYCLES;
  public constructor(public payload?: {}) {}
}

export class ActionQRUpdateCycles implements Action {
  readonly type = ReleaserActionTypes.QUESTIONNAIRE_RELEASE_UPDATE_CYCLES;
  constructor(public payload: { cycles: any }) {}
}

/**
 * Releaser actions types
 */
export type ReleaserActions =
  | ActionQuestionnaireReleaserGet
  | ActionQuestionnaireReleaserGetSuccess
  | ActionQuestionnaireReleaserGetError
  | ActionQRUpdateActiveTab
  | ActionQRUpdateGroup
  | ActionQRGroupWasApproved
  | ActionQRGroupWasReleased
  | ActionQRUpdateFilterMessage
  | ActionQRSetTriggers
  | ActionQCInitPersonQuestionFieldsAnswersReleaser
  | ActionAddApprovedGroup
  | ActionRecallGroup
  | ActionExportPdfRetrieve
  | ActionExportPdfRetrieveSuccess
  | ActionExportPdfRetrieveError
  | ActionQRGetHistoryGroup
  | ActionQRUpdateHistoryGroup
  | ActionReleaserRecallDDQ
  | ActionQRClearDialogStatus
  | ActionQRGetHistory
  | ActionQRUpdateHistory
  | ActionQRGetCycles
  | ActionQRUpdateCycles;

/**
 * Releaser initial state
 */
export const initialReleaserState: IQuestionnaireReleaserState = {
  loading: false,
  filterMessage: false
};

/**
 * Releaser state selector
 */
const featureSelector = createFeatureSelector<any>("qcRelease");
export const selectorReleaserState = createSelector(featureSelector, (state) => {
  if (state) {
    return state.releaser;
  }
});
export const selectorReleaserQuestionnaireState = createSelector(selectorReleaserState, (state) => {
  if (state) {
    return state.questionnaire;
  }
});
export const selectNeedsPreliminary = createSelector(selectorReleaserState, (state) => {
  if (state) {
    return state.needsAnswerPreliminary;
  }
});
export const selectorLoading = createSelector(selectorReleaserState, (state) => {
  if (state) {
    return state.loading;
  }
});
export const selectorCyclesQuestionnaire = createSelector(selectorReleaserState, (state) => {
  if (state) {
    return state.cyclesQuestionnaire;
  }
});

/**
 * Reducer function for releaser state.
 */
export function qcReleaserReducer(
  state: IQuestionnaireReleaserState = initialReleaserState,
  action: ReleaserActions
): IQuestionnaireReleaserState {
  switch (action.type) {
    case ReleaserActionTypes.QC_RELEASER_ADD_APPROVED_GROUP:
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          sections: state.questionnaire.sections.map((item) => {
            if (item.id !== action.payload.sectionId) {
              return item;
            }
            return {
              ...item,
              approvedGroups: item.approvedGroups + 1
            };
          })
        }
      };
    case ReleaserActionTypes.QC_RELEASER_RECALL_GROUP:
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          sections: state.questionnaire.sections.map((item) => {
            if (item.id !== action.payload.sectionId) {
              return item;
            }
            return {
              ...item,
              approvedGroups: item.approvedGroups - 1
            };
          })
        }
      };

    case ReleaserActionTypes.QC_RELEASER_EXPORT_PDF:
      return {
        ...state,
        loading: true,
        pdf: null,
        error: null
      };

    case ReleaserActionTypes.QC_RELEASER_EXPORT_PDF_SUCCESS:
      return {
        ...state,
        loading: false,
        pdf: action.payload.pdf,
        error: null
      };

    case ReleaserActionTypes.QC_RELEASER_EXPORT_PDF_ERROR:
      return {
        ...state,
        loading: true,
        pdf: null,
        error: action.payload.error
      };

    case ReleaserActionTypes.QUESTIONNAIRE_RELEASER_GET:
      return {
        ...state,
        loading: true,
        questionnaire: null,
        needsAnswerPreliminary: false,
        error: null
      };
    case ReleaserActionTypes.QUESTIONNAIRE_RELEASER_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        questionnaire: action.payload.questionnaire,
        needsAnswerPreliminary: action.payload.questionnaire && action.payload.questionnaire.needsAnswerPreliminary,
        error: null,
        activeTabId: action.payload.questionnaire.sections[0].id
      };
    case ReleaserActionTypes.QUESTIONNAIRE_RELEASER_GET_ERROR:
      return {
        ...state,
        loading: false,
        questionnaire: null,
        needsAnswerPreliminary: false,
        error: action.payload.error
      };
    case ReleaserActionTypes.QUESTIONNAIRE_RELEASED_GROUP:
      return {
        ...state,
        loading: false,
        questionnaire: Object.assign({}, state.questionnaire, {
          status: "Released"
        })
      };
    case ReleaserActionTypes.QUESTIONNAIRE_APPROVED_GROUP:
      return {
        ...state,
        loading: false,
        questionnaire: Object.assign({}, state.questionnaire, {
          showReleaseDialog: action.payload.isRecall
            ? false
            : state.questionnaire.approvedGroups + 1 === state.questionnaire.totalGroups,
          approvedGroups: action.payload.isRecall
            ? state.questionnaire.approvedGroups - 1
            : state.questionnaire.approvedGroups + 1
        })
      };
    case ReleaserActionTypes.QUESTIONNAIRE_UPDATE_ACTIVE_TAB:
      return {
        ...state,
        loading: false,
        activeTabId: action.payload.tabId
      };
    case ReleaserActionTypes.QUESTIONNAIRE_UPDATE_GROUP:
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          sections: state.questionnaire.sections.map((item) => {
            if (item.id !== action.payload.group.sectionId) {
              return item;
            }
            return {
              ...item,
              groups: item.groups.map((group) => {
                if (group.id !== action.payload.group.id) {
                  return group;
                }
                return Object.assign({}, group, action.payload.group);
              })
            };
          })
        }
      };
    case ReleaserActionTypes.UPDATE_FILTER_MESSAGE:
      return {
        ...state,
        loading: false,
        filterMessage: action.payload.filterMessage
      };
    case ReleaserActionTypes.QUESTIONNAIRE_RELEASE_SET_TRIGGERS:
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          initTriggers: true,
          sections: state.questionnaire.sections.map((item) => {
            if (action.payload.sectionId && item.id !== action.payload.sectionId) {
              return item;
            }
            return {
              ...item,
              groups: item.groups.map((group) => {
                if (action.payload.groupId && group.id !== action.payload.groupId) {
                  return group;
                }
                const relevanceQuestions = [];
                return {
                  ...group,
                  questions: group.questions.map((question) => {
                    if (question.relevanceTriggers.length === 0) {
                      return question;
                    }
                    let allRelevanceTriggersPresent = false;
                    let triggersQuestionInGroup = false;
                    let hasRelevanceParent = false;
                    const allAndRelevanceTriggersStatuses = [];
                    // Handle only "SelectionTrigger" type
                    question.relevanceTriggers.forEach((relevanceTrigger) => {
                      let simpleRelevanceTriggersStatus = false;
                      let complexOrRelevanceTriggersStatus = false;
                      let complexAndRelevanceTriggersStatus = false;
                      /**
                       * Relevance trigger type is 'Simple'
                       */
                      if (relevanceTrigger.type === "Simple") {
                        group.questions.forEach((triggerQuestion) => {
                          /**
                           * simpleRelevanceTriggersStatus is true don't continue map we have result
                           */
                          if (!simpleRelevanceTriggersStatus) {
                            if (triggerQuestion.key === relevanceTrigger.triggers[0].questionKey) {
                              if (triggerQuestion.answer.answers !== null) {
                                triggerQuestion.answer.answers.forEach((option) => {
                                  if (option.key === relevanceTrigger.triggers[0].selectedOptionKey) {
                                    /**
                                     * simpleRelevanceTriggersStatus set true when answer present
                                     */
                                    simpleRelevanceTriggersStatus = true;
                                  }

                                  if (option.countryId || option.CountryId) {
                                    const countryId = option.countryId || option.CountryId;
                                    if (countryId === relevanceTrigger.triggers[0].selectedOptionKey) {
                                      /**
                                       * simpleRelevanceTriggersStatus set true when answer present
                                       */
                                      simpleRelevanceTriggersStatus = true;
                                    }
                                  }    
                                });
                              }
                              if (relevanceQuestions.indexOf(triggerQuestion.key) > -1) {
                                hasRelevanceParent = true;
                                if (relevanceQuestions.indexOf(question.key) === -1) {
                                  relevanceQuestions.push(question.key);
                                }
                              }
                              triggersQuestionInGroup = true;
                            }
                          }
                        });
                        /**
                         * simpleRelevanceTriggersStatus = true; answer present
                         * allRelevanceTriggersPresent set true when answer present
                         */
                        simpleRelevanceTriggersStatus
                          ? (allRelevanceTriggersPresent = true)
                          : (allRelevanceTriggersPresent = false);
                      }

                      /**
                       * Relevance trigger type is 'Complex' and operator 'OR'
                       */
                      if (relevanceTrigger.type === "Complex" && relevanceTrigger.operator === "OR") {
                        group.questions.forEach((triggerQuestion) => {
                          /**
                           * complexOrRelevanceTriggersStatus is true don't continue map we have result
                           */
                          if (!complexOrRelevanceTriggersStatus) {
                            relevanceTrigger.triggers.forEach((trigger) => {
                              if (triggerQuestion.key === trigger.questionKey) {
                                if (triggerQuestion.answer.answers !== null) {
                                  triggerQuestion.answer.answers.forEach((option) => {
                                    if (option.key === trigger.selectedOptionKey) {
                                      /**
                                       * complexOrRelevanceTriggersStatus set true when one answer present
                                       */
                                      complexOrRelevanceTriggersStatus = true;
                                    }
                                  });
                                }
                                if (relevanceQuestions.indexOf(triggerQuestion.key) > -1) {
                                  hasRelevanceParent = true;
                                  if (relevanceQuestions.indexOf(question.key) === -1) {
                                    relevanceQuestions.push(question.key);
                                  }
                                }
                                triggersQuestionInGroup = true;
                              }
                            });
                          }
                        });
                        /**
                         * simpleRelevanceTriggersStatus = true; minimum one answer present
                         * allRelevanceTriggersPresent set true when minimum one answer present
                         */
                        complexOrRelevanceTriggersStatus
                          ? (allRelevanceTriggersPresent = true)
                          : (allRelevanceTriggersPresent = false);
                      }

                      /**
                       * Relevance trigger type is 'Complex' and operator 'AND'
                       */
                      if (relevanceTrigger.type === "Complex" && relevanceTrigger.operator === "AND") {
                        group.questions.forEach((triggerQuestion) => {
                          /**
                           * complexAndRelevanceTriggersStatus is true don't continue map we have result
                           */
                          if (!complexAndRelevanceTriggersStatus) {
                            relevanceTrigger.triggers.forEach((trigger) => {
                              if (triggerQuestion.key === trigger.questionKey) {
                                if (
                                  triggerQuestion.answer !== null &&
                                  triggerQuestion.answer.answers &&
                                  triggerQuestion.answer.answers.length !== 0
                                ) {
                                  let selectedOption = false;
                                  triggerQuestion.answer.answers.forEach((option) => {
                                    if (option.key === trigger.selectedOptionKey) {
                                      selectedOption = true;
                                    }
                                  });
                                  if (!selectedOption) {
                                    /**
                                     * complexAndRelevanceTriggersStatus set true when no selected option
                                     */
                                    complexAndRelevanceTriggersStatus = true;
                                  }
                                } else {
                                  /**
                                   * complexAndRelevanceTriggersStatus set true when minimum one answer is null
                                   */
                                  complexAndRelevanceTriggersStatus = true;
                                }
                                if (relevanceQuestions.indexOf(triggerQuestion.key) > -1) {
                                  hasRelevanceParent = true;
                                  if (relevanceQuestions.indexOf(question.key) === -1) {
                                    relevanceQuestions.push(question.key);
                                  }
                                }
                                triggersQuestionInGroup = true;
                              }
                            });
                          }
                        });
                        allAndRelevanceTriggersStatuses.push(complexAndRelevanceTriggersStatus);

                        /**
                         * allAndRelevanceTriggersStatuses has false element one trigger is active
                         * allRelevanceTriggersPresent set true when all answers present
                         */
                        allAndRelevanceTriggersStatuses.indexOf(false) === -1
                          ? (allRelevanceTriggersPresent = false)
                          : (allRelevanceTriggersPresent = true);
                      }
                    });
                    if (
                      triggersQuestionInGroup &&
                      !allRelevanceTriggersPresent &&
                      relevanceQuestions.indexOf(question.key) === -1
                    ) {
                      relevanceQuestions.push(question.key);
                    }
                    return {
                      ...question,
                      /**
                       * hideTriggers set true when we don't have all answers
                       */
                      hideTriggers: hasRelevanceParent
                        ? true
                        : triggersQuestionInGroup
                        ? !allRelevanceTriggersPresent
                        : false
                    };
                  })
                };
              })
            };
          })
        }
      };

    case ReleaserActionTypes.QUESTIONNAIRE_INIT_PERSON_QUESTION_FIELDS_ANSWERS_RELEASER:
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          sections: state.questionnaire.sections.map((item) => {
            if (item.id !== action.payload.sectionId) {
              return item;
            }
            return {
              ...item,
              groups: item.groups.map((group) => {
                if (group.id !== action.payload.groupId) {
                  return group;
                }
                return {
                  ...group,
                  questions: group.questions.map((question) => {
                    if (question.key !== action.payload.questionKey) {
                      return question;
                    }
                    return {
                      ...question,
                      answer: Object.assign({}, question.answer, {
                        summaryAnswers: [
                          {
                            fieldAnswers: action.payload.fields
                          }
                        ]
                      })
                    };
                  })
                };
              })
            };
          })
        }
      };

    case ReleaserActionTypes.QUESTIONNAIRE_RELEASE_GET_GROUP_HISTORY:
      return {
        ...state,
        loading: false,
        questionnaire: {
          ...state.questionnaire,
          sections: state.questionnaire.sections.map((item) => {
            if (item.id !== action.payload.group.sectionId) {
              return item;
            }
            return {
              ...item,
              groups: item.groups.map((group) => {
                if (
                  group.id !== action.payload.group.id ||
                  (group.id === action.payload.group.id && group.historyActions)
                ) {
                  return group;
                }
                return {
                  ...group,
                  historyActions: null
                };
              })
            };
          })
        }
      };

    case ReleaserActionTypes.QUESTIONNAIRE_RELEASE_UPDATE_GROUP_HISTORY:
      return {
        ...state,
        loading: false,
        questionnaire: {
          ...state.questionnaire,
          sections: state.questionnaire.sections.map((item) => {
            if (item.id !== action.payload.group.sectionId) {
              return item;
            }
            return {
              ...item,
              groups: item.groups.map((group) => {
                if (group.id !== action.payload.group.id) {
                  return group;
                }
                return {
                  ...group,
                  historyActions: action.payload.actions
                };
              })
            };
          })
        }
      };

    case ReleaserActionTypes.QC_RELEASER_CLEAR_DIALOG_STATUS:
      return {
        ...state,
        loading: false,
        questionnaire: Object.assign({}, state.questionnaire, {
          showReleaseDialog: false
        })
      };

    case ReleaserActionTypes.QUESTIONNAIRE_RELEASE_GET_HISTORY:
      return {
        ...state,
        loading: false,
        questionnaire: {
          ...state.questionnaire,
          historyActions: state.questionnaire.historyActions ? state.questionnaire.historyActions : null
        }
      };

    case ReleaserActionTypes.QUESTIONNAIRE_RELEASE_UPDATE_HISTORY:
      return {
        ...state,
        loading: false,
        questionnaire: {
          ...state.questionnaire,
          historyActions: action.payload.actions
        }
      };

    case ReleaserActionTypes.QUESTIONNAIRE_RELEASE_GET_CYCLES:
      return {
        ...state,
        loading: false,
        cyclesQuestionnaire: null
      };

    case ReleaserActionTypes.QUESTIONNAIRE_RELEASE_UPDATE_CYCLES:
      return {
        ...state,
        loading: false,
        cyclesQuestionnaire: action.payload.cycles
      };

    default:
      return state;
  }
}

/**
 * Interface for releaser state.
 */
export interface IQuestionnaireReleaserState {
  loading: boolean;
  questionnaire?: Questionnaire;
  cyclesQuestionnaire?: any;
  error?: HttpErrorResponse;
  activeTabId?: string;
  filterMessage?: boolean;
  pdf?: any;
  needsAnswerPreliminary?: boolean;
}
