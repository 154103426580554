import { Action, createFeatureSelector, createSelector } from "@ngrx/store";

export const SETTINGS_KEY = "SETTINGS";

export interface ISettingsState {
  theme: string;
  autoNightMode: boolean;
  pageAnimations: boolean;
  pageAnimationsDisabled: boolean;
  elementsAnimations: boolean;
  hideHeader: boolean;
  hideFooter: boolean;
}

export const initialState: ISettingsState = {
  theme: "DDD-THEME",
  autoNightMode: false,
  pageAnimations: true,
  pageAnimationsDisabled: false,
  elementsAnimations: true,
  hideHeader: false,
  hideFooter: false
};

export enum SettingsActionTypes {
  CHANGE_THEME = "[Settings] Change Theme",
  CHANGE_ANIMATIONS_PAGE_DISABLED = "[Settings] Change Animations Page Disabled",
  PERSIST = "[Settings] Persist",
  SETTINGS_TOGGLE_HEADER = "[Settings] Toggle Header",
  SETTINGS_TOGGLE_FOOTER = "[Settings] Toggle Footer"
}

export class ActionSettingsChangeTheme implements Action {
  readonly type = SettingsActionTypes.CHANGE_THEME;
  constructor(public payload: { theme: string }) {}
}

export class ActionSettingsChangeAnimationsPageDisabled implements Action {
  readonly type = SettingsActionTypes.CHANGE_ANIMATIONS_PAGE_DISABLED;
  constructor(public payload: { pageAnimationsDisabled: boolean }) {}
}

export class ActionSettingsPersist implements Action {
  readonly type = SettingsActionTypes.PERSIST;
  constructor(public payload: { settings: ISettingsState }) {}
}

export class ActionSettingsToggleHeader implements Action {
  readonly type = SettingsActionTypes.SETTINGS_TOGGLE_HEADER;
  constructor(public payload: boolean) {}
}

export class ActionSettingsToggleFooter implements Action {
  readonly type = SettingsActionTypes.SETTINGS_TOGGLE_FOOTER;
  constructor(public payload: boolean) {}
}

export type SettingsActions =
  | ActionSettingsChangeTheme
  | ActionSettingsChangeAnimationsPageDisabled
  | ActionSettingsPersist
  | ActionSettingsToggleHeader
  | ActionSettingsToggleFooter;

const featureSettingsSelector = createFeatureSelector<ISettingsState>("settings");
export const selectorSettings = createSelector(featureSettingsSelector, (state) => state || { theme: "" });

export const selectorHideHeader = createSelector(featureSettingsSelector, (state) => state.hideHeader || false);

export const selectorHideFooter = createSelector(featureSettingsSelector, (state) => state.hideFooter || false);

export function settingsReducer(state: ISettingsState = initialState, action: SettingsActions): ISettingsState {
  switch (action.type) {
    case SettingsActionTypes.CHANGE_THEME:
      return { ...state, theme: action.payload.theme };

    case SettingsActionTypes.SETTINGS_TOGGLE_HEADER:
      return {
        ...state,
        hideHeader: action.payload
      };

    case SettingsActionTypes.SETTINGS_TOGGLE_FOOTER:
      return {
        ...state,
        hideFooter: action.payload
      };

    default:
      return state;
  }
}
