import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { EmailMetadata } from "@app/modules/support/components/support/reducers/support.reducer";

@Component({
    selector: "ddd-resend-email-confirm-dialog",
    templateUrl: "./resend-email-confirm-dialog.component.html",
    standalone: true
})
export class ResendEmailConfirmDialogComponent implements OnInit {
  public dialog: any;
  public metadata: EmailMetadata;
  public user: any;

  constructor(
    public dialogRef: MatDialogRef<ResendEmailConfirmDialogComponent>,
    private readonly translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.metadata = data.metadata;
    this.user = data.user;
  }

  ngOnInit() {}
}
