import { NgModule } from "@angular/core";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";

import { SharedModule } from "@app/shared";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { ProfileRoutingModule } from "@modules/profile/profile-routing.module";
import { ProfileComponent } from "@app/modules/profile/components";
import { countryQuestionReducer } from "@shared/modules/questions-module-shared/components/country-question/reducers";
import { CountriesEffect } from "@shared/modules/questions-module-shared/components/country-question/effects";
import { CountryQuestionsService } from "@shared/modules/questions-module-shared/components/country-question/services";
import { MultiTranslateHttpLoader } from "@app/core/utils/customTranslateLoader";

export function translateHttpLoader(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [{ prefix: "./assets/i18n/profile/", suffix: ".json" }]);
}

@NgModule({
    imports: [
        ProfileRoutingModule,
        SharedModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: translateHttpLoader,
                deps: [HttpClient]
            },
            isolate: true
        }),
        StoreModule.forFeature("countries", {
            countriesList: countryQuestionReducer
        }),
        EffectsModule.forFeature([CountriesEffect]),
        ProfileComponent
    ],
    exports: [ProfileComponent],
    providers: [CountryQuestionsService]
})
export class ProfileModule {}
