import { HttpErrorResponse } from "@angular/common/http";
import { Action, createFeatureSelector, createSelector } from "@ngrx/store";
import { IUser } from "@app/core/auth/identity/user.reducer";
import {
  IInteractionFormModel,
  ISearchModel,
  IDataInteractionsGet,
  ICompaniesInteractionsResponse
} from "@shared/models/interaction.interfaces";

/**
 * Company interactions action types
 */
export enum CompanyInteractionsActionTypes {
  INTERACTIONS_GET = "[Interactions] Get",
  INTERACTIONS_GET_SUCCESS = "[Interactions] Get Success",
  INTERACTIONS_GET_ERROR = "[Interactions] Get Error",
  INTERACTION_ADD = "[Interactions] Add interaction",
  INTERACTION_SAVE = "[Interactions] Save interaction",
  INTERACTION_DELETE = "[Interactions] Delete interaction",
  COMPANY_USERS_GET = "[Interactions] Get Company Users",
  COMPANY_USERS_GET_SUCCESS = "[Interactions] Get Company Users Success",
  COMPANY_USERS_GET_ERROR = "[Interactions] Get Company Users Error",
  INTERACTIONS_EXPORT = "[Interactions] Export"
}

export class ActionInteractionsGet implements Action {
  readonly type = CompanyInteractionsActionTypes.INTERACTIONS_GET;
  constructor(
    public payload?: {
      page: number;
      size: number;
      companyId: string;
      search: ISearchModel;
    }
  ) {}
}

export class ActionInteractionsGetSuccess implements Action {
  readonly type = CompanyInteractionsActionTypes.INTERACTIONS_GET_SUCCESS;
  constructor(public payload: { interactionsData: ICompaniesInteractionsResponse }) {}
}

export class ActionInteractionsGetError implements Action {
  readonly type = CompanyInteractionsActionTypes.INTERACTIONS_GET_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionInteractionAdd implements Action {
  readonly type = CompanyInteractionsActionTypes.INTERACTION_ADD;
  constructor(
    public payload?: {
      interaction: IInteractionFormModel;
      interactionsGetData: IDataInteractionsGet;
    }
  ) {}
}

export class ActionInteractionSave implements Action {
  readonly type = CompanyInteractionsActionTypes.INTERACTION_SAVE;
  constructor(
    public payload?: {
      interaction: IInteractionFormModel;
      interactionsGetData: IDataInteractionsGet;
    }
  ) {}
}

export class ActionInteractionDelete implements Action {
  readonly type = CompanyInteractionsActionTypes.INTERACTION_DELETE;
  constructor(public payload: { id: string; interactionsGetData: IDataInteractionsGet }) {}
}

export class ActionCompanyUsersGet implements Action {
  readonly type = CompanyInteractionsActionTypes.COMPANY_USERS_GET;
  constructor(
    public payload?: {
      companyId: string;
    }
  ) {}
}

export class ActionCompanyUsersGetSuccess implements Action {
  readonly type = CompanyInteractionsActionTypes.COMPANY_USERS_GET_SUCCESS;
  constructor(public payload: { companyUsers: IUser[] }) {}
}

export class ActionCompanyUsersGetError implements Action {
  readonly type = CompanyInteractionsActionTypes.COMPANY_USERS_GET_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionInteractionExport {
  readonly type = CompanyInteractionsActionTypes.INTERACTIONS_EXPORT;
  constructor(
    public payload: {
      companyId: string;
    }
  ) {}
}

/**
 * Company interactions action types.
 */
export type CompanyInteractionsActions =
  | ActionInteractionsGet
  | ActionInteractionsGetSuccess
  | ActionInteractionsGetError
  | ActionInteractionAdd
  | ActionInteractionSave
  | ActionInteractionDelete
  | ActionCompanyUsersGet
  | ActionCompanyUsersGetSuccess
  | ActionCompanyUsersGetError
  | ActionInteractionExport;

/**
 * Company interactions initial state
 */
export const initialState: CompanyInteractionsState = {
  loading: false
};

const featureReviewFMSelector = createFeatureSelector<any>("interactionsCompanyState");

export const selectorInteractionsCompanyState = createSelector(featureReviewFMSelector, (state) => {
  if (state) {
    return state.interactionsCompany;
  }
});

export const selectorCompanyInteractionsData = createSelector(selectorInteractionsCompanyState, (state) => {
  if (state) {
    return state.interactionsData;
  }
});

export const selectorCompanyUsersData = createSelector(selectorInteractionsCompanyState, (state) => {
  if (state) {
    return state.companyUsers;
  }
});

export const selectorCompanyInteractionsLoading = createSelector(selectorInteractionsCompanyState, (state) => {
  if (state) {
    return state.loading;
  }
});

/**
 * Reducer function for company interactions state.
 */
export function companyInteractionsReducer(
  state: CompanyInteractionsState = initialState,
  action: CompanyInteractionsActions
): CompanyInteractionsState {
  switch (action.type) {
    case CompanyInteractionsActionTypes.INTERACTIONS_GET:
      return {
        ...state,
        loading: true,
        interactionsData: null,
        error: null
      };
    case CompanyInteractionsActionTypes.INTERACTIONS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        interactionsData: action.payload.interactionsData,
        error: null
      };
    case CompanyInteractionsActionTypes.INTERACTIONS_GET_ERROR:
      return {
        ...state,
        loading: false,
        interactionsData: null,
        error: action.payload.error
      };
    case CompanyInteractionsActionTypes.COMPANY_USERS_GET:
      return {
        ...state,
        loading: true,
        companyUsers: null,
        error: null
      };
    case CompanyInteractionsActionTypes.COMPANY_USERS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        companyUsers: action.payload.companyUsers,
        error: null
      };
    case CompanyInteractionsActionTypes.COMPANY_USERS_GET_ERROR:
      return {
        ...state,
        loading: false,
        companyUsers: null,
        error: action.payload.error
      };
    default:
      return state;
  }
}

/**
 * Interface for company interactions state.
 */
export interface CompanyInteractionsState {
  loading: boolean;
  interactionsData?: ICompaniesInteractionsResponse;
  companyUsers?: IUser[];
  error?: HttpErrorResponse;
}
