<div class="content">
  <div class="title">
    {{
      customField
        ? ("CUSTOM_FIELD_SAVE_DIALOG.EDIT_FIELD" | translate)
        : ("CUSTOM_FIELD_SAVE_DIALOG.ADD_FIELD" | translate)
    }}
  </div>
  <form class="custom-form setup-form" [formGroup]="form">
    <div class="control-group">
      <div class="label">
        {{ "CUSTOM_FIELD_SAVE_DIALOG.FORM.LABEL" | translate }}
      </div>
      <div class="control-box">
        <input class="input-form" type="text" id="label" formControlName="label" placeholder="label" />
        <ddd-field-error-show
          [displayError]="isFieldValid('label')"
          errorMsg="{{ 'CUSTOM_FIELD_SAVE_DIALOG.MESSAGES.ENTER_LABEL' | translate }}"
        >
        </ddd-field-error-show>
      </div>
    </div>
    <div class="control-group">
      <div class="label">
        {{ "CUSTOM_FIELD_SAVE_DIALOG.FORM.TYPE" | translate }}
      </div>
      <div class="control-box">
        <ng-select
          class="custom-select"
          formControlName="type"
          bindLabel="label"
          bindValue="type"
          [items]="customFieldsTypes"
          (change)="onTypeChange($event)"
          appendTo="html"
        ></ng-select>
        <ddd-field-error-show
          [displayError]="isFieldValid('type')"
          errorMsg="{{ 'CUSTOM_FIELD_SAVE_DIALOG.MESSAGES.ENTER_TYPE' | translate }}"
        >
        </ddd-field-error-show>
      </div>
    </div>
    <ng-container *ngIf="selectedCustomFieldType === 'List'">
      <div class="control-group">
        <div class="label">
          {{ "CUSTOM_FIELD_SAVE_DIALOG.FORM.OPTIONS" | translate }}
        </div>
        <div class="control-box">
          <div class="options-box">
            <input
              class="input-form"
              type="text"
              [(ngModel)]="newOption"
              [ngModelOptions]="{ standalone: true }"
              placeholder="please enter option and press plus"
            />
            <div class="plus-option" (click)="plusOption()">
              <i class="tabler-icon tabler-icon--circle-plus tabler-icon--grey"></i>
            </div>
          </div>
          <ng-container *ngIf="customFieldOptions.length > 0">
            <div class="options-content">
              <ng-container *ngFor="let option of customFieldOptions; let i = index">
                <div class="option-item">
                  <span>{{ option }}</span>
                  <i class="tabler-icon tabler-icon--circle-minus tabler-icon--grey" (click)="removeOption(i)"></i>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </form>
  <div class="actions">
    <button *ngIf="customField" class="ddd-button" (click)="deleteCustomField()">
      {{ "CUSTOM_FIELD_SAVE_DIALOG.DELETE_BUTTON" | translate }}
    </button>
    <button class="ddd-button" (click)="closeDialog()">
      {{ "CUSTOM_FIELD_SAVE_DIALOG.CANCEL_BUTTON" | translate }}
    </button>
    <button class="ddd-button raised" (click)="onSubmit()">
      {{
        customField
          ? ("CUSTOM_FIELD_SAVE_DIALOG.EDIT_FIELD" | translate)
          : ("CUSTOM_FIELD_SAVE_DIALOG.ADD_FIELD" | translate)
      }}
    </button>
  </div>
</div>
