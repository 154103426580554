import { Injectable } from "@angular/core";
import { Action } from "@ngrx/store";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { LocalStorageService, AnimationsService } from "@app/core";

import { ActionSettingsPersist, SETTINGS_KEY, SettingsActionTypes } from "@app/settings/settings/components/reducers";

@Injectable()
export class SettingsEffects {
  constructor(
    private actions$: Actions<Action>,
    private localStorageService: LocalStorageService,
    private animationsService: AnimationsService
  ) {}

  persistSettings = createEffect(
    (): Observable<Action> => {
      return this.actions$.pipe(
        ofType(SettingsActionTypes.PERSIST),
        tap((action: ActionSettingsPersist) => {
          const { settings } = action.payload;
          const { pageAnimations, elementsAnimations } = settings;
          this.localStorageService.setItem(SETTINGS_KEY, settings);
          this.animationsService.updateRouteAnimationType(pageAnimations, elementsAnimations);
        })
      );
    },
    { dispatch: false }
  );
}
