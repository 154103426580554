import { Injectable } from "@angular/core";

import { AUTH_KEY } from "@app/core/auth/auth.reducer";
import { LocalStorageService } from "@app/core/local-storage/local-storage.service";
import { HttpRequest } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class AuthService {
  isAuthenticated = false;
  cachedRequests: Array<HttpRequest<any>> = [];

  constructor(private localStorageService: LocalStorageService, private httpClient: HttpClient) {}

  public collectFailedRequest(request): void {
    this.cachedRequests.push(request);
  }

  getToken(): string {
    return this.localStorageService.getItem(AUTH_KEY);
  }

  public retryFailedRequests(): void {
    /*TODO: this method might be called after token refresh;*/
  }
}
