<div class="content">
  <div class="title">We have updated our Terms and Conditions</div>
  <ng-container *ngIf="!data.isKeycloakProvider; else ssoUserTermsText">
    <div class="message">
      We have made some changes to our
      <a href="{{ TERMS_AND_CONDITIONS_URL }}" target="_blank" rel="noopener">End User Terms</a>
      and
      <a href="{{ PRIVACY_POLICY_URL }}" target="_blank" rel="noopener"
        >Data Protection and Privacy Policy</a
      >
      to ensure that they continue to align with best practice and to make them clearer.
    </div>
    <div class="actions">
      <button class="ddd-button" (click)="dialogRef.close('ProcessedUpdatedTerms')">Close</button>
    </div>
  </ng-container>
  <ng-template #ssoUserTermsText>
    <div class="message sso-message">Before you connect to this service for the first time, please click the box below.</div>
    <div class="checkbox-box">
      <input
        class="c-checkbox"
        id="sso-terms"
        (click)="$event.stopPropagation(); setStatus()"
        name="sso-terms"
        type="checkbox"
      />
      <label class="message" for="sso-terms">I have read and agree to the TERMS</label>
    </div>

    <div class="actions">
      <button
        class="ddd-button raised"
        (click)="dialogRef.close('ProcessedUpdatedTerms')"
        [disabled]="!agreeWithTerms"
      >
        Confirm and proceed
      </button>
    </div>
  </ng-template>
</div>
