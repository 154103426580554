import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { UploadKeydocumentDialogComponent } from "./upload-keydocument-dialog.component";

@Injectable()
export class UploadKeydocumentDialogService {
  constructor(public dialog: MatDialog) {}

  public open(): Observable<any> {
    let dialogRef: MatDialogRef<UploadKeydocumentDialogComponent>;
    const config = new MatDialogConfig();
    config.panelClass = ["cg-dialog", "middle"];
    config.backdropClass = "cg-dialog-backdrop";
    config.disableClose = true;
    config.data = {};
    dialogRef = this.dialog.open(UploadKeydocumentDialogComponent, config);
    return dialogRef.afterClosed();
  }
}
