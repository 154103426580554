import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";

import { SharedModule } from "@app/shared";
import { settingsReducer } from "@app/settings/settings/components/reducers";
import { SettingsEffects } from "@app/settings/settings/components/effects";
import { SettingsComponent } from "@app/settings/settings/components";

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature("settings", settingsReducer),
        EffectsModule.forFeature([SettingsEffects]),
        SettingsComponent
    ]
})
export class SettingsModule {}
