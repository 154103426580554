import { ActionReducer } from "@ngrx/store";

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    const newState = reducer(state, action);
    console.log(`[DEBUG] action: ${action.type}`, {
      payload: (<any>action),
      oldState: state,
      newState
    });
    return newState;
  };
}
