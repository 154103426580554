import { Directive, ElementRef, HostListener, Output, EventEmitter } from "@angular/core";

import { NgControl } from "@angular/forms";

@Directive({
    selector: "[dddAppCapitalize]",
    standalone: true
})
export class AppCapitalizeDirective {
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  constructor(private elementRef: ElementRef<any>, private control: NgControl) {}

  private dirty = false;

  @HostListener("focusout")
  onFocusOut() {
    if (!this.dirty) {
      if (this.elementRef.nativeElement.value !== "") {
        this.elementRef.nativeElement.value =
          this.elementRef.nativeElement.value.charAt(0).toUpperCase() + this.elementRef.nativeElement.value.slice(1);
        this.control.control.setValue(this.elementRef.nativeElement.value);
        this.ngModelChange.emit(this.elementRef.nativeElement.value);
        this.dirty = true;
      }
    }
  }
}
