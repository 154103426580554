import { Component, OnInit } from "@angular/core";
import { MatProgressBarModule } from "@angular/material/progress-bar";

@Component({
    selector: "ddd-loading-screen",
    templateUrl: "./loading-screen.component.html",
    styleUrls: ["./loading-screen.component.scss"],
    standalone: true,
    imports: [MatProgressBarModule]
})
export class LoadingScreenComponent implements OnInit {
  public titleText = `Welcome to <b>Distributor Due Diligence platform</b>, streamlining the distributor review process for an entire industry.`;
  constructor() {}

  ngOnInit() {}
}
