import { Component, OnInit, OnDestroy } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { filter, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import * as moment from "moment";

import { LocalStorageService } from "@app/core/local-storage/local-storage.service";
import {
  ActionUserRetrieve,
  Role,
  selectorUser,
  SignupStatus,
  CompanyType,
  IUser
} from "@app/core/auth/identity/user.reducer";
import { environment } from "@env/environment";

import { ILastVisitedRoute, ROUTE_STATE_KEY } from "@shared/models";

export const ROUTES_LIST = {
  help: "help",
  support: "support",
  dashboard: "dashboard",
  fmguest: "fmguest",
  documents: "documents"
};

@Component({
    template: "",
    standalone: true
})
export class CallbackComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  constructor(
    private localStorageService: LocalStorageService,
    public oauthService: OAuthService,
    public router: Router,
    private readonly store: Store<any>
  ) {}

  ngOnInit() {
    this.store
      .pipe(
        select(selectorUser),
        takeUntil(this.unsubscribe$),
        filter((user: IUser) => !!user)
      )
      .subscribe((user: IUser) => {
        /* Check if user has Completed signupStatus  */
        if (this.signUpStatusIs(user, [SignupStatus.Completed])) {
          /*If user has no company*/
          if (
            !this.companyTypeIncludes(user.companyTypes, CompanyType.FundManager) &&
            !this.companyTypeIncludes(user.companyTypes, CompanyType.Distributor) &&
            !this.companyTypeIncludes(user.companyTypes, CompanyType.Intermediary)
          ) {
            return this.navigateIfPossible(ROUTES_LIST.help, user);
          }

          /* If user has only DocumentManager role */
          if (user.roles && user.roles.length === 1 && this.roleIncludes(user.roles, Role.DocumentManager)) {
            return this.navigateIfPossible(ROUTES_LIST.documents, user);
          }

          /*If user has role DDDAdmin */
          if (this.roleIncludes(user.roles, Role.DDDAdmin)) {
            return this.navigateIfPossible(ROUTES_LIST.support, user);
          }

          /*If user has role FMGuest */
          if (this.roleIncludes(user.roles, Role.FMGuest)) {
            return this.navigateIfPossible(ROUTES_LIST.fmguest, user);
          }

          if (this.companyTypeIncludes(user.companyTypes, CompanyType.FundManager)) {
            if (
              this.roleIncludes(user.roles, Role.FMTemplateOwner) ||
              this.roleIncludes(user.roles, Role.KeyContact) ||
              this.roleIncludes(user.roles, Role.Approver) ||
              this.roleIncludes(user.roles, Role.Reviewer)
            ) {
              /*If user has FundManager in companyTypes and role */
              return this.navigateIfPossible(ROUTES_LIST.dashboard, user);
            } else {
              /*If user has FundManager in companyTypes and without role */
              return this.navigateIfPossible(ROUTES_LIST.help, user);
            }
          } else if (this.companyTypeIncludes(user.companyTypes, CompanyType.Distributor)) {
            if (
              this.roleIncludes(user.roles, Role.KeyContact) ||
              this.roleIncludes(user.roles, Role.Completer) ||
              this.roleIncludes(user.roles, Role.Releaser)
            ) {
              /*If user has Distributor in companyTypes */
              return this.navigateIfPossible(ROUTES_LIST.dashboard, user);
            } else {
              /*If user has FundManager in companyTypes and without role */
              return this.navigateIfPossible(ROUTES_LIST.help, user);
            }
          }
        } else if (!this.signUpStatusIs(user, [SignupStatus.Completed])) {
          /*If user have not Completed signupStatus  */
          if (this.signUpStatusIs(user, [SignupStatus.New, SignupStatus.Invited, SignupStatus.PasswordCreated])) {
            return this.router.navigate(["/setup/profile-setup"]);
          }
          else{
            if (
            this.roleIncludes(user.roles, Role.KeyContact) ||
            this.roleIncludes(user.roles, Role.FMGuest) ||
            this.roleIncludes(user.roles, Role.DocumentManager)
          ) {
            if (this.signUpStatusIs(user, [SignupStatus.ProfileApproved])) {
              /*If user have ProfileApproved signupStatus go to company-setup page */
              return this.router.navigate(["/setup/company-setup"]);
            }
          }
        }
      } else {
        return this.router.navigate(["/logout"]);
      }
    });
  }

  public signUpStatusIs(user: IUser, signupStatuses: SignupStatus[]): boolean {
    return signupStatuses.includes(user.signupStatus);
  }

  public roleIncludes(array: Array<Role>, value: number): boolean {
    return array.indexOf(value) > -1;
  }

  public companyTypeIncludes(array: Array<CompanyType>, value: number): boolean {
    return array.indexOf(value) > -1;
  }

  public navigateIfPossible(routeFallBack = "/", user: IUser): void {
    const { routeName, expirationTime, userId, currentCompanyId }: ILastVisitedRoute =
      this.localStorageService.getItem(ROUTE_STATE_KEY) || {};

    const state = this.oauthService.state;
    if (this.oauthService.hasValidAccessToken() && state) {
      const redirectUrl = decodeURIComponent(state);
      this.router.navigateByUrl(redirectUrl);
      return;
    }
    
    routeName &&
    routeName.length > 1 &&
    userId &&
    user.id === userId &&
    currentCompanyId &&
    currentCompanyId === user.currentCompanyId &&
    moment(expirationTime).add(6, "hours").valueOf() >= moment().valueOf()
      ? this.router.navigate(["/" + routeName])
      : this.router.navigate([routeFallBack]);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
