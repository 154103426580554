<div class="content">
  <div class="title">
    <span *ngIf="data.setupCompanyReject">Confirmation</span>
    <span *ngIf="data.invitationCompanyReject">Reject company invitation</span>
    <span *ngIf="data.keyContactRoleReject">Reject key contact role</span>
  </div>
  <div class="message">
    <span *ngIf="data.setupCompanyReject || data.keyContactRoleReject"
      >Are you sure you want to reject the responsibility for this company? It is strongly recommended to provide a new
      key contact and save your time in future, otherwise it is likely that we will need to contact
      you for further clarifications.</span
    >
    <span *ngIf="data.invitationCompanyReject"
      >Are you sure that you want to reject this invitation? The person who invited you will be informed about your
      decision.</span
    >
  </div>
  <div class="setup-form custom-form" [formGroup]="keyContactForm">
    <div class="control-group">
      <div class="label">Rejection Reason</div>
      <div class="control-box">
        <textarea class="input-form textarea" formControlName="rejectionMessage"></textarea>
        <ddd-field-error-show
          [displayError]="isFieldValid('rejectionMessage')"
          errorMsg="Please enter rejection reason."
        >
        </ddd-field-error-show>
      </div>
    </div>
    <div class="sub-title">Optional: Propose a new Key Contact for this organisation</div>
    <div class="control-group">
      <div class="label">First Name</div>
      <div class="control-box">
        <input class="input-form" type="text" formControlName="firstName" dddAppCapitalize />
      </div>
    </div>
    <div class="control-group">
      <div class="label">Last Name</div>
      <div class="control-box">
        <input class="input-form" type="text" formControlName="lastName" dddAppCapitalize />
      </div>
    </div>
    <div class="control-group">
      <div class="label">Email</div>
      <div class="control-box">
        <input class="input-form" type="text" formControlName="email" />
        <div
          class="error"
          *ngIf="
            !keyContactForm?.get('email')?.valid &&
            keyContactForm?.get('email')?.value !== '' &&
            (keyContactForm?.get('email')?.touched || keyContactForm?.get('email')?.dirty)
          "
        >
          Please enter valid email
        </div>
      </div>
    </div>
    <div class="control-group">
      <div class="label">
        Landline
        <div
          class="info-icon"
          matTooltip="In case your company uses the extensions, please insert it in the following format: +000000000000 ext. 0000"
          [matTooltipPosition]="'right'"
        ></div>
      </div>
      <div class="control-box">
        <div class="phone-number-input-container">
          <div class="c-select">
            <select #countryCodeLandline>
              <option *ngIf="!landlineCountryAlphaCode" disabled selected value></option>
              <option
                value="{{ country.alpha2Code }}"
                *ngFor="let country of countriesSelect"
                [selected]="country.alpha2Code === landlineCountryAlphaCode"
              >
                {{ country.name }} ({{ country.phonePrefix }})
              </option>
            </select>
          </div>
          <input
            class="input-form"
            type="text"
            id="landline"
            formControlName="landline"
            placeholder="landline phone"
            (blur)="validateLandline()"
            #landline
            [ngClass]="{ 'empty-error': !validateLandline() }"
          />
        </div>
        <div class="error" *ngIf="!validateLandline()">Please enter a valid landline number.</div>
      </div>
    </div>
    <div class="control-group">
      <div class="label">Mobile Phone</div>
      <div class="control-box">
        <div class="phone-number-input-container">
          <div class="c-select">
            <select #mobilePhoneCountryCode>
              <option *ngIf="!phoneCountryAlphaCode" disabled selected value></option>
              <option
                value="{{ country.alpha2Code }}"
                *ngFor="let country of countriesSelect"
                [selected]="country.alpha2Code === phoneCountryAlphaCode"
              >
                {{ country.name }} ({{ country.phonePrefix }})
              </option>
            </select>
          </div>
          <input
            class="input-form"
            type="text"
            id="phone"
            formControlName="phone"
            placeholder="mobile phone"
            (blur)="validatePhoneNumber()"
            #mobilePhone
            [ngClass]="{ 'empty-error': !validatePhoneNumber() }"
          />
        </div>
        <div class="error" *ngIf="!validatePhoneNumber()">Please enter a valid phone number.</div>
      </div>
    </div>
  </div>
  <div class="actions">
    <button class="ddd-button" (click)="dialogRef.close()">Cancel</button>
    <button class="ddd-button raised" (click)="onSubmit()">
      {{ data.invitationCompanyReject || data.keyContactRoleReject ? "Reject" : "Reject responsibility" }}
    </button>
  </div>
</div>
