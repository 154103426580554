import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { selectorAuth } from "@app/core/auth/auth.reducer";

@Injectable()
export class UserGuardService implements CanActivate {
  isAuthenticated = false;

  constructor(private store: Store<any>) {
    this.store.subscribe(select(selectorAuth), (auth) => (this.isAuthenticated = auth.isAuthenticated));
  }

  canActivate(): boolean {
    return this.isAuthenticated;
  }
}
