import { environment } from "@env/environment";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

import { IManageTimelinesData, IFMCompanyData, IUpdateUserRoles } from "@modules/support/models";

@Injectable()
export class CompanyAdminService {
  private API_URL = environment.client.base_url;
  constructor(private httpClient: HttpClient) {}

  /**
   * Get base URL
   *
   * @return {*}
   * @memberof CompanyAdminService
   */
  _baseUrl() {
    return this.API_URL + "/api/admin/company";
  }

  /**
   * Get FM companies
   *
   * @param {number} page
   * @param {number} size
   * @param {*} filter
   * @return {*}
   * @memberof CompanyAdminService
   */
  getFm(page: number, size: number, filter: any) {
    return this.httpClient
      .post(`${this._baseUrl()}/fundmanagers/${page}/${size}`, filter, {
        responseType: "text"
      })
      .pipe(map((response) => JSON.parse(response)));
  }

  /**
   * Pause reminders
   *
   * @param {string} companyId
   * @return {*}
   * @memberof CompanyAdminService
   */
  pauseReminders(companyId: string) {
    return this.httpClient
      .put(this.API_URL + `/api/administration/pause/${companyId}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => res));
  }

  /**
   * Resume reminders
   *
   * @param {string} companyId
   * @return {*}
   * @memberof CompanyAdminService
   */
  resumeReminders(companyId: string) {
    return this.httpClient
      .put(this.API_URL + `/api/administration/resume/${companyId}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => res));
  }

  /**
   * Export fm companies
   *
   * @return {*}
   * @memberof CompanyAdminService
   */
  exportFMCompanies() {
    return this.httpClient.post(
      this.API_URL + `/api/admin/company/exportfundmanagers`,
      {},
      {
        responseType: "blob"
      }
    );
  }

  /**
   * Get list of company users
   *
   * @param {string} companyId
   * @return {*}  {Observable<any>}
   * @memberof CompanyAdminService
   */
  getCompanyUsers(companyId: string): Observable<any> {
    return this.httpClient
      .get(this.API_URL + `/api/user/users/${companyId}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Add new interaction
   *
   * @param {*} interaction
   * @param {string} companyId
   * @return {*}
   * @memberof CompanyAdminService
   */
  addInteraction(interaction: any, companyId: string) {
    return this.httpClient
      .post(this.API_URL + `/api/interaction/${companyId}`, interaction, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Add new task
   *
   * @param {*} task
   * @return {*}
   * @memberof CompanyAdminService
   */
  addTask(task: any) {
    return this.httpClient
      .post(this.API_URL + `/api/task`, task, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Get all users
   *
   * @return {*}  {Observable<any>}
   * @memberof CompanyAdminService
   */
  getAllUsers(): Observable<any> {
    return this.httpClient
      .get(this.API_URL + `/api/collegue`, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Trigger company event
   *
   * @param {string} companyId
   * @return {*}
   * @memberof CompanyAdminService
   */
  triggerCompanyEvent(companyId: string, startNewCycle: boolean, refreshCompletionDates: boolean, refreshDDQStructure: boolean) {
    return this.httpClient
      .put(
        this.API_URL + `/api/admin/company/trigger/${companyId}`,
        {
          startNewCycle: startNewCycle,
          refreshCompletionDates: refreshCompletionDates,
          refreshDDQStructure: refreshDDQStructure
        },
        {
          responseType: "text"
        }
      )
      .pipe(map((res: string) => res));
  }

  /**
   * Invite DM user
   *
   * @param {*} dmUser
   * @return {*}  {Observable<any>}
   * @memberof CompanyAdminService
   */
  inviteDMUser(dmUser: any): Observable<any> {
    return this.httpClient
      .post(this.API_URL + `/api/administration/inviteDmUser`, dmUser, {
        responseType: "text"
      })
      .pipe(map((res: string) => res));
  }

  /**
   * Manage timelines
   *
   * @param {IManageTimelinesData} manageData
   * @return {*}  {Observable<any>}
   * @memberof CompanyAdminService
   */
  manageTimelines(manageData: IManageTimelinesData): Observable<any> {
    let subURL: string;
    switch (manageData.timelineType) {
      case "Clarification":
        subURL = `/api/admin/company/distributor/${manageData.companyId}/updateClarificationDate`;
        break;
      case "FinalRelease":
        subURL = `/api/admin/company/distributor/${manageData.companyId}/updateFinalReleaseDate`;
        break;     
    }
    return this.httpClient
      .put(
        this.API_URL + subURL,
        {
          date: manageData.date
        },
        {
          responseType: "text"
        }
      )
      .pipe(map((res: string) => res));
  }

  /**
   * Toggle external documents sharing
   *
   * @param {string} distributorId
   * @param {boolean} newState
   * @return {*}
   * @memberof CompanyAdminService
   */
  externalDocumentsSharing(distributorId: string, newState: boolean) {
    return this.httpClient
      .put(
        this.API_URL +
          `/api/admin/company/distributor/${distributorId}/updateDocumentAccessForExternalGuest/${newState}`,
        {},
        {
          responseType: "text"
        }
      )
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Manage Company ownership by admin
   *
   * @param {string} distributorId
   * @param {string} internalOwnerId
   * @return {*}
   * @memberof CompanyAdminService
   */
  adminManageOwnership(distributorId: string, internalOwnerId: string) {
    return this.httpClient
      .post(
        this.API_URL + `/api/admin/company/updateownership`,
        {
          distributorId: distributorId,
          internalOwnerId: internalOwnerId
        },
        {
          responseType: "text"
        }
      )
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Add distributor company
   *
   * @param {*} distributorCompanyData
   * @return {*}
   * @memberof CompanyAdminService
   */
  addDistributorCompany(distributorCompanyData: any) {
    return this.httpClient
      .post(this.API_URL + `/api/admin/company/distributor`, distributorCompanyData, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Update distributor company
   *
   * @param {*} distributorCompanyData
   * @return {*}
   * @memberof CompanyAdminService
   */
  updateDistributorCompany(distributorCompanyData: any) {
    return this.httpClient
      .put(this.API_URL + `/api/admin/company/distributor/${distributorCompanyData.id}`, distributorCompanyData, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Get relationship data
   *
   * @param {string} companyId
   * @param {string} distributorId
   * @return {*}
   * @memberof CompanyAdminService
   */
  getRelationshipsData(companyId: string, distributorId: string) {
    if (distributorId) {
      const params = new HttpParams().set("distributorId", distributorId);
      return this.httpClient
        .get(this.API_URL + `/api/admin/company/relationshipInfo/${companyId}`, {
          params,
          responseType: "text"
        })
        .pipe(map((res: string) => JSON.parse(res)));
    }

    return this.httpClient
      .get(this.API_URL + `/api/admin/company/relationshipInfo/${companyId}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Add new relationship
   *
   * @param {*} relationship
   * @return {*}
   * @memberof CompanyAdminService
   */
  addRelationship(relationship: any) {
    return this.httpClient
      .post(this.API_URL + `/api/admin/company/relationship`, relationship, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Save relationship
   *
   * @param {*} relationship
   * @return {*}
   * @memberof CompanyAdminService
   */
  saveRelationship(relationship: any) {
    return this.httpClient
      .put(this.API_URL + `/api/admin/company/relationship`, relationship, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Delete relationship
   *
   * @param {string} distributorId
   * @param {string} fmCompanyId
   * @return {*}
   * @memberof CompanyAdminService
   */
  deleteRelationship(distributorId: string, fmCompanyId: string) {
    return this.httpClient
      .delete(this.API_URL + `/api/admin/company/relationship/${distributorId}/${fmCompanyId}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Get relationships actions history
   *
   * @param {*} distributorId
   * @return {*}  {Observable<any>}
   * @memberof CompanyAdminService
   */
  getRelationshipsActionsHistory(distributorId): Observable<any> {
    return this.httpClient
      .get(this.API_URL + `/api/history/adminhistory/${distributorId}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Get excel template file
   *
   * @return {*}
   * @memberof CompanyAdminService
   */
  getExcelTemplate() {
    return this.httpClient.post(
      this.API_URL + `/api/prepopulation/export`,
      {},
      {
        responseType: "blob"
      }
    );
  }

  /**
   * Get excel distributors template file
   *
   * @return {*}
   * @memberof CompanyAdminService
   */
    getDistributorsExcelTemplate(documentId: string) {
      return this.httpClient.get(
        this.API_URL + `/api/filestore/staticdocument/` + documentId,
        {
          responseType: "blob"
        }
      );
    }

  /**
   * Get excel template file
   *
   * @return {*}
   * @memberof CompanyAdminService
   */
  getExcelTemplateVersion() {
    return this.httpClient.post(
      this.API_URL + `/api/prepopulation/exportLatestVersion`,
      {},
      {
        responseType: "blob"
      }
    );
  }

  /**
   * Upload file excel document
   *
   * @param {File} document
   * @param {string} distributorId
   * @return {*}  {Observable<any>}
   * @memberof CompanyAdminService
   */
  uploadFileExcel(document: File, distributorId: string): Observable<any> {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    const formData = new FormData();
    formData.append("document", document, document.name);
    return this.httpClient
      .post(this.API_URL + "/api/prepopulation/import/" + distributorId, formData, {
        responseType: "text",
        headers: headers
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Upload file distributors excel document
   *
   * @param {File} document
   * @return {*}  {Observable<any>}
   * @memberof CompanyAdminService
   */
  uploadFileDistributorsExcel(document: File): Observable<any> {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    const formData = new FormData();
    formData.append("document", document, document.name);
    return this.httpClient
      .post(this.API_URL + "/api/admin/company/relations", formData, {
        responseType: "text",
        headers: headers
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Create new FM company
   * @param {IFMCompanyData} fmCompanyData
   * @return {*}
   * @memberof CompanyAdminService
   */
  addFMCompany(fmCompanyData: IFMCompanyData) {
    return this.httpClient
      .post(this.API_URL + `/api/admin/company/fundmanager`, fmCompanyData, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Export validation file
   *
   * @param {string} companyId
   * @return {*}
   * @memberof CompanyAdminService
   */
  exportValidationFile(companyId: string) {
    return this.httpClient.post(
      this.API_URL + `/api/prepopulation/validate/` + companyId,
      {},
      {
        responseType: "blob"
      }
    );
  }

  /**
   * Update user roles
   * @param {IUpdateUserRoles} model
   * @return {*}
   * @memberof CompanyAdminService
   */
  updateUserRoles(model: IUpdateUserRoles) {
    return this.httpClient
      .put(this.API_URL + `/api/admin/user/updateroles`, model, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   *
   * @param {string} readyCompanyId
   * @param {string} pendingCompanyId
   * @returns
   */
  cloneDDQ(readyCompanyId, pendingCompanyId) {
    return this.httpClient
      .put(this.API_URL + `/api/administration/copy/answers/${readyCompanyId}/${pendingCompanyId}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => res));
  }

  /**
   * Get FM cycles
   *
   * @param {string} companyId
   * @param {string} distributorId
   * @return {*}
   * @memberof CompanyAdminService
   */
  getFMCycles( distributorId: string , targetFmId: string, auditorFmId: string) {
    if (distributorId) {
      return this.httpClient
        .get(this.API_URL + `/api/admin/company/cycles/${distributorId}/${targetFmId}/${auditorFmId}`, {
          responseType: "text"
        })
        .pipe(map((res: string) => JSON.parse(res)));
    }
  }

  /**
   * Get paid FM companies
   *
   * @param {string} companyAuditorId
   * @return {*}
   * @memberof CompanyAdminService
   */
  getPaidFm(companyAuditorId: string) {
    return this.httpClient
      .post(`${this._baseUrl()}/fundmanagers/paid/${companyAuditorId}/`, null, {
        responseType: "text"
      })
      .pipe(map((response) => JSON.parse(response)));
  }
}
