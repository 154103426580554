import { ToastrService } from "ngx-toastr";
import { Injectable } from "@angular/core";
import {
  toastrSuccessConfig,
  toastrErrorConfig,
  toastrWarningConfig
} from "@app/core/toastr/toastr.reducer";

@Injectable()
export class NotificationService {
  private toastrConfig = {
    timeOut: 5700
  };

  constructor(private toastr: ToastrService) {}

  showSuccess(message, title = "", config = toastrSuccessConfig) {
    return this.toastr.success(message, title, config);
  }

  showInfo(message, title = "", config = this.toastrConfig) {
    return this.toastr.info(message, title, config);
  }

  showError(message, title = "", config = toastrErrorConfig) {
    return this.toastr.error(message, title, config);
  }

  showWarning(message, title = "", config = toastrWarningConfig) {
    return this.toastr.warning(message, title, config);
  }
}
