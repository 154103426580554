import { Component, OnInit, Inject, OnDestroy, ViewContainerRef } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as moment from "moment";

import {
  ActionUserAdminGetCompanies,
  ActionUserAdminGetCompaniesSuccess,
  Role,
  selectorAdminAllCompaniesList
} from "@app/core/auth/identity/user.reducer";
import {
  ActionInteractionAdd,
  ActionInteractionSave,
  ActionCompanyUsersGet,
  selectorCompanyUsersData
} from "@modules/company-interactions/store/reducers";
import { IInteraction, IInteractionFormModel } from "@shared/models/interaction.interfaces";
import {
  ActionAllCompanyUsersGet,
  ActionAllCompaniesInteractionAdd,
  ActionAllCompaniesInteractionSave
} from "@modules/all-companies-interactions/state/actions";
import {
  selectorAllCompanyUsersData
} from "@modules/all-companies-interactions/state/selectors";
import {
  ActionTaskInteractionAdd,
  ActionTaskInteractionUpdate,
  ActionTaskInteractionDelete
} from "@shared/modules/tasks-shared/store/reducers";
import { RejectAndConfirmDialogService } from "@shared/dialogs/reject-confirm-dialog/reject-confirm-dialog.service";
import {
  ActionSupportDistributorCompanyGetUsers,
  supportDistributorCompanyUsers,
  ActionSupportDistributorCompanyInteractionAdd
} from "@modules/support/components/support/reducers/distributor-companies.reducer";
import { TranslateModule } from "@ngx-translate/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FieldErrorShowComponent } from "../../components/field-error-show/field-error-show.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgIf } from "@angular/common";

@Component({
    selector: "ddd-save-interaction-dialog",
    templateUrl: "./save-interaction-dialog.component.html",
    styleUrls: ["./save-interaction-dialog.component.scss"],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgSelectModule, FieldErrorShowComponent, MatFormFieldModule, MatInputModule, MatDatepickerModule, TranslateModule]
})
export class SaveInteractionDialogComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  public interaction: IInteraction;
  public interactionTypes = [
    {
      type: 1,
      label: "Completion Chasing"
    },
    {
      type: 5,
      label: "Registration Chasing"
    },
    {
      type: 2,
      label: "Release Chasing"
    },
    {
      type: 3,
      label: "Technical Support"
    },
    {
      type: 6,
      label: "Set-up Chasing"
    },
    {
      type: 4,
      label: "Other (provide notes)"
    }
  ];
  public interactionStatuses = ["Successful", "Unsuccessful"];
  public interactionChannels = ["Email", "Phone", "Jira", "Chat", "Not Defined"];
  public taskInteractions = [];
  public selectedInteractionTypes = [];
  public selectedInteractionDate;
  public selectedInteractionStatus;
  public selectedInteractionChannel;
  public taggedUsers = [];
  public companyUsers = [];
  public allCompaniesList = [];
  public currentCompany: any;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private ADMIN_COMPANIES_PAGESIZE = 50;
  private stopAdminCompaniesFetching = false;
  public adminCompaniesListQuery: string;
  public maxDate = new Date();

  constructor(
    private readonly store: Store<any>,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<SaveInteractionDialogComponent>,
    private confirmDialogService: RejectAndConfirmDialogService,
    private viewContainerRef: ViewContainerRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.storeActions();
  }

  ngOnInit() {
    this.interaction = this.data.interaction;
    this.taskInteractions = this.data.interactionsGetData;
    this.companyUsers = [];
    if (this.data.fromAllCompanies) {
      if (this.interaction) {
        this.store.dispatch(
          ActionAllCompanyUsersGet({
            companyId: this.data.interactionsGetData.companyId
          })
        );
      }
    } else if (this.data.taskId) {
      this.companyUsers = this.data.companyUsers.map((user) => ({
        ...user,
        fullName: user.firstName + " " + user.lastName + " " + user.email
      }));
    } else if (!this.data.interactionsGetData && !this.data.taskId) {
      this.store.dispatch(
        new ActionSupportDistributorCompanyGetUsers({
          companyId: this.data.companyId
        })
      );
    } else {
      this.store.dispatch(
        new ActionCompanyUsersGet({
          companyId: this.data.interactionsGetData.companyId
        })
      );
    }
    if (this.interaction) {
      this.selectedInteractionTypes = this.interaction.type;
      this.selectedInteractionDate = this.interaction.date;
      this.selectedInteractionStatus = this.interaction.status != "NotDefined" ? this.interaction.status : null;
      this.selectedInteractionChannel =
        this.interaction.channel != "NotDefined" ? this.interaction.channel : "Not Defined";
      this.taggedUsers = this.interaction.taggedUsers.map((user) => ({
        ...user,
        fullName: user.firstName + " " + user.lastName + " " + user.email
      }));
      this.form = this.formBuilder.group({
        date: [
          {
            value: this.selectedInteractionDate,
            disabled: (!this.isCurrentUser() && !this.isAdmin()) || this.data.isFM
          },
          Validators.required
        ],
        type: [
          {
            value: this.selectedInteractionTypes,
            disabled: (!this.isCurrentUser() && !this.isAdmin()) || this.data.isFM
          },
          Validators.required
        ],
        status: [
          {
            value: this.selectedInteractionStatus,
            disabled: (!this.isCurrentUser() && !this.isAdmin()) || this.data.isFM
          }
        ],
        channel: [
          {
            value: this.selectedInteractionChannel,
            disabled: (!this.isCurrentUser() && !this.isAdmin()) || this.data.isFM
          },
          Validators.required
        ],
        notes: [
          {
            value: this.interaction.message,
            disabled: (!this.isCurrentUser() && !this.isAdmin()) || this.data.isFM
          },
          Validators.required
        ],
        tags: [
          {
            value: this.taggedUsers,
            disabled: (!this.isCurrentUser() && !this.isAdmin()) || this.data.isFM
          }
        ]
      });
    } else {
      this.selectedInteractionDate = moment();
      this.form = this.formBuilder.group({
        date: [{ value: null, disabled: false }, Validators.required],
        type: [null, Validators.required],
        status: [null],
        channel: [null, Validators.required],
        notes: [null, Validators.required],
        tags: [null],
        company: [null, this.data.fromAllCompanies ? Validators.required : null]
      });
    }
  }

  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }

  isCurrentUser() {
    if (this.interaction && this.data.currentUser) {
      return this.interaction.createdBy.id === this.data.currentUser.id;
    }
    return true;
  }

  isAdmin() {
    return this.data && this.data.currentUser && this.data.currentUser.roles.includes(Role.DDDAdmin);
  }

  /**
   * Submit save interaction form, will submit if form valid, else will start validating form fields function
   */
  onSubmit() {
    if (this.form.valid) {
      this.sentForm();
    } else if (!this.form.valid) {
      this.validateAllFormFields(this.form);
    }
  }

  /**
   * Delete task interaction
   */
  deleteInteraction(): void {
    const confirmDialog = {
      title: "Delete interaction",
      message: "Are you sure that you want to delete this interaction?",
      cancel: "Cancel",
      okBtn: "Ok"
    };
    this.confirmDialogService.confirm(confirmDialog, this.viewContainerRef).subscribe((res) => {
      if (res) {
        this.store.dispatch(
          new ActionTaskInteractionDelete({
            interaction: this.interaction
          })
        );
      }
      this.dialogRef.close(true);
    });
  }

  /**
   * Sent interaction form
   */
  sentForm() {
    const formData: IInteractionFormModel = this.getFormData();
    if (this.interaction) {
      if (this.data.fromAllCompanies && !this.data.taskId) {
        this.store.dispatch(
          ActionAllCompaniesInteractionSave({
            interaction: formData,
            interactionsGetData: this.data.interactionsGetData
          })
        );
      } else if (!this.data.fromAllCompanies && !this.data.taskId) {
        this.store.dispatch(
          new ActionInteractionSave({
            interaction: formData,
            interactionsGetData: this.data.interactionsGetData
          })
        );
      } else if (this.data.taskId) {
        this.store.dispatch(
          new ActionTaskInteractionUpdate({
            interaction: formData,
            companyId: this.data.companyId
          })
        );
      }
    } else {
      if (this.data.fromAllCompanies && !this.data.taskId) {
        this.store.dispatch(
          ActionAllCompaniesInteractionAdd({
            interaction: formData,
            interactionsGetData: {
              ...this.data.interactionsGetData,
              companyId: this.form.getRawValue().company
            }
          })
        );
      } else if (!this.data.fromAllCompanies && !this.data.taskId && this.data.interactionsGetData) {
        this.store.dispatch(
          new ActionInteractionAdd({
            interaction: formData,
            interactionsGetData: this.data.interactionsGetData
          })
        );
      } else if (!this.data.interactionsGetData && !this.data.taskId) {
        this.store.dispatch(
          new ActionSupportDistributorCompanyInteractionAdd({
            interaction: formData,
            companyId: this.data.companyId
          })
        );
      } else if (this.data.taskId) {
        this.store.dispatch(
          new ActionTaskInteractionAdd({
            interaction: formData,
            companyId: this.data.companyId
          })
        );
      }
    }
    this.dialogRef.close(true);
  }

  /**
   * Get from data
   */
  getFormData() {
    return {
      id: this.interaction ? this.interaction.id : null,
      message: this.form.getRawValue().notes,
      type: this.form.getRawValue().type,
      status: this.form.getRawValue().status ? this.form.getRawValue().status : "NotDefined",
      channel: this.form.getRawValue().channel
        ? this.form.getRawValue().channel !== "Not Defined"
          ? this.form.getRawValue().channel
          : "NotDefined"
        : "NotDefined",
      date: moment(this.form.getRawValue().date).startOf("day").utc().add(12, "hours").format(),
      taggedUsers: this.taggedUsers,
      taskId: this.data.taskId
    };
  }

  /**
   * Validate form fields function, mark fields as touched
   */
  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * Close interaction dialog
   */
  public closeInteractionDialog() {
    if (this.interaction) {
      this.dialogRef.close(true);
    } else {
      const confirmDialog = {
        title: "Close add interaction dialog",
        message: "Are you sure that you want to close add interaction dialog?",
        cancel: "Cancel",
        okBtn: "Ok"
      };
      this.confirmDialogService.confirm(confirmDialog, this.viewContainerRef).subscribe((res) => {
        if (res) {
          this.dialogRef.close(true);
        }
      });
    }
  }

  public isTwoRows(value: string): boolean {
    return !value || value.split(/\r*\n/).length <= 2;
  }

  public selectCompany() {
    if (this.data.fromAllCompanies) {
      this.taggedUsers = [];
      this.store.dispatch(
        ActionAllCompanyUsersGet({
          companyId: this.form.getRawValue().company
        })
      );
    }
  }

  storeActions() {
    if (this.data.fromAllCompanies) {
      this.store
        .pipe(select(selectorAdminAllCompaniesList), takeUntil(this.unsubscribe$))
        .subscribe((adminAllCompaniesList) => {
          this.stopAdminCompaniesFetching =
            adminAllCompaniesList &&
            adminAllCompaniesList.length > 0 &&
            adminAllCompaniesList.length % this.ADMIN_COMPANIES_PAGESIZE !== 0;
          this.allCompaniesList = adminAllCompaniesList.map((adminCompany) => {
            return {
              ...adminCompany,
              fullname: `[${adminCompany.name}], [${adminCompany.legalEntityId}]`
            };
          });
        });
      this.store.pipe(select(selectorAllCompanyUsersData), takeUntil(this.unsubscribe$)).subscribe((data) => {
        if (data) {
          // this.companyUsers = data.result.map((user) => ({
          this.companyUsers = data.map((user) => ({
            ...user,
            fullName: user.firstName + " " + user.lastName + " " + user.email
          }));
        }
      });
    } else if (!this.data.taskId && this.data.interactionsGetData) {
      this.store.pipe(select(selectorCompanyUsersData), takeUntil(this.unsubscribe$)).subscribe((data) => {
        if (data) {
          this.companyUsers = data.result.map((user) => ({
            ...user,
            fullName: user.firstName + " " + user.lastName + " " + user.email
          }));
        }
      });
    } else if (!this.data.taskId && !this.data.interactionsGetData) {
      this.store.pipe(select(supportDistributorCompanyUsers), takeUntil(this.unsubscribe$)).subscribe((data) => {
        if (data) {
          this.companyUsers = data.result.map((user) => ({
            ...user,
            fullName: user.firstName + " " + user.lastName + " " + user.email
          }));
        }
      });
    }
  }

  public fetchAdminCompanies() {
    if (!this.stopAdminCompaniesFetching) {
      this.store.dispatch(
        new ActionUserAdminGetCompanies({
          pagesize: this.ADMIN_COMPANIES_PAGESIZE,
          query: this.adminCompaniesListQuery
        })
      );
    }
  }

  public searchAdminCompanies(query) {
    this.adminCompaniesListQuery = query ? query.term : "";
    this.store.dispatch(
      new ActionUserAdminGetCompaniesSuccess({
        companies: []
      })
    );
    this.fetchAdminCompanies();
  }

  /**
   * Workaround to allow searching with ng-select using API instead of list search;
   */
  public customSearchFunction() {
    return true;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
