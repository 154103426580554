import { parsePhoneNumberWithError, parseNumber } from "libphonenumber-js";
import { isValidPhoneNumber } from "libphonenumber-js/max";
import { ElementRef } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

import { PhoneType, Phone } from "@app/core/auth/identity/user.reducer";

const DefaultPhoneNumberExtension = " ext. ";

export class PhoneNumberHelper {
  static getFormattedPhoneNumber(numberElement: ElementRef, countryCodeElement: ElementRef): string {
    if (numberElement && countryCodeElement) {
      let parsedPhone;
      try {
        parsedPhone = parsePhoneNumberWithError(
          numberElement.nativeElement.value,
          countryCodeElement.nativeElement.value
        );
      } catch (error) {
        return "";
      }
      let number = parsedPhone["number"];
      let numberExtension = parsedPhone["ext"];
      return number + (numberExtension ? DefaultPhoneNumberExtension + numberExtension : "");
    } else {
      return "";
    }
  }

  static validatePhoneNumber(numberElement: ElementRef, countryCodeElement: ElementRef): boolean {
    if (!numberElement) {
      return true;
    }
    if (numberElement.nativeElement.value !== "" && countryCodeElement.nativeElement.value !== "") {
      const userInputNumber = this.getFormattedPhoneNumber(numberElement, countryCodeElement);
      return isValidPhoneNumber(userInputNumber);
    } else {
      return true;
    }
  }

  static validateLandlineNumber(numberElement: ElementRef, countryCodeElement: ElementRef, form: UntypedFormGroup): boolean {
    if (!numberElement) {
      return true;
    }
    if (numberElement.nativeElement.value !== "" && countryCodeElement.nativeElement.value !== "") {
      const userInputNumber = this.getFormattedPhoneNumber(numberElement, countryCodeElement);
      return isValidPhoneNumber(userInputNumber);
    } else if (
      countryCodeElement.nativeElement.value === "" &&
      (form.get("landline").touched || form.get("landline").dirty)
    ) {
      return false;
    } else {
      return true;
    }
  }

  static getNumber(phones: Phone[], phoneType: PhoneType) {
    let phoneNumber = phones && phones.find((phone) => phone.type === phoneType).number;
    if (phoneNumber) {
      let parsedPhone;
      try {
        parsedPhone = parsePhoneNumberWithError(phoneNumber);
      } catch (error) {
        return null;
      }
      let nationalNumber = parsedPhone["nationalNumber"];
      let numberExtension = parsedPhone["ext"];
      return nationalNumber + (numberExtension ? DefaultPhoneNumberExtension + numberExtension : "");
    } else {
      return null;
    }
  }

  static getCountryCode(phones: Phone[], phoneType: PhoneType) {
    return parseNumber(phones.find((phone) => phone.type === phoneType).number)["country"];
  }
}
