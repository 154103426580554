import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { NgClass } from "@angular/common";

@Component({
    selector: "ddd-field-datetime-range",
    templateUrl: "./field-datetime-range.component.html",
    styleUrls: ["./field-datetime-range.component.scss"],
    standalone: true,
    imports: [NgClass, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatDatepickerModule]
})
export class FieldDatetimeRangeComponent implements OnInit {
  private _minValue: Date;
  private _maxValue: Date;
  private _value: UntypedFormGroup = this._defaultValue;
  private _valueModel: any = { from: null, until: null };
  private _disabled = false;
  private _placeholderFrom = "From";
  private _placeholderUntil = "Until";
  private _containerClasses: string = null;
  private _inputClasses: string = null;

  public untilMin: Date = null;
  public fromMax: Date = null;

  @Input()
  set minValue(value: Date) {
    this._minValue = value;
    this.validate();
  }
  get minValue(): Date {
    return this._minValue;
  }

  @Input()
  set maxValue(value: Date) {
    this._maxValue = value;
    this.validate();
  }
  get maxValue(): Date {
    return this._maxValue;
  }

  @Input()
  set value(range: UntypedFormGroup) {
    this._value = range;
    this.validate();
  }
  get value(): UntypedFormGroup {
    return this._value;
  }

  @Input()
  set valueModel(range: any) {
    this._valueModel = range;
    this._value.get("from").setValue(range.from || null);
    this._value.get("until").setValue(range.until || null);
    this.validate();
  }
  get valueModel(): any {
    return this._valueModel;
  }

  @Input()
  set disabled(val: boolean) {
    this._disabled = val;
  }
  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set placeholderFrom(val: string) {
    this._placeholderFrom = val;
  }
  get placeholderFrom(): string {
    return this._placeholderFrom;
  }

  @Input()
  set placeholderUntil(val: string) {
    this._placeholderUntil = val;
  }
  get placeholderUntil(): string {
    return this._placeholderUntil;
  }

  @Input()
  set containerClasses(val: string) {
    this._containerClasses = val;
  }
  get containerClasses(): string {
    return this._containerClasses;
  }

  @Input()
  set inputClasses(val: string) {
    this._inputClasses = val;
  }
  get inputClasses(): string {
    return this._inputClasses;
  }

  @Output()
  onValueChange: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();

  constructor() {}

  ngOnInit(): void {
    this.validate();
  }

  onChange($event): void {
    this.validate() && this.onValueChange.emit(this._value);
  }

  validate(): boolean {
    if (!this._value) {
      this._value = this._defaultValue;
    }

    if (!this._value["from"]) {
      this._value["from"] = new UntypedFormControl();
    }

    if (!this._value["until"]) {
      this._value["until"] = new UntypedFormControl();
    }

    if (this._minValue && this._maxValue && this._minValue > this._maxValue) {
      const tmp = this._minValue;
      this._minValue = this._maxValue;
      this._maxValue = tmp;
    }

    let from = this._value.get("from").value;
    let until = this._value.get("until").value;

    if (from) {
      if (this._minValue && from < this._minValue) {
        this._value.get("from").setValue(this._minValue, false);
        from = this._value.get("from").value;
      }

      if (until != null && from > until) {
        this._value.get("from").setValue(until, false);
        this._value.get("until").setValue(from, false);
        const tmp = from;
        from = until;
        until = tmp;
      }

      this.untilMin = from;
    }

    if (until) {
      if (this._maxValue && until > this._maxValue) {
        this._value.get("until").setValue(this._maxValue, false);
        until = this._value.get("until").value;
      }

      this.fromMax = until;
    } else if (this._maxValue) {
      this.fromMax = this._maxValue;
    }

    this._valueModel.from = this._value.get("from").value;
    this._valueModel.until = this._value.get("until").value;

    return true;
  }

  private get _defaultValue(): UntypedFormGroup {
    return new UntypedFormGroup({
      from: new UntypedFormControl(),
      until: new UntypedFormControl()
    });
  }
}
