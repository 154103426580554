import { Injectable, ViewContainerRef } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";

import { SaveColleagueDialogComponent } from "./save-colleague-dialog.component";

@Injectable()
export class SaveColleagueDialogService {
  constructor(private dialog: MatDialog) {}

  public open(
    viewContainerRef: ViewContainerRef,
    colleague?: any,
    isAdminAction?: boolean,
    editInvitedUser?: boolean,
    isLoggedUser?: boolean
  ): Observable<any> {
    let dialogRef: MatDialogRef<SaveColleagueDialogComponent>;
    const config = new MatDialogConfig();
    config.panelClass = ["cg-dialog", "middle"];
    config.backdropClass = "cg-dialog-backdrop";
    config.disableClose = true;
    config.data = {
      colleague: colleague,
      isAdminAction: isAdminAction,
      editInvitedUser: editInvitedUser,
      isLoggedUser: isLoggedUser
    };
    config.viewContainerRef = viewContainerRef;

    dialogRef = this.dialog.open(SaveColleagueDialogComponent, config);
    return dialogRef.afterClosed();
  }
}
