import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";

import { ActionToastrWarning } from "@app/core/toastr/toastr.reducer";
import { FormsModule } from "@angular/forms";

@Component({
    selector: "ddd-edit-dialog",
    templateUrl: "./edit-dialog.component.html",
    styleUrls: ["./edit-dialog.component.scss"],
    standalone: true,
    imports: [FormsModule]
})
export class EditDialogComponent implements OnInit {
  public dialog: any;
  public originalName: string;

  constructor(
    public dialogRef: MatDialogRef<EditDialogComponent>,
    private readonly store: Store<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.originalName = data.name;
  }

  ngOnInit() {}

  onSubmit() {
    if (this.data.existingNames && this.checkItemName(this.data.name)) {
      this.store.dispatch(
        new ActionToastrWarning({
          message: "Section names cannot be the same."
        })
      );
      return false;
    }
    this.dialogRef.close(this.data.name);
  }

  public checkItemName(itemName) {
    if (itemName.toLowerCase() === this.originalName.toLowerCase()) {
      return false;
    }
    const items = this.data.existingNames.filter((name) => name.toLowerCase() === itemName.toLowerCase());
    return items && items.length !== 0 ? true : false;
  }
}
