import { tap, take, switchMap, filter } from "rxjs/operators";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import {
  ActionUserRetrieve,
  Role,
  selectorUser,
  SignupStatus,
  IUser,
  CompanyType
} from "@app/core/auth/identity/user.reducer";
import { select, Store } from "@ngrx/store";
import { of } from "rxjs";
import * as _ from "lodash";
import { environment } from "@env/environment";
import { ActionToastrError } from "@app/core/toastr/toastr.reducer";

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(public router: Router, private readonly store: Store<any>, private oauthService: OAuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store
      .pipe(
        select(selectorUser),
        tap((user: IUser) => {
          if (!user) {
            if (this.oauthService.hasValidAccessToken()) {
              this.store.dispatch(new ActionUserRetrieve({ base_url: environment.client.base_url }));
            }
          }
        })
      )
      .pipe(
        filter((t) => !_.isNil(t)),
        take(1),
        switchMap((user: IUser) => of(this.isRouteAllowed(state.url, user)))
      );
  }

  private isRouteAllowed(url: string, user: IUser) {
    if ((user.signupStatus == SignupStatus.New || user.signupStatus == SignupStatus.Invited
      || user.signupStatus == SignupStatus.PasswordCreated) && url.includes("profile-setup")) {
      return true;
    }
    if (
      url.includes("company-setup") &&
      this.isInRole(user, [Role.KeyContact, Role.FMGuest, Role.DocumentManager], [SignupStatus.ProfileApproved])
    ) {
      return true;
    }
    if (
      url.includes("complete") &&
      this.isInRole(user, [Role.Completer, Role.KeyContact, Role.Populator], [SignupStatus.Completed]) &&
      this.companyTypeIncludes(user.companyTypes, CompanyType.Distributor)
    ) {
      return true;
    }
    if (url.includes("dashboard") && user.signupStatus === SignupStatus.Completed) {
      return true;
    }
    if (
      url.includes("admin") &&
      !url.includes("fmadmin") &&
      this.isInRole(user, [Role.KeyContact], [SignupStatus.Completed]) &&
      this.companyTypeIncludes(user.companyTypes, CompanyType.Distributor)
    ) {
      return true;
    }
    if (
      url.includes("fmadmin") &&
      this.isInRole(user, [Role.KeyContact], [SignupStatus.Completed]) &&
      this.companyTypeIncludes(user.companyTypes, CompanyType.FundManager)
    ) {
      return true;
    }
    if (
      url.includes("fmtasks") &&
      this.isInRole(
        user,
        [Role.KeyContact, Role.Approver, Role.Reviewer, Role.FMTemplateOwner],
        [SignupStatus.Completed]
      ) &&
      this.companyTypeIncludes(user.companyTypes, CompanyType.FundManager)
    ) {
      return true;
    }
    if (
      url.includes("reporting") &&
      this.isInRole(user, [Role.Reporter], [SignupStatus.Completed]) &&
      (
        this.companyTypeIncludes(user.companyTypes, CompanyType.FundManager) ||
        this.isInRole(user, [Role.DDDAdmin], [SignupStatus.Completed])
      )
    ) {
      return true;
    }
    if (
      url.includes("release") &&
      this.isInRole(user, [Role.KeyContact, Role.Releaser], [SignupStatus.Completed]) &&
      this.companyTypeIncludes(user.companyTypes, CompanyType.Distributor)
    ) {
      return true;
    }
    if (
      url.includes("assign") &&
      this.isInRole(user, [Role.KeyContact, Role.Populator], [SignupStatus.Completed]) &&
      this.companyTypeIncludes(user.companyTypes, CompanyType.Distributor)
    ) {
      return true;
    }
    if (
      url.includes("distributors") &&
      this.isInRole(user, [Role.KeyContact, Role.Reviewer, Role.Approver], [SignupStatus.Completed]) &&
      this.companyTypeIncludes(user.companyTypes, CompanyType.FundManager)
    ) {
      return true;
    }
    if (
      url.includes("managetemplate") &&
      this.isInRole(user, [Role.FMTemplateOwner], [SignupStatus.Completed]) &&
      this.companyTypeIncludes(user.companyTypes, CompanyType.FundManager)
    ) {
      return true;
    }
    if (
      url.includes("fundmanagers") &&
      this.isInRole(user, [Role.KeyContact, Role.Releaser], [SignupStatus.Completed]) &&
      this.companyTypeIncludes(user.companyTypes, CompanyType.Distributor)
    ) {
      return true;
    }
    if (
      url.includes("reviewgroups") &&
      this.isInRole(user, [Role.Approver, Role.KeyContact], [SignupStatus.Completed]) &&
      this.companyTypeIncludes(user.companyTypes, CompanyType.FundManager)
    ) {
      return true;
    }
    if (url.includes("support") && this.isInRole(user, [Role.DDDAdmin], [SignupStatus.Completed])) {
      return true;
    }
    if (url.includes("tasks") && this.isInRole(user, [Role.DDDAdmin], [SignupStatus.Completed])) {
      return true;
    }
    if (url.includes("allinteractions") && this.isInRole(user, [Role.DDDAdmin], [SignupStatus.Completed])) {
      return true;
    }
    if (
      url.includes("analytics") &&
      this.isInRole(user, [Role.KeyContact], [SignupStatus.Completed]) &&
      this.companyTypeIncludes(user.companyTypes, CompanyType.FundManager)
    ) {
      return true;
    }
    if (url.includes("documents") && this.isInRole(user, [Role.DocumentManager], [SignupStatus.Completed])) {
      return true;
    }
    if (
      url.includes("fmguest") &&
      this.isInRole(user, [Role.FMGuest], [SignupStatus.Completed]) &&
      this.companyTypeIncludes(user.companyTypes, CompanyType.Intermediary)
    ) {
      return true;
    }
    if (
      url.includes("fmanalytics") &&
      this.isInRole(user, [Role.KeyContact], [SignupStatus.Completed]) &&
      this.companyTypeIncludes(user.companyTypes, CompanyType.FundManager)
    ) {
      return true;
    }
    this.store.dispatch(
      new ActionToastrError({
        error: {
          message: "Access Denied"
        }
      })
    );
    if (user.signupStatus == SignupStatus.New || user.signupStatus == SignupStatus.Invited || user.signupStatus == SignupStatus.PasswordCreated) {
      this.router.navigate(["setup/profile-setup"]);
      return true
    }
    if (user.signupStatus ==  SignupStatus.ProfileApproved) {
      this.router.navigate(["setup/company-setup"]);
      return true
    }
    if (this.isInRole(user, [Role.DDDAdmin], [SignupStatus.Completed])) {
      this.router.navigate(["support"]);
    } else {
      if (user.signupStatus === SignupStatus.Completed) {
        this.router.navigate(["dashboard"]);
      } else if (user.signupStatus === SignupStatus.ProfileApproved) {
        this.router.navigate(["company-setup"]);
      }
    }
    return false;
  }

  private isInRole(user: IUser, roles: Role[], signupStatuses: SignupStatus[]) {
    return (
      user &&
      user.roles.length &&
      signupStatuses.includes(user.signupStatus) &&
      roles.some((t) => user.roles.includes(t))
    );
  }

  private isHasSingleRole(user: IUser, roles: Role[]) {
    return user && user.roles.length && user.roles.length === 1 && roles.some((t) => user.roles.includes(t));
  }

  public companyTypeIncludes(array: Array<CompanyType>, value: number): boolean {
    return array.indexOf(value) > -1;
  }
}
