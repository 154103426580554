import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input, ViewContainerRef } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialog, _MatDialogContainerBase } from "@angular/material/dialog";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { environment } from "@env/environment";
import { ActivatedRoute } from "@angular/router";
import { ActionToastrWarning } from "@app/core/toastr/toastr.reducer";

import {
  selectorUser,
  ActionUserUpdate,
  IUser,
  PhoneType,
  ActionUserApprove,
  SignupStatus
} from "@app/core/auth/identity/user.reducer";
import {
  Country,
  CountryQuestionGet,
  selectorCountries
} from "@shared/modules/questions-module-shared/components/country-question/reducers";
import { ConfigurableBaseComponent } from "@shared/components";
import { ActionUserResolveGuide, ActionRejectResponsibility } from "@app/core/auth/identity/user.reducer";
import { PhoneNumberHelper } from "@app/core/utils/phone-number-helper";
import { RejectAndConfirmDialogService } from "@shared/dialogs/reject-confirm-dialog/reject-confirm-dialog.service";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { NgSelectModule } from "@ng-select/ng-select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FieldErrorShowComponent } from "../../../../shared/components/field-error-show/field-error-show.component";
import { NgClass, NgIf, NgFor } from "@angular/common";

/**
 * The user profile component
 *
 * @export
 * @class ProfileComponent
 * @extends {ConfigurableBaseComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
    selector: "ddd-profile",
    templateUrl: "./profile.component.html",
    styleUrls: ["./profile.component.scss"],
    standalone: true,
    imports: [NgClass, NgIf, FormsModule, ReactiveFormsModule, FieldErrorShowComponent, MatTooltipModule, NgFor, NgSelectModule, MatProgressBarModule, TranslateModule]
})
export class ProfileComponent extends ConfigurableBaseComponent implements OnInit, OnDestroy {
  /**
   * Inputs need for show profile component in dialog
   *
   * @type {boolean}
   * @memberof ProfileComponent
   */
  @Input() updateUserInfoDialog: boolean;

  /**
   * ElementRef for mobile number input
   *
   * @type {ElementRef}
   * @memberof ProfileComponent
   */
  @ViewChild("mobilePhone") mobilePhone: ElementRef;

  /**
   * ElementRef for landline number input
   *
   * @type {ElementRef}
   * @memberof ProfileComponent
   */
  @ViewChild("landline") landline: ElementRef;

  /**
   *  ElementRef for mobile country code input
   *
   * @type {ElementRef}
   * @memberof ProfileComponent
   */
  @ViewChild("mobilePhoneCountryCode") mobilePhoneCountryCode: ElementRef;

  /**
   * ElementRef for landline country code input
   *
   * @type {ElementRef}
   * @memberof ProfileComponent
   */
  @ViewChild("countryCodeLandline") countryCodeLandline: ElementRef;

  /**
   * Unsubscribe subject for subscribers
   *
   * @type {Subject<void>}
   * @memberof ProfileComponent
   */
  unsubscribe$: Subject<void> = new Subject<void>();

  /**
   * Local reference of FormGroup
   *
   * @type {FormGroup}
   * @memberof ProfileComponent
   */
  form: UntypedFormGroup;

  /**
   * User information
   *
   * @type {IUser}
   * @memberof ProfileComponent
   */
  user: IUser;

  /**
   * Countries list
   *
   * @type {Country[]}
   * @memberof ProfileComponent
   */
  countriesSelect: Country[];

  /**
   * Selected value for landline country code
   *
   * @type {string}
   * @memberof ProfileComponent
   */
  landlineCountryAlphaCode: string;

  /**
   * Selected value for phone country code
   *
   * @type {string}
   * @memberof ProfileComponent
   */
  phoneCountryAlphaCode: string;

  /**
   * Boolean value for is admin status
   *
   * @memberof ProfileComponent
   */
  isAdmin = false;
  /**
   * @type {boolean}
   *  @memberof ProfileComponent
   */
  isFMGuest = false;
  /**
   * Departments list
   *
   * @type {string[]}
   * @memberof ProfileComponent
   */

  /**
   * @type {boolean}
   *  @memberof ProfileComponent
   */
  isSetup = false;

  /**
   * @type {boolean}
   *  @memberof ProfileComponent
   */
  isRejected = false;

  // TODO: move in to model file
  departmentOptions: string[] = [
    "Distribution: Distribution, Sales & Marketing",
    "Distribution: Distribution Operations/Sales Support",
    "Distribution: Investor Relationship Management",
    "Distribution: Distributor Relationship Management",
    "Product: Product Provider Management",
    "Product: Product Development, Governance & Management",
    "IM: Investment Management",
    "IM: Credit/Market/Investment Risk",
    "IM: Dealing/Trading",
    "GRC: Business/Enterprise Risk",
    "GRC: Compliance",
    "GRC: Financial Crime",
    "GRC: Legal",
    "GRC: Internal Control/Monitoring",
    "GRC: Internal Audit",
    "Ops & IT: Investment Operations & Accounting",
    "Ops & IT: Outsourced Service Provider Management",
    "Ops & IT: Information Technology",
    "Ops & IT: Information Security",
    "Other"
  ];

  /**
   * Boolean value for show department comment status
   *
   * @memberof ProfileComponent
   */
  showDepartmentComment = false;
  
  /**
   * Const value for terms url
   *
   * @memberof ProfileComponent
   */
  private TERMS_AND_CONDITIONS_URL = "https://carne-digital.atlassian.net/wiki/external/2723151873/MGE3MDJhNTc4NDc5NDZjM2E4OTI3MTQwNWFmNDZkMTA";

  /**
   * Creates an instance of ProfileComponent.
   * @param {Store<any>} store
   * @param {TranslateService} translate
   * @param {FormBuilder} formBuilder
   * @param {MatDialog} dialogRef
   * @memberof ProfileComponent
   */
  constructor(
    public readonly store: Store<any>,
    public readonly translate: TranslateService,
    private confirmDialogService: RejectAndConfirmDialogService,
    private viewContainerRef: ViewContainerRef,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialog
  ) {
    super(translate, store);
  }

  /**
   * Initialize the directive/component after Angular first displays the data-bound properties and sets the directive/component's
   * input properties.
   *
   * @memberof ProfileComponent
   */
  ngOnInit(): void {
    this.createForm();
    this.store.dispatch(new CountryQuestionGet({ base_url: environment.client.base_url }));

    this.store.pipe(select(selectorCountries), takeUntil(this.unsubscribe$)).subscribe((countries: Country[]) => {
      if (countries) {
        this.countriesSelect = countries;
      }
    });

    this.store.pipe(select(selectorUser), takeUntil(this.unsubscribe$)).subscribe((user: IUser) => {
      if (user) {
        const userPhones = user.phones;
        this.user = user;
        this.isAdmin = user.isSessionOpened;
        this.isFMGuest = user.roles.includes(9);
        if (
          user.signupStatus == SignupStatus.New ||
          user.signupStatus == SignupStatus.Invited ||
          user.signupStatus == SignupStatus.PasswordCreated
        ) {
          this.isSetup = true;
          this.form.addControl('terms', new UntypedFormControl(null));
        }
        this.form.controls["firstName"].patchValue(user.firstName);
        this.form.controls["lastName"].patchValue(user.lastName);
        this.form.controls["email"].patchValue(user.email);
        this.form.controls["titleRole"].patchValue(user.jobTitle);
        this.form.controls["department"].patchValue(user.departments);
        this.form.controls["departmentComment"].patchValue(user.departmentComment);
        this.selectDepartment();
        if (userPhones && userPhones.length !== 0) {
          if (userPhones.filter((x) => x.type == PhoneType.LandLine).length > 0) {
            this.landlineCountryAlphaCode = PhoneNumberHelper.getCountryCode(userPhones, PhoneType.LandLine);
            this.form.controls["landline"].patchValue(PhoneNumberHelper.getNumber(userPhones, PhoneType.LandLine));
          }
          if (userPhones.filter((x) => x.type == PhoneType.Mobile).length > 0) {
            this.phoneCountryAlphaCode = PhoneNumberHelper.getCountryCode(userPhones, PhoneType.Mobile);
            this.form.controls["phone"].patchValue(PhoneNumberHelper.getNumber(userPhones, PhoneType.Mobile));
          }
        }
      }
    });
  }

  /**
   * Create profile form with FormBuilder
   *
   * @memberof ProfileComponent
   */
  createForm(): void {
    this.form = this.formBuilder.group({
      firstName: [null, [Validators.required, this.isOnlyWhitespaces]],
      lastName: [null, [Validators.required, this.isOnlyWhitespaces]],
      email: [
        { value: null, disabled: true },
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ]
      ],
      landline: [null, Validators.required],
      phone: [null],
      titleRole: [null, [Validators.required, this.isOnlyWhitespaces]],
      department: [null, Validators.required],
      departmentComment: [null]
    });
  }

  /**
   * Submit user form, will submit if form valid, else will start validating form fields function
   *
   * @return {*}
   * @memberof ProfileComponent
   */
  onSubmit() {
    /**
     * If have not valid phone number stop submit
     */
    if (!this.validatePhoneNumber() || !this.validateLandline()) {
      return false;
    }
    if (this.form.valid) {
      const formData = {
        email: this.form.getRawValue().email,
        title: this.user.title,
        firstName: this.form.getRawValue().firstName,
        lastName: this.form.getRawValue().lastName,
        phones: [
          {
            type: PhoneType.Mobile,
            number: PhoneNumberHelper.getFormattedPhoneNumber(this.mobilePhone, this.mobilePhoneCountryCode)
          },
          {
            type: PhoneType.LandLine,
            number: PhoneNumberHelper.getFormattedPhoneNumber(this.landline, this.countryCodeLandline)
          }
        ],
        jobTitle: this.form.getRawValue().titleRole,
        departments: this.form.getRawValue().department,
        departmentComment: this.showDepartmentComment ? this.form.getRawValue().departmentComment : ""
      };
      if (this.isSetup) {
        if (this.form.getRawValue().terms) {
          this.store.dispatch(
            new ActionUserApprove({
              user: formData,
              base_url: environment.client.base_url
            })
          );
        } else {
          this.store.dispatch(
            new ActionToastrWarning({
              message: "If you read and agree to the TERMS please check."
            })
          );
        }
      } else if (!this.isSetup) {
        this.store.dispatch(
          new ActionUserUpdate({
            user: formData,
            base_url: environment.client.base_url
          })
        );
      }
      if (this.updateUserInfoDialog) {
        this.store.dispatch(
          new ActionUserResolveGuide({
            guidename: "UpdatedUserInfo",
            userGuidename: "updatedUserInfo",
            base_url: environment.client.base_url,
            dialog: true
          })
        );
        this.dialogRef.closeAll();
      }
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  /**
   * Check field valid for display error component
   *
   * @param {string} field
   * @return {*}  {boolean}
   * @memberof ProfileComponent
   */
  isFieldValid(field: string): boolean {
    if (this.form.get(field).disabled) {
      return false;
    } else {
      return !this.form.get(field).valid && (this.form.get(field).touched || this.form.get(field).dirty);
    }
  }

  /**
   * Validate form fields function, mark fields as touched
   *
   * @param {FormGroup} formGroup
   * @memberof ProfileComponent
   */
  validateAllFormFields(formGroup: UntypedFormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * Validate phone number
   *
   * @return {*}  {boolean}
   * @memberof ProfileComponent
   */
  validatePhoneNumber(): boolean {
    return PhoneNumberHelper.validatePhoneNumber(this.mobilePhone, this.mobilePhoneCountryCode);
  }

  /**
   * Validate landline number
   *
   * @return {*}  {boolean}
   * @memberof ProfileComponent
   */
  validateLandline(): boolean {
    return PhoneNumberHelper.validatePhoneNumber(this.landline, this.countryCodeLandline);
  }

  /**
   * Update data after select department
   *
   * @memberof ProfileComponent
   */
  selectDepartment(): void {
    const selectedDepartmentOptions = this.form.getRawValue().department;
    if (selectedDepartmentOptions && selectedDepartmentOptions.indexOf("Other") > -1) {
      this.showDepartmentComment = true;
      this.form.controls["departmentComment"].setValidators([Validators.required, this.isOnlyWhitespaces]);
    } else {
      this.showDepartmentComment = false;
      this.form.controls["departmentComment"].setValidators([]);
    }

    this.form.controls["departmentComment"].updateValueAndValidity();
  }

  /**
   * Close modals, needed for update user info modal
   *
   * @memberof ProfileComponent
   */
  closeModals(): void {
    this.dialogRef.closeAll();
  }

  /**
   * Additional validate rule, check if string has only whitespaces symbols
   *
   * @param {FormControl} control
   * @return {*}  {(null | { isOnlyWhitespaces: boolean })}
   * @memberof ProfileComponent
   */
  isOnlyWhitespaces(control: UntypedFormControl): null | { isOnlyWhitespaces: boolean } {
    const string = control.value;
    return string && string.trim().length > 0 ? null : { isOnlyWhitespaces: true };
  }

  rejectResponcibility() {
    var message =
      "<p>If you reject at this point, you are rejecting both your personal profile on the platform as well as the responsibility of any companies you are assigned to. You may be assigned to multiple companies and will be asked for each one individually to accept the responsibility.</p>" +
      "<p>If you believe someone else in your company should be the key contact for completing this questionnaire, please log in, reject responsibility and assign a new colleague as required.</p>" +
      '<div class="sub-title"><b>Please explain why you are rejecting this invitation</b></div>';

    const rejectDialog = {
      title: "Reject responsibility",
      message: message,
      cancel: "Cancel",
      okBtn: "Reject responsibility"
    };
    this.confirmDialogService.reject(rejectDialog, this.viewContainerRef, false).subscribe((res) => {
      if (res) {
        this.store.dispatch(
          new ActionRejectResponsibility({
            rejectUser: {
              rejectReason: res
            },
            base_url: environment.client.base_url
          })
        );
      }
    });
  }

  /**
   * Custom cleanup that needs to occur when the instance is destroyed, unsubscribe Observables and detach event handlers to avoid
   * memory leaks.
   *
   * @memberof ProfileComponent
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
