import { HttpErrorResponse } from "@angular/common/http";
import { Action, createFeatureSelector, createSelector } from "@ngrx/store";
import * as _ from "lodash";

import { Option } from "app/shared/models/questionnaire.interfaces";

export const OPTIONS_KEY = "OPTIONS.LIST";

/**
 * Choice question actions types
 */
export enum ChoiceQuestionActionTypes {
  CHOICE_QUESTION_GET = "[ChoiceQuestion] Get",
  CHOICE_QUESTION_GET_SUCCESS = "[ChoiceQuestion] Get Success",
  CHOICE_QUESTION_GET_ERROR = "[ChoiceQuestion] Get Error"
}

export class ChoiceQuestionGet implements Action {
  readonly type = ChoiceQuestionActionTypes.CHOICE_QUESTION_GET;
  constructor(public payload?: { key: string }) {}
}

export class ChoiceQuestionGetSuccess implements Action {
  readonly type = ChoiceQuestionActionTypes.CHOICE_QUESTION_GET_SUCCESS;
  constructor(public payload: { currentKey: string; options: Option[] }) {}
}

export class ChoiceQuestionGetError implements Action {
  readonly type = ChoiceQuestionActionTypes.CHOICE_QUESTION_GET_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

/**
 * Choice question action types.
 */
export type ChoiceQuestionActions = ChoiceQuestionGet | ChoiceQuestionGetSuccess | ChoiceQuestionGetError;

/**
 * Choice question initial state
 */
export const initialChoiceQuestionState: IChoiceQuestionsState = {
  loading: false,
  options: []
};

/**
 * Choice question state selector
 */
const featureChoiceQuestionState = createFeatureSelector<any>("questionsOptions");
export const selectorChoiceQuestionOptionsListState = createSelector(featureChoiceQuestionState, (state) => {
  if (state) {
    return state.optionsList;
  }
});

export const selectorChoiceQuestionState = createSelector(selectorChoiceQuestionOptionsListState, (state) => {
  if (state) {
    return state.options;
  }
});

/**
 * Reducer function for choice question state.
 */
export function choiceQuestionReducer(
  state: IChoiceQuestionsState = initialChoiceQuestionState,
  action: ChoiceQuestionActions
): IChoiceQuestionsState {
  switch (action.type) {
    case ChoiceQuestionActionTypes.CHOICE_QUESTION_GET:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ChoiceQuestionActionTypes.CHOICE_QUESTION_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        options: !_.find(state.options, { key: action.payload.currentKey })
          ? state.options.concat({
              key: action.payload.currentKey,
              options: action.payload.options
            })
          : state.options,
        error: null
      };
    case ChoiceQuestionActionTypes.CHOICE_QUESTION_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
}

/**
 * Interface for choice question state.
 */
export interface IChoiceQuestionsState {
  loading: boolean;
  options?: OptionsObj[];
  error?: HttpErrorResponse;
}

/**
 * Interface for options list.
 */
export interface OptionsObj {
  key: string;
  options: Option[];
}
