/**
 * Since we use srs/assets/environment.json we don't really need this config. anymore
 * we just keep it for consistency, but modifying it won't take any effect
 * this particular file is used for local dev. only
 * @type {{production: boolean; client: {base_url: string; identity_url: string}}}
 */
export const environment = {
  production: false,
  es_host: "https://es.local.distributordd.com",
  es_index: "dev",
  es_loglevel: "Debug",
  sc_dns: "",
  sc_loglevel: "",
  sc_environment: "",
  client: {
    main_site_url: "",
    base_url: "",
    reporting_url: "",
    identity_url: "",
    power_bi_url: ""
  }
};
