import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { LocalStorageService } from "../local-storage/local-storage.service";

import { TranslateService } from "@ngx-translate/core";
import {
  LANGUAGE_KEY,
  LanguageActionTypes,
  ActionAddLanguage,
  ActionPersistLanguage,
  ActionSetLanguage
} from "@app/core/i18n/i18n.reducer";

@Injectable()
export class LanguageEffects {
  constructor(
    private actions$: Actions<Action>,
    private localStorageService: LocalStorageService,
    private readonly translate: TranslateService
  ) {}

  setLanguage = createEffect(
    (): Observable<Action> => {
      return this.actions$.pipe(
        ofType(LanguageActionTypes.PERSIST),
        tap((action: ActionPersistLanguage) =>
          this.localStorageService.setItem(LANGUAGE_KEY, {
            currentLanguage: action.payload.currentLanguage
          })
        )
      );
    },
    { dispatch: false }
  );

  persistLanguage = createEffect(
    (): Observable<Action> => {
      return this.actions$.pipe(
        ofType(LanguageActionTypes.SET),
        tap((action: ActionSetLanguage) => this.translate.setDefaultLang(action.payload.currentLanguage))
      );
    },
    { dispatch: false }
  );

  addLanguages = createEffect(
    (): Observable<Action> => {
      return this.actions$.pipe(
        ofType(LanguageActionTypes.ADD),
        tap((action: ActionAddLanguage) => this.translate.addLangs(action.payload.listOfLanguages))
      );
    },
    { dispatch: false }
  );
}
