import { HttpErrorResponse } from "@angular/common/http";
import { Action } from "@ngrx/store";

export const COLLEAGUE_KEY = "SECTIONS.COLLEAGUE";

/**
 * Interface for colleague state.
 */
export interface IColleagueState {
  loading: boolean;
  colleagues?: Colleague[];
  importList?: Colleague[];
  importResult?: Colleague[];
  colleague?: Colleague;
  colleagueStatus?: ColleagueStatus;
  error?: HttpErrorResponse;
}

/**
 * Colleagues setup initial state
 */
export const initialColleagueState: IColleagueState = {
  loading: false,
  colleagueStatus: {
    action: "",
    result: null
  }
};

/**
 * Colleagues actions types
 */
export enum ColleaguesActionTypes {
  COLLEAGUES_RETRIVE = "[Colleagues] Retrieve",
  COLLEAGUES_RETRIVE_SUCCESS = "[Colleagues] Retrieve Success",
  COLLEAGUES_RETRIVE_ERROR = "[Colleagues] Retrieve Error",
  COLLEAGUES_ADD = "[Colleague] Add",
  COLLEAGUES_ADD_SUCCESS = "[Colleague] Add Success",
  COLLEAGUES_ADD_ERROR = "[Colleague] Add Error",
  COLLEAGUES_UPDATE = "[Colleague] Update",
  COLLEAGUES_UPDATE_SUCCESS = "[Colleague] Update Success",
  COLLEAGUES_UPDATE_ERROR = "[Colleague] Update Error",
  COLLEAGUES_DELETE = "[Colleague] Delete",
  COLLEAGUES_DELETE_SUCCESS = "[Colleague] Delete Success",
  COLLEAGUES_DELETE_ERROR = "[Colleague] Delete Error",
  COLLEAGUES_CHECK_EXIST = "[Colleague] Check Exist",
  COLLEAGUES_CHECK_EXIST_SUCCESS = "[Colleague] Check Exist Success",
  COLLEAGUES_CHECK_EXIST_ERROR = "[Colleague] Check Exist Error",
  IMPORT_LIST_RETRIVE = "[Colleagues] Import List Retrieve",
  IMPORT_LIST_RETRIVE_SUCCESS = "[Colleagues] Import List Retrieve Success",
  IMPORT_LIST_RETRIVE_ERROR = "[Colleagues] Import List Retrieve Error",
  IMPORT_LIST_SEND = "[Colleagues] Import List Send",
  IMPORT_LIST_SEND_SUCCESS = "[Colleagues] Import List Send Success",
  IMPORT_LIST_SEND_ERROR = "[Colleagues] Import List Send Error",
  CHANGE_COLLEAGUE_ACTIVE_STATUS = "[Colleagues] Change Active Status",
  COLLEAGUES_UPDATE_INVITED_USER = "[Colleague] Update Invited User",
  COLLEAGUES_CLEAR_STATUS = "[Colleague] Clear Status",
  COLLEAGUE_CLEAR_EXIST_STATUS = "[Colleague] Clear Exist Status"
}

export class ActionColleaguesRetrieve implements Action {
  readonly type = ColleaguesActionTypes.COLLEAGUES_RETRIVE;
  constructor() {}
}

export class ActionColleaguesRetrieveSuccess implements Action {
  readonly type = ColleaguesActionTypes.COLLEAGUES_RETRIVE_SUCCESS;
  constructor(public payload: { colleagues: Colleague[] }) {}
}

export class ActionColleaguesRetrieveError implements Action {
  readonly type = ColleaguesActionTypes.COLLEAGUES_RETRIVE_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionColleagueAdd implements Action {
  readonly type = ColleaguesActionTypes.COLLEAGUES_ADD;
  constructor(public payload: { colleague: Colleague }) {}
}

export class ActionColleagueAddSuccess implements Action {
  readonly type = ColleaguesActionTypes.COLLEAGUES_ADD_SUCCESS;
  constructor(public payload?: { colleague: Colleague }) {}
}

export class ActionColleagueAddError implements Action {
  readonly type = ColleaguesActionTypes.COLLEAGUES_ADD_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionColleagueUpdate implements Action {
  readonly type = ColleaguesActionTypes.COLLEAGUES_UPDATE;
  constructor(public payload: { colleague: Colleague }) {}
}

export class ActionColleagueUpdateSuccess implements Action {
  readonly type = ColleaguesActionTypes.COLLEAGUES_UPDATE_SUCCESS;
  constructor(public payload?: { colleague: Colleague }) {}
}

export class ActionColleagueUpdateError implements Action {
  readonly type = ColleaguesActionTypes.COLLEAGUES_UPDATE_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionColleagueDelete implements Action {
  readonly type = ColleaguesActionTypes.COLLEAGUES_DELETE;
  constructor(public payload: { id: string }) {}
}

export class ActionColleagueDeleteSuccess implements Action {
  readonly type = ColleaguesActionTypes.COLLEAGUES_DELETE_SUCCESS;
  constructor(public payload?: { id: string }) {}
}

export class ActionColleagueDeleteError implements Action {
  readonly type = ColleaguesActionTypes.COLLEAGUES_DELETE_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionColleagueCheckExist implements Action {
  readonly type = ColleaguesActionTypes.COLLEAGUES_CHECK_EXIST;
  constructor(public payload: { email: string }) {}
}

export class ActionColleagueCheckExistSuccess implements Action {
  readonly type = ColleaguesActionTypes.COLLEAGUES_CHECK_EXIST_SUCCESS;
  constructor(public payload: { colleague: Colleague }) {}
}

export class ActionColleagueCheckExistError implements Action {
  readonly type = ColleaguesActionTypes.COLLEAGUES_CHECK_EXIST_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionImportListRetrieve implements Action {
  readonly type = ColleaguesActionTypes.IMPORT_LIST_RETRIVE;
  constructor() {}
}

export class ActionImportListRetrieveSuccess implements Action {
  readonly type = ColleaguesActionTypes.IMPORT_LIST_RETRIVE_SUCCESS;
  constructor(public payload: { importList: Colleague[] }) {}
}

export class ActionImportListRetrieveError implements Action {
  readonly type = ColleaguesActionTypes.IMPORT_LIST_RETRIVE_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionChangeColleagueActiveStatus implements Action {
  readonly type = ColleaguesActionTypes.CHANGE_COLLEAGUE_ACTIVE_STATUS;
  constructor(public payload: { email: string }) {}
}

export class ActionImportListSend implements Action {
  readonly type = ColleaguesActionTypes.IMPORT_LIST_SEND;
  constructor(public payload: { importList: Array<string> }) {}
}

export class ActionImportListSendSuccess implements Action {
  readonly type = ColleaguesActionTypes.IMPORT_LIST_SEND_SUCCESS;
  constructor(public payload?: { importResult: Colleague[] }) {}
}

export class ActionImportListSendError implements Action {
  readonly type = ColleaguesActionTypes.IMPORT_LIST_SEND_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionColleagueUpdateInvitedUser implements Action {
  readonly type = ColleaguesActionTypes.COLLEAGUES_UPDATE_INVITED_USER;
  constructor(public payload: { colleague: Colleague; isLoggedUser: boolean }) {}
}

export class ActionColleaguesClearStatus implements Action {
  readonly type = ColleaguesActionTypes.COLLEAGUES_CLEAR_STATUS;
  constructor() {}
}

export class ActionColleagueClearExistStatus implements Action {
  readonly type = ColleaguesActionTypes.COLLEAGUE_CLEAR_EXIST_STATUS;
  constructor() {}
}

/**
 * Colleagues action types.
 */
export type ColleagueActions =
  | ActionColleaguesRetrieve
  | ActionColleaguesRetrieveSuccess
  | ActionColleaguesRetrieveError
  | ActionColleagueAdd
  | ActionColleagueAddSuccess
  | ActionColleagueAddError
  | ActionColleagueUpdate
  | ActionColleagueUpdateSuccess
  | ActionColleagueUpdateError
  | ActionColleagueDelete
  | ActionColleagueDeleteSuccess
  | ActionColleagueDeleteError
  | ActionColleagueCheckExist
  | ActionColleagueCheckExistSuccess
  | ActionColleagueCheckExistError
  | ActionImportListRetrieve
  | ActionImportListRetrieveSuccess
  | ActionImportListRetrieveError
  | ActionImportListSend
  | ActionImportListSendSuccess
  | ActionImportListSendError
  | ActionChangeColleagueActiveStatus
  | ActionColleagueUpdateInvitedUser
  | ActionColleaguesClearStatus
  | ActionColleagueClearExistStatus;

/**
 * Colleagues state selector
 */
export const selectorColleagues = (state) => state && state.colleagues && state.colleagues.colleagues;
export const selectorColleagueExist = (state) => state.colleagues && state.colleagues.colleagues.colleague;
export const selectorColleagueImportList = (state) => state.colleagues && state.colleagues.colleagues.importList;
export const selectorColleagueImportResult = (state) => state.colleagues && state.colleagues.colleagues.importResult;

/**
 * Reducer function for colleagues state.
 */
export function colleaguesReducer(
  state: IColleagueState = initialColleagueState,
  action: ColleagueActions
): IColleagueState {
  switch (action.type) {
    case ColleaguesActionTypes.COLLEAGUES_RETRIVE:
      return {
        ...state,
        loading: true,
        colleagues: null,
        colleagueStatus: {
          action: "",
          result: null
        },
        error: null
      };
    case ColleaguesActionTypes.COLLEAGUES_RETRIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        colleagues: action.payload.colleagues,
        colleagueStatus: {
          action: "",
          result: null
        },
        error: null
      };
    case ColleaguesActionTypes.COLLEAGUES_RETRIVE_ERROR:
      return {
        ...state,
        loading: false,
        colleagues: null,
        colleagueStatus: {
          action: "",
          result: null
        },
        error: action.payload.error
      };
    case ColleaguesActionTypes.COLLEAGUES_ADD_SUCCESS:
      return {
        ...state,
        colleagueStatus: {
          action: "add",
          result: action.payload
        }
      };
    case ColleaguesActionTypes.COLLEAGUES_UPDATE_SUCCESS:
      return {
        ...state,
        colleagueStatus: {
          action: "update",
          result: action.payload
        }
      };
    case ColleaguesActionTypes.COLLEAGUES_CHECK_EXIST:
      return {
        ...state,
        colleague: null
      };
    case ColleaguesActionTypes.COLLEAGUES_CHECK_EXIST_SUCCESS:
      return {
        ...state,
        colleague: action.payload.colleague
      };
    case ColleaguesActionTypes.COLLEAGUES_DELETE_SUCCESS:
      return {
        ...state,
        colleagueStatus: {
          action: "delete",
          result: action.payload
        }
      };
    case ColleaguesActionTypes.IMPORT_LIST_RETRIVE:
      return {
        ...state,
        loading: true,
        importList: null,
        importResult: null,
        error: null
      };
    case ColleaguesActionTypes.IMPORT_LIST_RETRIVE_SUCCESS:
      return {
        ...state,
        loading: true,
        importList: action.payload.importList.map((colleague) => {
          return {
            ...colleague,
            active: false
          };
        }),
        error: null
      };
    case ColleaguesActionTypes.IMPORT_LIST_RETRIVE_ERROR:
      return {
        ...state,
        loading: false,
        importList: null,
        error: action.payload.error
      };

    case ColleaguesActionTypes.CHANGE_COLLEAGUE_ACTIVE_STATUS:
      return {
        ...state,
        importList: state.importList.map((colleague) => {
          if (colleague.email !== action.payload.email) {
            return colleague;
          }
          return {
            ...colleague,
            active: !colleague.active
          };
        })
      };
    case ColleaguesActionTypes.IMPORT_LIST_SEND:
      return {
        ...state,
        loading: true,
        importList: null,
        error: null
      };
    case ColleaguesActionTypes.IMPORT_LIST_SEND_SUCCESS:
      return {
        ...state,
        loading: true,
        importResult: action.payload.importResult,
        error: null
      };
    case ColleaguesActionTypes.COLLEAGUES_CLEAR_STATUS:
      return {
        ...state,
        colleagueStatus: {
          action: "",
          result: null
        }
      };
    case ColleaguesActionTypes.COLLEAGUE_CLEAR_EXIST_STATUS:
      return {
        ...state,
        colleague: null
      };
    default:
      return state;
  }
}

/**
 * Interface for phone.
 */
export interface Phone {
  type: PhoneType;
  number: string;
}

/**
 * Interface for colleague status.
 */
export interface ColleagueStatus {
  action: string;
  result: any;
}

/**
 * Interface for colleague.
 */
export interface Colleague {
  id: string;
  companyId?: string;
  email: string;
  firstName: string;
  lastName: string;
  phones?: Phone[];
  title?: string;
  userSignupStatus?: number;
  active?: boolean;
}

export enum PhoneType {
  Mobile,
  LandLine
}
