import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { OAuthService, OAuthEvent } from "angular-oauth2-oidc";
import { Router } from "@angular/router";
import { filter } from "rxjs/operators";

import { ActionClear } from "@app/core/auth/identity/user.reducer";
import { environment } from "@env/environment";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@Component({
    template: '<div class="spinner-container"><mat-spinner></mat-spinner></div>',
    styleUrls: ["./logout.component.scss"],
    standalone: true,
    imports: [MatProgressSpinnerModule]
})
export class LogoutComponent implements OnInit {
  constructor(private oauthService: OAuthService, private readonly store: Store<any>, public router: Router) {}

  ngOnInit() {
    this.store.dispatch(new ActionClear());
    window.sessionStorage.clear();
    this.oauthService.logOut();
  }
}
