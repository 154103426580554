import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LogoutRoutingModule } from "./logout-routing.module";

import { SharedModule } from "@app/shared";
import { LogoutComponent } from "./logout.component";

@NgModule({
    imports: [CommonModule, LogoutRoutingModule, SharedModule, LogoutComponent],
    exports: [LogoutComponent]
})
export class LogoutModule {}
