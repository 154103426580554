<div class="content">
  <div class="title">
    {{
      interaction
        ? ("INTERACTION_SAVE_DIALOG.INTERACTION_DETAILS" | translate)
        : ("INTERACTION_SAVE_DIALOG.ADD_INTERACTION" | translate)
    }}
  </div>

  <form class="custom-form setup-form" [formGroup]="form">
    <ng-container *ngIf="interaction">
      <div class="control-group">
        <div class="label">{{ "FORM_INTERACTION.COMPANY" | translate }}</div>
        <div class="control-box">
          <div class="created-by-label">
            {{ data.companyName || interaction.companyName }}
          </div>
        </div>
      </div>
      <div class="control-group">
        <div class="label">{{ "FORM_INTERACTION.CREATED_BY" | translate }}</div>
        <div class="control-box">
          <div class="created-by-label">
            {{ interaction.createdBy.firstName }}
            {{ interaction.createdBy.lastName }},
            {{ interaction.createdBy.email }}
          </div>
        </div>
      </div>
    </ng-container>
    <div *ngIf="data.fromAllCompanies && !interaction" class="control-group">
      <div class="label">{{ "FORM_INTERACTION.COMPANY" | translate }}</div>
      <div class="control-box">
        <ng-select
          formControlName="company"
          [closeOnSelect]="true"
          [multiple]="false"
          [items]="allCompaniesList"
          (change)="selectCompany()"
          bindLabel="fullname"
          bindValue="id"
          placeholder="Click here to select Company"
          virtualScroll="true"
          notFoundText="Loading..."
          (search)="searchAdminCompanies($event)"
          (clear)="searchAdminCompanies($event)"
          [searchFn]="customSearchFunction"
          (scrollToEnd)="fetchAdminCompanies()"
          appendTo="html"
        >
        </ng-select>
        <ddd-field-error-show
          [displayError]="isFieldValid('company')"
          errorMsg="{{ 'MESSAGES_INTERACTION.ENTER_COMPANY' | translate }}"
        >
        </ddd-field-error-show>
      </div>
    </div>
    <div
      *ngIf="!data.fromAllCompanies && !interaction && !data.taskId && !data.interactionsGetData"
      class="control-group"
    >
      <div class="label">{{ "FORM_INTERACTION.COMPANY" | translate }}</div>
      <div class="control-box">
        <div class="created-by-label">
          {{ data.companyName || (interaction && interaction.companyName) }}
        </div>
      </div>
    </div>
    <div class="control-group">
      <div class="label">{{ "FORM_INTERACTION.DATE" | translate }}</div>
      <div class="control-box">
        <mat-form-field class="date-form-field">
          <input
            matInput
            [matDatepicker]="picker"
            placeholder="Choose a date"
            [ngModel]="selectedInteractionDate"
            formControlName="date"
            [max]="maxDate"
          />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker touchUi="true" #picker [disabled]="!this.isCurrentUser() && !this.isAdmin()"></mat-datepicker>
        </mat-form-field>
        <ddd-field-error-show
          [displayError]="isFieldValid('date')"
          errorMsg="{{ 'MESSAGES_INTERACTION.ENTER_DATE' | translate }}"
        >
        </ddd-field-error-show>
      </div>
    </div>
    <div class="control-group">
      <div class="label">{{ "FORM_INTERACTION.TYPE" | translate }}</div>
      <div class="control-box">
        <ng-select
          formControlName="type"
          bindLabel="label"
          bindValue="type"
          [items]="interactionTypes"
          [(ngModel)]="selectedInteractionTypes"
          [multiple]="true"
          appendTo="html"
        ></ng-select>
        <ddd-field-error-show
          [displayError]="isFieldValid('type')"
          errorMsg="{{ 'MESSAGES_INTERACTION.ENTER_TYPE' | translate }}"
        >
        </ddd-field-error-show>
      </div>
    </div>
    <div class="control-group">
      <div class="label">{{ "FORM_INTERACTION.STATUS" | translate }}</div>
      <div class="control-box">
        <ng-select
          formControlName="status"
          [items]="interactionStatuses"
          [(ngModel)]="selectedInteractionStatus"
          [searchable]="false"
          appendTo="html"
        ></ng-select>
      </div>
    </div>
    <div class="control-group">
      <div class="label">{{ "FORM_INTERACTION.CHANNEL" | translate }}</div>
      <div class="control-box">
        <ng-select
          formControlName="channel"
          [items]="interactionChannels"
          [(ngModel)]="selectedInteractionChannel"
          [searchable]="false"
          appendTo="html"
        ></ng-select>
        <ddd-field-error-show
          [displayError]="isFieldValid('channel')"
          errorMsg="{{ 'MESSAGES_INTERACTION.ENTER_CHANNEL' | translate }}"
        >
        </ddd-field-error-show>
      </div>
    </div>
    <div class="control-group">
      <div class="label">{{ "FORM_INTERACTION.NOTES" | translate }}</div>
      <div class="control-box">
        <textarea
          class="input-form"
          type="text"
          id="notes"
          formControlName="notes"
          placeholder="notes"
          [class.two-rows]="isTwoRows(form.get('notes').value)"
        ></textarea>
        <ddd-field-error-show
          [displayError]="isFieldValid('notes')"
          errorMsg="{{ 'MESSAGES_INTERACTION.ENTER_NOTES' | translate }}"
        >
        </ddd-field-error-show>
      </div>
    </div>
    <div class="control-group">
      <div class="label">{{ "FORM_INTERACTION.TAGS" | translate }}</div>
      <div class="control-box">
        <ng-select
          formControlName="tags"
          bindLabel="fullName"
          [items]="companyUsers"
          [(ngModel)]="taggedUsers"
          [multiple]="true"
          appendTo="html"
        >
        </ng-select>
      </div>
    </div>
  </form>

  <div class="actions">
    <ng-container *ngIf="!data.isFM">
      <button
        *ngIf="data.taskId && interaction"
        class="ddd-button"
        (click)="deleteInteraction()"
      >
        {{ "INTERACTION_SAVE_DIALOG.DELETE_INTERACTION" | translate }}
      </button>
    </ng-container>
    <button class="ddd-button" (click)="closeInteractionDialog()">
      {{ "INTERACTION_SAVE_DIALOG.CLOSE_BUTTON" | translate }}
    </button>
    <button
      *ngIf="(this.isCurrentUser() || this.isAdmin()) && !data.isFM"
      class="ddd-button raised"
      (click)="onSubmit()"
    >
      {{
        interaction
          ? ("INTERACTION_SAVE_DIALOG.SAVE_INTERACTION" | translate)
          : ("INTERACTION_SAVE_DIALOG.ADD_INTERACTION" | translate)
      }}
    </button>
  </div>
</div>
