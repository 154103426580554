import { HttpErrorResponse } from "@angular/common/http";
import { Action } from "@ngrx/store";
import * as _ from "lodash";

import { Field, Flag, Column } from "@shared/models/questionnaire.interfaces";

export interface ITemplateManagerState {
  loading: boolean;
  template?: Template;
  distributors?: any;
  templates?: TemplatesData;
  preselectedTemplate?: string;
  currentTemplateName?: string;
  reviewFrequencyData?: IReviewFrequencyData;
  error?: HttpErrorResponse;
}

export const initialTemplateManagerState: ITemplateManagerState = {
  loading: false
};

export enum TemplateManagerActionTypes {
  TEMPLATE_GET = "[Template] Get",
  TEMPLATE_GET_SUCCESS = "[Template] Get Success",
  TEMPLATE_GET_ERROR = "[Template] Get Error",
  TEMPLATE_UPDATE = "[Template] Update",
  TEMPLATE_UPDATE_SUCCESS = "[Template] Update Success",
  TEMPLATE_UPDATE_ERROR = "[Template] Update Error",
  TEMPLATE_PUBLISH = "[Template] Publish",
  TEMPLATE_PUBLISH_SUCCESS = "[Template] Publish Success",
  TEMPLATE_PUBLISH_ERROR = "[Template] Publish Error",
  TEMPLATES_GET = "[Templates] Get",
  TEMPLATES_GET_SUCCESS = "[Templates] Get Success",
  TEMPLATES_GET_ERROR = "[Templates] Get Error",
  UPDATE_SECTION_ACTIVE_STATUS = "[Templates] Update section active status",
  UPDATE_GROUP_ACTIVE_STATUS = "[Templates] Update group active status",
  TEMPLATE_RESET = "[Template] Reset",
  UPDATE_TEMPLATE_NAME = "[Template] Update name",
  CUSTOM_TEMPLATE_CHANGE_DOCUMENT_STATUS = "[Custom Template] Change Document Status",
  CUSTOM_TEMPLATE_CHANGE_GROUP_STATUS = "[Custom Template] Change Group Status",
  TEMPLATE_ADD_CUSTOM_SECTION = "[Template] Add Custom Sections",
  CHANGE_FLAGS = "[Question] Change Flags",
  TEMPLAT_UPDATE_HAS_RESIDUAL_RISK_CATEGORY = "[Tempate] Update Has Residual Risk Category",
  TEMPLATE_UPDATE_HAS_EXECUTIVE_OVERVIEW = "[Tempate] Update Has Executive Overview",
  TEMPLATE_UPDATE_HAS_RISK_MODEL = "[Tempate] Update Has Risk Model",
  UPDATE_OVERALL_COUNTRY_FLAGS = "[Question] Update Overall Country Flags",
  CHANGE_ANCHORED_QUESTION_FLAGS = "[Question] Change Anchored Question Flags",

  GET_ALL_DISTRIBUTORS = "[Template] Get All Distributors",
  GET_ALL_DISTRIBUTORS_SUCCESS = "[Template] Get All Distributors Success",
  GET_ALL_DISTRIBUTORS_ERROR = "[Template] Get All Distributors Error",
  TEMPLATES_GET_REVIEW_FREQUENCY_DATA = "[Template] Get Review Frequency Data",
  TEMPLATES_GET_REVIEW_FREQUENCY_DATA_SUCCESS = "[Template] Get Review Frequency Data Success",
  TEMPLATES_GET_REVIEW_FREQUENCY_DATA_ERROR = "[Template] Get Review Frequency Data Error",
  TEMPLATES_SET_REVIEW_FREQUENCY_DATA = "[Template] Set Review Frequency Data",
  TEMPLATES_SET_REVIEW_FREQUENCY_DATA_SUCCESS = "[Template] Set Review Frequency Data Success",
  TEMPLATES_SET_REVIEW_FREQUENCY_DATA_ERROR = "[Template] Set Review Frequency Data Error",

  UPDATE_SECTIONS_GROUPS_ORDER = "[Template] Update Sections and groups order"
}

export class ActionFMTemplateDistributorsGet implements Action {
  readonly type = TemplateManagerActionTypes.GET_ALL_DISTRIBUTORS;
  constructor(public payload?: { id: string; page: number; size: number }) {}
}

export class ActionFMTemplateDistributorsGetSuccess implements Action {
  readonly type = TemplateManagerActionTypes.GET_ALL_DISTRIBUTORS_SUCCESS;
  constructor(public payload: { distributors: any }) {}
}

export class ActionFMTemplateDistributorsGetError implements Action {
  readonly type = TemplateManagerActionTypes.GET_ALL_DISTRIBUTORS_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionAddCustomSections implements Action {
  readonly type = TemplateManagerActionTypes.TEMPLATE_ADD_CUSTOM_SECTION;
  constructor(public payload: { customSections: any }) {}
}

export class ActionCustomTemplateChangeQuestionDocumentStatus implements Action {
  readonly type = TemplateManagerActionTypes.CUSTOM_TEMPLATE_CHANGE_DOCUMENT_STATUS;
  constructor(
    public payload: {
      sectionIndex: number;
      groupIndex: number;
      questionIndex: number;
      helpText: string;
      text: string;
    }
  ) {}
}

export class ActionCustomTemplateChangeGroupDocumentStatus implements Action {
  readonly type = TemplateManagerActionTypes.CUSTOM_TEMPLATE_CHANGE_GROUP_STATUS;
  constructor(public payload: { sectionIndex: number; groupIndex: number }) {}
}

export class ActionFMTemplateGet implements Action {
  readonly type = TemplateManagerActionTypes.TEMPLATE_GET;
  constructor(public payload?: { id: string }) {}
}

export class ActionFMTemplateGetSuccess implements Action {
  readonly type = TemplateManagerActionTypes.TEMPLATE_GET_SUCCESS;
  constructor(public payload: { template: Template }) {}
}

export class ActionFMTemplateGetError implements Action {
  readonly type = TemplateManagerActionTypes.TEMPLATE_GET_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionFMTemplateUpdate implements Action {
  readonly type = TemplateManagerActionTypes.TEMPLATE_UPDATE;
  constructor(public payload?: { template: Template, reviewFrequencyData: IReviewFrequencyData }) {}
}

export class ActionFMTemplateUpdateSuccess implements Action {
  readonly type = TemplateManagerActionTypes.TEMPLATE_UPDATE_SUCCESS;
  constructor(public payload: { template: Template }) {}
}

export class ActionFMTemplateUpdateError implements Action {
  readonly type = TemplateManagerActionTypes.TEMPLATE_UPDATE_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionFMTemplatePublish implements Action {
  readonly type = TemplateManagerActionTypes.TEMPLATE_PUBLISH;
  constructor(public payload?: { id: string }) {}
}

export class ActionFMTemplatePublishSuccess implements Action {
  readonly type = TemplateManagerActionTypes.TEMPLATE_PUBLISH_SUCCESS;
  constructor(public payload: { template: Template }) {}
}

export class ActionFMTemplatePublishError implements Action {
  readonly type = TemplateManagerActionTypes.TEMPLATE_PUBLISH_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionFMTemplatesGet implements Action {
  readonly type = TemplateManagerActionTypes.TEMPLATES_GET;
  constructor(public payload?: { preselectedTemplate: any }) {}
}

export class ActionFMTemplatesGetSuccess implements Action {
  readonly type = TemplateManagerActionTypes.TEMPLATES_GET_SUCCESS;
  constructor(public payload: { templatesList: TemplateItem[]; preselectedTemplate: any }) {}
}

export class ActionFMTemplatesGetError implements Action {
  readonly type = TemplateManagerActionTypes.TEMPLATES_GET_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionFMTemplatesUpdateSectionActiveStatus implements Action {
  readonly type = TemplateManagerActionTypes.UPDATE_SECTION_ACTIVE_STATUS;
  constructor(public payload: { sourceSectionId: string; targetSectionId?: string }) {}
}

export class ActionFMTemplatesUpdateGroupActiveStatus implements Action {
  readonly type = TemplateManagerActionTypes.UPDATE_GROUP_ACTIVE_STATUS;
  constructor(public payload: { groupId: string; sectionId: string }) {}
}

export class ActionFMTemplateReset implements Action {
  readonly type = TemplateManagerActionTypes.TEMPLATE_RESET;
  constructor(public payload: { id: string }) {}
}

export class ActionFMTemplateUpdateTemplateName implements Action {
  readonly type = TemplateManagerActionTypes.UPDATE_TEMPLATE_NAME;
  constructor(public payload: { name: string; id: string }) {}
}

export class ActionFMTemplateQuestionChangeFlags implements Action {
  readonly type = TemplateManagerActionTypes.CHANGE_FLAGS;
  constructor(public payload: { flags: Flag[]; questionKey?: string }) {}
}

export class ActionFMTemplateUpdateOverallCountryFlags implements Action {
  readonly type = TemplateManagerActionTypes.UPDATE_OVERALL_COUNTRY_FLAGS;
  constructor(public payload: { flags: Flag[];}) {}
}


export class ActionFMTemplateUpdateHasResidualRiskCategory implements Action {
  readonly type = TemplateManagerActionTypes.TEMPLAT_UPDATE_HAS_RESIDUAL_RISK_CATEGORY;
  constructor() {}
}

export class ActionFMTemplateUpdateHasExecutiveOverview implements Action {
  readonly type = TemplateManagerActionTypes.TEMPLATE_UPDATE_HAS_EXECUTIVE_OVERVIEW;
  constructor() {}
}

export class ActionFMTemplateUpdateHasRiskModel implements Action {
  readonly type = TemplateManagerActionTypes.TEMPLATE_UPDATE_HAS_RISK_MODEL;
  constructor() {}
}

export class ActionFMTemplateUpdateSectionAndGroupsOrder implements Action {
  readonly type = TemplateManagerActionTypes.UPDATE_SECTIONS_GROUPS_ORDER;
  constructor(public payload: { sections: TemplateSection[] }) {}
}

export class ActionFMTemplateAnchoredQuestionChangeFlags implements Action {
  readonly type = TemplateManagerActionTypes.CHANGE_ANCHORED_QUESTION_FLAGS;
  constructor(public payload: { flags: Flag[]; questionKey?: string }) {}
}

export class ActionFMTemplateReviewFrequencyDataGet implements Action {
  readonly type = TemplateManagerActionTypes.TEMPLATES_GET_REVIEW_FREQUENCY_DATA;
  constructor(public payload?: { templateId: string;}) {}
}

export class ActionFMTemplateReviewFrequencyDataGetSuccess implements Action {
  readonly type = TemplateManagerActionTypes.TEMPLATES_GET_REVIEW_FREQUENCY_DATA_SUCCESS;
  constructor(public payload: { reviewFrequencyData: IReviewFrequencyData; }) {}
}

export class ActionFMTemplateReviewFrequencyDataGetError implements Action {
  readonly type = TemplateManagerActionTypes.TEMPLATES_GET_REVIEW_FREQUENCY_DATA_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionFMTemplateReviewFrequencyDataSet implements Action {
  readonly type = TemplateManagerActionTypes.TEMPLATES_SET_REVIEW_FREQUENCY_DATA;
  constructor(public payload?: { reviewFrequencyData: IReviewFrequencyData; templateId: string}) {}
}

export class ActionFMTemplateReviewFrequencyDataSetSuccess implements Action {
  readonly type = TemplateManagerActionTypes.TEMPLATES_SET_REVIEW_FREQUENCY_DATA_SUCCESS;
  constructor() {}
}

export class ActionFMTemplateReviewFrequencyDataSetError implements Action {
  readonly type = TemplateManagerActionTypes.TEMPLATES_SET_REVIEW_FREQUENCY_DATA_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export type TemplateActions =
  | ActionFMTemplateGet
  | ActionFMTemplateGetSuccess
  | ActionFMTemplateGetError
  | ActionFMTemplateUpdate
  | ActionFMTemplateUpdateSuccess
  | ActionFMTemplateUpdateError
  | ActionFMTemplatePublish
  | ActionFMTemplatePublishSuccess
  | ActionFMTemplatePublishError
  | ActionFMTemplatesGet
  | ActionFMTemplatesGetSuccess
  | ActionFMTemplatesGetError
  | ActionFMTemplatesUpdateSectionActiveStatus
  | ActionFMTemplatesUpdateGroupActiveStatus
  | ActionFMTemplateReset
  | ActionFMTemplateUpdateTemplateName
  | ActionCustomTemplateChangeQuestionDocumentStatus
  | ActionCustomTemplateChangeGroupDocumentStatus
  | ActionAddCustomSections
  | ActionFMTemplateQuestionChangeFlags
  | ActionFMTemplateUpdateOverallCountryFlags
  | ActionFMTemplateUpdateHasResidualRiskCategory
  | ActionFMTemplateUpdateHasExecutiveOverview
  | ActionFMTemplateUpdateHasRiskModel
  | ActionFMTemplateDistributorsGet
  | ActionFMTemplateDistributorsGetSuccess
  | ActionFMTemplateDistributorsGetError
  | ActionFMTemplateUpdateSectionAndGroupsOrder
  | ActionFMTemplateAnchoredQuestionChangeFlags
  | ActionFMTemplateReviewFrequencyDataGet
  | ActionFMTemplateReviewFrequencyDataGetSuccess
  | ActionFMTemplateReviewFrequencyDataGetError
  | ActionFMTemplateReviewFrequencyDataSet
  | ActionFMTemplateReviewFrequencyDataSetSuccess
  | ActionFMTemplateReviewFrequencyDataSetError;

export const selectorTemplateManagerState = (state) => state.fm.templates;
export const selectorTemplatesListManager = (state) => state.fm.templates.templates;
export const selectorTemplateManager = (state) => state.fm.templates.template;
export const selectorCurrentTemplateName = (state) => state.fm.templates.currentTemplateName;
export const selectorDistributorsTM = (state) => state.fm.templates.distributors;
export const selectorReviewFrequencyData = (state) => state.fm.templates.reviewFrequencyData;

export function templateReducer(
  state: ITemplateManagerState = initialTemplateManagerState,
  action: TemplateActions
): ITemplateManagerState {
  switch (action.type) {
    case TemplateManagerActionTypes.GET_ALL_DISTRIBUTORS:
      return {
        ...state,
        loading: true,
        distributors: null,
        error: null
      };
    case TemplateManagerActionTypes.GET_ALL_DISTRIBUTORS_SUCCESS:
      return {
        ...state,
        loading: false,
        distributors: action.payload.distributors,
        error: null
      };
    case TemplateManagerActionTypes.GET_ALL_DISTRIBUTORS_ERROR:
      return {
        ...state,
        loading: false,
        distributors: null,
        error: action.payload.error
      };
    case TemplateManagerActionTypes.TEMPLATE_ADD_CUSTOM_SECTION:
      return {
        ...state,
        template: {
          ...state.template,
          customSections: action.payload.customSections
        }
      };

    case TemplateManagerActionTypes.CUSTOM_TEMPLATE_CHANGE_GROUP_STATUS:
      return {
        ...state,
        template: {
          ...state.template,
          customSections: state.template.customSections.map((section, sectionIndex) => {
            if (sectionIndex !== action.payload.sectionIndex) {
              return section;
            }
            return {
              ...section,
              groups: section.groups.map((group, groupIndex) => {
                if (groupIndex !== action.payload.groupIndex) {
                  return group;
                }
                return {
                  ...group,
                  isActive: !group.isActive
                };
              })
            };
          })
        }
      };

    case TemplateManagerActionTypes.CUSTOM_TEMPLATE_CHANGE_DOCUMENT_STATUS:
      return {
        ...state,
        template: {
          ...state.template,
          customSections: state.template.customSections.map((section, sectionIndex) => {
            if (sectionIndex !== action.payload.sectionIndex) {
              return section;
            }
            return {
              ...section,
              groups: section.groups.map((group, groupIndex) => {
                if (groupIndex !== action.payload.groupIndex) {
                  return group;
                }
                return {
                  ...group,
                  questions: group.questions.map((question, questionIndex) => {
                    if (questionIndex !== action.payload.questionIndex) {
                      return question;
                    }
                    return {
                      ...question,
                      hasDocument: !question.hasDocument,
                      helpText: action.payload.helpText,
                      text: action.payload.text
                    };
                  })
                };
              })
            };
          })
        }
      };

    case TemplateManagerActionTypes.TEMPLATE_GET:
      return {
        ...state,
        loading: true,
        template: null,
        currentTemplateName: null,
        error: null
      };
    case TemplateManagerActionTypes.TEMPLATE_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        template: {
          ...action.payload.template,
          customSections: action.payload.template.customSections ? action.payload.template.customSections : []
        },
        currentTemplateName: action.payload.template.name,
        error: null
      };
    case TemplateManagerActionTypes.TEMPLATE_GET_ERROR:
      return {
        ...state,
        loading: false,
        template: null,
        currentTemplateName: null,
        error: action.payload.error
      };

    case TemplateManagerActionTypes.TEMPLATE_UPDATE:
      return {
        ...state,
        loading: true,
        template: null,
        error: null
      };
    case TemplateManagerActionTypes.TEMPLATE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        template: action.payload.template,
        currentTemplateName: action.payload.template.name,
        error: null
      };
    case TemplateManagerActionTypes.TEMPLATE_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        template: null,
        error: action.payload.error
      };

    case TemplateManagerActionTypes.TEMPLATE_PUBLISH:
      return {
        ...state,
        loading: true,
        template: null,
        error: null
      };
    case TemplateManagerActionTypes.TEMPLATE_PUBLISH_SUCCESS:
      return {
        ...state,
        loading: false,
        template: action.payload.template,
        error: null
      };
    case TemplateManagerActionTypes.TEMPLATE_PUBLISH_ERROR:
      return {
        ...state,
        loading: false,
        template: null,
        error: action.payload.error
      };
    case TemplateManagerActionTypes.TEMPLATES_GET:
      return {
        ...state,
        loading: true,
        templates: null,
        error: null
      };
    case TemplateManagerActionTypes.TEMPLATES_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: {
          list: action.payload.templatesList,
          disableGetFirstTemplate: false
        },
        preselectedTemplate: action.payload.preselectedTemplate,
        error: null
      };
    case TemplateManagerActionTypes.TEMPLATES_GET_ERROR:
      return {
        ...state,
        loading: false,
        templates: null,
        error: action.payload.error
      };
    case TemplateManagerActionTypes.UPDATE_SECTION_ACTIVE_STATUS:
      return {
        ...state,
        template: {
          ...state.template,
          sections: state.template.sections.map((section) => {
            if (section.id !== action.payload.sourceSectionId && section.id !== action.payload.targetSectionId) {
              return section;
            }
            return {
              ...section,
              isActive: _.find(section.groups, ["isActive", true]) ? true : false
            };
          })
        }
      };
    case TemplateManagerActionTypes.UPDATE_GROUP_ACTIVE_STATUS:
      return {
        ...state,
        template: {
          ...state.template,
          sections: state.template.sections.map((section) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            return {
              ...section,
              groups: section.groups.map((group) => {
                if (group.id !== action.payload.groupId) {
                  return group;
                }
                return {
                  ...group,
                  isActive: _.find(group.questions, ["isActive", true]) ? true : false
                };
              })
            };
          })
        }
      };

    case TemplateManagerActionTypes.UPDATE_TEMPLATE_NAME:
      return {
        ...state,
        currentTemplateName: action.payload.name,
        templates: {
          list: state.templates.list.map((group) => {
            if (group.id !== action.payload.id) {
              return group;
            }
            return {
              ...group,
              name: action.payload.name
            };
          }),
          disableGetFirstTemplate: true
        }
      };

    case TemplateManagerActionTypes.CHANGE_FLAGS:
      return {
        ...state,
        template: {
          ...state.template,
          sections: state.template.sections.map((section) => {
            return {
              ...section,
              groups: section.groups.map((group) => {
                return {
                  ...group,
                  questions: group.questions.map((question) => {
                    if (question.key === action.payload.questionKey) {
                        // if (action.payload.questionKey === "SI40") {
                        //   return {
                        //     ...question,
                        //     columns: question.columns.map((column, i) => {
                        //       if (i === 0) {
                        //         return {
                        //           ...column,
                        //           flags: action.payload.flags
                        //         };
                        //       } else {
                        //         return column;
                        //       }
                        //     })
                        //   };
                        // } else {
                          if (question.fields?.length) {
                            return {
                              ...question,
                              fields: question.fields.map((field) => {
                                return {
                                  ...field,
                                  flags: action.payload.flags.filter((x) => x.column == field.key) || []
                                };
                              })
                            };
                          } else {
                            return {
                              ...question,
                              flags: action.payload.flags
                            };
                          }
                        // }
                    }
                    return question;
                  })
                };
              })
            };
          })
        }
      };
    
    case TemplateManagerActionTypes.CHANGE_ANCHORED_QUESTION_FLAGS:
      return {
        ...state,
        template: {
          ...state.template,
          anchoredSections: state.template.anchoredSections.map((section) => {
            return {
              ...section,
              groups: section.groups.map((group) => {
                return {
                  ...group,
                  questions: group.questions.map((question) => {
                    if (question.key === action.payload.questionKey) {
                          if (question.fields?.length) {
                            return {
                              ...question,
                              fields: question.fields.map((field) => {
                                return {
                                  ...field,
                                  flags: action.payload.flags.filter((x) => x.column == field.key) || []
                                };
                              })
                            };
                          } else {
                            return {
                              ...question,
                              flags: action.payload.flags
                            };
                          }
                    }
                    return question;
                  })
                };
              })
            };
          })
        }
      };
    
    case TemplateManagerActionTypes.UPDATE_OVERALL_COUNTRY_FLAGS:
      return {
        ...state,
        template: {
          ...state.template,
          sections: state.template.sections.map((section) => {
            return {
              ...section,
              groups: section.groups.map((group) => {
                return {
                  ...group,
                  questions: group.questions.map((question) => {
                    if (                      
                      question.type === "CountryQuestion" ||
                      (question.type === "TableQuestion" && question.key === "SI40")
                    ) {
                      return {
                        ...question,
                        flags: action.payload.flags
                      };
                    } else if (question.type === "MultiFieldQuestion") {
                      if (question.fields?.length) {
                        return {
                          ...question,
                          fields: question.fields.map((field) => {
                            if (field.type == "CountryField") {
                              return {
                                ...field,
                                flags: action.payload.flags.map((flag) => {
                                  return {
                                    ...flag,
                                    column: field.key
                                  };
                                })
                              };
                            } else {
                              return {
                                ...field
                              };
                            }
                          })
                        };
                      }
                    }
                    return question;
                  })
                };
              })
            };
          })
        }
      };

    case TemplateManagerActionTypes.TEMPLAT_UPDATE_HAS_RESIDUAL_RISK_CATEGORY:
      return {
        ...state,
        template: {
          ...state.template,
          hasResidualRiskCategory: !state.template.hasResidualRiskCategory
        }
      };

    case TemplateManagerActionTypes.TEMPLATE_UPDATE_HAS_EXECUTIVE_OVERVIEW:
      return {
        ...state,
        template: {
          ...state.template,
          hasExecutiveOverview: !state.template.hasExecutiveOverview
        }
      };

    case TemplateManagerActionTypes.TEMPLATE_UPDATE_HAS_RISK_MODEL:
      return {
        ...state,
        template: {
          ...state.template,
          hasRiskModel: !state.template.hasRiskModel
        }
      };

    case TemplateManagerActionTypes.UPDATE_SECTIONS_GROUPS_ORDER:
      return {
        ...state,
        template: {
          ...state.template,
          sections: state.template.sections.map((section) => {
            const payloadSection = action.payload.sections.find((x) => x.id === section.id);
            if (!payloadSection) {
              return section;
            }

            const index = action.payload.sections.map((x) => x.id).indexOf(section.id);
            const updatedSection = {
              ...payloadSection,
              order: index
            };

            section = updatedSection;
            return {
              ...section,
              groups: section.groups.map((group) => {
                const index = section.groups.map((x) => x.id).indexOf(group.id);
                const updatedGroup = {
                  ...group,
                  order: index
                };

                group = updatedGroup;
                return {
                  ...group
                };
              })
            };
          })
        }
      };
      
    case TemplateManagerActionTypes.TEMPLATES_GET_REVIEW_FREQUENCY_DATA:
      return {
        ...state,
        loading: true,
        reviewFrequencyData: null,
        error: null
      };
    case TemplateManagerActionTypes.TEMPLATES_GET_REVIEW_FREQUENCY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        reviewFrequencyData: action.payload.reviewFrequencyData,
        error: null
      };
    case TemplateManagerActionTypes.TEMPLATES_GET_REVIEW_FREQUENCY_DATA_ERROR:
      return {
        ...state,
        loading: false,
        reviewFrequencyData: null,
        error: action.payload.error
      };
    case TemplateManagerActionTypes.TEMPLATES_SET_REVIEW_FREQUENCY_DATA_ERROR:
      return {
        ...state,
        loading: false,
        reviewFrequencyData: null,
        error: action.payload.error
      };
    default:
      return state;
  }
}

export interface TemplatesData {
  list: TemplateItem[];
  disableGetFirstTemplate: boolean;
}

export interface TemplateItem {
  id: string;
  name: string;
}

export interface IReviewFrequencyData {
  forLowRating: number;
  forMediumRating: number;
  forHighRating: number;
  forCriticalRating: number;
}

export class Template {
  id: string;
  name: string;
  status?: string;
  sections: TemplateSection[];
  isLowUpdate?: boolean;
  customSections: TemplateSection[];
  hasResidualRiskCategory: boolean;
  hasExecutiveOverview: boolean;
  hasRiskModel: boolean;
  anchoredSections: TemplateSection[];
}

export interface TemplateSection {
  id: string;
  label: string;
  isActive: boolean;
  groups: TemplateGroup[];
  isCustom?: boolean;
  order?: number;
}

export interface TemplateGroup {
  id: string;
  label: string;
  isActive: boolean;
  questions: TemplateQuestions[];
  order?: number;
}

export interface TemplateQuestions {
  key: string;
  label: string;
  text: string;
  helpText: string;
  isActive?: boolean;
  hasDocument?: boolean;
  flags?: Flag[];
  fields?: Field[];
  columns?: Column[];
  type?: string;
}
