import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "convertRole", pure: true,
    standalone: true
})
export class ConvertRolePipe implements PipeTransform {
  transform(role: string | number) {
    if (role === "KeyContact" || role === 0) {
      return "Key Contact";
    } else if (role === "Completer" || role === 1) {
      return "Completer";
    } else if (role === "Releaser" || role === 2) {
      return "Releaser";
    } else if (role === "Reviewer" || role === 3) {
      return "Reviewer";
    } else if (role === "Approver" || role === 4) {
      return "Approver";
    } else if (role === "FMTemplateOwner" || role === 5) {
      return "Template Owner";
    } else if (role === "DDDAdmin" || role === 6) {
      return "DDD Admin";
    } else if (role === "RelationshipManager" || role === 7) {
      return "Relationship Manager";
    } else if (role === "Reporter" || role === 8) {
      return "Reporter";
    } else if (role === "FMGuest" || role === 9) {
      return "FM Guest";
    } else if (role === "DocumentManager" || role === 10) {
      return "Document Manager";
    } else if (role === "Populator" || role === 11) {
      return "Populator";
    } else {
      return "";
    }
  }
}
