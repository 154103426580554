import { catchError, filter, takeUntil, tap } from "rxjs/operators";
import { throwError as observableThrowError, Observable, Subject } from "rxjs";
import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";

import { AuthService } from "@app/core/auth/auth.service";
import { OAuthService, OAuthStorage } from "angular-oauth2-oidc";
import { Router } from "@angular/router";
import { ActionToastrError, ActionToastrWarning } from "@app/core/toastr/toastr.reducer";
import { Store, select } from "@ngrx/store";
import { BrowserCheck } from "@app/core/utils/browserCheck";
import { LocalStorageService } from "@app/core/local-storage/local-storage.service";
import { environment } from "@env/environment";
import { IUser, selectorUser } from "./identity/user.reducer";

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private user: IUser;

  constructor(
    public auth: AuthService,
    public router: Router,
    private authStorage: OAuthStorage,
    private oauthService: OAuthService,
    private readonly store: Store<any>,
    private localStorageService: LocalStorageService
  ) {
    this.store
      .pipe(
        select(selectorUser),
        takeUntil(this.unsubscribe$),
        filter((user: IUser) => !!user)
      )
      .subscribe((user: IUser) => {
        this.user = user;
      });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const customReq = request.clone({
      setHeaders: Object.assign({}, this.getHeaders(request))
    });

    return next.handle(customReq).pipe(
      tap((ev: HttpEvent<any>) => {
        if (ev instanceof HttpResponse) {
          /*TODO: in case we will need to intercept response;*/
        }
      }),
      catchError((err) => {
        if (err instanceof HttpErrorResponse && err.error instanceof Blob && err.error.type === "application/json") {
          // https://github.com/angular/angular/issues/19888
          // When request of type Blob, the error is also in Blob instead of object of the json data
          return new Promise<any>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e: Event) => {
              try {
                const errmsg = JSON.parse((<any>e.target).result);
                reject(
                  new HttpErrorResponse({
                    error: errmsg,
                    headers: err.headers,
                    status: err.status,
                    statusText: err.statusText,
                    url: err.url
                  })
                );
              } catch (e) {
                reject(err);
              }
            };
            reader.onerror = (e) => {
              reject(err);
            };
            reader.readAsText(err.error);
          });
        }
        if (err instanceof HttpErrorResponse) {
          let errorMessage: string;
          let errorException = "";
          if (err.error && err.error instanceof ProgressEvent) {
            errorMessage = err.statusText;
          } else if (err.error && err.error.error) {
            errorMessage = err.error.error.message;
            errorException = err.error.error.exception;
          } else if (err.error && !(err.error instanceof Blob)) {
            const isJsonValid = this.isJsonString(err.error);
            errorMessage = isJsonValid ? JSON.parse(err.error).error.message : err.error;
            errorException = isJsonValid ? JSON.parse(err.error).error.exception : "";
          }
          if (errorMessage && errorMessage !== "Unknown Error") {
            this.store.dispatch(
              new ActionToastrError({
                error: {
                  message: errorMessage
                }
              })
            );
          }

          if (err.status === 401) {
            this.auth.collectFailedRequest(request);
            this.store.dispatch(
              new ActionToastrWarning({
                message: `Your session on Distributor Due Diligence platform has expired.`
              })
            );
            this.router.navigate(["/session-expired"]);
          } else if (err.status === 403) {
            this.store.dispatch(
              new ActionToastrError({
                error: {
                  message: errorMessage || `You don't have permissions to perform this action.`
                }
              })
            );
            if (!this.user) {
              this.router.navigate(["/logout"]);
            }
          }

          /* TODO: quick fix */
          /* TODO: more elegant solution should be used here */
          if (errorException === "EntityNotFoundException") {
            this.router.navigate(["help"]);
          }
        }

        return observableThrowError(err);
      })
    );
  }

  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  getHeaders(request) {
    const headers = {
      "Cache-Control": "no-store",
      Authorization: `Bearer ${this.oauthService.getAccessToken()}`
    };
    if (request && request.url && request.url.includes(environment.client.identity_url)) {
      headers["X-CORR-Tenant-Id"] = "366dcf58-824c-45c6-89b6-74e34ac4a2c8";
    }
    return headers;
  }
}
