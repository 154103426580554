import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ErrorHandler, NgModule, Optional, SkipSelf, ModuleWithProviders } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthGuardService } from "@app/core/auth/auth-guard.service";
import { AuthEffects } from "@app/core/auth/auth.effects";
import { authReducer } from "@app/core/auth/auth.reducer";
import { environment } from "@env/environment";
import { EffectsModule } from "@ngrx/effects";
import { MetaReducer, StoreModule, createReducerFactory, provideStore } from "@ngrx/store";
import { provideEffects } from '@ngrx/effects';

import { ToastrModule } from "ngx-toastr";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";

import { LocalStorageService } from "./local-storage/local-storage.service";
import { debug } from "./meta-reducers/debug.reducer";
import { initStateFromLocalStorage } from "./meta-reducers/init-state-from-local-storage.reducer";
import { languageReducer } from "@app/core/i18n/i18n.reducer";
import { LanguageEffects } from "@app/core/i18n/i18n.effects";
import { toastrReducer } from "@app/core/toastr/toastr.reducer";
import { ToastrEffects } from "@app/core/toastr/toastr.effects";
import { RequestInterceptor } from "@app/core/auth/request-interceptor.service";
import { AuthService } from "@app/core/auth/auth.service";
import { AuthGuard } from "@app/core/auth/identity/idsvr-auth-guard.service";
import { CallbackComponent } from "@app/core/auth/identity/idsvr-callback.component";
import { UserGuardService } from "@app/core/auth/identity/user-guard.service";
import { userReducer } from "@app/core/auth/identity/user.reducer";
import { UserEffects } from "@app/core/auth/identity/user.effects";
import { UserService } from "@app/core/auth/identity/user.service";
import { RoleGuard } from "@app/core/auth/guards/role-guard";
import { AnimationsService } from "@app/core/animations/animations.service";
import { GlobalErrorHandlerService } from "@app/core/error-handler/global-error-handler.service";

/* TODO: should be considered for remove */
// import { EsService } from '@app/core/es/es.service';

import { supportReducer } from "@modules/support/components/support/reducers";
import {
  SupportEffect,
  SupportFMCompanyEffect,
  SupportDistributorCompanyEffect
} from "@modules/support/components/support/effects";
import { SupportService, CompanyAdminService } from "@modules/support/components/support/services";
import { TemplateManagerService } from "@app/modules/template-manager/components/template-manager/services";

export const metaReducers: MetaReducer<any>[] = [initStateFromLocalStorage];

if (!environment.production) {
  metaReducers.unshift(debug);
}

/* TODO: In the process of migrating to standalone, we need to use two different methods to register the Store. https://github.com/ngrx/platform/issues/3700#issuecomment-1443965068 */
@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(),
        EffectsModule.forRoot(),
        environment.production
            ? []
            : StoreDevtoolsModule.instrument({
                name: "DDD app"
            }),
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            preventDuplicates: true,
            includeTitleDuplicates: true
        }),
        CallbackComponent
    ],
    providers: [
        LocalStorageService,
        AuthGuardService,
        AuthService,
        AuthGuard,
        RoleGuard,
        UserService,
        UserGuardService,
        SupportService,
        CompanyAdminService,
        TemplateManagerService,
        // EsService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandlerService
        },
        provideStore(
          {
            auth: authReducer,
            lang: languageReducer,
            toastr: toastrReducer,
            userState: userReducer,
            support: supportReducer
          },
          {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true
            }
          }
        ),
        provideEffects([
          AuthEffects,
          LanguageEffects,
          ToastrEffects,
          UserEffects,
          SupportEffect,
          SupportFMCompanyEffect,
          SupportDistributorCompanyEffect
      ])
    ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error("CoreModule is already loaded. Import it in the AppModule only");
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return { ngModule: CoreModule, providers: [AnimationsService] };
  }
}
