import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private oauthService: OAuthService, private router: Router) {}

  canActivate() {
    if (this.oauthService.hasValidAccessToken()) {
      return true;
    }
    if (this.oauthService.getAccessToken() && !this.oauthService.hasValidAccessToken()) {
      this.router.navigate(["/session-expired"]);
      return false;
    }
  }
}
