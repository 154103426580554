import { Action } from "@ngrx/store";
import { SupportEmailsFilter, SupportEmailsListResponse } from "@modules/support/models";

export const supportEmailsStoreName = "supportEmails";
const localState = (state) => state.support.supportEmails || supportEmailsInitialState;

export enum SupportEmailsActionTypes {
  SUPPORT_GET_EMAIL_FOLDERS = "[SupportEmails] Get emails folder",
  SUPPORT_GET_EMAIL_FOLDERS_SUCCESS = "[SupportEmails] Get emails folder success",
  SUPPORT_GET_EMAIL_FOLDERS_ERROR = "[SupportEmails] Get emails folder error",
  SUPPORT_GET_EMAILS_LIST = "[SupportEmails] Get emails list",
  SUPPORT_GET_EMAILS_LIST_SUCCESS = "[SupportEmails] Get emails list success",
  SUPPORT_GET_EMAILS_LIST_ERROR = "[SupportEmails] Get emails list error",
  SUPPORT_NOTHING_HAPPENDS = "[SupportEmails] Empty method",
  SUPPORT_SET_SEARCH_DATA = "[SupportEmails] Set emails search data",
  SUPPORT_EMAIL_DOWNLOAD = "[SupportEmails] Download email",
  SUPPORT_EMAIL_DOWNLOAD_SUCCESS = "[SupportEmails] Download email success",
  SUPPORT_EMAIL_DOWNLOAD_ERROR = "[SupportEmails] Download email success",
  ARCHIVE_EMAIL = "[SupportEmails] Archived Email",
  ARCHIVE_EMAIL_SUCCESS = "[SupportEmails] Archived Email Success",
  ARCHIVE_EMAIL_ERROR = "[SupportEmails] Archived Email Error"
}

export class ActionSupportGetEmailFolders implements Action {
  readonly type = SupportEmailsActionTypes.SUPPORT_GET_EMAIL_FOLDERS;
  constructor() {}
}

export class ActionSupportGetEmailFoldersSuccess implements Action {
  readonly type = SupportEmailsActionTypes.SUPPORT_GET_EMAIL_FOLDERS_SUCCESS;
  constructor(
    public payload: {
      result: any;
    }
  ) {}
}

export class ActionSupportGetEmailFoldersError implements Action {
  readonly type = SupportEmailsActionTypes.SUPPORT_GET_EMAIL_FOLDERS_ERROR;
  constructor(
    public payload: {
      error: any;
    }
  ) {}
}
export class ActionSupportGetEmailsList implements Action {
  readonly type = SupportEmailsActionTypes.SUPPORT_GET_EMAILS_LIST;
  constructor(
    public payload: {
      parentId;
      page;
      size;
    }
  ) {}
}

export class ActionSupportGetEmailsListSuccess implements Action {
  readonly type = SupportEmailsActionTypes.SUPPORT_GET_EMAILS_LIST_SUCCESS;
  constructor(public payload: { response: SupportEmailsListResponse }) {}
}

export class ActionSupportGetEmailsListError implements Action {
  readonly type = SupportEmailsActionTypes.SUPPORT_GET_EMAILS_LIST_ERROR;
  constructor(
    public payload: {
      error: any;
    }
  ) {}
}

export class ActionSupportNothingHappends implements Action {
  readonly type = SupportEmailsActionTypes.SUPPORT_NOTHING_HAPPENDS;
  constructor() {}
}

export class ActionSupportEmailsSetSearchData implements Action {
  readonly type = SupportEmailsActionTypes.SUPPORT_SET_SEARCH_DATA;
  constructor(
    public payload: {
      filter: {
        sort: string;
        dsc: boolean;
        search: {
          name: string;
        };
      };
      columns: any[];
    }
  ) {}
}

export class ActionSupportDownloadEmail implements Action {
  readonly type = SupportEmailsActionTypes.SUPPORT_EMAIL_DOWNLOAD;
  constructor(
    public payload: {
      bucketName: string;
      fileId: string;
      name: string;
    }
  ) {}
}

export class ActionSupportDownloadEmailSuccess implements Action {
  readonly type = SupportEmailsActionTypes.SUPPORT_EMAIL_DOWNLOAD_SUCCESS;
  constructor(
    public payload: {
      response: any;
    }
  ) {}
}

export class ActionSupportDownloadEmailError implements Action {
  readonly type = SupportEmailsActionTypes.SUPPORT_GET_EMAILS_LIST_ERROR;
  constructor(
    public payload: {
      error: any;
    }
  ) {}
}

export class ActionSupportArchiveEmail implements Action {
  readonly type = SupportEmailsActionTypes.ARCHIVE_EMAIL;
  constructor(public payload: { messageId: string; bucketName: string }) {}
}

export class ActionSupportArchiveEmailSuccess implements Action {
  readonly type = SupportEmailsActionTypes.ARCHIVE_EMAIL_SUCCESS;
  constructor(public payload: { result: any }) {}
}

export class ActionSupportArchiveEmailError implements Action {
  readonly type = SupportEmailsActionTypes.ARCHIVE_EMAIL_ERROR;
  constructor(public payload: { error: any }) {}
}

export type SupportEmailsAction =
  | ActionSupportGetEmailFolders
  | ActionSupportGetEmailFoldersSuccess
  | ActionSupportGetEmailFoldersError
  | ActionSupportGetEmailsList
  | ActionSupportGetEmailsListSuccess
  | ActionSupportGetEmailsListError
  | ActionSupportNothingHappends
  | ActionSupportEmailsSetSearchData
  | ActionSupportDownloadEmail
  | ActionSupportArchiveEmail
  | ActionSupportArchiveEmailSuccess
  | ActionSupportArchiveEmailError;

export const supportEmailsLoading = (state) => localState(state).loading;
export const supportEmailFolders = (state) => localState(state).emailFolders;
export const supportEmailsList = (state) => localState(state).list;
export const supportEmailsPage = (state) => localState(state).page;
export const supportEmailsSize = (state) => localState(state).size;
export const supportEmailsTotal = (state) => localState(state).total;

export const supportEmailsInitialState: SupportEmailsState = {
  loading: true,
  emailFolders: null,
  list: [],
  size: 20,
  page: 0,
  total: 0
};

export function supportEmailsReducer(
  state: SupportEmailsState = supportEmailsInitialState,
  action: SupportEmailsAction
) {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case SupportEmailsActionTypes.SUPPORT_GET_EMAIL_FOLDERS:
      return {
        ...state,
        loading: true
      };
    case SupportEmailsActionTypes.SUPPORT_GET_EMAIL_FOLDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        emailFolders: action.payload.result
      };
    case SupportEmailsActionTypes.SUPPORT_GET_EMAIL_FOLDERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case SupportEmailsActionTypes.SUPPORT_GET_EMAILS_LIST:
      return {
        ...state,
        loading: true
      };
    case SupportEmailsActionTypes.SUPPORT_GET_EMAILS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.response.result,
        total: action.payload.response.count
      };
    case SupportEmailsActionTypes.SUPPORT_GET_EMAILS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case SupportEmailsActionTypes.SUPPORT_SET_SEARCH_DATA:
      return {
        ...state,
        filter: action.payload
      };
    default:
      return state;
  }
}

export interface SupportEmailsState {
  loading: boolean;
  emailFolders: any;
  list: any[];
  page: number;
  size: number;
  total: number;
}

export interface SupportEmailsSearchData {
  filter: SupportEmailsFilter;
  columns: any[];
}
