import { Injectable, ViewContainerRef } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig } from "@angular/material/dialog";
import { TooltipDialogComponent } from "./tooltip-dialog.component";
import { TooltipDialogOptions } from "./tooltip-dialog.model";

@Injectable()
export class TooltipDialogService {
  constructor(private dialog: MatDialog) {}
  public open(viewContainerRef: ViewContainerRef, options: TooltipDialogOptions) {
    let dialogRef: MatDialogRef<TooltipDialogComponent>;
    const config = new MatDialogConfig();
    config.data = {
      options
    };
    config.panelClass = ["ddd-dialog", "wide"];
    config.backdropClass = "ddd-dialog-backdrop"; // Do not remove
    config.disableClose = false;
    config.viewContainerRef = viewContainerRef;
    dialogRef = this.dialog.open(TooltipDialogComponent, config);
    return dialogRef.afterClosed();
  }
}
