import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { select, Store } from "@ngrx/store";
import { filter, take } from "rxjs/operators";

import { selectorLanguage } from "@app/core/i18n/i18n.reducer";

@Injectable()
export class ConfigurableBaseEffect {
  constructor(public translate: TranslateService, public readonly store: Store<any>) {
    this.store
      .pipe(
        select(selectorLanguage),
        take(1),
        filter((lang) => lang)
      )
      .subscribe((lang) => {
        this.translate.setDefaultLang(lang.currentLanguage);
      });
  }
}
