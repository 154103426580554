import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { Observable, Subject } from "rxjs";

import { RejectResponsibilityDialogComponent } from "./reject-responsibility-dialog.component";
import { ActionCompanyReject } from "@modules/setup/components/setup/reducers/company-setup.reducer";
import { Store } from "@ngrx/store";
import { UntypedFormGroup } from "@angular/forms";

@Injectable({
  providedIn: "root"
})
export class RejectResponsibilityDialogService {
  constructor(private dialog: MatDialog, public store: Store<any>) {}

  public newKeyContact;
  private unsubscribe$: Subject<void> = new Subject<void>();

  public rejectResponibility(
    lei: string,
    businessTitle: string,
    setupCompanyReject?: boolean,
    invitationCompanyReject?: boolean,
    keyContactRoleReject?: boolean
  ): Observable<any> {
    let dialogRef: MatDialogRef<RejectResponsibilityDialogComponent>;
    const config = new MatDialogConfig();
    config.panelClass = ["cg-dialog", "middle"];
    config.backdropClass = "cg-dialog-backdrop";
    config.disableClose = true;
    config.data = {
      setupCompanyReject: setupCompanyReject,
      invitationCompanyReject: invitationCompanyReject,
      keyContactRoleReject: keyContactRoleReject,
      businessTitle: businessTitle
    };

    dialogRef = this.dialog.open(RejectResponsibilityDialogComponent, config);
    dialogRef.afterClosed().subscribe((res) => {
      if (res && setupCompanyReject) {
        this.newKeyContact = res;
        this.store.dispatch(
          new ActionCompanyReject({
            company: {
              legalEntityId: lei,
              rejectionmessage: this.newKeyContact.rejectionMessage,
              firstName: this.newKeyContact.firstName,
              lastName: this.newKeyContact.lastName,
              email: this.newKeyContact.email,
              phones: this.newKeyContact.phones
            }
          })
        );
        this.newKeyContact = new UntypedFormGroup({});
      }
    });
    return dialogRef.afterClosed();
  }
}
