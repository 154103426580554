import { Component, OnInit } from "@angular/core";
import { selectorUser, IUser } from "@app/core/auth/identity/user.reducer";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil, filter } from "rxjs/operators";
import { selectorLanguage } from "@app/core/i18n/i18n.reducer";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { Router, NavigationEnd, RouterLink } from "@angular/router";
import { Location, NgIf } from "@angular/common";
import { environment } from "@env/environment";

@Component({
    selector: "ddd-common-footer",
    templateUrl: "./common-footer.component.html",
    styleUrls: ["./common-footer.component.scss"],
    standalone: true,
    imports: [NgIf, RouterLink, TranslateModule]
})
export class CommonFooterComponent implements OnInit {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private PRIVACY_POLICY_URL = "https://www.carnegroup.com/wp-content/uploads/2023/07/20230629-Application-Platform-PN-Clean_-002.pdf";
  private TERMS_AND_CONDITIONS_URL = "https://carne-digital.atlassian.net/wiki/external/2723151873/MGE3MDJhNTc4NDc5NDZjM2E4OTI3MTQwNWFmNDZkMTA";
  user: IUser;

  public footerCopyrightDate = new Date().getFullYear();
  public showFooter: boolean;
  public isKYDBrand: boolean;
  public buildVersion: string;

  constructor(
    private readonly store: Store<any>,
    private readonly translateService: TranslateService,
    private location: Location,
    private router: Router
  ) {}

  ngOnInit() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.initCommonFooter();
    });

    this.initCommonFooter();

    this.store.pipe(select(selectorUser), takeUntil(this.unsubscribe$)).subscribe((user) => {
      if (user) {
        this.user = user;
        if (user.staticData) {
          this.buildVersion = `v. ${user.staticData.buildVersion}`;
        }
        this.isKYDBrand = this.user.brand === "KYD";
      }
    });

    this.store.pipe(select(selectorLanguage), takeUntil(this.unsubscribe$)).subscribe((lang) => {
      this.translateService.setDefaultLang(lang.currentLanguage);
    });
  }

  public initCommonFooter() {
    if (this.location.path().includes("callback")) {
      this.showFooter = false;
    } else if (this.location.path().includes("setup")) {
      this.showFooter = false;
    } else {
      this.showFooter = true;
    }
  }
}
