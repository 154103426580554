import { Injectable, ViewContainerRef } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";

import { ChangeReviewGroupApproversDialogComponent } from "./change-review-group-approvers-dialog.component";

@Injectable()
export class ChangeReviewGroupApproversDialogService {
  constructor(private dialog: MatDialog) {}

  public changeApprovers(dialogInfo: any, viewContainerRef: ViewContainerRef): Observable<any> {
    let dialogRef: MatDialogRef<ChangeReviewGroupApproversDialogComponent>;
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.viewContainerRef = viewContainerRef;
    config.panelClass = ["cg-dialog", "wide"];
    config.backdropClass = "ddd-dialog-backdrop";
    config.data = {
      type: "confirm",
      approvers: dialogInfo.approvers,
      id: dialogInfo.id,
      title: dialogInfo.title,
      subtitleCurrent: dialogInfo.subtitleCurrent,
      subtitleAvaliable: dialogInfo.subtitleAvaliable,
      component: dialogInfo.component,
      saveButtonName: dialogInfo.saveButtonName,
      addButtonName: dialogInfo.addButtonName
    };

    dialogRef = this.dialog.open(ChangeReviewGroupApproversDialogComponent, config);
    return dialogRef.afterClosed();
  }
}
