import {
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
  HostListener
} from "@angular/core";
import { OverlayContainer } from "@angular/cdk/overlay";
import { Observable, Subject } from "rxjs";
import { takeUntil, filter, map } from "rxjs/operators";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { ResolveStart, Router, RouterEvent, RouterOutlet } from "@angular/router";
import * as moment from "moment";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import * as Sentry from "@sentry/angular-ivy";
import { loadMessages } from 'devextreme/localization';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Subscription } from 'rxjs';

import { ActionAddLanguage, ActionPersistLanguage, ActionSetLanguage } from "@app/core/i18n/i18n.reducer";
import { OAuthEvent, OAuthService } from "angular-oauth2-oidc";
import { authConfig } from "@app/core/auth/identity/idsvr-authconfiig";
import { environment } from "@env/environment";
import {
  selectorUser,
  Role,
  IUser,
  CompanyType,
  UserCompany,
  ActionUserRetrieve,
  ExternalProvider
} from "@app/core/auth/identity/user.reducer";
import { AnimationsService } from "@app/core/animations/animations.service";
import {
  ActionSettingsChangeAnimationsPageDisabled,
  ActionSettingsChangeTheme,
  selectorHideFooter,
  selectorHideHeader,
  selectorSettings,
  ISettingsState
} from "@app/settings/settings/components/reducers";
import { LocalStorageService, BroadcastService } from "@app/core";
import { SESSION_BROADCAST_SERVICE } from '@app/core/utils/app.tokens';
import { CURRENT_THEME_KEY, ILastVisitedRoute, ROUTE_STATE_KEY } from "@shared/models";
import { BrowserCheck } from "@app/core/utils/browserCheck";
import { FirstVisitService, RejectAndConfirmDialogService } from "@shared/dialogs";
import { ProfileComponent } from "./modules/profile/components/profile/profile.component";
import { LoadingScreenComponent } from "./shared/components/loading-screen/loading-screen.component";
import { CommonFooterComponent } from "./shared/components/common-footer/common-footer.component";
import { CommonHeaderComponent } from "./shared/components/common-header/common-header.component";
import { NgIf, AsyncPipe } from "@angular/common";
declare let pendo: any;

@Component({
    selector: "ddd-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    standalone: true,
    imports: [NgIf, CommonHeaderComponent, RouterOutlet, CommonFooterComponent, LoadingScreenComponent, ProfileComponent, AsyncPipe]
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding("class") componentCssClass;
  @ViewChild("updatedUserInfoDialog", { static: true })
  updatedUserInfoDialog: TemplateRef<any>;

  public title = "DDD";
  public hideHeader$: Observable<boolean>;
  public hideFooter$: Observable<boolean>;
  public user$: Observable<IUser>;
  public isUserPresent: boolean;
  public updatedTermsPopupOpened: boolean;
  public updatedUserInfoPopupOpened: boolean;
  public sessionChangePopupOpened: boolean;

  private unsubscribe$: Subject<void> = new Subject<void>();
  
  userActivity;
  userActiveStatus: boolean = true;
  userInactive: Subject<any> = new Subject();
  sessionBroadcastSubscription = new Subscription();

  constructor(
    private readonly translate: TranslateService,
    private readonly store: Store<any>,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private oauthService: OAuthService,
    private animationService: AnimationsService,
    public overlayContainer: OverlayContainer,
    private localStorageService: LocalStorageService,
    private fisrtVisitDialogService: FirstVisitService,
    private dialog: MatDialog,
    private viewContainerRef: ViewContainerRef,
    private confirmDialogService: RejectAndConfirmDialogService,
    private serviceWorkerUpdate: SwUpdate,
    @Inject(SESSION_BROADCAST_SERVICE) private sessionBroadCastService: BroadcastService
  ) {
    this.serviceWorkerRun();
    this.store.dispatch(new ActionPersistLanguage({ currentLanguage: "en" }));
    this.store.dispatch(new ActionAddLanguage({ listOfLanguages: ["en"] }));
    this.store.dispatch(new ActionSetLanguage({ currentLanguage: "en" }));

    // this.store
    //   .pipe(select(selectorLanguage), takeUntil(this.unsubscribe$))
    //   .subscribe(lang => {
    //     this.translate.setDefaultLang(lang.currentLanguage);
    //   });

    this.configureCodeFlowAuthentication();

    this.hideHeader$ = this.store.pipe(select(selectorHideHeader));
    this.hideFooter$ = this.store.pipe(select(selectorHideFooter));
    this.user$ = this.store.pipe(
      select(selectorUser),
      takeUntil(this.unsubscribe$),
      filter((user: IUser) => !!user)
    );
    
    // Override strings in a devexpress dictionary https://js.devexpress.com/Angular/Documentation/Guide/Common/Localization/#Dictionaries/Override_Strings_in_a_Dictionary
    loadMessages({
      "en": {
          "OK": "Ok",
          "dxDataGrid-headerFilterOK": "Ok",
          "dxDiagram-dialogButtonOK": "Ok"
      }
    });
  }

  ngOnInit() {
    this.subscribeToSettings();
    this.initApp();

    if (environment.production) {
      this.appendOlarkScript();
    }
    if (environment.sc_environment === "prod") {
      this.appendPendoScript();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.sessionBroadcastSubscription.unsubscribe();
  }

  public serviceWorkerRun() {
    this.serviceWorkerUpdate.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });
    this.serviceWorkerUpdate.versionUpdates
        .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
        .subscribe(evt => {
          const confirmDialog = {
            title: "New app version ready",
            message: "We are pleased to inform you that a new version of the application is now available for your use. We recommend to update your current version by clicking the \"Apply\" button. The page will be reloaded automatically.",
            cancel: "Cancel",
            okBtn: "Apply",
          };

          this.confirmDialogService.confirm(confirmDialog, this.viewContainerRef).subscribe((res) => {
            if (res) {
              document.location.reload();
            }
          });
        });
        
    this.serviceWorkerUpdate.unrecoverable.subscribe(event => {
      const confirmDialog = {
        title: "Unrecoverable state of application",
        message: "We are sorry, but the current version of the application requires a full page reload to get updates. Please click \"Reload\" button and the page will refresh. If this issue persists, clear your browser cache and reopen your browser. Thanks for your cooperation!",
        cancel: "Cancel",
        okBtn: "Apply",
      };

      this.confirmDialogService.confirm(confirmDialog, this.viewContainerRef).subscribe((res) => {
        if (res) {
          document.location.reload();
        }
      });
    });
  }

  private configureCodeFlowAuthentication() {
    authConfig.issuer = environment.client.identity_url;
    this.oauthService.configure(authConfig);

    this.oauthService.loadDiscoveryDocument().then(() => {
      this.oauthService
        .tryLogin()
        .catch((err) => {
          console.error(err);
        })
        .then(() => {
          if (!this.oauthService.hasValidAccessToken()) {
            const requestUrl = this.getRedirectURL();
            this.oauthService.initCodeFlow(requestUrl);
          } else {
            this.silentRefresh();
            this.setInactiveUserTimeout();
            this.userInactive.subscribe(() =>  {
              console.log("User has been inactive 30 minutes");
              this.userActiveStatus = false;
              this.extendSessionPopup();
            });
          }
        });
    });

    this.oauthService.events.pipe(filter((e: OAuthEvent) => e.type === "session_terminated")).subscribe((e) => {
      console.log("Your session has been terminated!");
    });

    this.oauthService.events.pipe(filter((e: OAuthEvent) => e.type === "token_received")).subscribe((e) => {
      if (this.oauthService.hasValidAccessToken()) {
        if (!this.isUserPresent) {
          this.store.dispatch(new ActionUserRetrieve({ base_url: environment.client.base_url }));
        }
      }
    });
  }

  private subscribeToSettings() {
    if (BrowserCheck.isIEOrEdge()) {
      this.store.dispatch(
        new ActionSettingsChangeAnimationsPageDisabled({
          pageAnimationsDisabled: true
        })
      );
    }
    this.store.pipe(select(selectorSettings), takeUntil(this.unsubscribe$)).subscribe((settings: ISettingsState) => {
      this.localStorageService.setItem(CURRENT_THEME_KEY, settings.theme);
      this.setTheme(settings);
      this.animationService.updateRouteAnimationType(settings.pageAnimations, settings.elementsAnimations);
    });
  }

  setTheme(settings: ISettingsState) {
    const { theme } = settings;
    const effectiveTheme = theme && theme.toLowerCase();
    this.componentCssClass = effectiveTheme;
    /* TODO: check this*/
    // change adding theme class from app-root to body for correct drug and drop styles
    const classList = this.overlayContainer["_document"].body.classList;
    const toRemove = Array.from(classList).filter((item: string) => item.includes("-theme"));
    if (toRemove.length) {
      classList.remove(...toRemove);
    }
    classList.add(effectiveTheme);
  }

  /* init app */
  private initApp() {
    this.store
      .pipe(
        select(selectorUser),
        takeUntil(this.unsubscribe$),
        filter((user: IUser) => !!user)
      )
      .subscribe((user: IUser) => {
        this.changeTheme(user);
        Sentry.setTags({
          CompanyName: this.getCompanyName(user),
          CompanyType: this.splitCompanyTypesWithComma(user.companyTypes),
          Username: user.email,
          UserRole: this.splitRolesWithComma(user.roles),
          UserType: user.isSessionOpened ? "DDDAdmin" : this.splitCompanyTypesWithComma(user.companyTypes)
        });
        this.checkBuildVersion(user);
        this.setIsAdmin(user);
        this.setLatestRoute(user);

        if (!this.isUserPresent) {
          this.isUserPresent = true;
        }

        const guides = user && user.guides;
        const updatedTermsPopup =
          guides && guides.processedUpdatedTerms !== undefined ? !guides.processedUpdatedTerms : false;
        const updatedUserInfo = guides && guides.updatedUserInfo !== undefined ? !guides.updatedUserInfo : false;
        const updatedReportingAnnounce =
          guides && guides.processedUpdatedReportingAnnounce !== undefined
            ? !guides.processedUpdatedReportingAnnounce
            : false;

        if (updatedTermsPopup && !this.updatedTermsPopupOpened && !user.isSessionOpened) {
          const isKeycloakProvider: boolean = this.checkIfUserHasKeycloackProvider(user.loginProviders);
          this.fisrtVisitDialogService.updatedTermsFirstVisit(isKeycloakProvider).subscribe((confirm) => {
            this.updatedTermsPopupOpened = false;
          });
          this.updatedTermsPopupOpened = true;
        }

        if (updatedUserInfo && !this.updatedUserInfoPopupOpened && !user.isSessionOpened) {
          const config = new MatDialogConfig();
          config.width = "725px";
          config.height = "auto";
          config.maxHeight = "98vh";
          config.disableClose = true;

          this.dialog.open(this.updatedUserInfoDialog, config);
          this.updatedUserInfoPopupOpened = true;
        }
        this.pendoInitialize(user);
      });

      this.sessionBroadcastSubscription.add(this.sessionBroadCastService.messagesOfType('session').subscribe(message => {
        // Show a dialog to notify the user about the session change.
        const visibilityState = document.visibilityState;
        if (visibilityState === 'hidden' && !this.sessionChangePopupOpened) {
          this.handleSessionChange();
        }
      }));
  }

  private handleSessionChange() {
    this.sessionChangePopupOpened = true;
    const confirmDialog = {
      title: "Reminder",
      message: "You are working in multiple tabs. Session on this tab may be inactive. Consequently, any navigation or actions on this page may function incorrectly. It is recommended to refresh page to retreive its current state. You can also close this pop-up to leave without changes.",
      cancel: "Cancel",
      okBtn: "Refresh page",
    };

    this.confirmDialogService.confirm(confirmDialog, this.viewContainerRef).subscribe((res) => {
      if (res) {
        document.location.reload();
      }
      this.sessionChangePopupOpened = false;
    });
  }

  /* check build version */
  private checkBuildVersion(user: IUser): void {
    if (user && user.staticData && user.staticData.buildVersion) {
      const buildVersion = user.staticData.buildVersion;
      const currentBuildVersion = this.localStorageService.getItem("BUILD_VERSION");
      if (currentBuildVersion) {
        if (buildVersion !== currentBuildVersion) {
          this.localStorageService.clearStorage();
          this.clearCache();
          this.localStorageService.setItem("BUILD_VERSION", buildVersion);         
          location.reload();
        }
      } else if (buildVersion) {
        this.localStorageService.setItem("BUILD_VERSION", buildVersion);
      }
    }
  }

  private clearCache(): void {
    if ('caches' in window) {
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
  }


  /* change theme */
  private changeTheme(user: IUser): void {
    this.store.dispatch(new ActionSettingsChangeTheme({ theme: `${user.brand}-THEME` }));
  }

  /* set is admin */
  private setIsAdmin(user: IUser): void {
    const isAdmin = user.roles.indexOf(Role.DDDAdmin) > -1;
    this.localStorageService.setItem("IS_ADMIN", isAdmin || user.isSessionOpened);
  }

  /* append Olark script */
  private appendOlarkScript() {
    const olarkScript = document.createElement("script");
    const olarkIdentity = "6780-728-10-1929";

    olarkScript.innerHTML = ` ;(function(o,l,a,r,k,y){if(o.olark)return; r="script";y=l.createElement(r);r=l.getElementsByTagName(r)[0]; y.async=1;y.src="//"+a;r.parentNode.insertBefore(y,r); y=o.olark=function(){k.s.push(arguments);k.t.push(+new Date)}; y.extend=function(i,j){y("extend",i,j)}; y.identify=function(i){y("identify",k.i=i)}; y.configure=function(i,j){y("configure",i,j);k.c[i]=j}; k=y._={s:[],t:[+new Date],c:{},l:a}; })(window,document,"static.olark.com/jsclient/loader.js"); olark.identify('${olarkIdentity}');`;

    document.body.appendChild(olarkScript);
  }

  /* append Pendo script method */
  private appendPendoScript() {
    const pendoScript = document.createElement("script");
    const pendoIdentity = "62ef0805-c838-475e-6add-2c708ef9bc37";

    pendoScript.innerHTML = ` ;(function(apiKey){
      (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
      v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
          o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
          y=e.createElement(n);y.async=!0;y.src='https://cdn.eu.pendo.io/agent/static/'+apiKey+'/pendo.js';
          z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
    })('${pendoIdentity}');`;

    document.body.appendChild(pendoScript);
  }

  /* Pendo initialize */
  private pendoInitialize(user: IUser) {
    if (typeof pendo !== "undefined") {
      const sessionOpenIndentifier = "admin-session";
      const preparedUserId = user.isSessionOpened ? environment.sc_environment + "-" + sessionOpenIndentifier + "-" + user.id : environment.sc_environment + "-" + user.id;
      pendo.initialize({
        visitor: {
          id: preparedUserId,
          email: user.email,
          full_name: user.firstName + " " + user.lastName,
          company_type: this.splitCompanyTypesWithComma(user.companyTypes),
          company_name: this.getCompanyName(user),
          user_roles: this.splitRolesWithComma(user.roles),
          brand: user.brand
        },
        account: {
          id: preparedUserId
        }
      });
    }
  }

  private setLatestRoute(user: IUser) {
    /* Possible memory leak */
    this.router.events
      .pipe(
        filter((event: any) => event instanceof RouterEvent),
        filter((event: any) => event instanceof ResolveStart),
        filter(
          (event: any) =>
            event.url !== "/setup/intro-setup" &&
            event.url !== "/setup/company-setup" &&
            event.url !== "/session-expired" &&
            event.url !== "/logout" &&
            event.url !== "/callback" &&
            event.url !== "/" &&
            event.url.indexOf("loading-page") === -1 &&
            event.url.indexOf("token") === -1 &&
            event.url.indexOf("/Account/Logout") === -1
        )
      )
      .subscribe((event: ResolveStart) => {
        const currentRouteState: ILastVisitedRoute = {
          routeName: event.url,
          expirationTime: moment(moment.now()).valueOf(),
          userId: user.id,
          currentCompanyId: user.currentCompanyId
        };
        this.localStorageService.setItem(ROUTE_STATE_KEY, currentRouteState);
      });
  }

  /**
   * Joins roles in roles array with commas
   */
  private splitRolesWithComma(rolesArr: Array<string> | Array<number>): string {
    const modifiedRolesArray = [];

    rolesArr.forEach((role) => {
      role = Role[role];
      modifiedRolesArray.push(role);
    });

    return modifiedRolesArray.join(", ");
  }

  /**
   * Joins company types in array with commas
   */
  private splitCompanyTypesWithComma(typesArr: Array<string> | Array<number>): string {
    const modifiedTypesArray = [];

    typesArr.forEach((type) => {
      type = CompanyType[type];
      modifiedTypesArray.push(type);
    });

    return modifiedTypesArray.join(", ");
  }
  private getCompanyName(user: IUser): string {
    let companyName = "";

    user.companies.forEach((item) => {
      if (user.currentCompanyId === item.companyId) {
        companyName = item.companyName + " (" + item.lei + ")";
      }
    });

    return companyName;
  }

  private getRedirectURL(): string {
    const requestUrl = window.location.pathname + window.location.search;
    let additionalState;
    if (
      requestUrl !== "/setup/company-setup" &&
      requestUrl !== "/session-expired" &&
      requestUrl !== "/logout" &&
      requestUrl !== "/callback" &&
      requestUrl !== "/" &&
      requestUrl.indexOf("token") === -1 &&
      requestUrl.indexOf("/Account/Logout") === -1 &&
      requestUrl.indexOf("loading-page?action=changeCompany") === -1 &&
      requestUrl.indexOf("loading-page?action=changePassword") === -1
    ) {
      additionalState = requestUrl;
    }
    return additionalState;
  }

  private checkIfUserHasKeycloackProvider(loginProviders: ExternalProvider[]): boolean {
    return loginProviders && loginProviders.indexOf(ExternalProvider.Keycloak) > -1;
  }

  /**
   * Manage session and silent refresh
   */

  private setInactiveUserTimeout() {
    let inactiveTimeLimit = 60 * 30 * 1000; // 30 min
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), inactiveTimeLimit);
  }

  @HostListener('window:keydown')
  @HostListener('window:mousedown')
  refreshUserState() {
    clearTimeout(this.userActivity);
    this.setInactiveUserTimeout();
    this.userActiveStatus = true;
  }
  
  private silentRefresh() {
    // additional time(1 min) to call silent refresh method before access token expiration
    let additionaltime = 60 * 1000; // 1 min
    let timeout = this.oauthService.getAccessTokenExpiration() - (new Date().getTime() + additionaltime);

    setTimeout(() => {
      if (this.userActiveStatus) {
        this.oauthService
          .silentRefresh()
          .then((info) => {
            console.debug("refresh ok", info);
            this.silentRefresh();
          })
          .catch((err) => console.error("refresh error", err));
      } else {
        console.debug("silent refresh is suspended");
      }
    }, timeout);
  }

  private extendSessionPopup() {
    let closeDialogTimeout = this.oauthService.getAccessTokenExpiration() - (new Date().getTime());

    if (closeDialogTimeout > 0) {
      const message = `Dear user, your active session expires soon, and you will be logged out. 
      Please, make sure you have saved all your changes. 
      If you do not want to be logged out, click "Close" button below so your session will be extended.`;
      const confirmDialog = {
        title: "Session Expiration",
        message,
        okBtn: "Close",
        id: "extendSessionDialog"
      };
      if (!this.userActiveStatus) {
        this.confirmDialogService.confirm(confirmDialog, this.viewContainerRef).subscribe((res) => {
          if (res) {
            this.userActiveStatus = true;
            clearTimeout(this.userActivity);
            this.setInactiveUserTimeout();
          }
        });
      }
  
      setTimeout(() => {
        var extendSessionDialog = this.dialog.getDialogById("extendSessionDialog");
        if (extendSessionDialog) {
          extendSessionDialog.close();
        }
      }, closeDialogTimeout);
    }
  }
}
