import { Component, OnInit, Inject, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { Subject, Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService, TranslateModule } from "@ngx-translate/core";

import {
  Country,
  CountryQuestionGet,
  selectorCountries
} from "@shared/modules/questions-module-shared/components/country-question/reducers";
import { environment } from "@env/environment";
import {
  ActionColleagueAdd,
  ActionColleagueCheckExist,
  ActionColleagueUpdate,
  Colleague,
  selectorColleagueExist,
  ActionColleagueUpdateInvitedUser
} from "@modules/qc-complete/components/qc-complete/reducers";
import { selectorLanguage } from "@app/core/i18n/i18n.reducer";
import { ActionToastrWarning } from "@app/core/toastr/toastr.reducer";
import { ActionSupportEditUser } from "@app/modules/support/components/support/reducers/support.reducer";
import { selectorUser, IUser, PhoneType } from "@app/core/auth/identity/user.reducer";
import { PhoneNumberHelper } from "@app/core/utils/phone-number-helper";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgIf, NgFor, NgClass, AsyncPipe } from "@angular/common";
import { FieldErrorShowComponent } from "../../components/field-error-show/field-error-show.component";
import { AppCapitalizeDirective } from "../../directives/app-capitalize.directive";

/**
 * The save colleague dialog component
 *
 * @export
 * @class SaveColleagueDialogComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
    selector: "ddd-save-colleague-dialog",
    templateUrl: "./save-colleague-dialog.component.html",
    styleUrls: ["./save-colleague-dialog.component.scss"],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, AppCapitalizeDirective, FieldErrorShowComponent, NgIf, MatTooltipModule, NgFor, NgClass, AsyncPipe, TranslateModule]
})
export class SaveColleagueDialogComponent implements OnInit, OnDestroy {
  /**
   * Observable for user information
   *
   * @type {Observable<IUser>}
   * @memberof SaveColleagueDialogComponent
   */
  user$: Observable<IUser>;

  /**
   * Local reference of FormGroup
   *
   * @type {FormGroup}
   * @memberof SaveColleagueDialogComponent
   */
  form: UntypedFormGroup;

  /**
   * Colleague data
   *
   * @type {Colleague}
   * @memberof SaveColleagueDialogComponent
   */
  colleague: Colleague;

  /**
   * Unsubscribe subject for subscribers
   *
   * @private
   * @type {Subject<void>}
   * @memberof SaveColleagueDialogComponent
   */
  private unsubscribe$: Subject<void> = new Subject<void>();

  /**
   * Boolean value if is submit button clicked status
   *
   * @type {boolean}
   * @memberof SaveColleagueDialogComponent
   */
  submitButtonClicked: boolean;

  /**
   * Countries list
   *
   * @memberof SaveColleagueDialogComponent
   */
  countriesSelect: Country[];

  /**
   * Selected value for landline country code
   *
   * @type {string}
   * @memberof SaveColleagueDialogComponent
   */
  landlineCountryAlphaCode: string;

  /**
   * Selected value for phone country code
   *
   * @type {string}
   * @memberof SaveColleagueDialogComponent
   */
  phoneCountryAlphaCode: string;

  /**
   * Object of translation text
   *
   * @private
   * @memberof SaveColleagueDialogComponent
   */
  private colleagueMsg;

  /**
   * ElementRef for mobile number input
   *
   * @type {ElementRef}
   * @memberof SaveColleagueDialogComponent
   */
  @ViewChild("mobilePhone", { static: true })
  mobilePhone: ElementRef;

  /**
   * ElementRef for landline number input
   *
   * @type {ElementRef}
   * @memberof SaveColleagueDialogComponent
   */
  @ViewChild("landline", { static: true })
  landline: ElementRef;

  /**
   * ElementRef for mobile country code input
   *
   * @type {ElementRef}
   * @memberof SaveColleagueDialogComponent
   */
  @ViewChild("mobilePhoneCountryCode", { static: true })
  mobilePhoneCountryCode: ElementRef;

  /**
   * ElementRef for landline country code input
   *
   * @type {ElementRef}
   * @memberof SaveColleagueDialogComponent
   */
  @ViewChild("countryCodeLandline", { static: true })
  countryCodeLandline: ElementRef;

  /**
   * Creates an instance of SaveColleagueDialogComponent.
   * @param {Store<any>} store
   * @param {FormBuilder} formBuilder
   * @param {MatDialogRef<SaveColleagueDialogComponent>} dialogRef
   * @param {TranslateService} translate
   * @param {*} data
   * @memberof SaveColleagueDialogComponent
   */
  constructor(
    private readonly store: Store<any>,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<SaveColleagueDialogComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.user$ = this.store.pipe(select(selectorUser));
    this.store.pipe(select(selectorLanguage), takeUntil(this.unsubscribe$)).subscribe((lang) => {
      this.translate.setDefaultLang(lang.currentLanguage);
    });
    this.translate.get("COLLEAGUE_EFFECT_MESSAGE").subscribe((res: object) => {
      this.colleagueMsg = res;
    });
    this.store.pipe(select(selectorColleagueExist), takeUntil(this.unsubscribe$)).subscribe((response: any) => {
      /**
       * If user exist:
       * close dialog with colleague.
       * Otherwise: sent form to add colleague
       */
      if (response) {
        if (response !== "noExist" && this.submitButtonClicked) {
          this.dialogRef.close(this.getFormData());
          this.store.dispatch(
            new ActionToastrWarning({
              message: this.colleagueMsg["USER_EXIST"]
            })
          );
        } else if (response === "noExist" && this.submitButtonClicked) {
          this.sentForm();
        }
        if (this.submitButtonClicked) {
          this.submitButtonClicked = false;
        }
      }
    });
  }

  /**
   * Initialize the directive/component after Angular first displays the data-bound properties and sets the directive/component's
   * input properties.
   *
   * @memberof SaveColleagueDialogComponent
   */
  ngOnInit() {
    this.colleague = this.data.colleague;
    this.store.dispatch(new CountryQuestionGet({ base_url: environment.client.base_url }));

    this.store.pipe(select(selectorCountries), takeUntil(this.unsubscribe$)).subscribe((countries: Country[]) => {
      if (countries) {
        this.countriesSelect = countries;
        if (this.colleague && this.colleague.phones && this.colleague.phones.length !== 0) {
          this.phoneCountryAlphaCode = PhoneNumberHelper.getCountryCode(this.colleague.phones, PhoneType.Mobile);
          this.landlineCountryAlphaCode = PhoneNumberHelper.getCountryCode(this.colleague.phones, PhoneType.LandLine);
        }
      }
    });

    if (this.colleague) {
      const userPhones = this.colleague.phones;
      this.form = this.formBuilder.group({
        firstName: [this.colleague.firstName, Validators.required],
        lastName: [this.colleague.lastName, Validators.required],
        email: [
          { value: this.colleague.email, disabled: true },
          [
            Validators.required,
            Validators.pattern(
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
          ]
        ],
        phone: [PhoneNumberHelper.getNumber(userPhones, PhoneType.Mobile)],
        landline: [PhoneNumberHelper.getNumber(userPhones, PhoneType.LandLine), Validators.required]
      });
    } else {
      this.form = this.formBuilder.group({
        firstName: [null, Validators.required],
        lastName: [null, Validators.required],
        email: [
          null,
          [
            Validators.required,
            Validators.pattern(
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
          ]
        ],
        phone: [null],
        landline: [null, Validators.required]
      });
    }
  }

  /**
   * Validate phone number
   *
   * @return {*}
   * @memberof SaveColleagueDialogComponent
   */
  validatePhoneNumber() {
    return PhoneNumberHelper.validatePhoneNumber(this.mobilePhone, this.mobilePhoneCountryCode);
  }

  /**
   * Validate landline number
   *
   * @return {*}
   * @memberof SaveColleagueDialogComponent
   */
  validateLandline() {
    return PhoneNumberHelper.validateLandlineNumber(this.landline, this.countryCodeLandline, this.form);
  }

  /**
   * Check field valid for display error component
   *
   * @param {string} field
   * @return {*}
   * @memberof SaveColleagueDialogComponent
   */
  isFieldValid(field: string) {
    return !this.form.get(field).valid && (this.form.get(field).touched || this.form.get(field).dirty);
  }

  /**
   * Submit colleague form, will submit if form valid, else will start validating form fields function
   *
   * @return {*}
   * @memberof SaveColleagueDialogComponent
   */
  onSubmit() {
    /**
     * If have not valid phone number stop submit
     */
    if (!this.validatePhoneNumber() || !this.validateLandline()) {
      return false;
    }

    if (this.form.valid && !this.colleague) {
      this.submitButtonClicked = true;
      this.store.dispatch(
        new ActionColleagueCheckExist({
          email: this.form.getRawValue().email
        })
      );
    } else if (this.form.valid && this.colleague) {
      this.sentForm();
    } else if (!this.form.valid) {
      this.validateAllFormFields(this.form);
    }
  }

  /**
   * Sent colleague form
   *
   * @memberof SaveColleagueDialogComponent
   */
  sentForm() {
    const formData = this.getFormData();
    if (this.data.isAdminAction) {
      this.store.dispatch(
        new ActionSupportEditUser({
          user: formData,
          id: this.colleague.id
        })
      );
    } else {
      if (this.colleague) {
        if (this.data.editInvitedUser) {
          this.store.dispatch(
            new ActionColleagueUpdateInvitedUser({
              colleague: formData,
              isLoggedUser: this.data.isLoggedUser
            })
          );
        } else {
          this.store.dispatch(
            new ActionColleagueUpdate({
              colleague: formData
            })
          );
        }
      } else {
        this.store.dispatch(
          new ActionColleagueAdd({
            colleague: formData
          })
        );
      }
    }
    this.dialogRef.close();
  }

  /**
   * Get from data
   *
   * @return {*}
   * @memberof SaveColleagueDialogComponent
   */
  getFormData() {
    return {
      id: this.colleague ? this.colleague.id : null,
      email: this.form.getRawValue().email,
      firstName: this.form.getRawValue().firstName,
      lastName: this.form.getRawValue().lastName,
      phones: [
        {
          type: PhoneType.Mobile,
          number: PhoneNumberHelper.getFormattedPhoneNumber(this.mobilePhone, this.mobilePhoneCountryCode)
        },
        {
          type: PhoneType.LandLine,
          number: PhoneNumberHelper.getFormattedPhoneNumber(this.landline, this.countryCodeLandline)
        }
      ]
    };
  }

  /**
   * Validate form fields function, mark fields as touched
   *
   * @param {FormGroup} formGroup
   * @memberof SaveColleagueDialogComponent
   */
  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * Custom cleanup that needs to occur when the instance is destroyed, unsubscribe Observables and detach event handlers to avoid
   * memory leaks.
   *
   * @memberof SaveColleagueDialogComponent
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
