import { Action } from "@ngrx/store";

import {
  SupportCompanyFilter,
  SupportCompanyListResponse,
  IFMCompanyData,
  IUpdateUserRoles
} from "@modules/support/models";

export const fmCompaniesStoreName = "fmCompany";
const localState = (state) => state.support.fmCompany || supportFMCompanyInitialState;

export enum SupportFMCompanyActionTypes {
  FM_GET_COMPANIES = "[Support] Get fm companies",
  FM_GET_COMPANIES_SUCCESS = "[Support] Get fm companies success",
  FM_GET_COMPANIES_ERROR = "[Support] Get fm companies error",
  FM_EXPORT = "[Support] Export fm companied",
  FM_NOTHING_HAPPENDS = "[Support] Empty method",
  FM_SET_SEARCH_DATA = "[Support] Set FM search data",
  FM_ADD_COMPANY = "[Support] Add FM Company",
  FM_ADD_COMPANY_SUCCESS = "[Support] Add FM Company Success",
  FM_UPDATE_USER_ROLES = "[Support] Update User Roles",
  FM_UPDATE_USER_ROLES_SUCCESS = "[Support] Update User Roles Success",
  FM_GET_PAID_COMPANIES = "[Support] Get fm paid companies",
  FM_GET_PAID_COMPANIES_SUCCESS = "[Support] Get fm paid companies success",
  FM_GET_PAID_COMPANIES_ERROR = "[Support] Get fm paid companies error"
}

export class ActionSupportFMCompanyGetCompanies implements Action {
  readonly type = SupportFMCompanyActionTypes.FM_GET_COMPANIES;
  constructor(
    public payload: {
      page;
      size;
      filter: SupportCompanyFilter;
      withClearList?: boolean;
    }
  ) {}
}

export class ActionSupportFMCompanyGetCompaniesSuccess implements Action {
  readonly type = SupportFMCompanyActionTypes.FM_GET_COMPANIES_SUCCESS;
  constructor(public payload: SupportCompanyListResponse) {}
}

export class ActionSupportFMCompanyGetCompaniesError implements Action {
  readonly type = SupportFMCompanyActionTypes.FM_GET_COMPANIES_ERROR;
  constructor(
    public payload: {
      error: any;
    }
  ) {}
}

export class ActionSupportFMCompaniesExport implements Action {
  readonly type = SupportFMCompanyActionTypes.FM_EXPORT;
  constructor() {}
}

export class ActionSupportNothingHappends implements Action {
  readonly type = SupportFMCompanyActionTypes.FM_NOTHING_HAPPENDS;
  constructor() {}
}

export class ActionSupportFMCompanySetSearchData implements Action {
  readonly type = SupportFMCompanyActionTypes.FM_SET_SEARCH_DATA;
  constructor(
    public payload: {
      filter: SupportCompanyFilter;
      columns: any[];
    }
  ) {}
}

export class ActionFMAddCompany implements Action {
  readonly type = SupportFMCompanyActionTypes.FM_ADD_COMPANY;
  constructor(
    public payload?: {
      fmCompanyData: IFMCompanyData;
    }
  ) {}
}

export class ActionFMAddCompanySuccess implements Action {
  readonly type = SupportFMCompanyActionTypes.FM_ADD_COMPANY_SUCCESS;
  constructor() {}
}

export class ActionUpdateUserRoles implements Action {
  readonly type = SupportFMCompanyActionTypes.FM_UPDATE_USER_ROLES;
  constructor(
    public payload?: {
      model: IUpdateUserRoles;
    }
  ) {}
}

export class ActionUpdateUserRolesSuccess implements Action {
  readonly type = SupportFMCompanyActionTypes.FM_UPDATE_USER_ROLES_SUCCESS;
  constructor() {}
}

export class ActionSupportFMCompanyGetPaidCompanies implements Action {
  readonly type = SupportFMCompanyActionTypes.FM_GET_PAID_COMPANIES;
  constructor(
    public payload: {
      companyAuditorId: string;
      withClearList?: boolean;
    }
  ) {}
}

export class ActionSupportFMCompanyGetPaidCompaniesSuccess implements Action {
  readonly type = SupportFMCompanyActionTypes.FM_GET_PAID_COMPANIES_SUCCESS;
  constructor(public payload: SupportCompanyListResponse) {}
}

export class ActionSupportFMCompanyGetPaidCompaniesError implements Action {
  readonly type = SupportFMCompanyActionTypes.FM_GET_PAID_COMPANIES_ERROR;
  constructor(
    public payload: {
      error: any;
    }
  ) {}
}

export type SupportFMCompanyAction =
  | ActionSupportFMCompanyGetCompanies
  | ActionSupportFMCompanyGetCompaniesSuccess
  | ActionSupportFMCompanyGetCompaniesError
  | ActionSupportFMCompaniesExport
  | ActionSupportNothingHappends
  | ActionSupportFMCompanySetSearchData
  | ActionFMAddCompany
  | ActionFMAddCompanySuccess
  | ActionUpdateUserRoles
  | ActionUpdateUserRolesSuccess
  | ActionSupportFMCompanyGetPaidCompanies
  | ActionSupportFMCompanyGetPaidCompaniesSuccess
  | ActionSupportFMCompanyGetPaidCompaniesError;

export const supportFMCompanyLoading = (state) => localState(state).loading;
export const supportFMCompanyList = (state) => localState(state).list;
export const supportPaidFMCompanyList = (state) => localState(state).paidCompanyList;
export const supportFMCompanyPage = (state) => localState(state).page;
export const supportFMCompanySize = (state) => localState(state).size;
export const supportFMCompanyTotal = (state) => localState(state).total;

export const supportFMCompanyInitialState: SupportFMCompanyState = {
  loading: true,
  list: [],
  paidCompanyList: [],
  size: 20,
  page: 0,
  total: 0
};

export function supportFMCopmanyReducer(
  state: SupportFMCompanyState = supportFMCompanyInitialState,
  action: SupportFMCompanyAction
) {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case SupportFMCompanyActionTypes.FM_GET_COMPANIES:
      return {
        ...state,
        list: action.payload.withClearList ? [] : state.list,
        loading: true
      };
    case SupportFMCompanyActionTypes.FM_GET_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.result,
        total: action.payload.count
      };
    case SupportFMCompanyActionTypes.FM_GET_COMPANIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case SupportFMCompanyActionTypes.FM_SET_SEARCH_DATA:
      return {
        ...state,
        filter: action.payload
      };
    case SupportFMCompanyActionTypes.FM_ADD_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case SupportFMCompanyActionTypes.FM_GET_PAID_COMPANIES:
      return {
        ...state,
        list: action.payload.withClearList ? [] : state.list,
        loading: true
      };
    case SupportFMCompanyActionTypes.FM_GET_PAID_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        paidCompanyList: action.payload.result
      };
    case SupportFMCompanyActionTypes.FM_GET_PAID_COMPANIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
}

export interface SupportFMCompanyState {
  loading: boolean;
  list: any[];
  page: number;
  size: number;
  total: number;
  paidCompanyList: any[];
}

export interface FMCompaniesSearchData {
  filter: SupportCompanyFilter;
  columns: any[];
}
