import { Action, createFeatureSelector, createSelector } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import { IViewProgressState } from "@modules/view-progress/view-progress.module";
import { IDistributorDetails, IQResponseProgress } from "@shared/models/distributor.interfaces";
import { IUser } from "@app/core/auth/identity/user.reducer";

export const initialViewProgressState: IDistributor = {
  distributorDetails: null,
  relationshipData: null,
  loading: false
};

export enum ViewProgressActionTypes {
  DISTRIBUTOR_VIEW_PROGRESS_RETRIEVE = "[Distributor] Retrieve Progress",
  DISTRIBUTOR_VIEW_PROGRESS_RETRIEVE_SUCCESS = "[Distributor] Retrieve Progress Success",
  DISTRIBUTOR_VIEW_PROGRESS_RETRIEVE_ERROR = "[Distributor] Retrieve Progress Error",
  DISTRIBUTOR_RELATIONSHIP_INFO_ADD_CUSTOM_FIELD = "[Distributor] Relationship Info Add Custom Field",
  DISTRIBUTOR_RELATIONSHIP_INFO_SAVE_CUSTOM_FIELD = "[Distributor] Relationship Info Save Custom Field",
  DISTRIBUTOR_RELATIONSHIP_INFO_DELETE_CUSTOM_FIELD = "[Distributor] Relationship Info Delete Custom Field",
  DISTRIBUTOR_RELATIONSHIP_INFO_SAVE = "[Distributor] Relationship Info Save",
  DISTRIBUTOR_RELATIONSHIP_MANAGER_UPDATE = "[Distributor] Relationship Manager Update",
  DELETE_CUSTOM_FIELD = "[Distributor] Delete Custom Field",
  UPDATE_CUSTOM_FIELD = "[Distributor] Update Custom Field",
  ADD_CUSTOM_FIELD = "[Distributor] Add Custom Field",
  UPDATE_MAIN_FIELD_VALUE = "[Distributor] Update Main Field Value",
  UPDATE_CUSTOM_FIELD_VALUE = "[Distributor] Update Custom Field Value",
  DOCUMENT_GET = "[Distributor] Get Document",
  DOCUMENT_GET_SUCCESS = "[Distributor] Get Document Success",
  DOCUMENT_GET_ERROR = "[Distributor] Get Document Error",
  DOCUMENT_UPLOAD = "[Distributor] Upload Document",
  DOCUMENT_UPLOAD_SUCCESS = "[Distributor] Upload Document Success",
  DOCUMENT_UPLOAD_ERROR = "[Distributor] Upload Document Error",
  DELETE_DOCUMENT = "[Distributor] Delete Document"
}

export class ActionViewProgressRetirieve implements Action {
  readonly type = ViewProgressActionTypes.DISTRIBUTOR_VIEW_PROGRESS_RETRIEVE;
  constructor(public payload: { lei: string; base_url: string }) {}
}

export class ActionViewProgressSuccess implements Action {
  readonly type = ViewProgressActionTypes.DISTRIBUTOR_VIEW_PROGRESS_RETRIEVE_SUCCESS;
  constructor(public payload: { distributorDetails: IDistributorProgress }) {}
}

export class ActionViewProgressError implements Action {
  readonly type = ViewProgressActionTypes.DISTRIBUTOR_VIEW_PROGRESS_RETRIEVE_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionRelationshipSave implements Action {
  readonly type = ViewProgressActionTypes.DISTRIBUTOR_RELATIONSHIP_INFO_SAVE;
  constructor(
    public payload?: {
      fmRelationshipMetadata: IFmRelationshipMetadata;
      distributorLei: string;
    }
  ) {}
}

export class ActionRelationshipManagerUpdate implements Action {
  readonly type = ViewProgressActionTypes.DISTRIBUTOR_RELATIONSHIP_MANAGER_UPDATE;
  constructor(
    public payload?: {
      fmRelationshipManager: Array<IUser>;
    }
  ) {}
}

export class ActionRelationshipCustomFieldAdd implements Action {
  readonly type = ViewProgressActionTypes.DISTRIBUTOR_RELATIONSHIP_INFO_ADD_CUSTOM_FIELD;
  constructor(
    public payload?: {
      customField: ICustomField;
    }
  ) {}
}

export class ActionRelationshipCustomFieldSave implements Action {
  readonly type = ViewProgressActionTypes.DISTRIBUTOR_RELATIONSHIP_INFO_SAVE_CUSTOM_FIELD;
  constructor(
    public payload?: {
      customField: ICustomField;
    }
  ) {}
}

export class ActionRelationshipCustomFieldDelete implements Action {
  readonly type = ViewProgressActionTypes.DISTRIBUTOR_RELATIONSHIP_INFO_DELETE_CUSTOM_FIELD;
  constructor(public payload: { customField: ICustomField }) {}
}

export class ActionCustomFieldDelete implements Action {
  readonly type = ViewProgressActionTypes.DELETE_CUSTOM_FIELD;
  constructor(
    public payload: {
      customFieldId: string;
    }
  ) {}
}

export class ActionCustomFieldUpdate implements Action {
  readonly type = ViewProgressActionTypes.UPDATE_CUSTOM_FIELD;
  constructor(
    public payload: {
      customField: ICustomField;
    }
  ) {}
}

export class ActionCustomFieldAdd implements Action {
  readonly type = ViewProgressActionTypes.ADD_CUSTOM_FIELD;
  constructor(
    public payload: {
      customField: ICustomField;
    }
  ) {}
}

export class ActionUpdateMainFieldValue implements Action {
  readonly type = ViewProgressActionTypes.UPDATE_MAIN_FIELD_VALUE;
  constructor(
    public payload: {
      newValue: string;
      metadataName: string;
    }
  ) {}
}

export class ActionUpdateCustomFieldValue implements Action {
  readonly type = ViewProgressActionTypes.UPDATE_CUSTOM_FIELD_VALUE;
  constructor(
    public payload: {
      newValue: string;
      customFieldId: string;
    }
  ) {}
}

export class ActionGetDocument implements Action {
  readonly type = ViewProgressActionTypes.DOCUMENT_GET;
  constructor(
    public payload: {
      documentId: string;
      documentName: string;
    }
  ) {}
}

export class ActionGetDocumentSuccess implements Action {
  readonly type = ViewProgressActionTypes.DOCUMENT_GET_SUCCESS;
  constructor(
    public payload: {
      document: DocumentDownloadSuccess;
    }
  ) {}
}

export class ActionGetDocumentError implements Action {
  readonly type = ViewProgressActionTypes.DOCUMENT_GET_ERROR;
  constructor(
    public payload: {
      error: HttpErrorResponse;
    }
  ) {}
}

export class ActionUploadDocument implements Action {
  readonly type = ViewProgressActionTypes.DOCUMENT_UPLOAD;
  constructor(
    public payload: {
      document: File;
      description: string;
      uploadedDate: any;
      uploadedBy: IUser;
    }
  ) {}
}

export class ActionUploadDocumentSuccess implements Action {
  readonly type = ViewProgressActionTypes.DOCUMENT_UPLOAD_SUCCESS;
  constructor(
    public payload: {
      document: DocumentUploadSuccess;
    }
  ) {}
}

export class ActionUploadDocumentError implements Action {
  readonly type = ViewProgressActionTypes.DOCUMENT_UPLOAD_ERROR;
  constructor(
    public payload: {
      error: HttpErrorResponse;
    }
  ) {}
}

export class ActionDeleteDocument implements Action {
  readonly type = ViewProgressActionTypes.DELETE_DOCUMENT;
  constructor(
    public payload: {
      documentId: string;
    }
  ) {}
}

export type ViewProgressActions =
  | ActionViewProgressRetirieve
  | ActionViewProgressSuccess
  | ActionViewProgressError
  | ActionRelationshipCustomFieldAdd
  | ActionRelationshipCustomFieldSave
  | ActionRelationshipCustomFieldDelete
  | ActionRelationshipSave
  | ActionRelationshipManagerUpdate
  | ActionCustomFieldUpdate
  | ActionCustomFieldDelete
  | ActionCustomFieldAdd
  | ActionUpdateMainFieldValue
  | ActionUpdateCustomFieldValue
  | ActionGetDocument
  | ActionGetDocumentSuccess
  | ActionGetDocumentError
  | ActionUploadDocument
  | ActionUploadDocumentSuccess
  | ActionUploadDocumentError
  | ActionDeleteDocument;

const featureSelector = createFeatureSelector<IViewProgressState>("view-progress");
export const selectViewProgressBlock = createSelector(
  featureSelector,
  (state: IViewProgressState) => state && state.viewProgressState
);

export const selectorViewProgress = createSelector(
  selectViewProgressBlock,
  (state: IDistributor) => state && state.distributorDetails
);

export const selectorRelationshipData = createSelector(
  selectViewProgressBlock,
  (state: IDistributor) => state && state.relationshipData
);

export const selectorDocuments = createSelector(
  selectViewProgressBlock,
  (state: IDistributor) => state && state.docDownloadSuccess
);

export const selectorDocumentsLoading = createSelector(selectViewProgressBlock, (state) => {
  if (state) {
    return state.loading;
  }
});

export function viewProgressReducer(
  state: IDistributor = initialViewProgressState,
  action: ViewProgressActions
): IDistributor {
  switch (action.type) {
    case ViewProgressActionTypes.DISTRIBUTOR_VIEW_PROGRESS_RETRIEVE:
      return {
        ...state,
        loading: true,
        distributorDetails: null,
        relationshipData: null,
        docDownloadSuccess: null
      };
    case ViewProgressActionTypes.DISTRIBUTOR_VIEW_PROGRESS_RETRIEVE_SUCCESS:
      return {
        ...state,
        loading: false,
        distributorDetails: action.payload.distributorDetails,
        relationshipData: {
          disableUpdate: false,
          fmRelationshipMetadata: action.payload.distributorDetails.fmRelationshipMetadata,
          customRelationshipFieldDefinitions: action.payload.distributorDetails.customRelationshipFieldDefinitions.map(
            (customFieldDef: ICustomField) => {
              const relationshipMetadata = action.payload.distributorDetails.fmRelationshipMetadata;
              const customRelationshipFields =
                relationshipMetadata && relationshipMetadata.customRelationshipFields
                  ? relationshipMetadata.customRelationshipFields
                  : [];
              let value = [];
              customRelationshipFields.forEach((customField: CustomRelationshipField) => {
                if (customFieldDef.id === customField.id) {
                  value = customField.value;
                }
              });
              return {
                ...customFieldDef,
                fieldValue: value
              };
            }
          )
        },
        docDownloadSuccess: null
      };
    case ViewProgressActionTypes.DISTRIBUTOR_VIEW_PROGRESS_RETRIEVE_ERROR:
      return {
        ...state,
        loading: false,
        distributorDetails: null,
        relationshipData: null,
        docDownloadSuccess: null
      };

    case ViewProgressActionTypes.DISTRIBUTOR_RELATIONSHIP_MANAGER_UPDATE:
      return {
        ...state,
        loading: false,
        relationshipData: {
          ...state.relationshipData,
          fmRelationshipMetadata: {
            ...state.relationshipData.fmRelationshipMetadata,
            relationshipManagers: action.payload.fmRelationshipManager
          }
        },
        docDownloadSuccess: null
      };

    case ViewProgressActionTypes.DOCUMENT_GET:
      return {
        ...state,
        loading: true,
        relationshipData: null,
        docDownloadSuccess: null
      };
    case ViewProgressActionTypes.DOCUMENT_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        relationshipData: null,
        docDownloadSuccess: action.payload.document
      };
    case ViewProgressActionTypes.DOCUMENT_GET_ERROR:
      return {
        ...state,
        loading: false,
        relationshipData: null,
        docDownloadSuccess: null
      };
    case ViewProgressActionTypes.DOCUMENT_UPLOAD:
      return {
        ...state,
        loading: true,
        relationshipData: {
          ...state.relationshipData,
          fmRelationshipMetadata: {
            ...state.relationshipData.fmRelationshipMetadata,
            documents: [...state.relationshipData.fmRelationshipMetadata.documents]
          }
        },
        docDownloadSuccess: null
      };

    case ViewProgressActionTypes.DOCUMENT_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        relationshipData: {
          ...state.relationshipData,
          fmRelationshipMetadata: {
            ...state.relationshipData.fmRelationshipMetadata,
            documents: [...state.relationshipData.fmRelationshipMetadata.documents].concat([action.payload.document])
          }
        },
        docDownloadSuccess: null
      };

    case ViewProgressActionTypes.DOCUMENT_UPLOAD_ERROR:
      return {
        ...state,
        loading: false
      };

    case ViewProgressActionTypes.DELETE_DOCUMENT:
      return {
        ...state,
        loading: false,
        relationshipData: {
          ...state.relationshipData,
          fmRelationshipMetadata: {
            ...state.relationshipData.fmRelationshipMetadata,
            documents: state.relationshipData.fmRelationshipMetadata.documents.filter(
              (document: DocumentUploadSuccess) => document.id !== action.payload.documentId
            )
          }
        },
        docDownloadSuccess: null
      };

    case ViewProgressActionTypes.DELETE_CUSTOM_FIELD:
      return {
        ...state,
        loading: false,
        relationshipData: {
          ...state.relationshipData,
          disableUpdate: false,
          customRelationshipFieldDefinitions: state.relationshipData.customRelationshipFieldDefinitions.filter(
            (currentField: ICustomField) => currentField.id !== action.payload.customFieldId
          )
        },
        docDownloadSuccess: null
      };

    case ViewProgressActionTypes.UPDATE_CUSTOM_FIELD:
      return {
        ...state,
        loading: false,
        relationshipData: {
          ...state.relationshipData,
          disableUpdate: false,
          customRelationshipFieldDefinitions: state.relationshipData.customRelationshipFieldDefinitions.map(
            (currentField: ICustomField) => {
              if (currentField.id !== action.payload.customField.id) {
                return currentField;
              }
              return {
                ...currentField,
                label: action.payload.customField.label,
                allowedValues: action.payload.customField.allowedValues
              };
            }
          )
        },
        docDownloadSuccess: null
      };

    case ViewProgressActionTypes.ADD_CUSTOM_FIELD:
      const field = {
        ...action.payload.customField,
        fieldValue: []
      };
      return {
        ...state,
        loading: false,
        relationshipData: {
          ...state.relationshipData,
          disableUpdate: false,
          customRelationshipFieldDefinitions: state.relationshipData.customRelationshipFieldDefinitions.concat(field)
        },
        docDownloadSuccess: null
      };

    case ViewProgressActionTypes.UPDATE_MAIN_FIELD_VALUE:
      return {
        ...state,
        loading: false,
        relationshipData: {
          ...state.relationshipData,
          disableUpdate: false,
          fmRelationshipMetadata: {
            ...state.relationshipData.fmRelationshipMetadata,
            [action.payload.metadataName]: action.payload.newValue
          }
        },
        docDownloadSuccess: null
      };
    case ViewProgressActionTypes.UPDATE_CUSTOM_FIELD_VALUE:
      return {
        ...state,
        loading: false,
        relationshipData: {
          ...state.relationshipData,
          disableUpdate: true,
          customRelationshipFieldDefinitions: state.relationshipData.customRelationshipFieldDefinitions.map(
            (field: ICustomField) => {
              if (field.id !== action.payload.customFieldId) {
                return field;
              }
              return {
                ...field,
                fieldValue: [action.payload.newValue]
              };
            }
          )
        },
        docDownloadSuccess: null
      };
    default:
      return state;
  }
}

export interface IDistributor {
  distributorDetails: IDistributorProgress;
  relationshipData: IRelationshipData;
  docDownloadSuccess?: DocumentDownloadSuccess;
  loading: boolean;
}

export interface IRelationshipData {
  customRelationshipFieldDefinitions: ICustomField[];
  fmRelationshipMetadata: IFmRelationshipMetadata;
  disableUpdate?: boolean;
}

export interface IDistributorProgress {
  distributorDetails: IDistributorDetails;
  qResponseProgress: IQResponseProgress;
  customRelationshipFieldDefinitions: ICustomField[];
  fmRelationshipMetadata: IFmRelationshipMetadata;
}

export interface CustomRelationshipField {
  id: string;
  value: string[];
}

export interface IFmRelationshipMetadata {
  relationshipManagerId?: Array<string>; // depreacate
  relationshipManagerIds?: Array<string>;
  relationshipManagers: Array<IUser>;
  status: string;
  inherentRiskCategory: string;
  managementRegion: string;
  managementClassification: string;
  salesRegion: string;
  fundGroup: string;
  newContract: string;
  customRelationshipFields: CustomRelationshipField[];
  documents: DocumentUploadSuccess[];
  curatorId: string;
}

export interface ICustomField {
  id?: string;
  label?: string;
  helpText?: string;
  allowedValues?: Array<string>;
  type: string;
  allowMultiple?: boolean;
  status?: string;
  fieldValue?: Array<string>;
}

/**
 * Interface for document upload success.
 */
export interface DocumentUploadSuccess {
  id?: string;
  name: string;
  description: string;
  uploadedDate: any;
  uploadedBy: IDocumentUser;
}

/**
 * Interface for document download success.
 */
export interface DocumentDownloadSuccess {
  fileId: string;
  fileName: string;
  blob: Blob;
}

export interface IDocumentUser {
  id?: string;
  title?: string;
  firstName: string;
  lastName: string;
  email: string;
}
