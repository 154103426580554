import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { NgSelectModule } from "@ng-select/ng-select";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSelectModule } from "@angular/material/select";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatList, MatListOption, MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatTab, MatTabBody, MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatStepperModule } from "@angular/material/stepper";
import {
  DxChartModule,
  DxPieChartModule,
  DxRangeSliderModule,
  DxNumberBoxModule,
  DxTooltipModule,
  DxDataGridModule,
  DxSwitchModule,
  DxDropDownButtonModule,
  DxDateBoxModule,
  DxHtmlEditorModule
} from "devextreme-angular";
import { registerPalette } from "devextreme/viz/palette";
import { CountdownModule } from "ngx-countdown";

import {
  CommonFooterComponent,
  CommonHeaderComponent,
  FieldErrorShowComponent,
  FieldDatetimeRangeComponent,
  LoadingScreenComponent
} from "@shared/components";
import {
  EditDialogComponent,
  EditDialogService,
  EditDistributorDialogComponent,
  EditDistributorDialogService,
  RejectAndConfirmDialogComponent,
  RejectAndConfirmDialogService,
  ResendEmailConfirmDialogComponent,
  ResendEmailConfirmDialogService,
  UploadKeydocumentDialogComponent,
  UploadKeydocumentDialogService,
  SaveColleagueDialogComponent,
  DetailContactDialogComponent,
  SaveColleagueDialogService,
  ShowOptionsDialogComponent,
  ShowOptionsDialogService,
  ImportColleaguesDialogComponent,
  ImportColleaguesDialogService,
  FirstVisitService,
  RejectResponsibilityDialogComponent,
  RejectResponsibilityDialogService,
  ViewDistributorsDialogComponent,
  ViewDistributorsDialogService,
  ChangeReviewGroupApproversDialogService,
  ChangeReviewGroupApproversDialogComponent,
  SaveInteractionDialogComponent,
  SaveInteractionDialogService,
  SaveCustomFieldDialogComponent,
  SaveCustomFieldDialogService,
  TooltipDialogComponent,
  TooltipDialogService
} from "@shared/dialogs";
import { SavedFiltersService } from "./services/saved-filters.service";
import { NotificationService } from "./services/notification-service";
import { FMDashboardFirstVisitComponent } from "@shared/dialogs/first-visit-dialog/components/fm-dashboard-first-visit/fm-dashboard-first-visit.component";
import { CompleteNewCycleFirstVisitComponent } from "@shared/dialogs/first-visit-dialog/components/complete-new-cycle-first-visit/complete-new-cycle-first-visit.component";
import { UpdateTermsFirstVisitComponent } from "@shared/dialogs/first-visit-dialog/components/update-terms-first-visit/update-terms-first-visit.component";

import { ExportDdqDialogComponent } from "./dialogs/export-ddq-dialog/export-ddq-dialog.component";
import { ExportDdqDialogService } from "./dialogs/export-ddq-dialog/export-ddq-dialog.service";
import { ColleaguesService } from "@app/modules/qc-complete/components/qc-complete/services";
import { ConfigurableBaseComponent } from "@shared/components/configurable-base/configurable-base.component";
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { AppCapitalizeDirective } from "./directives/app-capitalize.directive";
import { FileDropDirective } from "./directives/file-drop.directive";
import { SpinnerMaterialComponent } from "./components/spinner-material/spinner-material.component";
import { DDDTableFixBottomScrollDirective } from "./directives/ddd-table-fix-bottom-scroll.directive";

registerPalette("dddCustomPalette", {
  // Applies in the BarGauge, Chart, Funnel, PieChart, PolarChart, Sankey, and TreeMap with a discrete colorizer
  simpleSet: ["#093466", "#64A71E", "#B99000", "#0987B3", "#F1963A", "#DF0D0D"],
  // Applies in the CircularGauge and LinearGauge
  indicatingSet: ["#093466", "#64A71E", "#B99000"],
  // Applies in the VectorMap and TreeMap with a gradient or range colorizer
  gradientSet: ["#093466", "#64A71E"]
});

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        MatExpansionModule,
        MatIconModule,
        MatDividerModule,
        MatCheckboxModule,
        MatTabsModule,
        MatDialogModule,
        MatChipsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatTooltipModule,
        MatStepperModule,
        NgSelectModule,
        MatRadioModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSlideToggleModule,
        DxChartModule,
        DxPieChartModule,
        MatAutocompleteModule,
        DxSwitchModule,
        DxRangeSliderModule,
        DxNumberBoxModule,
        DxTooltipModule,
        CountdownModule,
        MatListModule,
        DxDataGridModule,
        DxDropDownButtonModule,
        DxDateBoxModule,
        DxHtmlEditorModule,
        FieldErrorShowComponent,
        FieldDatetimeRangeComponent,
        CommonHeaderComponent,
        SaveColleagueDialogComponent,
        EditDialogComponent,
        EditDistributorDialogComponent,
        ShowOptionsDialogComponent,
        CommonFooterComponent,
        RejectAndConfirmDialogComponent,
        ResendEmailConfirmDialogComponent,
        UploadKeydocumentDialogComponent,
        ImportColleaguesDialogComponent,
        FMDashboardFirstVisitComponent,
        CompleteNewCycleFirstVisitComponent,
        UpdateTermsFirstVisitComponent,
        ExportDdqDialogComponent,
        RejectResponsibilityDialogComponent,
        DetailContactDialogComponent,
        ViewDistributorsDialogComponent,
        ChangeReviewGroupApproversDialogComponent,
        ConfigurableBaseComponent,
        SaveInteractionDialogComponent,
        SaveCustomFieldDialogComponent,
        AppCapitalizeDirective,
        DDDTableFixBottomScrollDirective,
        FileDropDirective,
        SpinnerMaterialComponent,
        LoadingScreenComponent,
        TooltipDialogComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FieldErrorShowComponent,
        FieldDatetimeRangeComponent,
        CommonHeaderComponent,
        MatCardModule,
        MatExpansionModule,
        MatIconModule,
        MatDividerModule,
        MatCheckboxModule,
        MatTabsModule,
        MatDialogModule,
        MatChipsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatSliderModule,
        MatMomentDateModule,
        MatTooltipModule,
        MatStepperModule,
        MatRadioModule,
        MatListModule,
        CommonFooterComponent,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSlideToggleModule,
        TranslateModule,
        ConfigurableBaseComponent,
        NgSelectModule,
        DxChartModule,
        DxPieChartModule,
        MatAutocompleteModule,
        DxSwitchModule,
        DxRangeSliderModule,
        DxNumberBoxModule,
        DxTooltipModule,
        DDDTableFixBottomScrollDirective,
        CountdownModule,
        FileDropDirective,
        LoadingScreenComponent,
        TooltipDialogComponent,
        DxDataGridModule,
        DxDropDownButtonModule,
        DxDateBoxModule,
        DxHtmlEditorModule
    ],
    providers: [
        RejectAndConfirmDialogService,
        ResendEmailConfirmDialogService,
        UploadKeydocumentDialogService,
        SaveColleagueDialogService,
        EditDialogService,
        EditDistributorDialogService,
        ShowOptionsDialogService,
        ImportColleaguesDialogService,
        FirstVisitService,
        ExportDdqDialogService,
        RejectResponsibilityDialogService,
        SavedFiltersService,
        ViewDistributorsDialogService,
        NotificationService,
        ChangeReviewGroupApproversDialogService,
        ColleaguesService,
        SaveInteractionDialogService,
        SaveCustomFieldDialogService,
        TooltipDialogService,
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: { useUtc: false }
        },
        { provide: MAT_DATE_LOCALE, useValue: "en-GB" }
    ]
})
export class SharedModule {}
