import { HttpErrorResponse } from "@angular/common/http";
import { Action, createSelector, createFeatureSelector } from "@ngrx/store";
import { IStateTablesData, ISubTable, Ownership, IPriorPeriodTableData, IPriorPeriodDistributorFilter, IPriorPeriodDistributor } from "@modules/fm-analytics/model/fm-analytics.model";

/**
 * Interface for analytics fm state.
 */
export interface AnalyticsFMState {
  loading: boolean;
  stateTablesData?: IStateTablesData;
  priorPeriodTableData?: IPriorPeriodTableData;
  priorPeriodDistributorList?: IPriorPeriodDistributor[];
  error?: HttpErrorResponse;
}

/**
 * AnalyticsFM initial state
 */
export const initialState: AnalyticsFMState = {
  loading: false
};

/**
 * AnalyticsFM selectors
 */
const featureAnalyticsFMSelector = createFeatureSelector<any>("analyticsFMState");
export const selectorAnalyticsFMState = createSelector(featureAnalyticsFMSelector, (state) => {
  if (state) {
    return state.analytics;
  }
});
export const selectorStateTablesData = createSelector(selectorAnalyticsFMState, (state) => {
  if (state) {
    return state.stateTablesData;
  }
});
export const selectorPriorPeriodTableData = createSelector(selectorAnalyticsFMState, (state) => {
  if (state) {
    return state.priorPeriodTableData;
  }
});

export const selectorAnalyticsFMLoading = createSelector(selectorAnalyticsFMState, (state) => {
  if (state) {
    return state.loading;
  }
});
export const selectorAnalyticsDistributorsFromPriorPeriod = createSelector(selectorAnalyticsFMState, (state) => {
  if (state) {
    return state.priorPeriodDistributorList;
  }
});

export enum AnalyticsFMActionTypes {
  ANALYTICS_FM_STATES_DATA_GET = "[AnalyticsFM] Get States Data",
  ANALYTICS_FM_STATES_DATA_GET_SUCCESS = "[AnalyticsFM] Get States Data Success",
  ANALYTICS_FM_STATES_DATA_GET_ERROR = "[AnalyticsFM] Get States Data Error",
  ANALYTICS_FM_PRIOR_PERIOD_DATA_GET = "[AnalyticsFM] Get Prior Period Data",
  ANALYTICS_FM_PRIOR_PERIOD_DATA_GET_SUCCESS = "[AnalyticsFM] Get Prior Period Data Success",
  ANALYTICS_FM_PRIOR_PERIOD_DATA_GET_ERROR = "[AnalyticsFM] Get Prior Period Data Error",
  ANALYTICS_DISTRIBUTORS_FROM_PRIOR_PERIOD_GET = "[AnalyticsFM] Get Distributors List In Prior Period",
  ANALYTICS_DISTRIBUTORS_FROM_PRIOR_PERIOD_GET_SUCCESS = "[AnalyticsFM] Get Distributors List In Prior Period Success",
  ANALYTICS_DISTRIBUTORS_FROM_PRIOR_PERIOD_GET_ERROR = "[AnalyticsFM] Get Distributors List In Prior Period Error",

  ANALYTICS_FM_EXPORT = "[AnalyticsFM] Export",
  ANALYTICS_FM_NOTHING_HAPPENDS = "[AnalyticsFM] empty method"
}

export class ActionAnalyticsFMStateDataGet implements Action {
  readonly type = AnalyticsFMActionTypes.ANALYTICS_FM_STATES_DATA_GET;
  constructor(public payload?: { type: Ownership; isTargetFM: boolean }) {}
}

export class ActionAnalyticsFMStateDataGetSuccess implements Action {
  readonly type = AnalyticsFMActionTypes.ANALYTICS_FM_STATES_DATA_GET_SUCCESS;
  constructor(public payload: { stateTablesData: IStateTablesData }) {}
}

export class ActionAnalyticsFMStateDataGetError implements Action {
  readonly type = AnalyticsFMActionTypes.ANALYTICS_FM_STATES_DATA_GET_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionAnalyticsFMPriorPeriodDataGet implements Action {
  readonly type = AnalyticsFMActionTypes.ANALYTICS_FM_PRIOR_PERIOD_DATA_GET;
  constructor() {}
}

export class ActionAnalyticsFMPriorPeriodDataGetSuccess implements Action {
  readonly type = AnalyticsFMActionTypes.ANALYTICS_FM_PRIOR_PERIOD_DATA_GET_SUCCESS;
  constructor(public payload: { priorPeriodTableData: IPriorPeriodTableData }) {}
}

export class ActionAnalyticsFMPriorPeriodDataGetError implements Action {
  readonly type = AnalyticsFMActionTypes.ANALYTICS_FM_PRIOR_PERIOD_DATA_GET_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionAnalyticsDistributorsFromPriorPeriodGet implements Action {
  readonly type = AnalyticsFMActionTypes.ANALYTICS_DISTRIBUTORS_FROM_PRIOR_PERIOD_GET;
  constructor(public payload: {filter: IPriorPeriodDistributorFilter}) {}
}

export class ActionAnalyticsDistributorsFromPriorPeriodGetSuccess implements Action {
  readonly type = AnalyticsFMActionTypes.ANALYTICS_DISTRIBUTORS_FROM_PRIOR_PERIOD_GET_SUCCESS;
  constructor(public payload: { distributorsInPriorPeriod: IPriorPeriodDistributor[] }) {}
}

export class ActionAnalyticsDistributorsFromPriorPeriodGetError implements Action {
  readonly type = AnalyticsFMActionTypes.ANALYTICS_DISTRIBUTORS_FROM_PRIOR_PERIOD_GET_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}



export class ActionAnalyticsFMExport implements Action {
  readonly type = AnalyticsFMActionTypes.ANALYTICS_FM_EXPORT;
  constructor(public payload: { analyticType: string; password: string; docName: string }) {}
}

export class ActionAnalyticNothingHappends implements Action {
  readonly type = AnalyticsFMActionTypes.ANALYTICS_FM_NOTHING_HAPPENDS;
  constructor() {}
}

export type AnalyticsFMActions =
  | ActionAnalyticsFMStateDataGet
  | ActionAnalyticsFMStateDataGetSuccess
  | ActionAnalyticsFMStateDataGetError
  | ActionAnalyticsFMPriorPeriodDataGet
  | ActionAnalyticsDistributorsFromPriorPeriodGet
  | ActionAnalyticsDistributorsFromPriorPeriodGetSuccess
  | ActionAnalyticsDistributorsFromPriorPeriodGetError
  | ActionAnalyticsFMPriorPeriodDataGetSuccess
  | ActionAnalyticsFMPriorPeriodDataGetError

  | ActionAnalyticsFMExport
  | ActionAnalyticNothingHappends;

export function analyticsFMReducer(
  state: AnalyticsFMState = initialState,
  action: AnalyticsFMActions
): AnalyticsFMState {
  switch (action.type) {
    case AnalyticsFMActionTypes.ANALYTICS_FM_STATES_DATA_GET:
      return {
        ...state,
        loading: true,
        stateTablesData: null,
        error: null
      };
    case AnalyticsFMActionTypes.ANALYTICS_FM_STATES_DATA_GET_SUCCESS:
      let data = action.payload.stateTablesData;
      if (action.payload.stateTablesData && action.payload.stateTablesData.subTables) {
        let totalCount = 0;
        action.payload.stateTablesData.subTables.forEach((subTable: ISubTable) => {
          totalCount = totalCount + subTable.grandTotal;
        });
        data = {
          ...data,
          grandTotal: totalCount
        };
      }
      return {
        ...state,
        loading: false,
        stateTablesData: data,
        error: null
      };
    case AnalyticsFMActionTypes.ANALYTICS_FM_STATES_DATA_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case AnalyticsFMActionTypes.ANALYTICS_FM_PRIOR_PERIOD_DATA_GET:
      return {
        ...state,
        stateTablesData: null,
        error: null
      };
    case AnalyticsFMActionTypes.ANALYTICS_FM_PRIOR_PERIOD_DATA_GET_SUCCESS:
      return {
        ...state,
        priorPeriodTableData: action.payload.priorPeriodTableData,
        error: null
      };
    case AnalyticsFMActionTypes.ANALYTICS_FM_PRIOR_PERIOD_DATA_GET_ERROR:
      return {
        ...state,
        error: action.payload.error
      };
      case AnalyticsFMActionTypes.ANALYTICS_DISTRIBUTORS_FROM_PRIOR_PERIOD_GET:
        return {
          ...state,
          loading: true,
          priorPeriodDistributorList: null,
          error: null
        };
      case AnalyticsFMActionTypes.ANALYTICS_DISTRIBUTORS_FROM_PRIOR_PERIOD_GET_SUCCESS:
        return {
          ...state,
          loading: false,
          priorPeriodDistributorList: action.payload.distributorsInPriorPeriod,
          error: null
        };
      case AnalyticsFMActionTypes.ANALYTICS_DISTRIBUTORS_FROM_PRIOR_PERIOD_GET_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload.error
        };

    default:
      return state;
  }
}
