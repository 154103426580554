import { createFeature, createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";

import { IUser } from "@app/core/auth/identity/user.reducer";
import { ICompaniesInteractionsResponse } from "@shared/models/interaction.interfaces";
import {
  ActionAllCompaniesInteractionsGet,
  ActionAllCompaniesInteractionsGetSuccess,
  ActionAllCompaniesInteractionsGetError,
  ActionAllCompanyUsersGet,
  ActionAllCompanyUsersGetSuccess,
  ActionAllCompanyUsersGetError
} from "@modules/all-companies-interactions/state/actions";

// interactionsCompany
export const ALL_COPMANIES_INTERACTIONS_FEATURE_KEY = 'allInteractionsCompanyState';

export interface AllCompaniesInteractionsSlice {
    [ALL_COPMANIES_INTERACTIONS_FEATURE_KEY]: CompanyInteractionsState
}

export const initialState: CompanyInteractionsState = {
  loading: false,
  interactionsData: null,
  companyUsers: null,
  error: null
}

/**
 *Interface for companies interactions state.
 *
 * @export
 * @interface CompanyInteractionsState
 */
 export interface CompanyInteractionsState {
  /**
   * Loading state status.
   *
   * @type {boolean}
   * @memberof CompanyInteractionsState
   */
  loading: boolean;

  /**
   * Companies interactions data.
   *
   * @type {ICompaniesInteractionsResponse}
   * @memberof CompanyInteractionsState
   */
  interactionsData: ICompaniesInteractionsResponse;

  /**
   * Company users data
   *
   * @type {IUser[]}
   * @memberof CompanyInteractionsState
   */
  companyUsers: IUser[];
  /**
   * Error status
   *
   * @type {HttpErrorResponse}
   * @memberof CompanyInteractionsState
   */
  error: HttpErrorResponse;
}

export const allCompaniesInteractionsFeature = createFeature({
  name: ALL_COPMANIES_INTERACTIONS_FEATURE_KEY,
  reducer: createReducer(
    initialState,
    on(ActionAllCompaniesInteractionsGet, (state, action) => {
        return {
          ...state,
          loading: true,
          interactionsData: null,
          error: null
        };
    }),
    on(ActionAllCompaniesInteractionsGetSuccess, (state, action) => {
        return {
          ...state,
          loading: false,
          interactionsData: action.interactionsData,
          error: null
        };
    }),
    on(ActionAllCompaniesInteractionsGetError, (state, action) => {
        return {
          ...state,
          loading: false,
          interactionsData: null,
          error: action.error
        };
    }),
    on(ActionAllCompanyUsersGet, (state, action) => {
        return {
          ...state,
          loading: true,
          companyUsers: null,
          error: null
        };
    }),
    on(ActionAllCompanyUsersGetSuccess, (state, action) => {
        return {
          ...state,
          loading: false,
          companyUsers: action.companyUsers.result,
          error: null
        };
    }),
    on(ActionAllCompanyUsersGetError, (state, action) => {
        return {
          ...state,
          loading: false,
          companyUsers: null,
          error: action.error
        };
    })
  )
});
