import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "@env/environment";

// const API_URL = environment.client.base_url;

@Injectable()
export class ViewProgressService {
  private API_URL = environment.client.base_url;
  constructor(private httpClient: HttpClient) {}

  retrieveDistributorProgress(distributorLei: string, baseUrl: string): Observable<any> {
    return this.httpClient
      .get((baseUrl ? baseUrl : this.API_URL) + `/api/progress/${distributorLei}?isAction=false`, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Save relationship information
   */
  saveRelationshipInfo(fmRelationshipMetadata, distributorLei) {
    return this.httpClient
      .put(this.API_URL + `/api/company/fmrelationship/save/${distributorLei}`, fmRelationshipMetadata, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Add custom field
   */
  addCustomField(customField: any) {
    return this.httpClient
      .post(this.API_URL + `/api/company/customfielddefinition/add`, customField, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Save custom field
   */
  saveCustomField(customField: any) {
    return this.httpClient
      .put(this.API_URL + `/api/company/customfielddefinition/update/${customField.id}`, customField, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Delete custom field
   */
  deleteCustomField(customField: any): Observable<any> {
    return this.httpClient
      .put(this.API_URL + `/api/company/customfielddefinition/update/${customField.id}`, customField, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Download document by ID
   */
  downloadDocument(documentId: string): Observable<any> {
    return this.httpClient.get(this.API_URL + `/api/filestore/document/${documentId}`, {
      responseType: "blob"
    });
  }

  /**
   * Upload document
   */
  uploadDocumnent(document: File): Observable<any> {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    const formData = new FormData();
    formData.append("document", document, document.name);
    return this.httpClient
      .put(this.API_URL + "/api/filestore/document", formData, {
        responseType: "text",
        headers: headers
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }
}
