import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { selectorCyclesQuestionnaire, ActionQRGetCycles } from "@modules/qc-release/reducers";

import { ActionToastrWarning } from "@app/core/toastr/toastr.reducer";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgIf, NgClass } from "@angular/common";

@Component({
    selector: "ddd-export-ddq-dialog",
    templateUrl: "./export-ddq-dialog.component.html",
    styleUrls: ["./export-ddq-dialog.component.scss"],
    standalone: true,
    imports: [NgIf, NgClass, FormsModule, NgSelectModule, MatProgressBarModule, ReactiveFormsModule]
})
export class ExportDdqDialogComponent implements OnInit, OnDestroy {
  public checked = false;
  public includeSummaryReport = false;
  public includeDetailReport = false;
  public excludeComments = false;
  public includeExtraData = false;
  public password = new UntypedFormControl("");
  public repeatPassword = new UntypedFormControl("");
  public showError = false;
  public showSpaceSymbolError = false;
  public user;
  public companyName;
  public loading = false;
  public component;
  public id;
  public cycles: Array<any>;
  public selectedCycleId: string;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public selectedCycleName: string;

  constructor(
    public dialogRef: MatDialogRef<ExportDdqDialogComponent>,
    private readonly store: Store<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.component = data.component;
    this.id = data.id;
    if (this.component === "review") {
      this.store.pipe(select(selectorCyclesQuestionnaire), takeUntil(this.unsubscribe$)).subscribe((cycles: any) => {
        if (cycles) {
          this.cycles = cycles;
          if (cycles.length > 0) {
            this.selectedCycleId = cycles[0].id;
          }
        }
      });
      this.loadQRCycles();
    }
  }

  public changeCheckboxStatus() {
    this.checked = !this.checked;
    this.password.reset();
    this.repeatPassword.reset();
    this.showError = false;
    this.showSpaceSymbolError = false;
  }

  public checkPasswordMatch() {
    this.showError = this.password.value !== this.repeatPassword.value;
    this.showSpaceSymbolError = this.password.value.includes(" ");
  }

  public submit() {
    if (this.checked) {
      if (
        this.password.value !== null &&
        this.password.value !== "" &&
        this.password.value === this.repeatPassword.value
      ) {
        this.closeDialog();
      } else {
        this.store.dispatch(
          new ActionToastrWarning({
            message: "Please provide password."
          })
        );
      }
    } else {
      this.closeDialog();
    }
  }

  public detailReportOptionClick(optionName: string) {
    switch (optionName) {
      case 'includeDetailReport': {
        this.includeDetailReport = !this.includeDetailReport;
        if (this.includeDetailReport) {
          this.excludeComments = false;
          this.includeExtraData = true;
        }
        else {
          this.excludeComments = false;
          this.includeExtraData = false;
        }
        break;
      }
      case 'includeExtraData': {
        this.includeExtraData = !this.includeExtraData;
        if (this.includeExtraData) {
          this.includeDetailReport = true;
        }
        else if (!this.excludeComments) {
          this.includeDetailReport = false;
        }
        break;
      }
      case 'excludeComments': {
        this.excludeComments = !this.excludeComments;
        if (this.excludeComments) {
          this.includeDetailReport = true;
        }
        else if (!this.includeExtraData) {
          this.includeDetailReport = false;
        }
        break;
      }
    }
  }

  public closeDialog() {
    this.dialogRef.close({
      password: this.password,
      component: this.component,
      id: this.id,
      includeSummaryReport: this.includeSummaryReport,
      includeExtraData: this.includeExtraData,
      excludeComments:  this.excludeComments,
      ddqId: this.selectedCycleId,
      cycleName: this.selectedCycleId ? this.cycles.find((cycle) => cycle.id === this.selectedCycleId).name : null
    });
  }

  public loadQRCycles(): void {
    this.store.dispatch(new ActionQRGetCycles());
  }

  ngOnInit() { }

  ngOnDestroy(): void { }
}
