import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ExportDdqDialogService } from "@shared/dialogs/export-ddq-dialog/export-ddq-dialog.service";
import { CompanyAdminService } from "../services";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { distinctUntilChanged, debounceTime, switchMap, catchError, map } from "rxjs/operators";

import {
  SupportDistributorCompanyActionTypes,
  ActionSupportDistributorCompanyPauseReminders,
  ActionSupportDistributorCompanyPauseRemindersError,
  ActionSupportDistributorCompanyPauseRemindersSuccess,
  ActionSupportDistributorCompanyGetUsersSuccess,
  ActionSupportDistributorCompanyGetUsers,
  ActionSupportDistributorCompanyGetUsersError,
  ActionSupportDistributorCompanyInteractionAdd,
  ActionSupportDistributorCompanyTaskAdd,
  ActionSupportDistributorAllUsersGet,
  ActionSupportDistributorAllUsersGetSuccess,
  ActionSupportDistributorAllUsersGetError,
  ActionSupportDistributorTriggerCompanyEvent,
  ActionSupportDistributorTriggerCompanyEventSuccess,
  ActionSupportDistributorTriggerCompanyEventError,
  ActionSupportDistributorInviteDMUser,
  ActionSupportDistributorInviteDMUserSuccess,
  ActionSupportDistributorInviteDMUserError,
  ActionSupportDistributorManageReleseTimelines,
  ActionSupportDistributorManageReleseTimelinesSuccess,
  ActionSupportDistributorManageReleseTimelinesError,
  ActionSupportDistributorManageOwnership,
  ActionSupportDistributorManageOwnershipSuccess,
  ActionSupportDistributorManageOwnershipError,
  ActionSupportDistributorExternalDocumentsSharing,
  ActionSupportDistributorExternalDocumentsSharingSuccess,
  ActionSupportDistributorExternalDocumentsSharingError,
  ActionSupportDistributorCompanyInteractionAddSuccess,
  ActionDistributorAddCompany,
  ActionDistributorAddCompanySuccess,
  ActionDistributorUpdateCompanyError,
  ActionDistributorUpdateCompany,
  ActionDistributorUpdateCompanySuccess,
  ActionGetRelationshipData,
  ActionGetRelationshipDataSuccess,
  ActionGetRelationshipDataError,
  ActionRelationshipAdd,
  ActionRelationshipSave,
  ActionRelationshipDelete,
  ActionRelationshipDeleteSuccess,
  ActionRelationshipsListAdd,
  ActionUpdateRelationshipsList,
  ActionRelationshipGetHistory,
  ActionRelationshipUpdateHistory,
  ActionGetValidationFile,
  ActionUpdateLoadingState,
  ActionCloneDDQ,
  ActionCloneDDQSuccess,
  ActionCloneDDQError,
  ActionGetFMCycles,
  ActionGetFMCyclesSuccess,
  ActionGetFMCyclesError
} from "../reducers/distributor-companies.reducer";
import { ActionToastrSuccess, ActionToastrError } from "@app/core/toastr/toastr.reducer";

@Injectable()
export class SupportDistributorCompanyEffect {
  constructor(
    private actions$: Actions<Action>,
    private companyService: CompanyAdminService,
    private exportService: ExportDdqDialogService
  ) {}

  toggleReminders = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportDistributorCompanyActionTypes.DISTRIBUTOR_PAUSE_REMINDERS),
      distinctUntilChanged(),
      debounceTime(300),
      switchMap((action: ActionSupportDistributorCompanyPauseReminders) => {
        const obs = action.payload.newState
          ? this.companyService.pauseReminders(action.payload.companyId)
          : this.companyService.resumeReminders(action.payload.companyId);
        return obs.pipe(
          map(
            () =>
              new ActionSupportDistributorCompanyPauseRemindersSuccess({
                companyId: action.payload.companyId
              })
          )
        );
      }),
      catchError((error) => of(new ActionSupportDistributorCompanyPauseRemindersError({ error })))
    );
  });

  getCompanyUsersData = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportDistributorCompanyActionTypes.DISTRIBUTOR_COMPANY_USERS_GET),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportDistributorCompanyGetUsers) =>
        this.companyService.getCompanyUsers(action.payload.companyId).pipe(
          map((companyUsers) => {
            return new ActionSupportDistributorCompanyGetUsersSuccess({
              companyUsers: companyUsers
            });
          }),
          catchError((error) => of(new ActionSupportDistributorCompanyGetUsersError({ error })))
        )
      )
    );
  });

  addInteraction = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportDistributorCompanyActionTypes.DISTRIBUTOR_COMPANY_INTERACTION_ADD),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportDistributorCompanyInteractionAdd) =>
        this.companyService.addInteraction(action.payload.interaction, action.payload.companyId).pipe(
          switchMap((result) => {
            return [
              new ActionToastrSuccess({
                message: "Successfully added interaction."
              }),
              new ActionSupportDistributorCompanyInteractionAddSuccess({
                companyId: action.payload.companyId,
                date: action.payload.interaction.date
              })
            ];
          }),
          catchError((error) =>
            of(
              new ActionToastrError({
                error: {
                  message: "Something went wrong, please try again later."
                }
              })
            )
          )
        )
      )
    );
  });

  addTask = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportDistributorCompanyActionTypes.DISTRIBUTOR_COMPANY_TASK_ADD),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportDistributorCompanyTaskAdd) =>
        this.companyService.addTask(action.payload.task).pipe(
          map((result) => {
            return new ActionToastrSuccess({
              message: "Succesfully added task."
            });
          }),
          catchError((error) =>
            of(
              new ActionToastrError({
                error: {
                  message: "Something went wrong, please try again later."
                }
              })
            )
          )
        )
      )
    );
  });

  getAllUsersData = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportDistributorCompanyActionTypes.DISTRIBUTOR_ALL_USERS_GET),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportDistributorAllUsersGet) =>
        this.companyService.getAllUsers().pipe(
          map((allUsers) => {
            return new ActionSupportDistributorAllUsersGetSuccess({ allUsers });
          }),
          catchError((error) => of(new ActionSupportDistributorAllUsersGetError({ error })))
        )
      )
    );
  });

  inviteCompanyIntoNewCycle = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportDistributorCompanyActionTypes.DISTRIBUTOR_TRIGGER_COMPANY_EVENT),
      distinctUntilChanged(),
      debounceTime(300),
      switchMap((action: ActionSupportDistributorTriggerCompanyEvent) =>
        this.companyService.triggerCompanyEvent(action.payload.companyId, action.payload.startNewCycle, action.payload.refreshCompletionDates, action.payload.refreshDDQStructure).pipe(
          switchMap((result) => [
            new ActionSupportDistributorTriggerCompanyEventSuccess(),
            new ActionToastrSuccess({
              message: "Changes were applied successfully."
            })
          ]),
          catchError((error) =>
            of(
              new ActionSupportDistributorTriggerCompanyEventError({
                error
              })
            )
          )
        )
      )
    );
  });

  inviteDMUser = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportDistributorCompanyActionTypes.DISTRIBUTOR_INVITE_DM_USER),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportDistributorInviteDMUser) =>
        this.companyService.inviteDMUser(action.payload.dmUser).pipe(
          switchMap((result) => [
            new ActionSupportDistributorInviteDMUserSuccess(),
            new ActionToastrSuccess({
              message: "Successfully invited Document Manager."
            })
          ]),
          catchError((error) =>
            of(
              new ActionToastrError(JSON.parse(error.error)),
              new ActionSupportDistributorInviteDMUserError({
                error
              })
            )
          )
        )
      )
    );
  });

  manageTimeLines = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportDistributorCompanyActionTypes.DISTRIBUTOR_MANAGE_RELEASE_TIMELINES),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportDistributorManageReleseTimelines) =>
        this.companyService.manageTimelines(action.payload.manageData).pipe(
          switchMap((result) => [
            new ActionSupportDistributorManageReleseTimelinesSuccess(),
            new ActionToastrSuccess({
              message: "Successfully updated."
            })
          ]),
          catchError((error) =>
            of(
              new ActionToastrError({
                error: {
                  message: "Something went wrong, please try again later."
                }
              }),
              new ActionSupportDistributorManageReleseTimelinesError({
                error
              })
            )
          )
        )
      )
    );
  });

  toggleExternalDocumentsSharing = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportDistributorCompanyActionTypes.DISTRIBUTOR_EXTERNAL_DOCUMENTS_SHARING),
      distinctUntilChanged(),
      debounceTime(300),
      switchMap((action: ActionSupportDistributorExternalDocumentsSharing) => {
        const obs = this.companyService.externalDocumentsSharing(action.payload.companyId, action.payload.newState);
        return obs.pipe(
          switchMap((result) => [
            new ActionSupportDistributorExternalDocumentsSharingSuccess({
              companyId: action.payload.companyId
            }),
            new ActionToastrSuccess({
              message: "Successfully updated."
            })
          ])
        );
      }),
      catchError((error) => of(new ActionSupportDistributorExternalDocumentsSharingError({ error })))
    );
  });

  manageOwnership = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportDistributorCompanyActionTypes.DISTRIBUTOR_TAKE_OWNERSHIP),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportDistributorManageOwnership) =>
        this.companyService.adminManageOwnership(action.payload.distributorId, action.payload.internalOwnerId).pipe(
          switchMap((result) => [
            new ActionSupportDistributorManageOwnershipSuccess(),
            new ActionToastrSuccess({
              message: "Company ownership has been successfully reassigned."
            })
          ]),
          catchError((error) =>
            of(
              new ActionToastrError(JSON.parse(error.error)),
              new ActionSupportDistributorManageOwnershipError({
                error
              })
            )
          )
        )
      )
    );
  });

  getRelationshipData = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportDistributorCompanyActionTypes.GET_RELATIONSHIP_DATA),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionGetRelationshipData) =>
        this.companyService.getRelationshipsData(action.payload.companyId, action.payload.distributorId).pipe(
          map((list) => {
            return new ActionGetRelationshipDataSuccess({
              list: list
            });
          }),
          catchError((error) => of(new ActionGetRelationshipDataError({ error })))
        )
      )
    );
  });

  /**
   * When RELATIONSHIP_ADD action is dispatched, call the service to add new relationship.
   * @memberof SupportDistributorCompanyEffect
   */
  addRelationship = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportDistributorCompanyActionTypes.RELATIONSHIP_ADD),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionRelationshipAdd) =>
        this.companyService.addRelationship(action.payload.relationship).pipe(
          switchMap((relationshipData) => [
            new ActionToastrSuccess({
              message: "Successfully added relationship."
            }),
            new ActionRelationshipsListAdd({
              relationshipData: action.payload.relationshipData
            })
          ]),
          catchError((error) =>
            of(
              new ActionToastrError({
                error: {
                  message: "Something went wrong, please try again later."
                }
              })
            )
          )
        )
      )
    );
  });

  /**
   * When DISTRIBUTOR_ADD_COMPANY action is dispatched, call the service to add new relationship.
   * @memberof SupportDistributorCompanyEffect
   */
  addDistributorCompany = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportDistributorCompanyActionTypes.DISTRIBUTOR_ADD_COMPANY),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionDistributorAddCompany) =>
        this.companyService.addDistributorCompany(action.payload.distributorCompanyData).pipe(
          switchMap((relationshipData) => [
            new ActionToastrSuccess({
              message: "Successfully added distributor company."
            }),
            new ActionDistributorAddCompanySuccess()
          ]),
          catchError((error) =>
            of(
              new ActionToastrError({
                error: {
                  message: "Something went wrong, please try again later."
                }
              })
            )
          )
        )
      )
    );
  });

  /**
   * When DISTRIBUTOR_UPDATE_COMPANY action is dispatched, call the service to update distributor company
   * @memberof SupportDistributorCompanyEffect
   */
  updateDistributorCompany = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportDistributorCompanyActionTypes.DISTRIBUTOR_UPDATE_COMPANY),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionDistributorUpdateCompany) =>
        this.companyService.updateDistributorCompany(action.payload.distributorCompanyData).pipe(
          switchMap((relationshipData) => [
            new ActionToastrSuccess({
              message: "Successfully update distributor company."
            }),
            new ActionDistributorUpdateCompanySuccess()
          ]),
          catchError((error) =>
            of(
              new ActionToastrError({
                error: {
                  message: "Something went wrong, please try again later."
                }
              }),
              new ActionDistributorUpdateCompanyError({ error })
            )
          )
        )
      )
    );
  });

  /**
   * When RELATIONSHIP_SAVE action is dispatched, call the service to save relationship.
   * @memberof SupportDistributorCompanyEffect
   */
  saveRelationship = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportDistributorCompanyActionTypes.RELATIONSHIP_SAVE),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionRelationshipSave) =>
        this.companyService.saveRelationship(action.payload.relationship).pipe(
          switchMap((relationshipData) => [
            new ActionToastrSuccess({
              message: "Successfully updated relationship."
            }),
            new ActionUpdateRelationshipsList({
              relationshipData: action.payload.relationshipData
            })
          ]),
          catchError((error) =>
            of(
              new ActionToastrError({
                error: {
                  message: "Something went wrong, please try again later."
                }
              })
            )
          )
        )
      )
    );
  });

  /**
   * When RELATIONSHIP_DELETE action is dispatched, call the service to delete relationship.
   * @memberof SupportDistributorCompanyEffect
   */
  deleteInteraction = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(SupportDistributorCompanyActionTypes.RELATIONSHIP_DELETE),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionRelationshipDelete) =>
        this.companyService.deleteRelationship(action.payload.distributorId, action.payload.fmCompanyId).pipe(
          switchMap((result) => {
            return [
              new ActionRelationshipDeleteSuccess({
                index: action.payload.index
              }),
              new ActionToastrSuccess({
                message: "Successfully deleted relationship."
              })
            ];
          }),
          catchError((error) =>
            of(
              new ActionToastrError({
                error: {
                  message: "Something went wrong, please try again later."
                }
              })
            )
          )
        )
      )
    );
  });

  /**
   * When RELATIONSHIPS_GET_HISTORY action is dispatched, call the service to get relationships actions history.
   * @memberof SupportDistributorCompanyEffect
   */
  getQRHistory = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportDistributorCompanyActionTypes.RELATIONSHIPS_GET_HISTORY),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionRelationshipGetHistory) =>
        this.companyService.getRelationshipsActionsHistory(action.payload.distributorId).pipe(
          switchMap((actions) => [
            new ActionRelationshipUpdateHistory({
              actions: actions
            })
          ]),
          catchError((error) =>
            of(
              new ActionToastrError({
                error: {
                  message: "Something went wrong try later."
                }
              })
            )
          )
        )
      )
    );
  });

  exportValidationFile = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(SupportDistributorCompanyActionTypes.DISTRIBUTOR_GET_VALIDATION_FILE),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionGetValidationFile) => {
        return this.companyService.exportValidationFile(action.payload.companyId).pipe(
          map((document) => {
            this.exportService.downloadBlobFile(document, { type: "application/vnd.ms-excel" }, "validation_file.xlsx");
            return new ActionUpdateLoadingState({ loadingStatus: false });
          }),
          catchError((error) =>
            of(
              new ActionUpdateLoadingState({ loadingStatus: false }),
              new ActionToastrError({
                error: {
                  message: "Something went wrong try later."
                }
              })
            )
          )
        );
      })
    );
  });

  cloneDDQ = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportDistributorCompanyActionTypes.CLONE_DDQ),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionCloneDDQ) =>
        this.companyService.cloneDDQ(action.payload.readyCompanyId, action.payload.pendingCompanyId).pipe(
          switchMap((result) => {
            return [
              new ActionToastrSuccess({
                message: "Successfully cloned DDQ."
              }),
              new ActionCloneDDQSuccess()
            ];
          }),
          catchError((error) =>
            of(
              new ActionToastrError({
                error: {
                  message: error.message
                }
              }),
              new ActionCloneDDQError({
                error
              })
            )
          )
        )
      )
    );
  });

  getFMCycles = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportDistributorCompanyActionTypes.GET_FM_CYCLES),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionGetFMCycles) =>
        this.companyService.getFMCycles( action.payload.distributorId, action.payload.targetFmId,action.payload.auditorFmId).pipe(
          map((list) => {
            return new ActionGetFMCyclesSuccess({
              list: list
            });
          }),
          catchError((error) => of(new ActionGetFMCyclesError({ error })))
        )
      )
    );
  });
}
