import { Injectable, ViewContainerRef } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";

import { ImportColleaguesDialogComponent } from "./import-colleagues-dialog.component";

@Injectable()
export class ImportColleaguesDialogService {
  constructor(private dialog: MatDialog) {}

  public open(viewContainerRef: ViewContainerRef): Observable<string> {
    let dialogRef: MatDialogRef<ImportColleaguesDialogComponent>;
    const config = new MatDialogConfig();
    config.panelClass = ["cg-dialog", "middle"];
    config.backdropClass = "cg-dialog-backdrop";
    config.disableClose = true;
    config.viewContainerRef = viewContainerRef;

    dialogRef = this.dialog.open(ImportColleaguesDialogComponent, config);
    return dialogRef.afterClosed();
  }
}
