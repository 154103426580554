<div *ngIf="!!options">
  <header class="ddd-dialog-header">
    <title class="dialog-title">{{ options.title }}</title>
    <div class="ddd-icon close-primary" (click)="close()"></div>
  </header>
  <div class="ddd-dialog-main">
    <div class="section-text" *ngIf="options.type === 'TEXT'" [innerHtml]="options.content"></div>
    <div class="section-list" *ngIf="options.type === 'LIST'">
      <ul>
        <li *ngFor="let element of options.content" [innerHtml]="element"></li>
      </ul>
    </div>
    <div class="section-grid" *ngIf="options.type === 'GRID'">
      <table>
        <tbody>
          <tr class="section-grid__row" *ngFor="let row of grid">
            <td class="section-grid__col" *ngFor="let col of row" [style.flex]="col.flex">
              <div>
                <span [innerHtml]="col.content"></span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <footer class="ddd-dialog-footer" *ngIf="!!options.buttonText">
    <button class="ddd-button primary" tabindex="-1" autofocus="false" (click)="close()">
      {{ options.buttonText }}
    </button>
  </footer>
</div>
