<div class="content">
  <div class="title">Select file for import</div>
  <br />
  <form class="custom-form cg-form" [formGroup]="form">
    <div class="label">Description</div>
    <div class="control-group">
      <div class="control-box">
        <input
          class="input-form"
          type="text"
          id="description"
          formControlName="description"
          placeholder="description"
          maxlength="255"
        />
        <ddd-field-error-show [displayError]="isFieldValid('description')" errorMsg="Please enter a description">
        </ddd-field-error-show>
      </div>
    </div>
    <div>
      <input
        hidden
        type="file"
        #importFileInput
        accept="image/, text/, .pdf, .xls, .xlsx, .doc, .docx, .ppt, .pptx, .jpg, .jpeg, .txt"
        (change)="handleInputFile($event)"
      />
      <div class="dropzone" (click)="importFileInput.click()" dddFileDrop (filesDropped)="handleDrop($event)">
        <i class="tabler-icon tabler-icon--cloud-upload tabler-icon--grey tabler-icon--big"></i>
        <div class="upload-box-browse">Drag and Drop file in this area or click and select your file for import</div>
        <div *ngIf="fileListOutput">
          You have choosen file for import:
          <strong>{{ fileListOutput.name }}</strong>
        </div>
      </div>
    </div>
  </form>
  <br />
  <div class="actions">
    <button class="ddd-button" (click)="dialogRef.close()">Cancel</button>
    <button class="ddd-button raised" (click)="onSubmit()">Import</button>
  </div>
</div>
