import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

import { Dialog } from "@shared/models/questionnaire.interfaces";
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ActionToastrWarning } from "@app/core/toastr/toastr.reducer";
import { Store } from "@ngrx/store";
import { AppCapitalizeDirective } from "../../directives/app-capitalize.directive";
import { NgIf, NgFor } from "@angular/common";

@Component({
    selector: "ddd-reject-confirm-dialog",
    templateUrl: "./reject-confirm-dialog.component.html",
    styleUrls: ["./reject-confirm-dialog.component.scss"],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, AppCapitalizeDirective, NgFor]
})
export class RejectAndConfirmDialogComponent implements OnInit {
  public dialog: any;
  public isRejectDialog = false;
  public canDeactivate = false;
  public confirmColleagues = false;
  public companySetupInfoPopup = false;
  public submitCompanySetupInfo = false;
  public confirmAmendAnswers = false;
  public templateNameInput = new UntypedFormControl("");

  public canDeactivateDialog: Dialog;
  public confirmColleaguesDialog: Dialog;
  public companySetupInfoPopupDialog: Dialog;
  public submitCompanySetupInfoDialog: Dialog;
  public confirmAmendAnswersDialog: Dialog;
  public rejectCompanyContent: object;

  public rejectCompanyForm: UntypedFormGroup;

  constructor(
    public store: Store<any>,
    public dialogRef: MatDialogRef<RejectAndConfirmDialogComponent>,
    private readonly translate: TranslateService,
    public formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // TODO: implement better approach here
    if (data.type && data.type === "reject") {
      this.isRejectDialog = true;
      this.canDeactivate = false;
      this.confirmColleagues = false;
      this.companySetupInfoPopup = false;
      this.submitCompanySetupInfo = false;
      this.confirmAmendAnswers = false;
    } else if (data.type === "confirm") {
      this.isRejectDialog = false;
      this.canDeactivate = false;
      this.confirmColleagues = false;
      this.companySetupInfoPopup = false;
      this.submitCompanySetupInfo = false;
      this.confirmAmendAnswers = false;
    } else if (data.type === "confirmLeave") {
      this.isRejectDialog = false;
      this.canDeactivate = true;
      this.confirmColleagues = false;
      this.companySetupInfoPopup = false;
    } else if (data.type === "confirmSentInvites") {
      this.isRejectDialog = false;
      this.canDeactivate = false;
      this.confirmColleagues = true;
      this.companySetupInfoPopup = false;
      this.submitCompanySetupInfo = false;
      this.confirmAmendAnswers = false;
    } else if (data.type === "companySetupInfo") {
      this.companySetupInfoPopup = true;
      this.isRejectDialog = false;
      this.canDeactivate = false;
      this.confirmColleagues = false;
      this.submitCompanySetupInfo = false;
      this.confirmAmendAnswers = false;
    } else if (data.type === "submitCompanySetupInfo") {
      this.submitCompanySetupInfo = true;
      this.companySetupInfoPopup = false;
      this.isRejectDialog = false;
      this.canDeactivate = false;
      this.confirmColleagues = false;
      this.confirmAmendAnswers = false;
    } else if (data.type === "confirmAmendAnswers") {
      this.submitCompanySetupInfo = false;
      this.companySetupInfoPopup = false;
      this.isRejectDialog = false;
      this.canDeactivate = false;
      this.confirmColleagues = false;
      this.confirmAmendAnswers = true;
    }
  }

  ngOnInit() {
    if (this.data.templateName) {
      this.templateNameInput.setValue(this.data.templateName);
    }
    if (this.data.type === "reject") {
      if (this.data.companyReject) {
        this.rejectCompanyForm = this.formBuilder.group({
          message: ["", Validators.required],
          firstName: "",
          lastName: "",
          email: [
            "",
            [
              Validators.pattern(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              )
            ]
          ]
        });

        this.translate.get("DASHBOARD_REJECT_COMPANY").subscribe((res: object) => {
          this.rejectCompanyContent = res;
        });
      }
    } else if (this.data.type === "confirmLeave") {
      const translateString = this.data.isDialog ? "CONFIRM_LEAVE_FROM_DIALOG" : "CONFIRM_LEAVE_PAGE_DIALOG";
      this.translate.get(translateString).subscribe((res: object) => {
        this.canDeactivateDialog = {
          title: res["TITLE"],
          message: res["MESSAGE"],
          cancel: res["CANCEL_BUTTON"],
          okBtn: res["OK_BUTTON"]
        };
      });
    } else if (this.data.type === "confirmSentInvites") {
      this.translate.get("CONFIRM_INVITED_USERS_DIALOG").subscribe((res: object) => {
        this.confirmColleaguesDialog = {
          title: res["TITLE"],
          message: null,
          cancel: null,
          okBtn: res["OK_BUTTON"]
        };
      });
    } else if (this.data.type === "companySetupInfo") {
      this.translate.get("COMPANY_SETUP_INFO").subscribe((res: object) => {
        this.companySetupInfoPopupDialog = {
          title: res["TITLE"],
          message: res["MESSAGE"],
          cancel: null,
          okBtn: res["OK_BUTTON"]
        };
      });
    } else if (this.data.type === "submitCompanySetupInfo") {
      this.translate.get("COMPANY_SETUP_CONFIRMATION").subscribe((res: object) => {
        this.submitCompanySetupInfoDialog = {
          title: res["TITLE"],
          message: res["MESSAGE"],
          cancel: res["CANCEL_BUTTON"],
          okBtn: res["OK_BUTTON"]
        };
      });
    } else if (this.data.type === "confirmAmendAnswers") {
      this.translate.get("PRELIMINARY_QUESTIONS_VALIDATION_ERROR_DIALOG").subscribe((res: object) => {
        this.confirmAmendAnswersDialog = {
          title: res["TITLE"],
          message: res["MESSAGE"],
          okBtn: res["OK_BUTTON"],
          cancel: res["CANCEL_BUTTON"]
        };
      });
    }
  }

  public checkMessageOnClose(message: string) {
    if (message) {
      this.dialogRef.close(message);
    } else {
      this.store.dispatch(
        new ActionToastrWarning({
          message: "You have to provide a message."
        })
      );
    }
  }
}
