import { Directive, EventEmitter, HostListener, HostBinding, Output } from "@angular/core";
import * as _ from "lodash";

@Directive({
    selector: "[dddFileDrop]",
    standalone: true
})
export class FileDropDirective {
  @Output() filesDropped = new EventEmitter<FileList>();

  constructor() {}
  @HostBinding("class.active") activeClass = false;

  @HostListener("drop", ["$event"])
  onDrop($event) {
    $event.preventDefault();
    const transfer = $event.dataTransfer;
    this.filesDropped.emit(transfer.files);
    this.activeClass = false;
  }

  @HostListener("dragover", ["$event"])
  onDragOver($event) {
    $event.preventDefault();
    this.activeClass = true;
  }

  @HostListener("dragleave", ["$event"])
  onDragLeave($event) {
    this.activeClass = false;
  }
}
