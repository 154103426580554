import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import * as StackTrace from "stacktrace-js";

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  public ES_IS_AVAILABLE: boolean;

  constructor(private injector: Injector) {
    // const loggingService = this.injector.get(EsService);
    // loggingService.isAvailable().then(
    //   () => {
    //     this.ES_IS_AVAILABLE = true;
    //   },
    //   elasticError => {
    //     console.error('Server is down', elasticError);
    //   }
    // );
  }

  handleError(error) {
    // const loggingService = this.injector.get(EsService);
    const location = this.injector.get<LocationStrategy>(LocationStrategy as any);
    const url = location instanceof PathLocationStrategy ? location.path() : "";
    const message = error.message ? error.message : error.toString();

    if (this.ES_IS_AVAILABLE) {
      StackTrace.fromError(error).then((stackframes) => {
        const stackString = stackframes
          .splice(0, 20)
          .map(function (sf) {
            return sf.toString();
          })
          .join("\n");

        // loggingService.logs({ message, url, stack: stackString });
      });
    }

    /* hide error from console (when commented) */
    throw error;
  }
}
