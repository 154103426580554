import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, EMPTY } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "@env/environment";
import { Colleague } from "@modules/qc-complete/components/qc-complete/reducers";

/**
 * A colleague service
 */
@Injectable()
export class ColleaguesService {
  private API_URL = environment.client.base_url;
  private response = new BehaviorSubject(EMPTY);
  public keyContactsResponse = this.response.asObservable();

  constructor(private httpClient: HttpClient) {}

  /**
   * Retrieve list of colleagues
   */
  retrieveListOfColleagues(base_url): Observable<Colleague[]> {
    return this.httpClient
      .get(base_url + "/api/collegue", {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Add the colleague
   */
  addColleague(colleague: Colleague): Observable<any> {
    return this.httpClient
      .post(this.API_URL + "/api/collegue", colleague, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Update the colleague
   */
  updateColleague(colleague: Colleague): Observable<any> {
    return this.httpClient
      .put(this.API_URL + `/api/collegue/`, colleague, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Delete the colleague
   */
  deleteColleague(id: string): Observable<any> {
    return this.httpClient
      .delete(this.API_URL + `/api/collegue/${id}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Check the colleague
   */
  checkColleague(base_url, email: string): Observable<any> {
    return this.httpClient
      .get(base_url + `/api/collegue/check/${email}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Retrieve import list of colleagues
   */
  retrieveImportList(): Observable<Colleague[]> {
    return this.httpClient
      .get(this.API_URL + "/api/collegue/import", {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Send import list of colleagues
   */
  sendImportList(importList: Array<string>): Observable<any> {
    return this.httpClient
      .put(this.API_URL + `/api/collegue/addcolleagues`, importList, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Update invited user
   */
  updateInvitedUser(colleague: Colleague): Observable<any> {
    return this.httpClient
      .put(this.API_URL + `/api/user/edit/`, colleague, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }
}
