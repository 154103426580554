import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AuthGuard } from "@app/core/auth/identity/idsvr-auth-guard.service";
import { CallbackComponent } from "@app/core/auth/identity/idsvr-callback.component";
import { RoleGuard } from "@app/core/auth/guards/role-guard";
import { LogoutComponent } from "@app/modules/logout/logout.component";

const routes: Routes = [
  { path: "", component: CallbackComponent, pathMatch: "full" },
  { path: "logout", component: LogoutComponent, pathMatch: "full" },
  {
    path: "setup",
    loadChildren: () => import("app/modules/setup/setup.module").then((m) => m.SetupModule),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: "fm-distributors",
    loadChildren: () =>
      import("app/modules/fm-distributors/fm-distributors.module").then((m) => m.FMDistributorsModule),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: "profile",
    loadChildren: () => import("app/modules/profile/profile.module").then((m) => m.ProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: "qc",
    loadChildren: () => import("app/modules/questionnaire-completer/qc.module").then((m) => m.QcModule),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: "support",
    loadChildren: () => import("app/modules/support/support.module").then((m) => m.SupportModule),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: "managetemplate",
    loadChildren: () =>
      import("app/modules/template-manager/template-manager.module").then((m) => m.TemplateManagerModule),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: "reviewgroups",
    loadChildren: () => import("app/modules/review-groups/review-groups.module").then((m) => m.ReviewGroupsModule),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: "fundmanagers",
    loadChildren: () => import("app/modules/fund-managers/fund-managers.module").then((m) => m.FundManagersModule),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: "help",
    loadChildren: () => import("app/modules/help/help.module").then((m) => m.HelpModule),
    canActivate: [AuthGuard]
  },
  {
    path: "dashboard",
    loadChildren: () => import("app/modules/dashboard/dashboard.module").then((m) => m.DashboardModule),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: "admin",
    loadChildren: () => import("app/modules/admin/admin.module").then((m) => m.AdminModule),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: "fmadmin",
    loadChildren: () => import("app/modules/fm-admin/fm-admin.module").then((m) => m.FMAdminModule),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: "distributors/view-progress/:distributor",
    loadChildren: () => import("app/modules/view-progress/view-progress.module").then((m) => m.ViewProgressModule),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: "support/companies/view-progress/:distributor",
    loadChildren: () => import("app/modules/view-progress/view-progress.module").then((m) => m.ViewProgressModule),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: "support/companies/interactions/:companyId/:companyName",
    loadChildren: () =>
      import("app/modules/company-interactions/company-interactions.module").then((m) => m.CompanyInteractionsModule),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: "allinteractions",
    loadChildren: () =>
      import("app/modules/all-companies-interactions").then(
        (m) => m.ANALYTICS_ROUTES
      ),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: "tasks",
    loadChildren: () => import("app/modules/tasks/tasks.module").then((m) => m.TasksModule),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: "tasks/task-details/:taskId",
    loadChildren: () => import("app/modules/task-details/task-details.module").then((m) => m.TaskDetailsModule),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: "reporting",
    loadChildren: () => import("app/modules/reporting/reporting.module").then((m) => m.ReportingModule),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: "distributors/review/:distributor",
    loadChildren: () => import("app/modules/fm-review/fm-review.module").then((m) => m.FMReviewModule),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: "fmguest",
    loadChildren: () =>
      import("app/modules/fm-guest-landing/fm-guest-landing.module").then((m) => m.FMGuestLandingModule),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: "fmtasks",
    loadChildren: () => import("app/modules/fm-tasks/fm-tasks.module").then((m) => m.FMTasksModule),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: "fmtasks/task-details/:taskId",
    loadChildren: () => import("app/modules/task-details/task-details.module").then((m) => m.TaskDetailsModule),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: "analytics",
    loadChildren: () => import("app/modules/analytics/analytics.module").then((m) => m.AnalyticsModule),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: "documents",
    loadChildren: () =>
      import("app/modules/document-management/document-management.module").then((m) => m.DocumentManagementModule),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: "session-expired",
    loadChildren: () => import("app/modules/session-expired/session-expired.module").then((m) => m.SessionExpiredModule)
  },
  {
    path: "guidance",
    loadChildren: () => import("app/modules/guidance/guidance.module").then((m) => m.GuidanceModule),
    canActivate: [AuthGuard]
  },
  {
    path: "loading-page",
    loadChildren: () => import("app/modules/loading-page/loading-page.module").then((m) => m.LoadingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: "fmanalytics",
    loadChildren: () => import("app/modules/fm-analytics/fm-analytics.module").then((m) => m.AnalyticsFMModule),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: "session-end",
    loadChildren: () => import("app/modules/session-end/session-end.module").then((m) => m.SessionEndModule)
  },
  { path: "**", redirectTo: "" }
];

@NgModule({ imports: [RouterModule.forRoot(routes)], exports: [RouterModule] })
export class AppRoutingModule {}
