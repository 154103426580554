import { Component, OnInit, Inject, OnDestroy, ViewContainerRef } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import * as _ from "lodash";

import { ConfigurableBaseComponent } from "@shared/components";
import {
  ActionRelationshipCustomFieldAdd,
  ActionRelationshipCustomFieldSave,
  ActionRelationshipCustomFieldDelete
} from "@app/modules/view-progress/component/view-progress/reducers";
import { RejectAndConfirmDialogService } from "@shared/dialogs/reject-confirm-dialog/reject-confirm-dialog.service";
import { ActionToastrWarning } from "@app/core/toastr/toastr.reducer";
import { NgIf, NgFor } from "@angular/common";
import { NgSelectModule } from "@ng-select/ng-select";
import { FieldErrorShowComponent } from "../../components/field-error-show/field-error-show.component";

@Component({
    selector: "ddd-save-custom-field",
    templateUrl: "./save-custom-field-dialog.component.html",
    styleUrls: ["./save-custom-field-dialog.component.scss"],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, FieldErrorShowComponent, NgSelectModule, NgIf, NgFor, TranslateModule]
})
export class SaveCustomFieldDialogComponent extends ConfigurableBaseComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;
  public customField: any;
  public customFieldsTypes = [
    {
      type: "String",
      label: "Text"
    },
    {
      type: "Number",
      label: "Numeric"
    },
    {
      type: "List",
      label: "List"
    }
  ];
  public selectedCustomFieldType;
  public customFieldOptions = [];
  public newOption;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    public readonly translate: TranslateService,
    public readonly store: Store<any>,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<SaveCustomFieldDialogComponent>,
    private confirmDialogService: RejectAndConfirmDialogService,
    private viewContainerRef: ViewContainerRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(translate, store);
  }

  ngOnInit() {
    this.customField = this.data.customField;
    if (this.customField) {
      this.selectedCustomFieldType = this.customField.allowedValues.length === 0 ? this.customField.type : "List";
      this.customFieldOptions = this.customField.allowedValues;
      this.form = this.formBuilder.group({
        type: [
          {
            value: this.selectedCustomFieldType,
            disabled: true
          }
        ],
        label: [this.customField.label, Validators.required]
      });
    } else {
      this.form = this.formBuilder.group({
        type: [null, Validators.required],
        label: [null, Validators.required]
      });
    }
  }

  public onTypeChange(value) {
    if (value) {
      this.selectedCustomFieldType = value.type;
    } else {
      this.selectedCustomFieldType = null;
    }
  }

  public isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched && this.form.get(field).dirty;
  }

  /**
   * Submit save custom field form, will submit if form valid, else will start validating form fields function
   */
  public onSubmit() {
    if (this.selectedCustomFieldType === "List" && this.customFieldOptions.length === 0) {
      this.translate.get("NO_OPTIONS").subscribe((res) => {
        this.store.dispatch(
          new ActionToastrWarning({
            message: res
          })
        );
      });
    } else {
      if (this.form.valid) {
        this.sentForm();
      } else if (!this.form.valid) {
        this.validateAllFormFields(this.form);
      }
    }
  }

  /**
   * Sent custom field form
   */
  public sentForm() {
    const formData = this.getFormData();
    if (this.customField) {
      this.store.dispatch(
        new ActionRelationshipCustomFieldSave({
          customField: formData
        })
      );
    } else {
      this.store.dispatch(
        new ActionRelationshipCustomFieldAdd({
          customField: formData
        })
      );
    }
    this.dialogRef.close(true);
  }

  /**
   * Get from data
   */
  public getFormData() {
    if (this.selectedCustomFieldType === "List") {
      return {
        id: this.customField ? this.customField.id : null,
        label: this.form.getRawValue().label,
        type: "String",
        allowedValues: this.customFieldOptions,
        allowMultiple: false,
        status: this.customField ? this.customField.status : null
      };
    } else {
      return {
        id: this.customField ? this.customField.id : null,
        label: this.form.getRawValue().label,
        type: this.form.getRawValue().type,
        allowedValues: [],
        allowMultiple: false,
        status: this.customField ? this.customField.status : null
      };
    }
  }

  /**
   * Validate form fields function, mark fields as touched and dirty
   */
  public validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * Plus option
   */
  public plusOption() {
    if (this.newOption && this.newOption !== "") {
      this.customFieldOptions.push(this.newOption);
    }
    this.newOption = null;
  }

  /**
   * Remove option
   */
  public removeOption(index) {
    _.pullAt(this.customFieldOptions, index);
  }

  /**
   * Close dialog
   */
  public closeDialog() {
    this.dialogRef.close(true);
  }

  /**
   * Delete custom field
   */
  public deleteCustomField() {
    if (this.customField) {
      const confirmDialog = {
        title: "Delete custom field",
        message: "Are you sure that you want to delete custom field?",
        cancel: "Cancel",
        okBtn: "Ok"
      };
      this.confirmDialogService.confirm(confirmDialog, this.viewContainerRef).subscribe((res) => {
        if (res) {
          this.store.dispatch(
            new ActionRelationshipCustomFieldDelete({
              customField: {
                ...this.customField,
                status: "Deleted"
              }
            })
          );
          this.dialogRef.close(true);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
