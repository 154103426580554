import { HttpErrorResponse } from "@angular/common/http";
import { Action, createFeatureSelector, createSelector } from "@ngrx/store";

export const COUNTRIES_KEY = "COUNTRIES.LIST";

/**
 * Choice question actions types
 */
export enum CountryQuestionActionTypes {
  COUNTRY_QUESTION_GET = "[CountryQuestion] Get",
  COUNTRY_QUESTION_GET_SUCCESS = "[CountryQuestion] Get Success",
  COUNTRY_QUESTION_GET_ERROR = "[CountryQuestion] Get Error"
}

export class CountryQuestionGet implements Action {
  readonly type = CountryQuestionActionTypes.COUNTRY_QUESTION_GET;
  constructor(public payload: { base_url: string }) {}
}

export class CountryQuestionGetSuccess implements Action {
  readonly type = CountryQuestionActionTypes.COUNTRY_QUESTION_GET_SUCCESS;
  constructor(public payload: { countries: Country[] }) {}
}

export class CountryQuestionGetError implements Action {
  readonly type = CountryQuestionActionTypes.COUNTRY_QUESTION_GET_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

/**
 * Country question actions types
 */
export type CountryQuestionActions = CountryQuestionGet | CountryQuestionGetSuccess | CountryQuestionGetError;

/**
 * Country question initial state
 */
export const initialState: ICountryQuestionsState = {
  loading: false
};

const featureCountrySelector = createFeatureSelector<any>("countries");
export const selectorCountriesListState = createSelector(
  featureCountrySelector,
  (state) => state && state.countriesList
);
/**
 * Country question state selector
 */
export const selectorCountries = createSelector(selectorCountriesListState, (state) => state && state.countries);

/**
 * Reducer function for country question state.
 */
export function countryQuestionReducer(
  state: ICountryQuestionsState = initialState,
  action: CountryQuestionActions
): ICountryQuestionsState {
  switch (action.type) {
    case CountryQuestionActionTypes.COUNTRY_QUESTION_GET:
      return {
        ...state,
        loading: true,
        countries: null,
        error: null
      };
    case CountryQuestionActionTypes.COUNTRY_QUESTION_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: action.payload.countries,
        error: null
      };
    case CountryQuestionActionTypes.COUNTRY_QUESTION_GET_ERROR:
      return {
        ...state,
        loading: false,
        countries: null,
        error: action.payload.error
      };
    default:
      return state;
  }
}

/**
 * Interface for country question state.
 */
export interface ICountryQuestionsState {
  loading: boolean;
  countries?: Country[];
  error?: HttpErrorResponse;
}

/**
 * Interface for country.
 */
export interface Country {
  key: string;
  name: string;
  alpha2Code: string;
  alpha3Code: string;
  region: string;
  subRegion: string;
  phonePrefix?: string;
  id?: string;
  intermediateRegion?: string;
  regionCode?: string;
  subRegionCode?: string;
  intermediateRegionCode?: string;
  iso?: string;
  isO31662?: string;
}
