<div class="content">
  <div class="title">Information</div>
  <div *ngIf="roles.keyContact && roles.approver && roles.fmTemplateOwner" class="message">
    You now have access to the Template Manager and Review Groups screens. The Template Manager screen is where you are
    able to set up your DDQ templates that will be reviewed.
    <br /><br />
    The Review Groups screen will allow you to set up the groups that will review the distributor responses.
    Functionality will only be unlocked once the Template Manager set up is complete.
    <br /><br />
    Please now go to the Template Manager screen to complete this part of the process.
  </div>
  <div *ngIf="roles.keyContact && !roles.approver && roles.fmTemplateOwner" class="message">
    You now have access to the Template Manager screen. The Template Manager screen is where you are able to set up your
    DDQ templates that will be reviewed. Your Review Group Owners will only be invited to the system once the Template
    Manager set up is complete. Please now go to the Template Manager screen to complete this part of the process.
  </div>
  <div *ngIf="roles.keyContact && roles.approver && !roles.fmTemplateOwner" class="message">
    You now have access to the Review Groups screen. The Review Groups screen will allow you to set up the groups that
    will review the distributor responses. Functionality will only be unlocked once the Template Manager set up is
    complete. You will receive an email when this activity is available to be completed.
  </div>
  <div *ngIf="!roles.keyContact && roles.approver && !roles.fmTemplateOwner" class="message">
    You now have access to the Review Groups screen. The Review Groups screen will allow you to set up the groups that
    will review the distributor responses. Functionality will only be unlocked once the Template Manager set up is
    complete. You will receive an email when this activity is available to be completed.
  </div>
  <div class="actions">
    <button class="ddd-button raised increased-indents" (click)="dialogRef.close('FMKeyContactBridgingScreen')">Ok</button>
  </div>
</div>
