<div
  class="content"
  *ngIf="
    isRejectDialog &&
    !canDeactivateDialog &&
    !confirmColleagues &&
    !companySetupInfoPopup &&
    !submitCompanySetupInfo &&
    !confirmAmendAnswers
  "
>
  <div class="title">{{ dialog.title }}</div>
  <div *ngIf="dialog.message" class="message" [innerHTML]="dialog.message"></div>
  <div *ngIf="!data.companyReject" class="custom-form">
    <div class="control-box">
      <textarea class="input-form textarea" [(ngModel)]="data.message" placeholder="message"></textarea>
    </div>
  </div>
  <div *ngIf="data.companyReject">
    <div class="setup-form custom-form" [formGroup]="rejectCompanyForm">
      <div class="control-group">
        <div class="label">{{ rejectCompanyContent["REASON"] }}</div>
        <div class="control-box">
          <textarea class="input-form textarea" formControlName="message"></textarea>
        </div>
      </div>
      <div
        class="error"
        *ngIf="
          !rejectCompanyForm?.get('message')?.valid &&
          rejectCompanyForm?.get('message')?.value === '' &&
          (rejectCompanyForm?.get('message')?.touched || rejectCompanyForm?.get('message')?.dirty)
        "
      >
        {{ rejectCompanyContent["REASON_ERROR"] }}
      </div>
      <div class="sub-title">{{ rejectCompanyContent["OPTIONAL_TEXT"] }}</div>
      <div class="control-group">
        <div class="label">{{ rejectCompanyContent["FIRST_NAME"] }}</div>
        <div class="control-box">
          <input class="input-form" type="text" formControlName="firstName" dddAppCapitalize />
        </div>
      </div>
      <div class="control-group">
        <div class="label">{{ rejectCompanyContent["LAST_NAME"] }}</div>
        <div class="control-box">
          <input class="input-form" type="text" formControlName="lastName" dddAppCapitalize />
        </div>
      </div>
      <div class="control-group">
        <div class="label">{{ rejectCompanyContent["EMAIL"] }}</div>
        <div class="control-box">
          <input class="input-form" type="text" formControlName="email" />
        </div>
      </div>
      <div
        class="error"
        *ngIf="
          !rejectCompanyForm?.get('email')?.valid &&
          rejectCompanyForm?.get('email')?.value !== '' &&
          (rejectCompanyForm?.get('email')?.touched || rejectCompanyForm?.get('email')?.dirty)
        "
      >
        {{ rejectCompanyContent["EMAIL_ERROR"] }}
      </div>
    </div>
  </div>
  <div class="actions">
    <button class="ddd-button" (click)="dialogRef.close(false)">
      {{ dialog.cancel }}
    </button>
    <button *ngIf="!data.companyReject" class="ddd-button raised" (click)="checkMessageOnClose(data.message)">
      {{ dialog.okBtn }}
    </button>
    <button
      *ngIf="data.companyReject"
      class="ddd-button raised"
      (click)="dialogRef.close(rejectCompanyForm?.value)"
      [disabled]="!rejectCompanyForm?.valid"
    >
      {{ dialog.okBtn }}
    </button>
  </div>
</div>

<ng-container
  *ngIf="
    !isRejectDialog &&
    !canDeactivateDialog &&
    !confirmColleagues &&
    !companySetupInfoPopup &&
    !submitCompanySetupInfo &&
    !confirmAmendAnswers
  "
>
  <ng-container *ngIf="!data.newDesign">
    <div class="content">
      <div class="title">{{ dialog.title }}</div>
      <div class="message" *ngIf="dialog.message !== 'input'" [innerHTML]="dialog.message"></div>
      <div class="template-name-input" *ngIf="dialog.message === 'input'">
        <input class="ddd-input" [formControl]="templateNameInput" />
      </div>
      <div class="message" *ngIf="dialog.extraMessage">
        {{ dialog.extraMessage }}
      </div>
      <div class="actions">
        <button class="ddd-button" *ngIf="dialog.cancel" (click)="dialogRef.close()">
          {{ dialog.cancel }}
        </button>
        <button class="ddd-button raised" (click)="dialogRef.close(true)" *ngIf="dialog.message !== 'input'">
          {{ dialog.okBtn }}
        </button>
        <button
          class="ddd-button raised"
          (click)="dialogRef.close(templateNameInput.value)"
          *ngIf="dialog.message === 'input'"
        >
          {{ dialog.okBtn }}
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="data.newDesign">
    <div>
      <header class="ddd-dialog-header">
        <div class="dialog-title">{{ dialog.title }}</div>
      </header>
      <div class="ddd-dialog-main">
        <div class="dialog-message" [innerHTML]="dialog.message"></div>
      </div>
      <footer class="ddd-dialog-footer end">
        <button class="ddd-button" (click)="dialogRef.close()">
          {{ dialog.cancel }}
        </button>
        <button class="ddd-button raised" (click)="dialogRef.close(true)">
          {{ dialog.okBtn }}
        </button>
      </footer>
    </div>
  </ng-container>
</ng-container>

<div
  class="content"
  *ngIf="
    !isRejectDialog &&
    canDeactivateDialog &&
    !confirmColleagues &&
    !companySetupInfoPopup &&
    !submitCompanySetupInfo &&
    !confirmAmendAnswers
  "
>
  <div class="title">{{ canDeactivateDialog.title }}</div>
  <div class="message">{{ canDeactivateDialog.message }}</div>
  <div class="actions">
    <button class="ddd-button" *ngIf="dialog.cancel" (click)="dialogRef.close(false)">
      {{ canDeactivateDialog.cancel }}
    </button>
    <button class="ddd-button raised" (click)="dialogRef.close(true)">
      {{ canDeactivateDialog.okBtn }}
    </button>
  </div>
</div>

<div
  class="content"
  *ngIf="
    !isRejectDialog &&
    !canDeactivateDialog &&
    confirmColleagues &&
    !companySetupInfoPopup &&
    !submitCompanySetupInfo &&
    !confirmAmendAnswers
  "
>
  <div class="title">
    {{ confirmColleaguesDialog.title }}
  </div>
  <ul class="colleagues-invited" *ngFor="let invitedUser of data.invitedColleagues">
    <li>
      {{ invitedUser.firstName }} {{ invitedUser.lastName }},
      {{ invitedUser.email }}
    </li>
  </ul>
  <div class="actions">
    <button class="ddd-button raised" (click)="dialogRef.close()">
      {{ confirmColleaguesDialog.okBtn }}
    </button>
  </div>
</div>

<div
  class="content"
  *ngIf="
    !isRejectDialog &&
    !canDeactivateDialog &&
    !confirmColleagues &&
    companySetupInfoPopup &&
    !submitCompanySetupInfo &&
    !confirmAmendAnswers
  "
>
  <div class="title">
    {{ companySetupInfoPopupDialog.title }}
  </div>
  <div class="message company-setup" [innerHTML]="companySetupInfoPopupDialog.message"></div>
  <div class="actions">
    <button class="ddd-button raised" (click)="dialogRef.close()">
      {{ companySetupInfoPopupDialog.okBtn }}
    </button>
  </div>
</div>

<div
  class="content"
  *ngIf="
    !isRejectDialog &&
    !canDeactivateDialog &&
    !confirmColleagues &&
    !companySetupInfoPopup &&
    submitCompanySetupInfo &&
    !confirmAmendAnswers
  "
>
  <div class="title">{{ submitCompanySetupInfoDialog.title }}</div>
  <div class="message">{{ submitCompanySetupInfoDialog.message }}</div>
  <div class="actions">
    <button class="ddd-button" (click)="dialogRef.close()">
      {{ submitCompanySetupInfoDialog.cancel }}
    </button>
    <button class="ddd-button raised" (click)="dialogRef.close(true)">
      {{ submitCompanySetupInfoDialog.okBtn }}
    </button>
  </div>
</div>

<div
  class="content"
  *ngIf="
    !isRejectDialog &&
    !canDeactivateDialog &&
    !confirmColleagues &&
    !companySetupInfoPopup &&
    !submitCompanySetupInfo &&
    confirmAmendAnswers
  "
>
  <div class="title">{{ confirmAmendAnswersDialog.title }}</div>
  <div class="message">{{ confirmAmendAnswersDialog.message }}</div>
  <div class="actions">
    <button class="ddd-button" (click)="dialogRef.close()">
      {{ confirmAmendAnswersDialog.cancel }}
    </button>
    <button class="ddd-button raised" (click)="dialogRef.close(true)">
      {{ confirmAmendAnswersDialog.okBtn }}
    </button>
  </div>
</div>
