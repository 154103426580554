import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, EMPTY } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "@env/environment";

// const API_URL = environment.client.base_url;

@Injectable()
export class FundManagerAdminService {
  private API_URL = environment.client.base_url;
  isAuthenticated = false;
  private response = new BehaviorSubject(EMPTY);
  public approversResponse = this.response.asObservable();

  constructor(private httpClient: HttpClient) {}

  getFMCompanyDetails(companyId, base_url): Observable<any> {
    return this.httpClient
      .get(base_url + `/api/company/fundmanager/${companyId}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  updateFMCompany(base_url, distributorId, model) {
    return this.httpClient
      .put(
        base_url + `/api/company/fundmanager/${distributorId}`,
        {
          address: model,
          curatorId: model.curatorId
        },
        {
          responseType: "text"
        }
      )
      .pipe(map((res: string) => JSON.parse(res)));
  }

  getTargetFmCompanies(base_url, page, size): Observable<any> {
    return this.httpClient
      .get(base_url + `/api/company/getTargetFmCompanies/${page}/${size}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  getIndependentlyReviewDistributors(base_url, targetFm, page, size): Observable<any> {
    return this.httpClient
      .get(base_url + `/api/company/getIndependetlyReviewed/${targetFm}/${page}/${size}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  getReviewGroupsInfo(base_url, page, size): Observable<any> {
    return this.httpClient
      .get(base_url + `/api/questionnairetemplatergapprover/reviewgroupsinfo/${page}/${size}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  changeTemplateName(base_url, templateName, templateId): Observable<any> {
    const template = {
      rgTemplateId: templateId,
      rgTemplateName: templateName
    };
    return this.httpClient
      .put(base_url + `/api/questionnairetemplatergapprover/updatereviewgroupname`, template, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  changeTemplateApprovers(base_url, approvers) {
    this.httpClient
      .put(base_url + `/api/questionnairetemplatergapprover/updatereviewgroupapprover`, approvers, {
        responseType: "text"
      })
      .subscribe((res: any) => {
        if (res) {
          this.response.next(res);
        }
      });
  }

  setReporterRole(userId: string, isActive: boolean): Observable<any> {
    return this.httpClient.put(
      this.API_URL + `/api/user/setuserasreporter/${userId}/${isActive}`,
      {},
      {
        responseType: "text"
      }
    );
  }

  getUsers(distributorId, page, size) {
    return this.httpClient
      .get(this.API_URL + `/api/company/distributor/${distributorId}/users/${page}/${size}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }
}
