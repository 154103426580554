import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { environment } from "@env/environment";
import { IReviewFrequencyData } from "@app/modules/template-manager/components/template-manager/reducers";

// const API_URL = environment.client.base_url;

@Injectable()
export class TemplateManagerService {
  private API_URL = environment.client.base_url;
  constructor(private httpClient: HttpClient) {}
  getAllDistributors(id, page, size): Observable<any> {
    return this.httpClient
      .get(this.API_URL + `/api/questionnairetemplatefmtowner/distributors/${id}/${page}/${size}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  getTemplate(id): Observable<any> {
    return this.httpClient
      .get(this.API_URL + `/api/questionnairetemplatefmtowner/${id}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  updateTemplate(template): Observable<any> {
    return this.httpClient
      .put(this.API_URL + `/api/questionnairetemplatefmtowner`, template, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  publishTemplate(id): Observable<any> {
    return this.httpClient
      .put(
        this.API_URL + `/api/questionnairetemplatefmtowner/publish/${id}`,
        {},
        {
          responseType: "text"
        }
      )
      .pipe(map((res: string) => JSON.parse(res)));
  }

  getTemplates(): Observable<any> {
    return this.httpClient
      .get(this.API_URL + "/api/questionnairetemplatefmtowner", {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  getChoiceQuestionOptions(key: string): Observable<any> {
    return this.httpClient
      .get(this.API_URL + `/api/options/${key}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Reset template API-call
   */
  resetTemplate(id): Observable<any> {
    return this.httpClient
      .put(
        this.API_URL + `/api/questionnairetemplatefmtowner/reset/${id}`,
        {},
        {
          responseType: "text"
        }
      )
      .pipe(map((res: string) => JSON.parse(res)));
  }

  getReviewFrequencyData(templateId: string): Observable<any> {
    return this.httpClient
      .get(this.API_URL + `/api/schedulereview/getreviewfrequency/${templateId}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  setReviewFrequencyData(reviewFrequencyData: IReviewFrequencyData, templateId: string): Observable<any> {
    return this.httpClient
      .put(this.API_URL + `/api/schedulereview/setreviewfrequency/${templateId}`, reviewFrequencyData, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }
}
