import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { select, Store } from "@ngrx/store";
import { selectorLanguage } from "@app/core/i18n/i18n.reducer";
import { filter, take } from "rxjs/operators";

/**
 * The configurable base component
 *
 * @export
 * @class ConfigurableBaseComponent
 */
@Component({
    selector: "ddd-base",
    templateUrl: "./configurable-base.component.html",
    styleUrls: ["./configurable-base.component.scss"],
    standalone: true
})
export class ConfigurableBaseComponent {
  /**
   * Creates an instance of ConfigurableBaseComponent.
   * @param {TranslateService} translate
   * @param {Store<any>} store
   * @memberof ConfigurableBaseComponent
   */
  constructor(public readonly translate: TranslateService, public readonly store: Store<any>) {
    this.storeBaseActions();
  }

  /**
   * Store actions of base component
   *
   * @memberof ConfigurableBaseComponent
   */
  storeBaseActions(): void {
    this.store
      .pipe(
        select(selectorLanguage),
        take(1),
        filter((lang) => !!lang)
      )
      .subscribe((lang) => {
        this.translate.setDefaultLang(lang.currentLanguage);
      });
  }

  /**
   * A custom trackBy function for NgForOf iterates by index
   *
   * @param {number} index
   * @param {*} item
   * @return {*}  {number}
   * @memberof ConfigurableBaseComponent
   */
  trackByFn(index: number, item: any): number {
    return index;
  }

  /**
   * A custom trackBy function for NgForOf iterates by id
   *
   * @param {number} index
   * @param {*} item
   * @return {*}  {string}
   * @memberof ConfigurableBaseComponent
   */
  trackById(index: number, item: any): string {
    return item.id;
  }
}
