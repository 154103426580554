<div class="field-datetime-range__container" [ngClass]="containerClasses">
  <div class="field-datetime-range__content" [formGroup]="value">
    <mat-form-field class="field-datetime-range__from" [ngClass]="inputClasses" class="table-form-field">
      <input
        formControlName="from"
        [placeholder]="placeholderFrom"
        (dateChange)="onChange($event)"
        (dateInput)="onChange($event)"
        [min]="minValue"
        [max]="fromMax"
        matInput
        [matDatepicker]="pickerFrom"
      />
      <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
      <mat-datepicker #pickerFrom></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="field-datetime-range__until" [ngClass]="inputClasses" class="table-form-field">
      <input
        formControlName="until"
        [formControl]="value.get('until')"
        [min]="untilMin"
        [placeholder]="placeholderUntil"
        (dateChange)="onChange($event)"
        (dateInput)="onChange($event)"
        matInput
        [max]="maxValue"
        [matDatepicker]="pickerUntil"
      />
      <mat-datepicker-toggle matSuffix [for]="pickerUntil"></mat-datepicker-toggle>
      <mat-datepicker #pickerUntil></mat-datepicker>
    </mat-form-field>
  </div>
</div>
