import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CompanyInteractionsState, ALL_COPMANIES_INTERACTIONS_FEATURE_KEY } from "@modules/all-companies-interactions/state/reducers";


/**
 * Companies interactions state selector
 */
export const allCompaniesInteractionsFeatureSelector = createFeatureSelector<CompanyInteractionsState>(ALL_COPMANIES_INTERACTIONS_FEATURE_KEY);

/**
 * Companies interactions data selector
 */
export const selectorCompanyInteractionsData = createSelector(
  allCompaniesInteractionsFeatureSelector,
  state => state.interactionsData
);

/**
 * Companies users data selector
 */
export const selectorAllCompanyUsersData = createSelector(
  allCompaniesInteractionsFeatureSelector,
  state => state.companyUsers
);

/**
 * Companies interactions loading status selector
 */
export const selectorCompanyInteractionsLoading = createSelector(
  allCompaniesInteractionsFeatureSelector,
  state => state.loading
);