import { Injectable, ViewContainerRef } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";

import { RejectAndConfirmDialogComponent } from "./reject-confirm-dialog.component";

@Injectable()
export class RejectAndConfirmDialogService {
  constructor(private dialog: MatDialog) {}

  public clearNewCompliters;

  public reject(dialogInfo: any, viewContainerRef: ViewContainerRef, companyReject: boolean): Observable<any> {
    let dialogRef: MatDialogRef<RejectAndConfirmDialogComponent>;
    const config = new MatDialogConfig();
    config.panelClass = ["cg-dialog", "middle"];
    config.backdropClass = "cg-dialog-backdrop";
    config.disableClose = true;
    config.data = {
      type: "reject",
      companyReject: companyReject
    };
    config.viewContainerRef = viewContainerRef;

    dialogRef = this.dialog.open(RejectAndConfirmDialogComponent, config);
    dialogRef.componentInstance.dialog = dialogInfo;
    return dialogRef.afterClosed();
  }

  public confirm(dialogInfo: any, viewContainerRef: ViewContainerRef, newDesign?: boolean): Observable<boolean> {
    let dialogRef: MatDialogRef<RejectAndConfirmDialogComponent>;
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.id = dialogInfo.id;
    config.viewContainerRef = viewContainerRef;
    if (newDesign) {
      config.panelClass = "ddd-dialog";
      config.backdropClass = "ddd-dialog-backdrop";
      config.autoFocus = false;
    } else {
      config.panelClass = ["cg-dialog", "middle"];
      config.backdropClass = "cg-dialog-backdrop";
      config.autoFocus = false;
    }
    config.data = {
      type: "confirm",
      templateName: dialogInfo.templateName,
      newDesign: newDesign
    };

    dialogRef = this.dialog.open(RejectAndConfirmDialogComponent, config);
    dialogRef.componentInstance.dialog = dialogInfo;
    return dialogRef.afterClosed();
  }

  public confirmPageLeave(dialogInfo: any, viewContainerRef: ViewContainerRef, isDialog?: boolean): Observable<boolean> {
    let dialogRef: MatDialogRef<RejectAndConfirmDialogComponent>;
    const config = new MatDialogConfig();
    config.panelClass = ["cg-dialog", "middle"];
    config.backdropClass = "cg-dialog-backdrop";
    config.disableClose = true;
    config.viewContainerRef = viewContainerRef;
    config.data = {
      type: "confirmLeave",
      isDialog: isDialog
    };

    dialogRef = this.dialog.open(RejectAndConfirmDialogComponent, config);
    dialogRef.componentInstance.dialog = dialogInfo;
    dialogRef.afterClosed();
    return dialogRef.afterClosed();
  }

  public confirmSentInvites(viewContainerRef: ViewContainerRef, data: any): Observable<boolean> {
    let dialogRef: MatDialogRef<RejectAndConfirmDialogComponent>;
    const config = new MatDialogConfig();
    config.panelClass = ["cg-dialog", "middle"];
    config.backdropClass = "cg-dialog-backdrop";
    config.disableClose = true;
    config.viewContainerRef = viewContainerRef;
    config.data = {
      invitedColleagues: data,
      type: "confirmSentInvites"
    };
    dialogRef = this.dialog.open(RejectAndConfirmDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public showCompanySetupInfo(viewContainerRef: ViewContainerRef): Observable<boolean> {
    let dialogRef: MatDialogRef<RejectAndConfirmDialogComponent>;
    const config = new MatDialogConfig();
    config.panelClass = ["cg-dialog", "middle"];
    config.backdropClass = "cg-dialog-backdrop";
    config.disableClose = true;
    config.viewContainerRef = viewContainerRef;
    config.data = {
      type: "companySetupInfo"
    };

    dialogRef = this.dialog.open(RejectAndConfirmDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public submitCompanySetupInfo(viewContainerRef: ViewContainerRef): Observable<boolean> {
    let dialogRef: MatDialogRef<RejectAndConfirmDialogComponent>;
    const config = new MatDialogConfig();
    config.panelClass = ["cg-dialog", "middle"];
    config.backdropClass = "cg-dialog-backdrop";
    config.disableClose = true;
    config.viewContainerRef = viewContainerRef;
    config.data = {
      type: "submitCompanySetupInfo"
    };

    dialogRef = this.dialog.open(RejectAndConfirmDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public confirmAmendPriliminaryAnswers(viewContainerRef: ViewContainerRef): Observable<boolean> {
    let dialogRef: MatDialogRef<RejectAndConfirmDialogComponent>;
    const config = new MatDialogConfig();
    config.panelClass = ["cg-dialog", "middle"];
    config.backdropClass = "cg-dialog-backdrop";
    config.disableClose = true;
    config.viewContainerRef = viewContainerRef;
    config.data = {
      type: "confirmAmendAnswers"
    };

    dialogRef = this.dialog.open(RejectAndConfirmDialogComponent, config);
    return dialogRef.afterClosed();
  }
}
