import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";

import { environment } from "@env/environment";
import { ActionExportPdfRetrieve } from "@app/modules/qc-release/reducers";
import { DDQReviewExportPdfRetrieve } from "@app/modules/fm-review/components/fm-review/reducers";
import { ExportDdqDialogComponent } from "./export-ddq-dialog.component";
import { DDQReviewGuestExportPdfRetrieve } from "@app/modules/fm-guest-landing/reducers";
import { ActionAnalyticsExport } from "@modules/analytics/store/reducers";
import { ActionAnalyticsFMExport } from "@modules/fm-analytics/store/reducers";
import { ActionToastrInfo } from "@app/core/toastr/toastr.reducer";

// const API_URL = environment.client.base_url;

@Injectable()
export class ExportDdqDialogService {
  private API_URL = environment.client.base_url;
  private pdfPassword: string;
  public docName: string;
  public distributorId: string;
  public cycleName: string;
  public loading = false;
  public reportTable;
  public reportType;
  public analyticsType;
  public responseId: string;

  constructor(
    public dialog: MatDialog,
    private readonly store: Store<any>,
    private route: ActivatedRoute,
    private httpClient: HttpClient
  ) {}

  public exportDDQ(
    docName,
    component,
    id,
    reportType,
    reportTable?,
    distributorId?,
    analyticsType?,
    responseId?
  ): void {
    this.reportTable = reportTable;
    this.reportType = reportType;
    this.docName = docName;
    this.distributorId = distributorId;
    this.responseId = responseId;
    this.analyticsType = analyticsType;
    let dialogRef: MatDialogRef<ExportDdqDialogComponent>;
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.panelClass = ["cg-dialog", "middle"];
    config.backdropClass = "cg-dialog-backdrop";
    config.data = {
      component: component,
      id: id,
      docName: docName,
      distributorId: distributorId,
      selectedViewType: analyticsType
    };
    dialogRef = this.dialog.open(ExportDdqDialogComponent, config);
    dialogRef.afterClosed().subscribe((result) => {
      return result && this.exportApiCall(result);
    });
  }

  public exportApiCall({
    password,
    component,
    id,
    includeSummaryReport,
    includeExtraData,
    excludeComments,
    ddqId,
    cycleName
  }) {
    this.pdfPassword = password.value;
    this.loading = true;
    this.store.dispatch(
      new ActionToastrInfo({
        message: "",
        isExportInfoMessage: true
      })
    );
    if (component === "review") {
      this.store.dispatch(
        new ActionExportPdfRetrieve({
          password: this.pdfPassword,
          docName: `${cycleName} ${this.docName}.pdf`,
          ddqId: ddqId
        })
      );
    } else if (component === "fm-review") {
      this.store.dispatch(
        new DDQReviewExportPdfRetrieve({
          password: this.pdfPassword,
          docName: `${this.docName}.pdf`,
          distributorId: this.distributorId,
          includeSummaryReport: includeSummaryReport,
          includeExtraData: includeExtraData,
          excludeComments: excludeComments,
          documentId: id
        })
      );
    } else if (component === "fm-guest-landing") {
      this.store.dispatch(
        new DDQReviewGuestExportPdfRetrieve({
          password: this.pdfPassword,
          docName: `${this.docName}.pdf`,
          distributorId: this.distributorId,
          includeExtraData: includeExtraData,
          responseId: this.responseId
        })
      );
    } else if (component === "analytics") {
      this.store.dispatch(
        new ActionAnalyticsExport({
          analyticType: this.analyticsType,
          password: this.pdfPassword,
          docName: `${this.docName}.xlsx`
        })
      );
    } else if (component === "fm-analytics") {
      this.store.dispatch(
        new ActionAnalyticsFMExport({
          analyticType: this.analyticsType,
          password: this.pdfPassword,
          docName: `${this.docName}.xlsx`
        })
      );
    }
  }

  public exportPdf(password, ddqId): Observable<any> {
    let params = new HttpParams();
    if (password !== null) {
      params = params.set("password", password);
    }
    if (ddqId) {
      params = params.set("id", ddqId);
    }
    this.store.dispatch(
      new ActionToastrInfo({
        message: "",
        isExportInfoMessage: true
      })
    );
    return this.httpClient.get(this.API_URL + `/api/questionnaireresponsekeycontact/export`, {
      responseType: "blob",
      params: params
    });
  }

  public exportReviewPdf(
    distributorId,
    password,
    includeSummaryReport,
    includeExtraData,
    excludeComments,
    documentId
  ): Observable<any> {
    let params = new HttpParams()
      .set("includeSummaryReport", includeSummaryReport.toString())
      .set("includeExtraData", includeExtraData.toString())
      .set("excludeComments", excludeComments.toString());
    if (password !== null) {
      params = params.set("password", password);
    }
    this.store.dispatch(
      new ActionToastrInfo({
        message: "",
        isExportInfoMessage: true
      })
    );
    return this.httpClient.get(
      this.API_URL + `/api/questionnaireduediligencereviewer/export/${distributorId}/${documentId}`,
      {
        responseType: "blob",
        params: params
      }
    );
  }

  public exportReviewGuestPdf(distributorId, responseId, password, includeExtraData): Observable<any> {
    let params = new HttpParams().set("includeExtraData", includeExtraData.toString());
    if (password !== null) {
      params = params.set("password", password);
    }
    this.store.dispatch(
      new ActionToastrInfo({
        message: "",
        isExportInfoMessage: true
      })
    );
    return this.httpClient.get(
      this.API_URL + `/api/questionnaireduediligencefmguest/export/${distributorId}/${responseId}`,
      {
        responseType: "blob",
        params: params
      }
    );
  }

  public downloadBlobFile(body, options: BlobPropertyBag, filename: string) {
    const blob = new Blob([body], options);
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    this.loading = false;
    this.dialog.closeAll();
  }
}
