import { Component, Input, OnInit } from "@angular/core";
import { NgIf, NgClass } from "@angular/common";

@Component({
    selector: "ddd-field-error-show",
    templateUrl: "./field-error-show.component.html",
    styleUrls: ["./field-error-show.component.scss"],
    standalone: true,
    imports: [NgIf, NgClass]
})
export class FieldErrorShowComponent implements OnInit {
  @Input() errorMsg: string;
  @Input() displayError: boolean;
  @Input() newDesign: boolean;

  constructor() {}

  ngOnInit() {}
}
