<div class="content">
  <div class="title">Edit Name</div>
  <div class="custom-form">
    <div class="control-group">
      <div class="control-box">
        <input class="input-form" [(ngModel)]="data.name" placeholder="name" maxlength="100" />
      </div>
    </div>
  </div>
  <div class="actions">
    <button class="ddd-button" (click)="dialogRef.close(false)">Cancel</button>
    <button class="ddd-button raised" (click)="onSubmit()">Save</button>
  </div>
</div>
