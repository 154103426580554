<div class="content view-distributors-dialog-full">
  <div class="title">Distributors</div>
  <div class="table-box" [hidden]="isLoading">
    <table
      mat-table
      [dataSource]="dataSource"
      class="mat-table mat-elevation-z8"
      [class.scroll-visible]="isScrollVisible"
    >
      <!-- Name Column -->
      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let item">{{ item.name }}</td>
      </ng-container>

      <!-- LEI Column -->
      <ng-container matColumnDef="LEI">
        <th mat-header-cell *matHeaderCellDef>LEI</th>
        <td mat-cell *matCellDef="let item">
          {{ item.legalEntityId }}
        </td>
      </ng-container>

      <!-- Key Contact Column -->
      <ng-container matColumnDef="Key Contact">
        <th mat-header-cell *matHeaderCellDef>Key Contact</th>
        <td mat-cell *matCellDef="let item">
          <div *ngFor="let keyContact of item.keyContacts">
            {{ keyContact?.firstName }} {{ keyContact?.lastName }}
            <div>{{ keyContact?.email }}</div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      #paginator
      [pageSizeOptions]="[5, 10, 20, 50]"
      [pageSize]="size"
      [length]="dataLength"
      showFirstLastButtons
      (page)="paginatorEvent($event)"
    ></mat-paginator>
  </div>
  <ng-container *ngIf="isLoading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-container>
  <div class="actions">
    <button class="ddd-button raised" style="padding: 8px 40px" (click)="dialogRef.close()">Ok</button>
  </div>
</div>
