<div class="content">
  <div class="title">
    {{
      colleague
        ? ("COOLEAGUE_SAVE_DIALOG.EDIT_COLLEAGUE" | translate)
        : ("COOLEAGUE_SAVE_DIALOG.ADD_COLLEAGUE" | translate)
    }}
  </div>

  <form class="custom-form setup-form" [formGroup]="form">
    <div class="control-group">
      <div class="label">{{ "FORM.FIRST_NAME" | translate }}</div>
      <div class="control-box">
        <input
          class="input-form"
          type="text"
          id="firstName"
          formControlName="firstName"
          placeholder="first name"
          dddAppCapitalize
        />
        <ddd-field-error-show
          [displayError]="isFieldValid('firstName')"
          errorMsg="{{ 'MESSAGES.ENTER_FIRST_NAME' | translate }}"
        >
        </ddd-field-error-show>
      </div>
    </div>
    <div class="control-group">
      <div class="label">{{ "FORM.LAST_NAME" | translate }}</div>
      <div class="control-box">
        <input
          class="input-form"
          type="text"
          id="lastName"
          formControlName="lastName"
          placeholder="last name"
          dddAppCapitalize
        />
        <ddd-field-error-show
          [displayError]="isFieldValid('lastName')"
          errorMsg="{{ 'MESSAGES.ENTER_LAST_NAME' | translate }}"
        >
        </ddd-field-error-show>
      </div>
    </div>
    <div class="control-group">
      <div class="email-content">
        <div class="label" style="flex-basis: auto">
          {{ "FORM.EMAIL" | translate }}
          <div
            *ngIf="colleague"
            class="info-icon"
            matTooltip="{{ 'COLLEAGUE_EMAIL_TOOLTIP' | translate : { user: (user$ | async) } }}"
            [matTooltipPosition]="'right'"
          ></div>
        </div>
      </div>
      <div class="control-box">
        <input class="input-form" type="email" id="email" formControlName="email" placeholder="email" />
        <ddd-field-error-show
          [displayError]="isFieldValid('email')"
          errorMsg="{{ 'MESSAGES.ENTER_EMAIL' | translate }}"
        >
        </ddd-field-error-show>
      </div>
    </div>
    <div class="control-group">
      <div class="label">
        {{ "FORM.LANDLINE" | translate }}
        <div
          class="info-icon"
          matTooltip="{{ 'LANDLINE_PHONE_TOOLTIP' | translate }}"
          [matTooltipPosition]="'right'"
        ></div>
      </div>
      <div class="control-box">
        <div class="phone-number-input-container">
          <div class="c-select">
            <select #countryCodeLandline>
              <option *ngIf="!landlineCountryAlphaCode" disabled selected value></option>
              <option
                value="{{ country.alpha2Code }}"
                *ngFor="let country of countriesSelect"
                [selected]="country.alpha2Code === landlineCountryAlphaCode"
              >
                {{ country.name }} ({{ country.phonePrefix }})
              </option>
            </select>
          </div>
          <input
            class="input-form"
            type="text"
            id="landline"
            formControlName="landline"
            placeholder="landline phone"
            (blur)="validateLandline()"
            #landline
            [ngClass]="{ 'empty-error': !validateLandline() }"
          />
        </div>
        <div class="question-text-error" *ngIf="!validateLandline() && !isFieldValid('landline')">
          {{ "MESSAGES.VALID_LANDLINE" | translate }}
        </div>
        <ddd-field-error-show
          [displayError]="isFieldValid('landline')"
          errorMsg="{{ 'MESSAGES.ENTER_LANDLINE' | translate }}"
        >
        </ddd-field-error-show>
      </div>
    </div>
    <div class="control-group">
      <div class="label">{{ "FORM.MOBILE_PHONE" | translate }}</div>
      <div class="control-box">
        <div class="phone-number-input-container">
          <div class="c-select">
            <select #mobilePhoneCountryCode>
              <option *ngIf="!phoneCountryAlphaCode" disabled selected value></option>
              <option
                value="{{ country.alpha2Code }}"
                *ngFor="let country of countriesSelect"
                [selected]="country.alpha2Code === phoneCountryAlphaCode"
              >
                {{ country.name }} ({{ country.phonePrefix }})
              </option>
            </select>
          </div>
          <input
            class="input-form"
            type="text"
            id="phone"
            formControlName="phone"
            placeholder="mobile phone"
            (blur)="validatePhoneNumber()"
            #mobilePhone
            [ngClass]="{ 'empty-error': !validatePhoneNumber() }"
          />
        </div>
        <div class="question-text-error" *ngIf="!validatePhoneNumber()">
          {{ "MESSAGES.VALID_PHONE" | translate }}
        </div>
      </div>
    </div>
  </form>

  <div class="actions">
    <button class="ddd-button" (click)="dialogRef.close()">
      {{ "COOLEAGUE_SAVE_DIALOG.CANCEL_BUTTON" | translate }}
    </button>
    <button class="ddd-button raised" (click)="onSubmit()">
      {{
        colleague
          ? ("COOLEAGUE_SAVE_DIALOG.EDIT_COLLEAGUE" | translate)
          : ("COOLEAGUE_SAVE_DIALOG.ADD_COLLEAGUE" | translate)
      }}
    </button>
  </div>
</div>
