import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

import { environment } from "@env/environment";
import { Colleague } from "@modules/qc-complete/components/qc-complete/reducers";
import { KeyContactInfo } from "../reducers/support.reducer";

@Injectable()
export class SupportService {
  private API_URL = environment.client.base_url;
  constructor(private httpClient: HttpClient) {}

  getCompanies(page, size, sort?, dsc?, company?, lei?, status?, country?, keyContactName?, keyContactEmail?) {
    let url = environment.client.base_url + `/api/company/companies/${page}/${size}/?`;
    // TODO: refactor with better approach; terrible code
    if (company) {
      url += `company=${company}&`;
    }
    if (status) {
      url += `status=${status}&`;
    }
    if (keyContactName) {
      url += `keyContactName=${keyContactName}&`;
    }
    if (keyContactEmail) {
      url += `keyContactEmail=${keyContactEmail}&`;
    }
    if (lei) {
      url += `lei=${lei}&`;
    }
    if (sort) {
      url += `sort=${sort}&dsc=${dsc}`;
    }
    return this.httpClient.post(url, country ? country : []).pipe(map((res: any) => res));
  }

  exportComanies(sort?, dsc?, company?, lei?, status?, country?, keyContactName?, keyContactEmail?) {
    let url = environment.client.base_url + "/api/company/companies/export/?";
    if (company) {
      url += `company=${company}&`;
    }
    if (status) {
      url += `status=${status}&`;
    }
    if (keyContactName) {
      url += `keyContactName=${keyContactName}&`;
    }
    if (keyContactEmail) {
      url += `keyContactEmail=${keyContactEmail}&`;
    }
    if (lei) {
      url += `lei=${lei}&`;
    }
    if (sort) {
      url += `sort=${sort}&dsc=${dsc}`;
    }

    return this.httpClient.post(url, country ? country : [], {
      responseType: "blob"
    });
  }

  exportUsers(firstName, lastName, email, company, jobTitle, departments, status, signupStatus) {
    let url = environment.client.base_url + "/api/user/users/export/?";
    if (firstName) {
      url += `firstName=${firstName}&`;
    }
    if (lastName) {
      url += `lastName=${lastName}&`;
    }
    if (email) {
      url += `email=${email}&`;
    }
    if (company) {
      url += `company=${company}&`;
    }
    if (jobTitle) {
      url += `jobTitle=${jobTitle}&`;
    }
    if (departments) {
      url += `departments=${departments}&`;
    }
    if (status) {
      url += `status=${status}&`;
    }
    if (signupStatus) {
      url += `signupStatus=${signupStatus}&`;
    }
    return this.httpClient.get(url, {
      responseType: "blob"
    });
  }

  getUsers(page, size, sort?, dsc?, firstName?, lastName?, email?, company?, jobTitle?, departments?, status?, signupStatus?) {
    let url = environment.client.base_url + `/api/user/users/${page}/${size}/?`;
    if (firstName) {
      url += `firstName=${firstName}&`;
    }
    if (lastName) {
      url += `lastName=${lastName}&`;
    }
    if (email) {
      url += `email=${email}&`;
    }
    if (company) {
      url += `company=${company}&`;
    }
    if (jobTitle) {
      url += `jobTitle=${jobTitle}&`;
    }
    if (departments) {
      url += `departments=${departments}&`;
    }
    if (status) {
      url += `status=${status}&`;
    }
    if (signupStatus) {
      url += `signupStatus=${signupStatus}&`;
    }
    if (sort && sort.length !== 0) {
      url += `sort=${sort}&dsc=${dsc}`;
    }
    return this.httpClient
      .get(url, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  replaceKeyContact(companyId: string, keyContact: KeyContactInfo): Observable<any> {
    return this.httpClient
      .put(this.API_URL + `/api/company/keycontact/${companyId}`, keyContact, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  getAllCompanies(page: number, count: number, query = "") {
    const url =
      `${environment.client.base_url}/api/admin/company?page=${page}&size=${count}` + (query ? `&filter=${query}` : "");
    return this.httpClient
      .get(url, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  startSupportSession(companyId, userId) {
    return this.httpClient
      .put(
        environment.client.base_url + `/api/support/session/start/${companyId}`,
        {
          userId
        },
        {
          responseType: "text"
        }
      )
      .pipe(map((res: string) => JSON.parse(res)));
  }

  endSupportSession() {
    return this.httpClient
      .put(
        environment.client.base_url + `/api/support/session/stop`,
        {},
        {
          responseType: "text"
        }
      )
      .pipe(map((res: string) => JSON.parse(res)));
  }

  editUser(base_url, user) {
    return this.httpClient
      .put((base_url || environment.client.base_url) + `/api/user/edituser`, user, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  // Admin companies keyContact Actions

  adminGetCompanyUsers(base_url, companyId: string, isFMGuestUsers?: boolean): Observable<any> {
    const url = isFMGuestUsers ? `/api/user/fmGuestUsers/${companyId}` : `/api/user/users/${companyId}`;
    return this.httpClient
      .get(base_url + url, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  adminAddColleague(base_url, companyId, colleague: Colleague): Observable<any> {
    return this.httpClient
      .put(base_url + `/api/user/add/${companyId}`, colleague, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  adminUpdateKeyContacts(base_url, companyId, keyContacts) {
    return this.httpClient
      .put(base_url + `/api/company/keycontacts/${companyId}`, keyContacts, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  adminFindUsersByEmail(base_url, email): Observable<any> {
    const params = new HttpParams().set("email", email);
    return this.httpClient
      .get(base_url + `/api/user/users/0/1000`, {
        responseType: "text",
        params: params
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /*
   * Get emails metadata
   */
  getEmailMetadata(userId: string) {
    return this.httpClient
      .get(this.API_URL + `/api/user/emailmeta/${userId}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /*
   *
   */
  resendEmail(messageId: string) {
    return this.httpClient
      .put(this.API_URL + `/api/user/emailmeta/resend/${messageId}`, {
        responseType: "text"
      })
      .pipe(map((res: boolean) => res));
  }

  resendWeeklyReminder(userId: string){
    return this.httpClient
    .put(this.API_URL + `/api/user/resendreminder/${userId}`, {
      responseType: "text"
    })
    .pipe(map((res: boolean) => res));
  }  

  pauseReminders(companyId) {
    return this.httpClient
      .put(this.API_URL + `/api/administration/pause/${companyId}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => res));
  }

  resumeReminders(companyId) {
    return this.httpClient
      .put(this.API_URL + `/api/administration/resume/${companyId}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => res));
  }

  resendInvite(userId, companyId, invitorId) {
    return this.httpClient
      .post(
        this.API_URL + `/api/user/resendinvitations/${userId}`,
        {
          companyId: companyId,
          invitorId: invitorId
        },
        {
          responseType: "text"
        }
      )
      .pipe(map((res: string) => res));
  }

  muteUser(userId) {
    return this.httpClient
      .put(this.API_URL + `/api/admin/user/${userId}/mute`, {
        responseType: 'text'
      })
      .pipe(map((res: string) => res));
  }

  unmuteUser(userId) {
    return this.httpClient
      .put(this.API_URL + `/api/admin/user/${userId}/unmute`, {
        responseType: 'text'
      })
      .pipe(map((res: string) => res));
  }

  /*
   * Get email details
   */
  getEmailDetails(messageId: string, bucketName: string) {
    const url = bucketName
      ? `/api/inbox/mailInfo/${bucketName}/${messageId}`
      : `/api/administration/emaildetails/${messageId}`;
    return this.httpClient
      .get(this.API_URL + url, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /*
   * Unlock user
   */
  unlockUser(email: string) {
    return this.httpClient
      .put(this.API_URL + `/api/admin/user/unlock/${email}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => res));
  }

  deleteUser(userId) {
    return this.httpClient
      .post(this.API_URL + `/api/admin/user/${userId}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => res));
  }

  removeUserFromCompanies(userId, companiesIds) {
    return this.httpClient
      .post(
        this.API_URL + `/api/admin/user/${userId}`,
        {
          companyIds: companiesIds
        },
        {
          responseType: "text"
        }
      )
      .pipe(map((res: string) => res));
  }

  /*
   * Get distributor FM companies
   */
  getDistributorFMCompanies(distributorId: string) {
    return this.httpClient
      .get(this.API_URL + `/api/distributors/fmIds/${distributorId}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /**
   * Add new user
   */
  addNewUser(userData: any) {
    return this.httpClient
      .post(this.API_URL + `/api/admin/user/createFmUser`, userData, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /*
   * Get HeaderFilter data
   */
  getHeaderFilterData(columnName: string) {
    return this.httpClient
      .get(this.API_URL + `/api/distributors/headerFilter/${columnName}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }

  /*
   * Get user info from carne identity
   */
  getIdentityUserInfo(email: string) {
    return this.httpClient
      .get(this.API_URL + `/api/admin/user/identityUserInfo/${email}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => JSON.parse(res)));
  }
  
  /**
   * External curator prepopulate
   *
   * @param {string} companyId
   * @return {*}
   * @memberof CompanyAdminService
   */
  externalCuratorPrepopulate(companyId: string) {
    return this.httpClient
      .put(this.API_URL + `/api/prepopulation/curatorresponse/${companyId}`, {
        responseType: "text"
      })
      .pipe(map((res: string) => res));
  }
}
