<div class="content">
  <div class="title">Resend email</div>
  <div class="message">
    Are you sure you want to resend this email to {{ user.firstName }} {{ user.lastName }} {{ user.email }}? Click Send
    to continue.
  </div>
  <div class="actions">
    <button class="ddd-button" (click)="dialogRef.close(false)">Cancel</button>
    <button class="ddd-button raised" (click)="dialogRef.close(true)">Send</button>
  </div>
</div>
