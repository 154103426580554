import { HttpErrorResponse } from "@angular/common/http";
import { Action, createFeatureSelector, createSelector } from "@ngrx/store";
import * as _ from "lodash";

import { IUser } from "@app/core/auth/identity/user.reducer";
import { IInteraction, IInteractionFormModel } from "@shared/models/interaction.interfaces";
import { ITaskData } from "@app/shared/modules/tasks-shared/model/fm-tasks.model";

/**
 * Company tasks action types
 */

export enum TasksActionTypes {
  TASKS_GET = "[Tasks] Get",
  TASKS_GET_SUCCESS = "[Tasks] Get Success",
  TASKS_GET_ERROR = "[Tasks] Get Error",
  FM_TASKS_GET = "[FM Tasks] Get",
  FM_TASKS_GET_SUCCESS = "[FM Tasks] Get Success",
  FM_TASKS_GET_ERROR = "[FM Tasks] Get Error",
  TASKS_ADD = "[Tasks] Add Task",
  TASKS_SAVE = "[Tasks] Save Task",
  TASK_DELAY = "[Tasks] Delay Task",
  TASKS_DELETE = "[Tasks] Delete Task",
  TASKS_CLOSE = "[Tasks] Close Task",
  TASK_UPDATE_STATUS = "[Tasks] Update Status",
  COMPANY_USERS_GET = "[Tasks] Get Company Users",
  COMPANY_USERS_GET_SUCCESS = "[Tasks] Get Company Users Success",
  COMPANY_USERS_GET_ERROR = "[Tasks] Get Company Users Error",
  ALL_USERS_GET = "[Tasks] Get All Users",
  ALL_USERS_GET_SUCCESS = "[Tasks] Get All Users Success",
  ALL_USERS_GET_ERROR = "[Tasks] Get All Users Error",
  ASSIGN_TASK_TO_USER = "[Tasks] Assign Task to User",
  TASK_DETAILS_GET = "[Tasks] Get Task Details",
  TASK_DETAILS_GET_SUCCESS = "[Tasks] Get Task Details Success",
  TASK_DETAILS_GET_ERROR = "[Tasks] Get Task Details Error",
  TASK_HISTORY_GET = "[Tasks] Get Task History",
  TASK_HISTORY_GET_SUCCESS = "[Tasks] Get Task History Success",
  TASK_HISTORY_GET_ERROR = "[Tasks] Get Task History Error",
  GET_COMPANY_DETAILS = "[Tasks] Get Company Details",
  GET_COMPANY_DETAILS_SUCCESS = "[Tasks] Get Company Details Success",
  GET_COMPANY_DETAILS_ERROR = "[Tasks] Get Company Details Error",
  TASK_INTERACTIONS_GET = "[Tasks] Get Task Interactions",
  TASK_INTERACTIONS_GET_SUCCESS = "[Tasks] Get Task Interactions Success",
  TASK_INTERACTIONS_GET_ERROR = "[Tasks] Get Task Interactions Error",
  TASK_INTERACTION_ADD = "[Tasks] Add task interaction",
  TASK_INTERACTION_UPDATE = "[Tasks] Update task interaction",
  TASK_INTERACTION_DELETE = "[Tasks] Delete task interaction",
  FM_TASKS_EXPORT = "[Tasks] Export fund manager tasks",
  TASKS_EXPORT = "[Tasks] Export tasks",
  GET_FM_COMPANIES = "[Tasks] Get FM Companies",
  GET_FM_COMPANIES_SUCCESS = "[Tasks] Get FM Companies Success",
  GET_FM_COMPANIES_ERROR = "[Tasks] Get FM Companies Error",
  ASSIGN_TASK_TO_FM = "[Task] Assign Task to FM",
  TASK_SEND_CUSTOM_EMAIL = "[Task] Send custom email users",
  TASK_SEND_CUSTOM_EMAIL_SUCCESS = "[Task] Send custom email Success",
  TASK_SEND_CUSTOM_EMAIL_ERROR = "[Task] Send custom email Error",
  TASK_SEND_TEST_EMAIL = "[Task] Send test email users",
  TASK_SEND_TEST_EMAIL_SUCCESS = "[Task] Send test email users Success",
  TASK_SEND_TEST_EMAIL_ERROR = "[Task] Send test email users Error",
  ASSIGN_TASK_TO_ADMIN = "[Task] Assign task to admin",
  ASSIGN_TASK_TO_ADMIN_USER = "[Task] Assign task to selected admin-user",
  RETURN_TO_TASKS_SCREEN = "[Task] Return to tasks screen",
  REDIRECT_TO_TASK_DETAILS = "[Task] Redirect to task details",
  SET_TASK_FILTER = "[Task] Set tasks filter",
  DISTRIBUTOR_SUMMARY_GET = "[Task] Get Distributor Status Summary",
  DISTRIBUTOR_SUMMARY_GET_SUCCESS = "[Task] Get Distributor Status Summary Success",
  DISTRIBUTOR_SUMMARY_GET_ERROR = "[Task] Get Distributor Status Summary Error",
  DISTRIBUTOR_SUMMARY_SAVE = "[Task] Save Distributor Status Summary"
}

export class ActionTasksGet implements Action {
  readonly type = TasksActionTypes.TASKS_GET;
  constructor(
    public payload?: {
      page: number;
      size: number;
      search?: any;
    }
  ) {}
}

export class ActionTasksGetSuccess implements Action {
  readonly type = TasksActionTypes.TASKS_GET_SUCCESS;
  constructor(public payload: { tasksData: ITaskData }) {}
}

export class ActionTasksGetError implements Action {
  readonly type = TasksActionTypes.TASKS_GET_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionFMTasksGet implements Action {
  readonly type = TasksActionTypes.FM_TASKS_GET;
  constructor(
    public payload?: {
      page: number;
      size: number;
      search?: any;
    }
  ) {}
}

export class ActionFMTasksGetSuccess implements Action {
  readonly type = TasksActionTypes.FM_TASKS_GET_SUCCESS;
  constructor(public payload: { tasksData: ITaskData }) {}
}

export class ActionFMTasksGetError implements Action {
  readonly type = TasksActionTypes.FM_TASKS_GET_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionTasksExport implements Action {
  readonly type = TasksActionTypes.TASKS_EXPORT;
  constructor(public payload?: { password: string }) {}
}

export class ActionFMTasksExport implements Action {
  readonly type = TasksActionTypes.FM_TASKS_EXPORT;
  constructor(public payload?: { password: string }) {}
}

export class ActionTasksAdd implements Action {
  readonly type = TasksActionTypes.TASKS_ADD;
  constructor(
    public payload?: {
      task: ITask;
    }
  ) {}
}

export class ActionTasksSave implements Action {
  readonly type = TasksActionTypes.TASKS_SAVE;
  constructor(
    public payload?: {
      task: ITask;
      tasksGetData: IDataTaskGet;
    }
  ) {}
}

export class ActionTasksDelete implements Action {
  readonly type = TasksActionTypes.TASKS_DELETE;
  constructor(public payload: { id: string; tasksGetData: IDataTaskGet }) {}
}

export class ActionTaskCompanyUsersGet implements Action {
  readonly type = TasksActionTypes.COMPANY_USERS_GET;
  constructor(
    public payload?: {
      companyId: string;
    }
  ) {}
}

export class ActionCompanyUsersGetSuccess implements Action {
  readonly type = TasksActionTypes.COMPANY_USERS_GET_SUCCESS;
  constructor(public payload: { companyUsers: any }) {}
}

export class ActionCompanyUsersGetError implements Action {
  readonly type = TasksActionTypes.COMPANY_USERS_GET_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionAllUsersGet implements Action {
  readonly type = TasksActionTypes.ALL_USERS_GET;
  constructor() {}
}

export class ActionAllUsersGetSuccess implements Action {
  readonly type = TasksActionTypes.ALL_USERS_GET_SUCCESS;
  constructor(public payload: { allUsers: any }) {}
}

export class ActionAllUsersGetError implements Action {
  readonly type = TasksActionTypes.ALL_USERS_GET_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionTasksClose implements Action {
  readonly type = TasksActionTypes.TASKS_CLOSE;
  constructor(public payload: { id: string; message: string }) {}
}

export class ActionAssignTaskToUser implements Action {
  readonly type = TasksActionTypes.ASSIGN_TASK_TO_USER;
  constructor(public payload: { id: string; tasksGetData: IDataTaskGet }) {}
}

export class ActionGetTaskDetails implements Action {
  readonly type = TasksActionTypes.TASK_DETAILS_GET;
  constructor(public payload: { id: string }) {}
}

export class ActionGetTaskDetailsSuccess implements Action {
  readonly type = TasksActionTypes.TASK_DETAILS_GET_SUCCESS;
  constructor(public payload: { taskData: ITask }) {}
}

export class ActionGetTaskDetailsError implements Action {
  readonly type = TasksActionTypes.TASK_DETAILS_GET_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionGetTaskHistory implements Action {
  readonly type = TasksActionTypes.TASK_HISTORY_GET;
  constructor(public payload: { taskId: string }) {}
}

export class ActionGetTaskHistorySuccess implements Action {
  readonly type = TasksActionTypes.TASK_HISTORY_GET_SUCCESS;
  constructor(public payload: { taskActions: ITaskAction[] }) {}
}

export class ActionGetTaskHistoryError implements Action {
  readonly type = TasksActionTypes.TASK_HISTORY_GET_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionCompanyDetailsGet implements Action {
  readonly type = TasksActionTypes.GET_COMPANY_DETAILS;
  constructor(
    public payload?: {
      companyId: string;
    }
  ) {}
}

export class ActionCompanyDetailsGetSuccess implements Action {
  readonly type = TasksActionTypes.GET_COMPANY_DETAILS_SUCCESS;
  constructor(public payload: { companyDetails: any }) {}
}

export class ActionCompanyDetailsGetError implements Action {
  readonly type = TasksActionTypes.GET_COMPANY_DETAILS_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionUpdateStatus implements Action {
  readonly type = TasksActionTypes.TASK_UPDATE_STATUS;
  constructor() {}
}

export class ActionTasksDelay implements Action {
  readonly type = TasksActionTypes.TASK_DELAY;
  constructor(
    public payload?: {
      task: ITask;
      delay: any;
    }
  ) {}
}

export class ActionGetTaskInteractions implements Action {
  readonly type = TasksActionTypes.TASK_INTERACTIONS_GET;
  constructor(public payload: { id: string }) {}
}

export class ActionGetTaskInteractionsSuccess implements Action {
  readonly type = TasksActionTypes.TASK_INTERACTIONS_GET_SUCCESS;
  constructor(public payload: { taskInteractions: IInteraction[] }) {}
}

export class ActionGetTaskInteractionsError implements Action {
  readonly type = TasksActionTypes.TASK_INTERACTIONS_GET_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionTaskInteractionAdd implements Action {
  readonly type = TasksActionTypes.TASK_INTERACTION_ADD;
  constructor(
    public payload?: {
      interaction: IInteractionFormModel;
      companyId: string;
    }
  ) {}
}

export class ActionTaskInteractionUpdate implements Action {
  readonly type = TasksActionTypes.TASK_INTERACTION_UPDATE;
  constructor(
    public payload?: {
      interaction: IInteractionFormModel;
      companyId: string;
    }
  ) {}
}

export class ActionTaskInteractionDelete implements Action {
  readonly type = TasksActionTypes.TASK_INTERACTION_DELETE;
  constructor(
    public payload?: {
      interaction: IInteraction;
    }
  ) {}
}

export class ActionGetFMCompanies implements Action {
  readonly type = TasksActionTypes.GET_FM_COMPANIES;
  constructor(public payload: { distributorId: string }) {}
}

export class ActionGetFMCompaniesSuccess implements Action {
  readonly type = TasksActionTypes.GET_FM_COMPANIES_SUCCESS;
  constructor(public payload: { fMCompanies: IFMCompany[] }) {}
}

export class ActionGetFMCompaniesError implements Action {
  readonly type = TasksActionTypes.GET_FM_COMPANIES_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionAssignTaskToFM implements Action {
  readonly type = TasksActionTypes.ASSIGN_TASK_TO_FM;
  constructor(
    public payload: {
      taskId: string;
      model: any;
    }
  ) {}
}

export class ActionSendCustomEmail implements Action {
  readonly type = TasksActionTypes.TASK_SEND_CUSTOM_EMAIL;
  constructor(
    public payload: {
      form: any;
    }
  ) {}
}

export class ActionSendCustomEmailSuccess implements Action {
  readonly type = TasksActionTypes.TASK_SEND_CUSTOM_EMAIL_SUCCESS;
  constructor(
    public payload: {
      result: boolean;
    }
  ) {}
}

export class ActionSendCustomEmailError implements Action {
  readonly type = TasksActionTypes.TASK_SEND_CUSTOM_EMAIL_ERROR;
  constructor(
    public payload: {
      error: HttpErrorResponse;
    }
  ) {}
}

export class ActionSendTestEmail implements Action {
  readonly type = TasksActionTypes.TASK_SEND_TEST_EMAIL;
  constructor(
    public payload: {
      form: any;
    }
  ) {}
}

export class ActionSendTestEmailSuccess implements Action {
  readonly type = TasksActionTypes.TASK_SEND_TEST_EMAIL_SUCCESS;
  constructor(
    public payload: {
      result: boolean;
    }
  ) {}
}

export class ActionSendTestEmailError implements Action {
  readonly type = TasksActionTypes.TASK_SEND_TEST_EMAIL_ERROR;
  constructor(
    public payload: {
      error: HttpErrorResponse;
    }
  ) {}
}

export class ActionAssignTaskToAdmin implements Action {
  readonly type = TasksActionTypes.ASSIGN_TASK_TO_ADMIN;
  constructor(
    public payload: {
      taskId: string;
      model: any;
    }
  ) {}
}

export class ActionAssignTaskToAdminUser implements Action {
  readonly type = TasksActionTypes.ASSIGN_TASK_TO_ADMIN_USER;
  constructor(
    public payload: {
      taskId: string;
      userId: string;
      taskGetData: IDataTaskGet;
    }
  ) {}
}

export class ActionReturnToTasksScreen implements Action {
  readonly type = TasksActionTypes.RETURN_TO_TASKS_SCREEN;
  constructor(public payload: {}) {}
}

export class ActionRedirectToTaskDetails implements Action {
  readonly type = TasksActionTypes.REDIRECT_TO_TASK_DETAILS;
  constructor(
    public payload: {
      taskId: string;
    }
  ) {}
}

export class ActionTasksSetSearchData implements Action {
  readonly type = TasksActionTypes.SET_TASK_FILTER;
  constructor(public payload: { filter: SupportTasksSearchData }) {}
}

export class ActionDistributorSummarySave implements Action {
  readonly type = TasksActionTypes.DISTRIBUTOR_SUMMARY_SAVE;
  constructor(
    public payload: {
      distributorId: string;
      statusSummary: IDistributorStatusSummary;
      taskId: string;
    }
  ) {}
}

export type TasksActions =
  | ActionTasksGet
  | ActionTasksGetSuccess
  | ActionTasksGetError
  | ActionFMTasksGet
  | ActionFMTasksGetSuccess
  | ActionFMTasksGetError
  | ActionTasksAdd
  | ActionTasksSave
  | ActionTasksDelete
  | ActionTasksDelay
  | ActionTasksClose
  | ActionTaskCompanyUsersGet
  | ActionCompanyUsersGetSuccess
  | ActionCompanyUsersGetError
  | ActionAllUsersGet
  | ActionAllUsersGetSuccess
  | ActionAllUsersGetError
  | ActionAssignTaskToUser
  | ActionGetTaskDetails
  | ActionGetTaskDetailsSuccess
  | ActionGetTaskDetailsError
  | ActionGetTaskHistory
  | ActionGetTaskHistorySuccess
  | ActionGetTaskHistoryError
  | ActionCompanyDetailsGet
  | ActionCompanyDetailsGetSuccess
  | ActionCompanyDetailsGetError
  | ActionUpdateStatus
  | ActionGetTaskInteractions
  | ActionGetTaskInteractionsSuccess
  | ActionGetTaskInteractionsError
  | ActionTaskInteractionAdd
  | ActionTaskInteractionUpdate
  | ActionTaskInteractionDelete
  | ActionTasksExport
  | ActionFMTasksExport
  | ActionGetFMCompanies
  | ActionGetFMCompaniesSuccess
  | ActionGetFMCompaniesError
  | ActionAssignTaskToFM
  | ActionSendCustomEmail
  | ActionSendCustomEmailSuccess
  | ActionSendCustomEmailError
  | ActionSendTestEmail
  | ActionSendTestEmailSuccess
  | ActionSendTestEmailError
  | ActionAssignTaskToAdmin
  | ActionAssignTaskToAdminUser
  | ActionReturnToTasksScreen
  | ActionRedirectToTaskDetails
  | ActionTasksSetSearchData
  | ActionDistributorSummarySave;

/**
 * Tasks initial state
 */
export const initialState: TasksState = {
  loading: false
};

const featureTasksSelector = createFeatureSelector<any>("tasksState");

export const selectorTasksState = createSelector(featureTasksSelector, (state) => {
  if (state) {
    return state.tasks;
  }
});

export const selectorTasksData = createSelector(selectorTasksState, (state) => {
  if (state) {
    return state.tasksData;
  }
});

export const selectorTasksLoading = createSelector(selectorTasksState, (state) => {
  if (state) {
    return state.loading;
  }
});

export const selectorTaskCompanyUsersData = createSelector(selectorTasksState, (state) => {
  if (state) {
    return state.companyUsers;
  }
});

export const selectorAllAdminUsersData = createSelector(selectorTasksState, (state) => {
  if (state) {
    return state.allAdminUsers;
  }
});

export const selectorTaskDetailsData = createSelector(selectorTasksState, (state) => {
  if (state) {
    return state.taskDetails;
  }
});

export const selectorTaskHistoryData = createSelector(selectorTasksState, (state) => {
  if (state) {
    return state.taskActions;
  }
});

export const selectorCompanyDetailsData = createSelector(selectorTasksState, (state) => {
  if (state) {
    return state.companyDetails;
  }
});

export const selectorTaskDueDate = createSelector(selectorTasksState, (state) => {
  if (state) {
    return state.dueDate;
  }
});
export const selectorTaskInteractionsData = createSelector(selectorTasksState, (state) => {
  if (state) {
    return state.taskInteractions;
  }
});
export const selectorFMCompaniesData = createSelector(selectorTasksState, (state) => {
  if (state) {
    return state.fMCompanies;
  }
});

export const selectorDistributorStatusSummary = createSelector(selectorTasksState, (state) => {
  if (state) {
    return state.taskDetails?.supportDeskSummary;
  }
});

/**
 * Reducer function for tasks state.
 */
export function tasksReducer(state: TasksState = initialState, action: TasksActions): TasksState {
  switch (action.type) {
    case TasksActionTypes.TASKS_GET:
      return {
        ...state,
        loading: true,
        companyDetails: null,
        companyUsers: null,
        tasksData: null,
        error: null
      };
    case TasksActionTypes.TASKS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        tasksData: action.payload.tasksData,
        error: null
      };
    case TasksActionTypes.TASKS_GET_ERROR:
      return {
        ...state,
        loading: false,
        tasksData: null,
        error: action.payload.error
      };
    case TasksActionTypes.FM_TASKS_GET:
      return {
        ...state,
        loading: true,
        companyDetails: null,
        companyUsers: null,
        tasksData: null,
        error: null
      };
    case TasksActionTypes.FM_TASKS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        tasksData: action.payload.tasksData,
        error: null
      };
    case TasksActionTypes.FM_TASKS_GET_ERROR:
      return {
        ...state,
        loading: false,
        tasksData: null,
        error: action.payload.error
      };
    case TasksActionTypes.COMPANY_USERS_GET:
      return {
        ...state,
        loading: true,
        companyUsers: null,
        error: null
      };
    case TasksActionTypes.COMPANY_USERS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        companyUsers: action.payload.companyUsers,
        error: null
      };
    case TasksActionTypes.COMPANY_USERS_GET_ERROR:
      return {
        ...state,
        loading: false,
        companyUsers: null,
        error: action.payload.error
      };
    case TasksActionTypes.ALL_USERS_GET:
      return {
        ...state,
        allAdminUsers: null,
        error: null
      };
    case TasksActionTypes.ALL_USERS_GET_SUCCESS:
      return {
        ...state,
        allAdminUsers: action.payload.allUsers,
        error: null,
        loading: false
      };
    case TasksActionTypes.ALL_USERS_GET_ERROR:
      return {
        ...state,
        allAdminUsers: null,
        error: action.payload.error
      };
    case TasksActionTypes.TASK_DETAILS_GET:
      return {
        ...state,
        companyUsers: null,
        companyDetails: null,
        taskDetails: null,
        loading: true
      };
    case TasksActionTypes.TASK_DETAILS_GET_SUCCESS:
      return {
        ...state,
        taskDetails: action.payload.taskData,
        loading: false
      };
    case TasksActionTypes.TASK_HISTORY_GET:
      return {
        ...state,
        loading: true,
        taskActions: null,
        error: null
      };
    case TasksActionTypes.TASK_HISTORY_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        taskActions: action.payload.taskActions,
        error: null
      };
    case TasksActionTypes.TASK_HISTORY_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case TasksActionTypes.GET_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        companyDetails: action.payload.companyDetails
      };
    case TasksActionTypes.TASK_UPDATE_STATUS:
      return {
        ...state,
        taskDetails: {
          ...state.taskDetails,
          status: 2
        }
      };
    case TasksActionTypes.TASK_INTERACTIONS_GET:
      return {
        ...state,
        taskInteractions: null
      };
    case TasksActionTypes.TASK_INTERACTIONS_GET_SUCCESS:
      return {
        ...state,
        taskInteractions: action.payload.taskInteractions
      };
    case TasksActionTypes.GET_FM_COMPANIES:
      return {
        ...state,
        loading: true,
        fMCompanies: null
      };
    case TasksActionTypes.GET_FM_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        fMCompanies: action.payload.fMCompanies
      };
    case TasksActionTypes.GET_FM_COMPANIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case TasksActionTypes.TASK_SEND_CUSTOM_EMAIL:
      return {
        ...state,
        loading: true
      };
    case TasksActionTypes.TASK_SEND_CUSTOM_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case TasksActionTypes.TASK_SEND_CUSTOM_EMAIL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case TasksActionTypes.TASK_SEND_TEST_EMAIL:
      return {
        ...state,
        loading: true
      };
    case TasksActionTypes.TASK_SEND_TEST_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case TasksActionTypes.TASK_SEND_TEST_EMAIL_ERROR:
      return {
        ...state,
        error: action.payload.error
      };
    case TasksActionTypes.SET_TASK_FILTER:
      return {
        ...state
      };
    default:
      return state;
  }
}

/**
 * Interfaces for tasks
 */
export interface TasksState {
  loading: boolean;
  tasksData?: ITaskData;
  companyUsers?: any;
  taskDetails?: ITask;
  taskActions?: ITaskAction[];
  companyDetails?: any;
  allAdminUsers?: Array<any>;
  error?: HttpErrorResponse;
  taskInteractions?: IInteraction[];
  fMCompanies?: IFMCompany[];
  statusSummary?: IDistributorStatusSummary;
}

export interface IDataTaskGet {
  page: number;
  size: number;
  search?: any;
}

export interface ITask {
  id: string;
  title: string;
  summary: string;
  dueDate?: Date;
  companyId?: string;
  company?: ICompany;
  status?: number;
  assignedToIds?: Array<string>;
  taggedUserIds?: Array<string>;
  tags?: Array<string>;
  // details?: string,
  createBy?: IUser;
  taggedUsers?: Array<IUser>;
  assignedTo?: Array<IUser>;
  activeDays?: number;
  referredToFMActiveDays?: number;
  fmCompany?: IFMCompany;
  message?: string;
  supportDeskSummary?: IDistributorStatusSummary;
  aviableFmCompanies?: Array<ShortFmCompanyInfo>;
}

export interface ICompany {
  id: string;
  legalEntityId: string;
  name: string;
}

export interface ITaskAction {
  date: string;
  action: string;
  notes: string;
}

export interface IFMCompany {
  id: string;
  name: string;
  legalEntityId: string;
  keyContacts: IUser[];
  users: IUser[];
  status?: number;
}

export interface IKeyContact {
  id: string;
  firstName: string;
  lastName: string;
}

export interface SupportTasksSearchData {
  filter: {
    dsc: boolean;
    sort: string;
    title: string;
    company: string;
    createdDateFrom: any;
    createdDateTo: any;
    assignedTo: string;
    priorities: any[];
    statuses: any[];
    dueDateFrom: any;
    dueDateTo: any;
    tags: any[];
    onlyMine: boolean;
    showAll: boolean;
    overdue: boolean;
  };
  columns: any[];
}

export interface IDistributorStatusSummary {
  comment?: string;
  authorId: string;
  updatedDate?: string;
}

export interface ShortFmCompanyInfo {
  companyName: string;
  legalEntityId: string;
  status: number;
}
