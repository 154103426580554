import { Component, OnInit, Inject, OnDestroy, HostListener } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Dialog } from "@shared/models/questionnaire.interfaces";
import { ViewDistributorsDialogService } from "./view-distributors-dialog.service";
import { Store, select } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";
import { Subject, Observable } from "rxjs";
import {
  selectorDistributorsRG,
  ActionDistributorsGet
} from "@app/modules/review-groups/components/review-groups/reducers";
import {
  selectorDistributorsTM,
  ActionFMTemplateDistributorsGet
} from "@app/modules/template-manager/components/template-manager/reducers";
import {
  AuditorGetIndependentlyReviewedDistributors,
  selectorListDistributors
} from "@app/modules/fm-review/components/fm-review/reducers";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatPaginatorModule } from "@angular/material/paginator";
import { NgFor, NgIf } from "@angular/common";
import { MatTableModule } from "@angular/material/table";

@Component({
    selector: "ddd-view-distributors-dialog",
    templateUrl: "./view-distributors-dialog.component.html",
    styleUrls: ["./view-distributors-dialog.component.scss"],
    standalone: true,
    imports: [MatTableModule, NgFor, MatPaginatorModule, NgIf, MatProgressBarModule]
})
export class ViewDistributorsDialogComponent implements OnInit, OnDestroy {
  public dialog: any;
  public viewDistributorsDialog: Dialog;
  public displayedColumns = ["Name", "LEI", "Key Contact"];
  public unsubscribe$: Subject<void> = new Subject();
  public dataSource;
  public size = 20;
  public isLoading = false;
  public dataLength;

  public isScrollVisible = false;
  public checkScroll() {
    const value = !this.dataSource ? 0 : this.dataSource.length;

    if (value != null && value >= 6) {
      this.isScrollVisible = true;
    } else {
      this.isScrollVisible = false;
    }
    this.onResize();
  }

  @HostListener("window:resize", ["$event"]) // Dynamically set width of table header
  onResize() {
    setTimeout(() => {
      const tHead: any = document.querySelector('.view-distributors-dialog-full thead[role="rowgroup"]');
      const row: any = document.querySelector(".view-distributors-dialog-full tr.mat-row");
      if (row) {
        const rowRect = row.getBoundingClientRect();
        tHead.style.width = `${rowRect.width}px`;
      }
    });
  }

  constructor(
    public dialogRef: MatDialogRef<ViewDistributorsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly store: Store<any>
  ) {}

  ngOnInit() {
    if (this.data.component === "reviewGroups") {
      this.isLoading = true;
      this.store.pipe(select(selectorDistributorsRG), takeUntil(this.unsubscribe$)).subscribe((res) => {
        if (res) {
          this.dataSource = res.result;
          this.dataLength = res.count;
          this.isLoading = false;
          this.checkScroll();
        }
      });
    } else if (this.data.component === "templateManager") {
      this.isLoading = true;
      this.store.pipe(select(selectorDistributorsTM), takeUntil(this.unsubscribe$)).subscribe((res) => {
        if (res) {
          this.dataSource = res.result;
          this.dataLength = res.count;
          this.isLoading = false;
          this.checkScroll();
        }
      });
    } else if (this.data.component === "targetFms") {
      this.isLoading = true;
      this.store.pipe(select(selectorListDistributors), takeUntil(this.unsubscribe$)).subscribe((res) => {
        if (res) {
          this.dataSource = res.result;
          this.dataLength = res.count;
          this.isLoading = false;
          this.checkScroll();
        }
      });
    }
  }

  public paginatorEvent(event) {
    if (this.data.component === "reviewGroups") {
      this.isLoading = true;
      this.size = event.pageSize;
      this.store.dispatch(
        new ActionDistributorsGet({
          id: this.data.id,
          page: event.pageIndex,
          size: event.pageSize
        })
      );
    } else if (this.data.component === "templateManager") {
      this.isLoading = true;
      this.size = event.pageSize;
      this.store.dispatch(
        new ActionFMTemplateDistributorsGet({
          id: this.data.id,
          page: event.pageIndex,
          size: event.pageSize
        })
      );
    } else if (this.data.component === "targetFms") {
      this.isLoading = true;
      this.size = event.pageSize;
      this.store.dispatch(
        new AuditorGetIndependentlyReviewedDistributors({
          fundManagerId: this.data.id,
          page: event.pageIndex,
          size: event.pageSize
        })
      );
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
