import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";

import { ViewDistributorsDialogComponent } from "./view-distributors-dialog.component";

@Injectable({
  providedIn: "root"
})
export class ViewDistributorsDialogService {
  public dataSource = [];

  constructor(private dialog: MatDialog) {}

  public viewDistributors(component, id): Observable<boolean> {
    let dialogRef: MatDialogRef<ViewDistributorsDialogComponent>;
    const config = new MatDialogConfig();
    config.panelClass = ["cg-dialog", "middle"];
    config.backdropClass = "cg-dialog-backdrop";
    config.disableClose = true;
    config.data = {
      component: component,
      id: id
    };

    dialogRef = this.dialog.open(ViewDistributorsDialogComponent, config);
    return dialogRef.afterClosed();
  }
}
