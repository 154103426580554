<!--The content below is only a placeholder and can be replaced.-->
<ddd-common-header *ngIf="!(hideHeader$ | async)" [loggedUser]="user$ | async"></ddd-common-header>
<router-outlet></router-outlet>
<ddd-common-footer *ngIf="!(hideFooter$ | async)" class="footer-container"></ddd-common-footer>
<ng-container *ngIf="!isUserPresent">
  <ddd-loading-screen></ddd-loading-screen>
</ng-container>

<ng-template #updatedUserInfoDialog>
  <ddd-profile [updateUserInfoDialog]="true"></ddd-profile>
</ng-template>
