<div class="content" *ngIf="!dialogRef.componentInstance.loading; else loading">
  <div class="title">
    {{ data.component === "analytics" || data.component === "fm-analytics" ? "Export" : "Export DDQ" }}
  </div>
  <div *ngIf="component === 'fm-review'" [ngClass]="{'disabled-item' : includeDetailReport, 'option-container': true }">
    <button class="checkbox-container" (click)="includeSummaryReport = !includeSummaryReport" [disabled]="includeDetailReport">
      <input type="checkbox" [checked]="includeSummaryReport" />
      <span class="checkmark"></span>
    </button>
    <span class="checkbox-label spacing">Do you want to produce a summary report (includes executive summary and overview, and
      questionnaire ratings)?</span>
  </div>
  <div *ngIf="component === 'fm-review'" [ngClass]="{'disabled-item' : includeSummaryReport, 'option-container': true }">
    <button class="checkbox-container" (click)="detailReportOptionClick('includeDetailReport')" [disabled]="includeSummaryReport">
      <input type="checkbox" [checked]="includeDetailReport" />
      <span class="checkmark"></span>
    </button>
    <span class="checkbox-label spacing">Do you want to produce a detailed report (includes summary report, overall
      questionnaire summary and all the questions)?</span>
  </div>
  <ul *ngIf="component === 'fm-review'" [ngClass]="{'disabled-item' : includeSummaryReport}">
    <li class="option-container">
      <button class="checkbox-container" (click)="detailReportOptionClick('includeExtraData')" [disabled]="includeSummaryReport">
        <input type="checkbox" [checked]="includeExtraData" />
        <span class="checkmark"></span>
      </button>
      <span class="checkbox-label spacing">Include flags, ratings, review comments and approval summary for DDQ</span>
    </li>
    <li class="option-container">
      <button class="checkbox-container" (click)="detailReportOptionClick('excludeComments')" [disabled]="includeSummaryReport">
        <input type="checkbox" [checked]="excludeComments" />
        <span class="checkmark"></span>
      </button>
      <span class="checkbox-label spacing">Exclude additional comments to questions from distributor</span>
    </li>
  </ul>
  <ng-container *ngIf="component === 'review'">
    <ng-container *ngIf="cycles; else loadingCycles">
      <ng-container *ngIf="cycles && cycles.length !== 0; else noDocumentsMessage">
        <div class="cycle-select">
          <form class="custom-form">
            <div class="control-group">
              <div class="label">Version of DDQ Export</div>
              <div class="control-box">
                <ng-select
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="selectedCycleId"
                  [closeOnSelect]="true"
                  [multiple]="false"
                  [items]="cycles"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="Click here to select version of DDQ export"
                  appendTo="html">
                </ng-select>
              </div>
            </div>
          </form>
        </div>
      </ng-container>
      <ng-template #noDocumentsMessage>
        <div class="no-export-text">
          You don't have any available Due Diligence documents. Export will be enabled only after DDQ Release.
        </div>
      </ng-template>
    </ng-container>
    <ng-template #loadingCycles>
      <mat-progress-bar class="loading-cycles" mode="indeterminate"></mat-progress-bar>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="component !== 'review' || (component === 'review' && cycles && cycles.length !== 0)">
    <div class="password-protect-container">
      <button class="checkbox-container" (click)="changeCheckboxStatus()">
        <input type="checkbox" [checked]="checked" />
        <span class="checkmark"></span>
      </button>
      <span class="checkbox-label spacing">Do you want to password protect the report document?</span>
    </div>
    <div class="custom-form" *ngIf="checked">
      <div class="control-group">
        <div class="label">Enter Password</div>
        <div class="control-box">
          <input class="input-form" type="password" [formControl]="password" (keyup)="checkPasswordMatch()" />
        </div>
      </div>
      <div class="control-group">
        <div class="label">Repeat Password</div>
        <div class="control-box">
          <input class="input-form" type="password" [formControl]="repeatPassword" (keyup)="checkPasswordMatch()" />
        </div>
      </div>
      <div class="error" *ngIf="showError">Password confirmation doesn't match password</div>
      <div class="error" *ngIf="showSpaceSymbolError">Space are not supported in the password</div>
    </div>
  </ng-container>
  <div class="actions">
    <button class="ddd-button" (click)="dialogRef.close()">Cancel</button>
    <button
      class="ddd-button raised"
      (click)="submit()"
      [disabled]="showError || showSpaceSymbolError || (component === 'review' && !selectedCycleId)"
    >
      Export
    </button>
  </div>
</div>

<ng-template #loading>
  <div class="export-loader-text">
    Preparing your export, this could take a few seconds. The window will close automatically.
  </div>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>