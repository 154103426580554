import { Injectable, ViewContainerRef } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { ShowOptionsDialogComponent } from "./show-options-dialog.component";
import { Question } from "@app/shared/models";

@Injectable()
export class ShowOptionsDialogService {
  constructor(private dialog: MatDialog) {}

  public show(viewContainerRef: ViewContainerRef, question: Question, readOnly?: boolean, isGlobalUpdateCountriesFlags?: boolean): Observable<string> {
    let dialogRef: MatDialogRef<ShowOptionsDialogComponent>;
    const config = new MatDialogConfig();
    config.panelClass = ["cg-dialog", "middle"];
    config.backdropClass = "cg-dialog-backdrop";
    config.disableClose = true;
    config.data = { question, readOnly, isGlobalUpdateCountriesFlags };
    config.viewContainerRef = viewContainerRef;

    dialogRef = this.dialog.open(ShowOptionsDialogComponent, config);
    return dialogRef.afterClosed();
  }
}
