import { Injectable, ViewContainerRef } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";

import { SaveCustomFieldDialogComponent } from "./save-custom-field-dialog.component";
import { ICustomField } from "@app/modules/view-progress/component/view-progress/reducers";

@Injectable()
export class SaveCustomFieldDialogService {
  constructor(private dialog: MatDialog) {}

  public open(viewContainerRef: ViewContainerRef, customField: ICustomField): Observable<string> {
    let dialogRef: MatDialogRef<SaveCustomFieldDialogComponent>;
    const config = new MatDialogConfig();
    config.panelClass = ["cg-dialog", "middle"];
    config.backdropClass = "cg-dialog-backdrop";
    config.disableClose = true;
    config.data = { customField: customField };
    config.viewContainerRef = viewContainerRef;

    dialogRef = this.dialog.open(SaveCustomFieldDialogComponent, config);
    return dialogRef.afterClosed();
  }
}
