<div class="content">
  <div class="title">{{ "OPTIONS.TITLE" | translate }}</div>
  <div class="sub-title">
    <span>{{ data.isGlobalUpdateCountriesFlags ? ("OPTIONS.SUB_TITLE_COUNTRY_UPDATE" | translate) : ("OPTIONS.SUB_TITLE" | translate) }}</span>
    <span *ngIf="data.question.isStatic && data.question.columns.length === 1">{{
      "OPTIONS.SUB_TITLE_ONE_COLUMN" | translate
    }}</span>
    <span *ngIf="data.question.isStatic && data.question.columns.length > 1">{{
      "OPTIONS.SUB_TITLE_MORE_COLUMN" | translate
    }}</span>
  </div>
  <div class="main-box" *ngIf="options || columnOptionsCount === 0 || fieldOptionsCount === 0; else loading">
    <ng-container *ngIf="data.question.isStatic">
      <div *ngFor="let column of data.question.columns; let k = index">
        <div *ngIf="data.question.columns.length > 1" class="column-label">
          {{ column.label }}
        </div>
        <div class="table-box column-box">
          <table class="mat-mdc-table" cellspacing="0" cellpadding="0" border="0">
            <thead>
              <tr class="mat-mdc-header-row">
                <th class="mat-mdc-header-cell response-cell">
                  <div class="response-cell-box">
                    {{ "OPTIONS.TABLE_HEADER_RESPONSE" | translate }}
                    <div
                      class="info-icon"
                      matTooltip="{{ 'OPTIONS.RESPONSE_INFO' | translate }}"
                      [matTooltipPosition]="'right'"
                    ></div>
                  </div>
                </th>
                <th class="mat-mdc-header-cell flag-cell" width="50px">
                  <div class="flag-cell-box">
                    <div
                      class="tabler-icon tabler-icon--alert-triangle warn-green"
                      matTooltip="{{ 'OPTIONS.GREEN_FLAG_INFO' | translate }}"
                      [matTooltipPosition]="'right'"
                    ></div>
                  </div>
                </th>
                <th class="mat-mdc-header-cell flag-cell" width="50px">
                  <div class="flag-cell-box">
                    <div
                      class="tabler-icon tabler-icon--alert-triangle warn-amber"
                      matTooltip="{{ 'OPTIONS.AMBER_FLAG_INFO' | translate }}"
                      [matTooltipPosition]="'right'"
                    ></div>
                  </div>
                </th>
                <th class="mat-mdc-header-cell flag-cell" width="50px">
                  <div class="flag-cell-box">
                    <div
                      class="tabler-icon tabler-icon--alert-triangle warn-red"
                      matTooltip="{{ 'OPTIONS.RED_FLAG_INFO' | translate }}"
                      [matTooltipPosition]="'right'"
                    ></div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="mat-mdc-row" *ngFor="let option of columnFlags[k].options; let i = index">
                <td class="mat-mdc-cell">
                  {{ option.text ? option.text : option.name }}
                </td>
                <td class="mat-mdc-cell" width="50px">
                  <div class="checkbox-box">
                    <input
                      [ngModel]="columnFlags[k].flags[i].selectedFlag"
                      [disabled]="data.readOnly"
                      [value]="'Green'"
                      name="flag_{{ i }}_{{ k }}"
                      (click)="columnFlagUpdate('Green', i, k)"
                      class="checkbox-custom checkbox-custom-right checkbox-rounded"
                      type="radio"
                    />
                    <span class="filter"></span>
                  </div>
                </td>
                <td class="mat-mdc-cell" width="50px">
                  <div class="checkbox-box">
                    <input
                      [ngModel]="columnFlags[k].flags[i].selectedFlag"
                      [disabled]="data.readOnly"
                      [value]="'Amber'"
                      name="flag_{{ i }}_{{ k }}"
                      (click)="columnFlagUpdate('Amber', i, k)"
                      class="checkbox-custom checkbox-custom-right checkbox-rounded"
                      type="radio"
                    />
                    <span class="filter"></span>
                  </div>
                </td>
                <td class="mat-mdc-cell" width="50px">
                  <div class="checkbox-box">
                    <input
                      [ngModel]="columnFlags[k].flags[i].selectedFlag"
                      [disabled]="data.readOnly"
                      [value]="'Red'"
                      name="flag_{{ i }}_{{ k }}"
                      (click)="columnFlagUpdate('Red', i, k)"
                      class="checkbox-custom checkbox-custom-right checkbox-rounded"
                      type="radio"
                    />
                    <span class="filter"></span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="data.question.type === 'MultiFieldQuestion'">
      <div *ngFor="let field of data.question.fields; let k = index">
        <div *ngIf="data.question.fields.length > 1" class="column-label">
          {{ field.label }}
        </div>
        <div class="table-box column-box">
          <table class="mat-mdc-table" cellspacing="0" cellpadding="0" border="0">
            <thead>
              <tr class="mat-mdc-header-row">
                <th class="mat-mdc-header-cell response-cell">
                  <div class="response-cell-box">
                    {{ "OPTIONS.TABLE_HEADER_RESPONSE" | translate }}
                    <div
                      class="info-icon"
                      matTooltip="{{ 'OPTIONS.RESPONSE_INFO' | translate }}"
                      [matTooltipPosition]="'right'"
                    ></div>
                  </div>
                </th>
                <th class="mat-mdc-header-cell flag-cell" width="50px">
                  <div class="flag-cell-box">
                    <div
                      class="tabler-icon tabler-icon--alert-triangle warn-green"
                      matTooltip="{{ 'OPTIONS.GREEN_FLAG_INFO' | translate }}"
                      [matTooltipPosition]="'right'"
                    ></div>
                  </div>
                </th>
                <th class="mat-mdc-header-cell flag-cell" width="50px">
                  <div class="flag-cell-box">
                    <div
                      class="tabler-icon tabler-icon--alert-triangle warn-amber"
                      matTooltip="{{ 'OPTIONS.AMBER_FLAG_INFO' | translate }}"
                      [matTooltipPosition]="'right'"
                    ></div>
                  </div>
                </th>
                <th class="mat-mdc-header-cell flag-cell" width="50px">
                  <div class="flag-cell-box">
                    <div
                      class="tabler-icon tabler-icon--alert-triangle warn-red"
                      matTooltip="{{ 'OPTIONS.RED_FLAG_INFO' | translate }}"
                      [matTooltipPosition]="'right'"
                    ></div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="mat-mdc-row" *ngFor="let option of fieldFlags[k].options; let i = index">
                <td class="mat-mdc-cell">
                  {{ option.text ? option.text : option.name }}
                </td>
                <td class="mat-mdc-cell" width="50px">
                  <div class="checkbox-box">
                    <input
                      [ngModel]="fieldFlags[k].flags[i].selectedFlag"
                      [disabled]="data.readOnly"
                      [value]="'Green'"
                      name="flag_{{ i }}_{{ k }}"
                      (click)="fieldFlagUpdate('Green', i, k)"
                      class="checkbox-custom checkbox-custom-right checkbox-rounded"
                      type="radio"
                    />
                    <span class="filter"></span>
                  </div>
                </td>
                <td class="mat-mdc-cell" width="50px">
                  <div class="checkbox-box">
                    <input
                      [ngModel]="fieldFlags[k].flags[i].selectedFlag"
                      [disabled]="data.readOnly"
                      [value]="'Amber'"
                      name="flag_{{ i }}_{{ k }}"
                      (click)="fieldFlagUpdate('Amber', i, k)"
                      class="checkbox-custom checkbox-custom-right checkbox-rounded"
                      type="radio"
                    />
                    <span class="filter"></span>
                  </div>
                </td>
                <td class="mat-mdc-cell" width="50px">
                  <div class="checkbox-box">
                    <input
                      [ngModel]="fieldFlags[k].flags[i].selectedFlag"
                      [disabled]="data.readOnly"
                      [value]="'Red'"
                      name="flag_{{ i }}_{{ k }}"
                      (click)="fieldFlagUpdate('Red', i, k)"
                      class="checkbox-custom checkbox-custom-right checkbox-rounded"
                      type="radio"
                    />
                    <span class="filter"></span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-container>
    <div *ngIf="!data.question.isStatic && data.question.type != 'MultiFieldQuestion'" class="table-box">
      <table class="mat-mdc-table" cellspacing="0" cellpadding="0" border="0">
        <thead>
          <tr class="mat-mdc-header-row">
            <th class="mat-mdc-header-cell response-cell">
              <div class="response-cell-box">
                {{ "OPTIONS.TABLE_HEADER_RESPONSE" | translate }}
                <div
                  class="info-icon"
                  matTooltip="{{ 'OPTIONS.RESPONSE_INFO' | translate }}"
                  [matTooltipPosition]="'right'"
                ></div>
              </div>
            </th>
            <th class="mat-mdc-header-cell flag-cell" width="50px">
              <div class="flag-cell-box">
                <div
                  class="tabler-icon tabler-icon--alert-triangle warn-green"
                  matTooltip="{{ 'OPTIONS.GREEN_FLAG_INFO' | translate }}"
                  [matTooltipPosition]="'right'"
                ></div>
              </div>
            </th>
            <th class="mat-mdc-header-cell flag-cell" width="50px">
              <div class="flag-cell-box">
                <div
                  class="tabler-icon tabler-icon--alert-triangle warn-amber"
                  matTooltip="{{ 'OPTIONS.AMBER_FLAG_INFO' | translate }}"
                  [matTooltipPosition]="'right'"
                ></div>
              </div>
            </th>
            <th class="mat-mdc-header-cell flag-cell" width="50px">
              <div class="flag-cell-box">
                <div
                  class="tabler-icon tabler-icon--alert-triangle warn-red"
                  matTooltip="{{ 'OPTIONS.RED_FLAG_INFO' | translate }}"
                  [matTooltipPosition]="'right'"
                ></div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="mat-mdc-row" *ngFor="let option of options; let i = index">
            <td class="mat-mdc-cell">
              {{ option.text ? option.text : option.name }}
            </td>
            <td class="mat-mdc-cell">
              <div class="checkbox-box">
                <input
                  [ngModel]="flags[i].selectedFlag"
                  [disabled]="data.readOnly"
                  [value]="'Green'"
                  name="flag_{{ i }}"
                  (click)="flagUpdate('Green', i)"
                  class="checkbox-custom checkbox-custom-right checkbox-rounded"
                  type="radio"
                />
                <span class="filter"></span>
              </div>
            </td>
            <td class="mat-mdc-cell">
              <div class="checkbox-box">
                <input
                  [ngModel]="flags[i].selectedFlag"
                  [disabled]="data.readOnly"
                  [value]="'Amber'"
                  name="flag_{{ i }}"
                  (click)="flagUpdate('Amber', i)"
                  class="checkbox-custom checkbox-custom-right checkbox-rounded"
                  type="radio"
                />
                <span class="filter"></span>
              </div>
            </td>
            <td class="mat-mdc-cell">
              <div class="checkbox-box">
                <input
                  [ngModel]="flags[i].selectedFlag"
                  [disabled]="data.readOnly"
                  [value]="'Red'"
                  name="flag_{{ i }}"
                  (click)="flagUpdate('Red', i)"
                  class="checkbox-custom checkbox-custom-right checkbox-rounded"
                  type="radio"
                />
                <span class="filter"></span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <ng-template #loading>
    <mat-mdc-progress-bar class="loading-options" mode="indeterminate"></mat-mdc-progress-bar>
  </ng-template>
  <div *ngIf="options || columnOptionsCount === 0 || fieldOptionsCount === 0" class="actions">
    <button *ngIf="!data.readOnly" class="ddd-button" (click)="showResetFlagDialog()">
      {{ "OPTIONS.RESET_BUTTON" | translate }}
    </button>
    <button class="ddd-button" (click)="dialogRef.close()">
      {{ "OPTIONS.CANCEL_BUTTON" | translate }}
    </button>
    <button
      *ngIf="!data.readOnly && data.question.type !== 'MultiFieldQuestion'"
      class="ddd-button raised"
      (click)="dialogRef.close(data.question.isStatic ? getAllColumnsFlags() : flags)"
    >
      {{ data.isGlobalUpdateCountriesFlags ? ("OPTIONS.APPLY_BUTTON" | translate) : ("OPTIONS.OK_BUTTON" | translate) }}
    </button>
    <button
    *ngIf="!data.readOnly && data.question.type === 'MultiFieldQuestion'"
    class="ddd-button raised"
    (click)="dialogRef.close(getAllFieldsFlags())"
  >
    {{ "OPTIONS.OK_BUTTON" | translate }}
  </button>
  </div>
</div>
