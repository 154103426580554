import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { environment } from "@env/environment";
import { NgIf } from "@angular/common";

@Component({
    selector: "ddd-update-terms-first-visit",
    templateUrl: "./update-terms-first-visit.component.html",
    styleUrls: ["./update-terms-first-visit.component.scss"],
    standalone: true,
    imports: [NgIf]
})
export class UpdateTermsFirstVisitComponent implements OnInit {
  public dialog: any;
  private PRIVACY_POLICY_URL = "https://www.carnegroup.com/wp-content/uploads/2023/07/20230629-Application-Platform-PN-Clean_-002.pdf";
  private TERMS_AND_CONDITIONS_URL = "https://carne-digital.atlassian.net/wiki/external/2723151873/MGE3MDJhNTc4NDc5NDZjM2E4OTI3MTQwNWFmNDZkMTA";
  public agreeWithTerms: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<UpdateTermsFirstVisitComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  public setStatus() {
    this.agreeWithTerms = !this.agreeWithTerms;
  }
}
