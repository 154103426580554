import { Injectable, ViewContainerRef } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig } from "@angular/material/dialog";
import { EditDistributorDialogComponent } from "./edit-distributor-dialog.component";

@Injectable()
export class EditDistributorDialogService {
  constructor(private dialog: MatDialog) {}

  public open(viewContainerRef: ViewContainerRef, distributor: any) {
    let dialogRef: MatDialogRef<EditDistributorDialogComponent>;
    const config = new MatDialogConfig();
    config.panelClass = ["cg-dialog", "middle"];
    config.backdropClass = "ddd-dialog-backdrop";
    config.disableClose = true;
    config.data = { distributor };
    config.viewContainerRef = viewContainerRef;

    dialogRef = this.dialog.open(EditDistributorDialogComponent, config);
    return dialogRef.afterClosed();
  }
}
