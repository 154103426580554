import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Role } from "@app/core/auth/identity/user.reducer";
import { NgIf } from "@angular/common";

@Component({
    selector: "ddd-fm-dashboard-first-visit",
    templateUrl: "./fm-dashboard-first-visit.component.html",
    standalone: true,
    imports: [NgIf]
})
export class FMDashboardFirstVisitComponent implements OnInit {
  public dialog: any;
  public roles = {
    keyContact: false,
    approver: false,
    fmTemplateOwner: false
  };

  constructor(
    public dialogRef: MatDialogRef<FMDashboardFirstVisitComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data.userRoles) {
      this.roles = {
        keyContact: this.roleIncludes(this.data.userRoles, Role.KeyContact),
        approver: this.roleIncludes(this.data.userRoles, Role.Approver),
        fmTemplateOwner: this.roleIncludes(this.data.userRoles, Role.FMTemplateOwner)
      };
    }
  }

  public roleIncludes(array: Array<Role>, value: number): boolean {
    return array.indexOf(value) > -1;
  }
}
