import { environment as defaultEnvironment } from "@env/environment";

/**
 * custom environment loader, will be used to load environment.json with base_url and other config.
 */
export const environmentLoader: any = new Promise((resolve, reject) => {
  const xmlhttp = new XMLHttpRequest(),
    method = "GET",
    url = "./assets/environment.json";

  xmlhttp.open(method, url, true);

  xmlhttp.onload = function () {
    if (xmlhttp.status === 200) {
      resolve(JSON.parse(xmlhttp.responseText));
    } else {
      resolve(defaultEnvironment);
    }
  };

  xmlhttp.send();
});
