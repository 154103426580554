<div class="profile-section general-section" [ngClass]="{ 'in-dialog': updateUserInfoDialog }">
  <div class="content-box">
    <div *ngIf="!isSetup" class="page-title">{{ "PROFILE.TITLE" | translate }}</div>
    <div *ngIf="isSetup" class="instruction">
      <div class="template-change-help-message">
        <div class="c-font--heavy-bold">{{ "PROFILE.TITLE_SETUP" | translate }}</div>
        <div style="margin-top: 10px">{{ "PROFILE.TITLE_SETUP_INSTRUCTION_KEYCONTACT" | translate }}</div>
      </div>
    </div>
  </div>
  <div *ngIf="user; else loading" class="main-box">
    <form class="custom-form setup-form" [formGroup]="form">
      <div class="control-group">
        <div class="label">{{ "PROFILE.FIRST_NAME" | translate }}</div>
        <div class="control-box">
          <input
            class="input-form"
            type="text"
            id="firstName"
            formControlName="firstName"
            placeholder="first name"
            dddAppCapitalize
            aria-label="first name"
          />
          <ddd-field-error-show
            [displayError]="isFieldValid('firstName')"
            errorMsg="{{ 'PROFILE.MESSAGES.ENTER_FIRST_NAME' | translate }}"
          >
          </ddd-field-error-show>
        </div>
      </div>
      <div class="control-group">
        <div class="label">{{ "PROFILE.LAST_NAME" | translate }}</div>
        <div class="control-box">
          <input
            class="input-form"
            type="text"
            id="lastName"
            formControlName="lastName"
            placeholder="last name"
            dddAppCapitalize
            aria-label="last name"
          />
          <ddd-field-error-show
            [displayError]="isFieldValid('lastName')"
            errorMsg="{{ 'PROFILE.MESSAGES.ENTER_LAST_NAME' | translate }}"
          >
          </ddd-field-error-show>
        </div>
      </div>
      <div class="control-group">
        <div class="label">{{ "PROFILE.EMAIL" | translate }}</div>
        <div class="control-box" matTooltip="{{ 'PROFILE.TOOLTIP.EMAIL' | translate }}" [matTooltipPosition]="'right'">
          <input
            class="input-form"
            type="email"
            id="email"
            formControlName="email"
            placeholder="email"
            aria-label="email"
          />
          <ddd-field-error-show
            [displayError]="isFieldValid('email')"
            errorMsg="{{ 'PROFILE.MESSAGES.ENTER_EMAIL' | translate }}"
          >
          </ddd-field-error-show>
        </div>
      </div>
      <div class="control-group">
        <div class="label" dddLandlineTooltip>
          {{ "PROFILE.LANDLINE" | translate }}
          <div
            class="info-icon"
            matTooltip="{{ 'PROFILE.LANDLINE_PHONE_TOOLTIP' | translate }}"
            [matTooltipPosition]="'right'"
          ></div>
        </div>
        <div class="control-box">
          <div class="phone-number-input-container">
            <div class="c-select">
              <select #countryCodeLandline aria-label="country code">
                <option *ngIf="!landlineCountryAlphaCode" disabled selected value></option>
                <option
                  value="{{ country.alpha2Code }}"
                  *ngFor="let country of countriesSelect"
                  [selected]="country.alpha2Code === landlineCountryAlphaCode"
                >
                  {{ country.name }} ({{ country.phonePrefix }})
                </option>
              </select>
            </div>
            <input
              class="input-form"
              type="text"
              id="landline"
              formControlName="landline"
              placeholder="landline phone"
              (blur)="validateLandline()"
              #landline
              [ngClass]="{ 'empty-error': !validateLandline() }"
              aria-label="landline"
            />
          </div>
          <div class="question-text-error" *ngIf="!validateLandline()">
            {{ "PROFILE.MESSAGES.VALID_LANDLINE" | translate }}
          </div>
          <ddd-field-error-show
            [displayError]="isFieldValid('landline')"
            errorMsg="{{ 'PROFILE.MESSAGES.ENTER_LANDLINE' | translate }}"
          >
          </ddd-field-error-show>
        </div>
      </div>
      <div class="control-group">
        <div class="label">{{ "PROFILE.PHONE" | translate }}</div>
        <div class="control-box">
          <div class="phone-number-input-container">
            <div class="c-select">
              <select #mobilePhoneCountryCode aria-label="country code">
                <option *ngIf="!phoneCountryAlphaCode" disabled selected value></option>
                <option
                  value="{{ country.alpha2Code }}"
                  *ngFor="let country of countriesSelect"
                  [selected]="country.alpha2Code === phoneCountryAlphaCode"
                >
                  {{ country.name }} ({{ country.phonePrefix }})
                </option>
              </select>
            </div>
            <input
              class="input-form"
              type="text"
              id="phone"
              formControlName="phone"
              placeholder="mobile phone"
              (blur)="validatePhoneNumber()"
              #mobilePhone
              [ngClass]="{ 'empty-error': !validatePhoneNumber() }"
              aria-label="mobile phone"
            />
          </div>
          <div class="question-text-error" *ngIf="!validatePhoneNumber()">
            {{ "PROFILE.MESSAGES.VALID_PHONE" | translate }}
          </div>
        </div>
      </div>
      <div class="control-group">
        <div class="label">{{ "PROFILE.TITLE_ROLE" | translate }}</div>
        <div class="control-box">
          <input
            class="input-form"
            type="text"
            id="titleRole"
            formControlName="titleRole"
            placeholder="Title/Role"
            maxlength="120"
            dddAppCapitalize
            aria-label="Title/Role"
          />
          <ddd-field-error-show
            [displayError]="isFieldValid('titleRole')"
            errorMsg="{{ 'PROFILE.MESSAGES.ENTER_TITLE_ROLE' | translate }}"
          >
          </ddd-field-error-show>
        </div>
      </div>
      <div class="control-group">
        <div class="label">{{ "PROFILE.DEPARTMENT" | translate }}</div>
        <div class="control-box">
          <ng-select
            formControlName="department"
            [multiple]="true"
            [items]="departmentOptions"
            (change)="selectDepartment()"
            placeholder="Click here to select division"
            appendTo="html"
          >
          </ng-select>
          <ddd-field-error-show
            [displayError]="isFieldValid('department')"
            errorMsg="{{ 'PROFILE.MESSAGES.ENTER_DEPARTMENT' | translate }}"
          >
          </ddd-field-error-show>
        </div>
      </div>
      <ng-container *ngIf="showDepartmentComment">
        <div class="control-group">
          <div class="label"></div>
          <div class="control-box">
            <input
              class="input-form"
              type="text"
              id="departmentComment"
              formControlName="departmentComment"
              placeholder="division comment"
              maxlength="120"
              aria-label="division comment"
            />
            <ddd-field-error-show
              [displayError]="isFieldValid('departmentComment')"
              errorMsg="{{ 'PROFILE.MESSAGES.ENTER_DEPARTMENT_COMMENT' | translate }}"
            >
            </ddd-field-error-show>
          </div>
        </div>
      </ng-container>
      <ng-container>
        <div class="control-group">
          <div class="label"></div>
          <div class="control-box">
            <div *ngIf="isSetup" class="term-group">
              <div class="checkbox-box">
                <input
                  formControlName="terms"
                  class="c-checkbox"
                  [ngModel]="correctResponse"
                  type="checkbox"
                />
                <span class="terms">
                  I have read and agree to the
                  <a href="{{ TERMS_AND_CONDITIONS_URL }}" target="_blank" rel="noopener" class="nav-item sub-link">TERMS</a>
                </span>
              </div>
            </div>
            <div class="actions">
              <ng-container *ngIf="isSetup">
                <button *ngIf="!isFMGuest" class="ddd-button" (click)="rejectResponcibility()">
                  {{ "PROFILE.REJECT_RESPONSIBILITY" | translate }}
                </button>
              </ng-container>
              <button class="ddd-button raised" [disabled]="isAdmin" (click)="onSubmit()">
                {{ isSetup ? ("PROFILE.CONFIRM_PROCEED" | translate) : ("PROFILE.UPDATE" | translate) }}
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </form>
  </div>

  <ng-template #loading>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-template>
</div>
