import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { AuthService } from "./auth.service";

import { AUTH_KEY, AuthActionTypes } from "./auth.reducer";
import { CustomAction } from "@app/core/utils/customAction";
import { LocalStorageService } from "@app/core/local-storage/local-storage.service";
import { ActionSetAuthHeader } from "@app/core/auth/auth.reducer";

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions<CustomAction>,
    private localStorageService: LocalStorageService,
    private router: Router,
    private service: AuthService
  ) {}

  /* TODO: not sure if we need login effect, since login functionality is performed by identity
   * server; */
  login = createEffect(
    (): Observable<Action> => {
      return this.actions$.pipe(
        ofType(AuthActionTypes.LOGIN),
        tap((action) => this.localStorageService.setItem(AUTH_KEY, { isAuthenticated: true }))
      );
    },
    { dispatch: false }
  );

  logout = createEffect(
    (): Observable<CustomAction> => {
      return this.actions$.pipe(
        ofType(AuthActionTypes.LOGOUT),
        tap((action) => {
          /* TODO: path should be verified */
          this.router.navigate([""]);
          this.localStorageService.setItem(AUTH_KEY, { isAuthenticated: false });
        })
      );
    },
    { dispatch: false }
  );

  setAuthHeader = createEffect(
    (): Observable<CustomAction> => {
      return this.actions$.pipe(
        ofType(AuthActionTypes.SET_AUTH_HEADER),
        tap((action: ActionSetAuthHeader) => {
          this.localStorageService.setItem(AUTH_KEY, {
            token: action.payload.token
          });
        })
      );
    },
    { dispatch: false }
  );
}
