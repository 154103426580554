import { Component, OnInit, Inject, ViewChild, ElementRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import {
  Country,
  CountryQuestionGet,
  selectorCountries
} from "@shared/modules/questions-module-shared/components/country-question/reducers";
import { environment } from "@env/environment";
import { PhoneNumberHelper } from "@app/core/utils/phone-number-helper";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AppCapitalizeDirective } from "../../directives/app-capitalize.directive";
import { FieldErrorShowComponent } from "../../components/field-error-show/field-error-show.component";
import { NgIf, NgFor, NgClass } from "@angular/common";

@Component({
    selector: "ddd-reject-responsibility-dialog",
    templateUrl: "./reject-responsibility-dialog.component.html",
    styleUrls: ["./reject-responsibility-dialog.component.scss"],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, FieldErrorShowComponent, AppCapitalizeDirective, MatTooltipModule, NgFor, NgClass]
})
export class RejectResponsibilityDialogComponent implements OnInit {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public keyContactForm: UntypedFormGroup;
  public countriesSelect;
  public landlineCountryAlphaCode;
  public phoneCountryAlphaCode;
  @ViewChild("mobilePhone", { static: true })
  mobilePhone: ElementRef;
  @ViewChild("landline", { static: true })
  landline: ElementRef;
  @ViewChild("mobilePhoneCountryCode", { static: true })
  mobilePhoneCountryCode: ElementRef;
  @ViewChild("countryCodeLandline", { static: true })
  countryCodeLandline: ElementRef;

  constructor(
    private readonly store: Store<any>,
    public dialogRef: MatDialogRef<RejectResponsibilityDialogComponent>,
    public formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.store.dispatch(new CountryQuestionGet({ base_url: environment.client.base_url }));
    this.keyContactForm = this.formBuilder.group({
      firstName: "",
      lastName: "",
      rejectionMessage: [null, Validators.required],
      email: [
        "",
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ],
      landline: [null],
      phone: [null]
    });

    this.store.pipe(select(selectorCountries), takeUntil(this.unsubscribe$)).subscribe((countries: Country[]) => {
      if (countries) {
        this.countriesSelect = countries;
      }
    });
  }
  /**
   * Submit colleague form, will submit if form valid, else will start validating form fields function
   */
  onSubmit() {
    const formData = this.getFormData();
    /**
     * If have not valid phone number stop submit
     */
    if (!this.validatePhoneNumber() || !this.validateLandline()) {
      return false;
    }

    if (this.keyContactForm.getRawValue().rejectionMessage) {
      this.keyContactForm.get("rejectionMessage").setValue(this.keyContactForm.getRawValue().rejectionMessage.trim());
    }

    if (this.keyContactForm.valid) {
      this.dialogRef.close(formData);
    } else if (!this.keyContactForm.valid) {
      this.validateAllFormFields(this.keyContactForm);
    }
  }

  validatePhoneNumber() {
    return PhoneNumberHelper.validatePhoneNumber(this.mobilePhone, this.mobilePhoneCountryCode);
  }

  validateLandline() {
    return PhoneNumberHelper.validatePhoneNumber(this.landline, this.countryCodeLandline);
  }

  /**
   * Get from data
   */
  getFormData() {
    return {
      rejectionMessage: this.keyContactForm.getRawValue().rejectionMessage,
      email: this.keyContactForm.getRawValue().email,
      firstName: this.keyContactForm.getRawValue().firstName,
      lastName: this.keyContactForm.getRawValue().lastName,
      phones: [
        {
          type: "Mobile",
          number: PhoneNumberHelper.getFormattedPhoneNumber(this.mobilePhone, this.mobilePhoneCountryCode)
        },
        {
          type: "Landline",
          number: PhoneNumberHelper.getFormattedPhoneNumber(this.landline, this.countryCodeLandline)
        }
      ]
    };
  }

  isFieldValid(field: string) {
    return !this.keyContactForm.get(field).valid && this.keyContactForm.get(field).touched;
  }

  /**
   * Validate form fields function, mark fields as touched
   */
  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
