import { Injectable } from "@angular/core";

@Injectable()
export class SavedFiltersService {
  private filters = {
    page: 0,
    size: 20,
    searchData: {
      search: {
        name: "",
        status: "",
        country: "",
        managementRegion: "",
        approver: "",
        onlyPendingApprovalActions: false,
        onlyClarificationResponses: false
      },
      dsc: false,
      sort: ""
    }
  };

  getFilters(page, size, searchData) {
    this.filters.page = page;
    this.filters.size = size;
    this.filters.searchData = searchData.value;
  }

  returnFilters() {
    return this.filters;
  }
}
