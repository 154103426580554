import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, map, switchMap } from "rxjs/operators";

import { LocalStorageService } from "@app/core/local-storage/local-storage.service";
import { CountryQuestionsService } from "@shared/modules/questions-module-shared/components/country-question/services";
import {
  CountryQuestionActionTypes,
  CountryQuestionGet,
  CountryQuestionGetError,
  CountryQuestionGetSuccess
} from "@shared/modules/questions-module-shared/components/country-question/reducers";
export const COUNTRIES_KEY = "COUNTRIES.LIST";

/**
 * Effects for country question.
 * @export
 * @class ChoiceEffect
 */
@Injectable()
export class CountriesEffect {
  constructor(
    private actions$: Actions<Action>,
    private localStorageService: LocalStorageService,
    private service: CountryQuestionsService
  ) {}

  /**
   * When COUNTRY_QUESTION_GET action is dispatched, call the service to retrieve countries for select.
   * @memberof ChoiceEffect
   */
  retrieveCountries = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(CountryQuestionActionTypes.COUNTRY_QUESTION_GET),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: CountryQuestionGet) =>
        this.service.getCountries(action.payload.base_url).pipe(
          map((countries) => {
            this.localStorageService.setItem(COUNTRIES_KEY, countries);
            return new CountryQuestionGetSuccess({ countries });
          }),
          catchError((error) => of(new CountryQuestionGetError({ error })))
        )
      )
    );
  });
}
