import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import {
  ActionToastrError,
  ActionToastrSuccess,
  ActionToastrWarning,
  ActionToastrInfo,
  ToastrActionTypes,
  toastrSuccessConfig,
  toastrErrorConfig,
  toastrWarningConfig,
  toastrInfoConfig
} from "@app/core/toastr/toastr.reducer";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class ToastrEffects {
  constructor(private actions$: Actions<Action>, private toastr: ToastrService) {}

  showSuccess = createEffect(
    (): Observable<Action> => {
      return this.actions$.pipe(
        ofType(ToastrActionTypes.SHOW_SUCCESS),
        tap((action: ActionToastrSuccess) => this.toastr.success("", action.payload.message, toastrSuccessConfig))
      );
    },
    { dispatch: false }
  );

  showError = createEffect(
    (): Observable<Action> => {
      return this.actions$.pipe(
        ofType(ToastrActionTypes.SHOW_ERROR),
        tap((action: ActionToastrError) => {
          this.toastr.error("", action.payload.error.message, toastrErrorConfig);
        })
      );
    },
    { dispatch: false }
  );

  showWarning = createEffect(
    (): Observable<Action> => {
      return this.actions$.pipe(
        ofType(ToastrActionTypes.SHOW_WARNING),
        tap((action: ActionToastrWarning) => this.toastr.warning("", action.payload.message, toastrWarningConfig))
      );
    },
    { dispatch: false }
  );

  showInfo = createEffect(
    (): Observable<Action> => {
      return this.actions$.pipe(
        ofType(ToastrActionTypes.SHOW_INFO),
        tap((action: ActionToastrInfo) => this.toastr.info("", action.payload.isExportInfoMessage ? "Your file is being exported. This might take a few minutes." : action.payload.message, toastrInfoConfig))
      );
    },
    { dispatch: false }
  );
}
