import { Action } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";

export const AUTH_KEY = "AUTH";

export enum AuthActionTypes {
  LOGIN = "[Auth] Login",
  LOGOUT = "[Auth] Logout",
  SET_AUTH_HEADER = "[Set_Auth_Header] Set Auth Header"
}

export class ActionAuthLogin implements Action {
  readonly type = AuthActionTypes.LOGIN;
  constructor(public payload?: any) {}
}

export class ActionAuthLogout implements Action {
  readonly type = AuthActionTypes.LOGOUT;
  constructor(public payload?: any) {}
}

export class ActionSetAuthHeader implements Action {
  constructor(public payload?: { token: string }) {}
  readonly type = AuthActionTypes.SET_AUTH_HEADER;
}

export type AuthActions = ActionAuthLogin | ActionAuthLogout | ActionSetAuthHeader;

export const initialState: AuthState = {
  isAuthenticated: false
};

export const selectorAuth = (state) => state.auth;

export function authReducer(state: AuthState = initialState, action: AuthActions): AuthState {
  switch (action.type) {
    case AuthActionTypes.LOGIN:
      return { ...state, isAuthenticated: true };

    case AuthActionTypes.LOGOUT:
      return { ...state, isAuthenticated: false };
    default:
      return state;
  }
}

export interface AuthState {
  isAuthenticated: boolean;
  error?: HttpErrorResponse;
}
