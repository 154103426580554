import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "@env/environment";
import { Store } from "@ngrx/store";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { LocalStorageService } from "@app/core/local-storage/local-storage.service";

import { COUNTRIES_KEY } from "@shared/modules/questions-module-shared/components/country-question/reducers";

// const API_URL = environment.client.base_url;

/**
 * A country question service
 */
@Injectable()
export class CountryQuestionsService {
  private API_URL = environment.client.base_url;
  constructor(
    private localStorageService: LocalStorageService,
    private readonly store: Store<any>,
    private httpClient: HttpClient
  ) {}

  /**
   * Get countries list, from local storage or server
   */
  getCountries(baseUrl: string): Observable<any> {
    const countriesFromLocalStorage = this.localStorageService.getItem(COUNTRIES_KEY);
    /**
     * Check if countries list present with key parameter
     * Otherwise: get correct countries list from server.
     */
    if (countriesFromLocalStorage && countriesFromLocalStorage[0].key) {
      return of(this.localStorageService.getItem(COUNTRIES_KEY).map((countries) => countries));
    } else {
      return this.httpClient
        .get((baseUrl ? baseUrl : this.API_URL) + "/api/country", {
          responseType: "text"
        })
        .pipe(map((res: string) => JSON.parse(res)));
    }
  }
}
