import { HttpErrorResponse } from "@angular/common/http";
import { Action, createFeatureSelector, createSelector } from "@ngrx/store";
import * as _ from "lodash";

import {
  IDDQDocument,
  IDDQGroup,
  IDDQSection,
  IGroupReview,
  IQuestionClarificationsModel
} from "@shared/models/ddq-document.interfaces";
import { Question, IAuditTrail } from "@shared/models/questionnaire.interfaces";

/**
 * FM review action types
 */
export enum FMReviewActionTypes {
  DDQ_GET_REVIEW_DATA = "[FMReview] Get",
  DDQ_GET_REVIEW_DATA_SUCCESS = "[FMReview] Get Success",
  DDQ_GET_REVIEW_DATA_ERROR = "[FMReview] Get Error",
  DDQ_GET_REVIEW_DOCUMENTS = "[FMReview] Get Review Documents",
  DDQ_GET_REVIEW_DOCUMENTS_SUCCESS = "[FMReview] Get Review Documents Success",
  DDQ_GET_REVIEW_DOCUMENTS_ERROR = "[FMReview] Get Review Documents Error",
  DDQ_REVIEW_UPDATE_FILTER_MESSAGE = "[FMReview] Update Filter Message",
  DDQ_REVIEW_UPDATE_SECTION_RATING = "[FMReview] Update Section Rating",
  DDQ_REVIEW_UPDATE_GROUP_RATING = "[FMReview] Update Group Rating",
  DDQ_REVIEW_UPDATE_QUESTION_RATING = "[FMReview] Update Question Rating",
  DDQ_REVIEW_UPDATE_OVERALL_RATING = "[FMReview] Update Overall Rating",
  DDQ_REVIEW_UPDATE_SECTION_APPROVER_DOCUMENTS = "[FMReview] Update Section Approver Documents",
  DDQ_REVIEW_UPDATE_SECTION_DOCUMENTS = "[FMReview] Update Section Documents",
  DDQ_REVIEW_UPDATE_GROUP_DOCUMENTS = "[FMReview] Update Group Documents",
  DDQ_REVIEW_UPDATE_QUESTION_ANSWER = "[FMReview] Update Question Answer",
  DDQ_REVIEW_UPDATE_QUESTION_DOCUMENTS = "[FMReview] Update Question Documents",
  DDQ_REVIEW_UPDATE_OVERALL_DOCUMENTS = "[FMReview] Update Overall Documents",
  DDQ_REVIEW_EXPORT_PDF = "[FMReview] Export PDF",
  DDQ_REVIEW_EXPORT_PDF_SUCCESS = "[FMReview] Export PDF Success",
  DDQ_REVIEW_EXPORT_PDF_ERROR = "[FMReview] Export PDF Error",
  DDQ_REVIEW_GET_HISTORY_ACTIONS = "[FMReview] Get History Actions",
  DDQ_UPDATE_SUM_QUESTIONS_RATINGS = "[FMReview] Update Sum Questions Ratings"
}

export class DDQReviewDataGet implements Action {
  readonly type = FMReviewActionTypes.DDQ_GET_REVIEW_DATA;
  constructor(public payload: { documentId: string }) {}
}

export class DDQReviewDataGetSuccess implements Action {
  readonly type = FMReviewActionTypes.DDQ_GET_REVIEW_DATA_SUCCESS;
  constructor(public payload: { ddqReviewData: IDDQDocument }) {}
}

export class DDQReviewDataGetError implements Action {
  readonly type = FMReviewActionTypes.DDQ_GET_REVIEW_DATA_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class DDQReviewDocumentsGet implements Action {
  readonly type = FMReviewActionTypes.DDQ_GET_REVIEW_DOCUMENTS;
  constructor(public payload?: {}) {}
}

export class DDQReviewDocumentsGetSuccess implements Action {
  readonly type = FMReviewActionTypes.DDQ_GET_REVIEW_DOCUMENTS_SUCCESS;
  constructor(public payload: { reviewDocumentsList: any[] }) {}
}

export class DDQReviewDocumentsGetError implements Action {
  readonly type = FMReviewActionTypes.DDQ_GET_REVIEW_DOCUMENTS_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class DDQReviewUpdateFilterMessage implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_FILTER_MESSAGE;
  constructor(public payload: { filterMessage: boolean }) {}
}

export class DDQReviewUpdateSectionRating implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_SECTION_RATING;
  constructor(public payload: { rating: string; sectionId: string }) {}
}

export class DDQReviewUpdateGroupRating implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_GROUP_RATING;
  constructor(public payload: { rating: string; groupId: string; sectionId: string }) {}
}

export class DDQReviewUpdateQuestionRating implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_QUESTION_RATING;
  constructor(
    public payload: {
      rating: string;
      questionKey: string;
      groupId: string;
      sectionId: string;
    }
  ) {}
}

export class DDQReviewUpdateOverallRating implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_OVERALL_RATING;
  constructor(public payload: { rating: string }) {}
}

export class DDQReviewUpdateSectionApproverDocuments implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_SECTION_APPROVER_DOCUMENTS;
  constructor(
    public payload: {
      sectionId: string;
      answer?: any;
      deleteIndex?: number;
    }
  ) {}
}

export class DDQReviewUpdateSectionDocuments implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_SECTION_DOCUMENTS;
  constructor(
    public payload: {
      sectionId: string;
      answer?: any;
      deleteIndex?: number;
    }
  ) {}
}

export class DDQReviewUpdateGroupDocuments implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_GROUP_DOCUMENTS;
  constructor(
    public payload: {
      groupId: string;
      answer?: any;
      deleteIndex?: number;
    }
  ) {}
}

export class DDQReviewUpdateQuestionAnswer implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_QUESTION_ANSWER;
  constructor(
    public payload: {
      sectionId: string;
      questionKey: string;
      answer: string;
    }
  ) {}
}

export class DDQReviewUpdateQuestionDocuments implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_QUESTION_DOCUMENTS;
  constructor(
    public payload: {
      sectionId: string;
      questionKey: string;
      answer?: any;
      deleteIndex?: number;
    }
  ) {}
}

export class DDQReviewUpdateOverallDocuments implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_OVERALL_DOCUMENTS;
  constructor(
    public payload: {
      answer?: any;
      deleteIndex?: number;
    }
  ) {}
}

export class DDQReviewGuestExportPdfRetrieve implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_EXPORT_PDF;
  constructor(
    public payload?: {
      password: string;
      docName: string;
      distributorId: string;
      includeExtraData: boolean;
      responseId: string;
    }
  ) {}
}

export class DDQReviewGuestExportPdfRetrieveSuccess implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_EXPORT_PDF_SUCCESS;
  constructor(public payload: { pdf: any }) {}
}

export class DDQReviewGuestExportPdfRetrieveError implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_EXPORT_PDF_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class DDQReviewGetHistoryActions implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_GET_HISTORY_ACTIONS;
  public constructor(
    public payload: {
      section?: IDDQSection;
      questionnaireId: string;
    }
  ) {}
}

export class DDQUpdateSumQuestionsRating implements Action {
  readonly type = FMReviewActionTypes.DDQ_UPDATE_SUM_QUESTIONS_RATINGS;
  constructor(
    public payload: {
      ratingType: string;
      addAction: boolean;
      groupId: string;
      sectionId: string;
    }
  ) {}
}

/**
 * FM review actions.
 */
export type FMReviewActions =
  | DDQReviewDataGet
  | DDQReviewDataGetSuccess
  | DDQReviewDataGetError
  | DDQReviewUpdateFilterMessage
  | DDQReviewUpdateSectionRating
  | DDQReviewUpdateGroupRating
  | DDQReviewUpdateOverallRating
  | DDQReviewUpdateQuestionRating
  | DDQReviewUpdateSectionApproverDocuments
  | DDQReviewUpdateSectionDocuments
  | DDQReviewUpdateGroupDocuments
  | DDQReviewUpdateQuestionDocuments
  | DDQReviewUpdateQuestionAnswer
  | DDQReviewUpdateOverallDocuments
  | DDQReviewGuestExportPdfRetrieve
  | DDQReviewGuestExportPdfRetrieveSuccess
  | DDQReviewGuestExportPdfRetrieveError
  | DDQReviewGetHistoryActions
  | DDQUpdateSumQuestionsRating
  | DDQReviewDocumentsGet
  | DDQReviewDocumentsGetSuccess
  | DDQReviewDocumentsGetError;

/**
 * FM review initial state
 */
export const initialState: IFMReviewState = {
  preloadOptionsGroup: null,
  ddqReviewData: null,
  loading: true,
  filterMessage: false,
  ddqReviewDocuments: null
};

const featureReviewFMSelector = createFeatureSelector<any>("reviewFMState");
export const selectorReviewFMState = createSelector(featureReviewFMSelector, (state) => {
  if (state) {
    return state.reviewFM;
  }
});

export const selectorDDQReviewData = createSelector(selectorReviewFMState, (state) => {
  if (state) {
    return state.ddqReviewData;
  }
});

export const selectorDDQReviewPDF = createSelector(selectorReviewFMState, (state) => {
  if (state) {
    return state.pdf;
  }
});

export const selectorDDQReviewPreloadOptions = createSelector(selectorReviewFMState, (state) => {
  if (state) {
    return state.preloadOptionsGroup;
  }
});

export const selectorDDQReviewClarifications = createSelector(selectorReviewFMState, (state) => {
  if (state) {
    return state.clarifications;
  }
});

export const selectorLoading = createSelector(selectorReviewFMState, (state) => {
  if (state) {
    return state.loading;
  }
});

export const selectorDDQReviewDocuments = createSelector(selectorReviewFMState, (state) => {
  if (state) {
    return state.ddqReviewDocuments;
  }
});

/**
 * Reducer function for fm review state.
 */
export function fmReviewReducer(state: IFMReviewState = initialState, action: FMReviewActions): IFMReviewState {
  switch (action.type) {
    case FMReviewActionTypes.DDQ_GET_REVIEW_DATA:
      return {
        ...state,
        loading: true,
        ddqReviewData: null,
        error: null
      };
    case FMReviewActionTypes.DDQ_GET_REVIEW_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...action.payload.ddqReviewData,
          approveOverallRating:
            action.payload.ddqReviewData && action.payload.ddqReviewData.approval.length !== 0
              ? action.payload.ddqReviewData.approval[0].rating
              : "None",
          approveOverallDocuments:
            action.payload.ddqReviewData && action.payload.ddqReviewData.approval.length !== 0
              ? action.payload.ddqReviewData.approval[0].approveDocuments
              : [],
          approveOverallComment:
            action.payload.ddqReviewData && action.payload.ddqReviewData.approval.length !== 0
              ? action.payload.ddqReviewData.approval[0].approverComment
              : "",
          sections: action.payload.ddqReviewData.sections.map((section: IDDQSection) => {
            const sectionRating = section.reviews.length === 0 ? "None" : section.reviews[0].rating;
            const sectionApprove =
              section.reviews.length !== 0 && section.reviews[0].approvals.length !== 0
                ? section.reviews[0].approvals[0]
                : false;
            const sumQuestionRatingsSection =
              section.reviews.length === 0
                ? {
                    none: 0,
                    query: 0,
                    satisfactory: 0,
                    unsatisfactory: 0
                  }
                : section.reviews[0].questionRatings;
            if (section.isCustom) {
              return {
                ...section,
                approveDocuments: sectionApprove ? sectionApprove.approveDocuments : [],
                reviewComment: section.reviews.length === 0 ? "" : section.reviews[0].reviewerComment
              };
            }
            return {
              ...section,
              status: section.reviews.length === 0 ? "Draft" : section.reviews[0].status,
              reviewRating: sectionApprove && sectionApprove.rating !== "None" ? sectionApprove.rating : sectionRating,
              reviewDocuments: section.reviews.length === 0 ? [] : section.reviews[0].reviewDocuments,
              approveDocuments: sectionApprove ? sectionApprove.approveDocuments : [],
              reviewComment: section.reviews.length === 0 ? "" : section.reviews[0].reviewerComment,
              sumQuestionRatings: sumQuestionRatingsSection,
              groups: section.groups.map((group: IDDQGroup) => {
                const sumQuestionRatingsGroup = {
                  none: 0,
                  query: 0,
                  satisfactory: 0,
                  unsatisfactory: 0
                };
                if (section.reviews.length !== 0 && section.reviews[0].groupReviews.length !== 0) {
                  let groupComment = "";
                  let groupRating = "None";
                  let groupDocuments = [];
                  let sumQuestionRatingsGroup = {
                    none: 0,
                    query: 0,
                    satisfactory: 0,
                    unsatisfactory: 0
                  };
                  section.reviews[0].groupReviews.forEach((groupReview: IGroupReview) => {
                    if (group.id === groupReview.groupId) {
                      groupComment = groupReview.reviewerComment;
                      groupRating = groupReview.rating;
                      groupDocuments = groupReview.reviewDocuments;
                      sumQuestionRatingsGroup = groupReview.questionRatings;
                    }
                  });
                  return {
                    ...group,
                    reviewDocuments: groupDocuments,
                    reviewRating: groupRating,
                    reviewComment: groupComment,
                    sumQuestionRatings: sumQuestionRatingsGroup
                  };
                } else {
                  return {
                    ...group,
                    questions: group.questions.map((question: Question) => {
                      return {
                        ...question,
                        reviewRating: "None"
                      };
                    }),
                    reviewDocuments: [],
                    reviewRating: "None",
                    reviewComment: "",
                    sumQuestionRatings: sumQuestionRatingsGroup
                  };
                }
              })
            };
          })
        },
        error: null
      };
    case FMReviewActionTypes.DDQ_GET_REVIEW_DATA_ERROR:
      return {
        ...state,
        loading: false,
        ddqReviewData: null,
        error: action.payload.error
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_FILTER_MESSAGE:
      return {
        ...state,
        loading: false,
        filterMessage: action.payload.filterMessage
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_SECTION_RATING:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            const sectionRating = action.payload.rating;
            return {
              ...section,
              sectionDirty: true,
              reviewRating: sectionRating !== section.reviewRating ? sectionRating : "None"
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_GROUP_RATING:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            return {
              ...section,
              groupDirty: true,
              groups: section.groups.map((group: IDDQGroup) => {
                if (group.id !== action.payload.groupId) {
                  return group;
                }
                const groupRating = action.payload.rating;
                return {
                  ...group,
                  dirty: true,
                  reviewRating: groupRating !== group.reviewRating ? groupRating : "None"
                };
              })
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_QUESTION_RATING:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            return {
              ...section,
              groupDirty: true,
              groups: section.groups.map((group: IDDQGroup) => {
                if (group.id !== action.payload.groupId) {
                  return group;
                }
                return {
                  ...group,
                  dirty: true,
                  questions: group.questions.map((question: Question) => {
                    if (question.key !== action.payload.questionKey) {
                      return question;
                    }
                    const questionRating = action.payload.rating;
                    return {
                      ...question,
                      reviewRating: questionRating !== question.reviewRating ? questionRating : "None"
                    };
                  })
                };
              })
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_SECTION_APPROVER_DOCUMENTS:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            return {
              ...section,
              approveDocuments:
                action.payload.deleteIndex !== undefined
                  ? [...section.approveDocuments]
                      .slice(0, action.payload.deleteIndex)
                      .concat([...section.approveDocuments].slice(action.payload.deleteIndex + 1))
                  : Object.keys(action.payload.answer).length === 0 && action.payload.answer.constructor === Object
                  ? []
                  : section.approveDocuments.concat(action.payload.answer)
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_SECTION_DOCUMENTS:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            return {
              ...section,
              sectionDirty: true,
              reviewDocuments:
                action.payload.deleteIndex !== undefined
                  ? [...section.reviewDocuments]
                      .slice(0, action.payload.deleteIndex)
                      .concat([...section.reviewDocuments].slice(action.payload.deleteIndex + 1))
                  : Object.keys(action.payload.answer).length === 0 && action.payload.answer.constructor === Object
                  ? []
                  : section.reviewDocuments.concat(action.payload.answer)
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_GROUP_DOCUMENTS:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            return {
              ...section,
              groupDirty: true,
              groups: section.groups.map((group: IDDQGroup) => {
                if (group.id !== action.payload.groupId) {
                  return group;
                }
                return {
                  ...group,
                  dirty: true,
                  reviewDocuments:
                    action.payload.deleteIndex !== undefined
                      ? [...group.reviewDocuments]
                          .slice(0, action.payload.deleteIndex)
                          .concat([...group.reviewDocuments].slice(action.payload.deleteIndex + 1))
                      : Object.keys(action.payload.answer).length === 0 && action.payload.answer.constructor === Object
                      ? []
                      : group.reviewDocuments.concat(action.payload.answer)
                };
              })
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_QUESTION_DOCUMENTS:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            return {
              ...section,
              sectionDirty: true,
              groups: section.groups.map((group: IDDQGroup) => {
                return {
                  ...group,
                  questions: group.questions.map((question: Question) => {
                    if (question.key !== action.payload.questionKey) {
                      return question;
                    }
                    return {
                      ...question,
                      answer: {
                        ...question.answer,
                        documents:
                          action.payload.deleteIndex !== undefined
                            ? [...question.answer.documents]
                                .slice(0, action.payload.deleteIndex)
                                .concat([...question.answer.documents].slice(action.payload.deleteIndex + 1))
                            : Object.keys(action.payload.answer).length === 0 &&
                              action.payload.answer.constructor === Object
                            ? []
                            : question.answer.documents.concat(action.payload.answer)
                      }
                    };
                  })
                };
              })
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_OVERALL_DOCUMENTS:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          approveOverallDocuments:
            action.payload.deleteIndex !== undefined
              ? [...state.ddqReviewData.approveOverallDocuments]
                  .slice(0, action.payload.deleteIndex)
                  .concat([...state.ddqReviewData.approveOverallDocuments].slice(action.payload.deleteIndex + 1))
              : Object.keys(action.payload.answer).length === 0 && action.payload.answer.constructor === Object
              ? []
              : state.ddqReviewData.approveOverallDocuments.concat(action.payload.answer)
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_QUESTION_ANSWER:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: true,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            return {
              ...section,
              sectionDirty: true,
              groups: section.groups.map((group: IDDQGroup) => {
                return {
                  ...group,
                  questions: group.questions.map((question: Question) => {
                    if (question.key !== action.payload.questionKey) {
                      return question;
                    }
                    return {
                      ...question,
                      answer: {
                        ...question.answer,
                        answer: action.payload.answer,
                        empty: action.payload.answer === ""
                      }
                    };
                  })
                };
              })
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_EXPORT_PDF:
      return {
        ...state,
        loading: true,
        pdf: null,
        error: null
      };

    case FMReviewActionTypes.DDQ_REVIEW_EXPORT_PDF_SUCCESS:
      return {
        ...state,
        loading: false,
        pdf: action.payload.pdf,
        error: null
      };

    case FMReviewActionTypes.DDQ_REVIEW_EXPORT_PDF_ERROR:
      return {
        ...state,
        loading: true,
        pdf: null,
        error: action.payload.error
      };

    case FMReviewActionTypes.DDQ_UPDATE_SUM_QUESTIONS_RATINGS:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            return {
              ...section,
              sumQuestionRatings: {
                ...section.sumQuestionRatings,
                query:
                  action.payload.ratingType === "Query"
                    ? action.payload.addAction
                      ? section.sumQuestionRatings.query + 1
                      : section.sumQuestionRatings.query - 1
                    : section.sumQuestionRatings.query,
                satisfactory:
                  action.payload.ratingType === "Satisfactory"
                    ? action.payload.addAction
                      ? section.sumQuestionRatings.satisfactory + 1
                      : section.sumQuestionRatings.satisfactory - 1
                    : section.sumQuestionRatings.satisfactory,
                unsatisfactory:
                  action.payload.ratingType === "Unsatisfactory"
                    ? action.payload.addAction
                      ? section.sumQuestionRatings.unsatisfactory + 1
                      : section.sumQuestionRatings.unsatisfactory - 1
                    : section.sumQuestionRatings.unsatisfactory
              },
              groups: section.groups.map((group: IDDQGroup) => {
                if (group.id !== action.payload.groupId) {
                  return group;
                }
                return {
                  ...group,
                  sumQuestionRatings: {
                    ...section.sumQuestionRatings,
                    query:
                      action.payload.ratingType === "Query"
                        ? action.payload.addAction
                          ? group.sumQuestionRatings.query + 1
                          : group.sumQuestionRatings.query - 1
                        : group.sumQuestionRatings.query,
                    satisfactory:
                      action.payload.ratingType === "Satisfactory"
                        ? action.payload.addAction
                          ? group.sumQuestionRatings.satisfactory + 1
                          : group.sumQuestionRatings.satisfactory - 1
                        : group.sumQuestionRatings.satisfactory,
                    unsatisfactory:
                      action.payload.ratingType === "Unsatisfactory"
                        ? action.payload.addAction
                          ? group.sumQuestionRatings.unsatisfactory + 1
                          : group.sumQuestionRatings.unsatisfactory - 1
                        : group.sumQuestionRatings.unsatisfactory
                  }
                };
              })
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_GET_REVIEW_DOCUMENTS:
      return {
        ...state,
        loading: true,
        ddqReviewData: null,
        ddqReviewDocuments: null,
        error: null
      };

    case FMReviewActionTypes.DDQ_GET_REVIEW_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        ddqReviewDocuments: action.payload.reviewDocumentsList,
        error: null
      };

    case FMReviewActionTypes.DDQ_GET_REVIEW_DOCUMENTS_ERROR:
      return {
        ...state,
        loading: false,
        ddqReviewDocuments: null,
        error: action.payload.error
      };

    default:
      return state;
  }
}

/**
 * Interface for fm review state.
 */
export interface IFMReviewState {
  loading: boolean;
  ddqReviewData?: IDDQDocument;
  error?: HttpErrorResponse;
  preloadOptionsGroup?: IDDQGroup;
  filterMessage?: boolean;
  ddqReviewDocuments: any;
  pdf?: any;
  clarifications?: Array<IQuestionClarificationsModel>;
}
