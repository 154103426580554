import { Action, createFeatureSelector, createSelector } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";

import { IDXDataGridFilter, DxDataGridKey } from "@shared/modules/filters-module-shared/model/filters.model";

/**
 * Interface for user state.
 * @export
 * @interface UserState
 */
export interface UserState {
  /**
   * Loading state status.
   *
   * @type {boolean}
   * @memberof UserState
   */
  loading: boolean;

  /**
   * User information
   *
   * @type {IUser}
   * @memberof UserState
   */
  user?: IUser;

  /**
   * Boolean value to check change company status
   *
   * @type {boolean}
   * @memberof UserState
   */
  changeCompanyStatus?: boolean;

  /**
   * Action company link url
   *
   * @type {string}
   * @memberof UserState
   */
  actionCompanyLink?: string;

  /**
   * Action company query parameters
   *
   * @type {{[key: string]: any}}
   * @memberof UserState
   */
  actionCompanyQueryParams?: {[key: string]: any};

  /**
   * Companies list for support module
   *
   * @type {any[]}
   * @memberof UserState
   */
  adminAllCompaniesList?: any[];

  /**
   * Action change password url
   *
   * @type {string}
   * @memberof UserState
   */
  changePasswordUrl?: string;

  /**
   * Error status
   *
   * @type {HttpErrorResponse}
   * @memberof UserState
   */
  error?: HttpErrorResponse;
}

/**
 * User initial state
 */
export const initialState: UserState = {
  loading: false,
  user: null
};

/**
 * Users actions types
 *
 * @export
 * @enum {number}
 */
export enum UserActionTypes {
  USER_RETRIEVE = "[User] Retrieve",
  USER_RETRIEVE_SUCCESS = "[User] Retrieve Success",
  USER_RETRIEVE_ERROR = "[User] Retrieve Error",
  USER_UPDATE = "[User] Update",
  USER_UPDATE_SUCCESS = "[User] Update Success",
  USER_UPDATE_ERROR = "[User] Update Error",
  REJECT_RESPONSIBILITY = "[User] Reject Responsibiblity",
  REJECT_RESPONSIBILITY_SUCCESS = "[User] Rejected Responsibiblity Success",
  USER_APPROVE = "[User] User Approve",
  USER_APPROVE_SUCCESS = "[User] Approve Success",
  USER_APPROVE_ERROR = "[User] Approve Error",
  CHANGE_COMPANY = "[User] Change Company",
  CHANGE_COMPANY_SUCCESS = "[User] Change Company Success",
  CHANGE_COMPANY_ERROR = "[User] Change Company Error",
  CLEAR_USER_STORE = "[User] Clear User and Company Store",
  RESOLVE_GUIDE = "[User] Resolve Guide",
  RESOLVE_GUIDE_SUCCESS = "[User] Resolve Guide Success",
  CLEAR_UPDATED_GUIDES = "[User] Clear Updated Guides",
  USER_UPDATE_DETAILS = "[User] Update Details",
  USER_SET_DIALOG_STATUS = "[User] Set Dialog Status",
  USER_CLEAR_DIALOG_STATUS = "[User] Clear Dialog Status",
  USER_REMOVE_GROUP_AFTER_DOCUMENTS_VERIFY = "[User] Remove Group After Documents Verify",
  SSO_USER_LOGOUT = "[User] SSO User Logout",
  USER_CHANGE_PASSWORD = "[User] change password",
  USER_CHANGE_PASSWORD_SUCCESS = "[User] change password success",
  USER_CHANGE_PASSWORD_ERROR = "[User] change password error",

  ADMIN_GET_COMPANIES = "[Admin] Get companies",
  ADMIN_GET_COMPANIES_SUCCESS = "[Admin] Get companies success",
  ADMIN_GET_COMPANIES_ERROR = "[Admin] Get companies error",
  ADMIN_SUPPORT_SESSION_START = "[Admin] Support session start",
  ADMIN_SUPPORT_SESSION_END = "[Admin] Support session end",
  ADMIN_SUPPORT_SESSION_END_SUCCESS = "[Admin] Support session end success",

  UPDATE_ACTIVE_FILTER = "[UserFilters] Update Active Filter",
  CLEAR_ACTIVE_FILTER = "[UserFilters] Clear Active Filter"
}

/**
 * Store action for retrive user information
 *
 * @export
 * @class ActionUserRetrieve
 * @implements {Action}
 */
export class ActionUserRetrieve implements Action {
  /**
   * Type of action
   *
   * @memberof ActionUserRetrieve
   */
  readonly type = UserActionTypes.USER_RETRIEVE;

  /**
   * Creates an instance of ActionUserRetrieve.
   * @param {{ base_url?: string }} payload
   * @memberof ActionUserRetrieve
   */
  constructor(public payload: { base_url?: string }) {}
}

/**
 * Store action for success retrive user information
 *
 * @export
 * @class ActionUserRetrieveSuccess
 * @implements {Action}
 */
export class ActionUserRetrieveSuccess implements Action {
  /**
   * Type of action
   *
   * @memberof ActionUserRetrieveSuccess
   */
  readonly type = UserActionTypes.USER_RETRIEVE_SUCCESS;

  /**
   * Creates an instance of ActionUserRetrieveSuccess.
   * @param {{ user: IUser }} payload
   * @memberof ActionUserRetrieveSuccess
   */
  constructor(public payload: { user: IUser }) {}
}

/**
 * Store action for error retrive user information
 *
 * @export
 * @class ActionUserRetrieveError
 * @implements {Action}
 */
export class ActionUserRetrieveError implements Action {
  /**
   * Type of action
   *
   * @memberof ActionUserRetrieveError
   */
  readonly type = UserActionTypes.USER_RETRIEVE_ERROR;

  /**
   * Creates an instance of ActionUserRetrieveError.
   * @param {{ error: HttpErrorResponse }} payload
   * @memberof ActionUserRetrieveError
   */
  constructor(public payload: { error: HttpErrorResponse }) {}
}

/**
 * Store action for update user information
 *
 * @export
 * @class ActionUserUpdate
 * @implements {Action}
 */
export class ActionUserUpdate implements Action {
  /**
   * Type of action
   *
   * @memberof ActionUserUpdate
   */
  readonly type = UserActionTypes.USER_UPDATE;

  /**
   * Creates an instance of ActionUserUpdate.
   * @param {{ user: IUser; base_url: string }} payload
   * @memberof ActionUserUpdate
   */
  constructor(public payload: { user: IUser; base_url: string }) {}
}

export class ActionUserApprove implements Action {
  /**
   * Type of action
   *
   * @memberof ActionUserApprove
   */
  readonly type = UserActionTypes.USER_APPROVE;

  /**
   * Creates an instance of ActionUserUpdate.
   * @param {{ user: IUser; base_url: string }} payload
   * @memberof ActionUserUpdate
   */
  constructor(public payload: { user: IUser; base_url: string }) {}
}

/**
 * Store action for success update user information
 *
 * @export
 * @class ActionUserUpdateSuccess
 * @implements {Action}
 */
export class ActionUserUpdateSuccess implements Action {
  /**
   * Type of action
   *
   * @memberof ActionUserUpdateSuccess
   */
  readonly type = UserActionTypes.USER_UPDATE_SUCCESS;

  /**
   * Creates an instance of ActionUserUpdateSuccess.
   * @param {{ user: IUser }} payload
   * @memberof ActionUserUpdateSuccess
   */
  constructor(public payload: { user: IUser }) {}
}

/**
 * Store action for success approve user
 *
 * @export
 * @class ActionUserUpdateSuccess
 * @implements {Action}
 */
export class ActionUserApproveSuccess implements Action {
  /**
   * Type of action
   *
   * @memberof ActionUserApproveSuccess
   */
  readonly type = UserActionTypes.USER_APPROVE_SUCCESS;

  /**
   * Creates an instance of ActionUserUpdateSuccess.
   * @param {{ user: IUser }} payload
   * @memberof ActionUserApproveSuccess
   */
  constructor(public payload: { user: IUser }) {}
}

/**
 * Store action for success reject user
 *
 * @export
 * @class ActionUserUpdateSuccess
 * @implements {Action}
 */
export class ActionRejectResponsibilitySuccess implements Action {
  /**
   * Type of action
   *
   * @memberof ActionRejectResponsibilitySuccess
   */
  readonly type = UserActionTypes.REJECT_RESPONSIBILITY_SUCCESS;

  /**
   * Creates an instance of ActionUserUpdateSuccess.
   * @param {{ user: IUser }} payload
   * @memberof ActionRejectResponsibilitySuccess
   */
  constructor(public payload: { user: IUser }) {}
}

/**
 * Store action for error update user information
 *
 * @export
 * @class ActionUserUpdateError
 * @implements {Action}
 */
export class ActionUserUpdateError implements Action {
  /**
   * Type of action
   *
   * @memberof ActionUserUpdateError
   */
  readonly type = UserActionTypes.USER_UPDATE_ERROR;

  /**
   * Creates an instance of ActionUserUpdateError.
   * @param {{ error: HttpErrorResponse }} payload
   * @memberof ActionUserUpdateError
   */
  constructor(public payload: { error: HttpErrorResponse }) {}
}

/**
 * Store action for change company
 *
 * @export
 * @class ActionChangeCompany
 * @implements {Action}
 */
export class ActionChangeCompany implements Action {
  /**
   * Type of action
   *
   * @memberof ActionChangeCompany
   */
  readonly type = UserActionTypes.CHANGE_COMPANY;

  /**
   * Creates an instance of ActionChangeCompany.
   * @param {{
   *       id: string;
   *       base_url: string;
   *       actionLink?: string;
   *       queryParams?: {[key: string]: any};
   *     }} payload
   * @memberof ActionChangeCompany
   */
  constructor(
    public payload: {
      id: string;
      base_url: string;
      actionLink?: string;
      queryParams?: {[key: string]: any};
    }
  ) {}
}

/**
 * Store action for success change company
 *
 * @export
 * @class ActionChangeCompanySuccess
 * @implements {Action}
 */
export class ActionChangeCompanySuccess implements Action {
  /**
   * Type of action
   *
   * @memberof ActionChangeCompanySuccess
   */
  readonly type = UserActionTypes.CHANGE_COMPANY_SUCCESS;

  /**
   * Creates an instance of ActionChangeCompanySuccess.
   * @param {{
   *       result: boolean;
   *       actionLink?: string;
   *       queryParams?: {[key: string]: any};
   *     }} payload
   * @memberof ActionChangeCompanySuccess
   */
  constructor(
    public payload: {
      result: boolean;
      actionLink?: string;
      queryParams?: {[key: string]: any};
    }
  ) {}
}

/**
 * Store action for error change company
 *
 * @export
 * @class ActionChangeCompanyError
 * @implements {Action}
 */
export class ActionChangeCompanyError implements Action {
  /**
   * Type of action
   *
   * @memberof ActionChangeCompanyError
   */
  readonly type = UserActionTypes.CHANGE_COMPANY_ERROR;

  /**
   * Creates an instance of ActionChangeCompanyError.
   * @param {{ error: HttpErrorResponse }} payload
   * @memberof ActionChangeCompanyError
   */
  constructor(public payload: { error: HttpErrorResponse }) {}
}

/**
 * Store action for clearing action
 *
 * @export
 * @class ActionClear
 * @implements {Action}
 */
export class ActionClear implements Action {
  /**
   * Type of action
   *
   * @memberof ActionClear
   */
  readonly type = UserActionTypes.CLEAR_USER_STORE;

  /**
   * Creates an instance of ActionClear.
   * @memberof ActionClear
   */
  constructor() {}
}

/**
 * Store action for resolve guide
 *
 * @export
 * @class ActionUserResolveGuide
 * @implements {Action}
 */
export class ActionUserResolveGuide implements Action {
  /**
   * Type of action
   *
   * @memberof ActionUserResolveGuide
   */
  readonly type = UserActionTypes.RESOLVE_GUIDE;

  /**
   * Creates an instance of ActionUserResolveGuide.
   * @param {{
   *       guidename: string;
   *       userGuidename: string;
   *       base_url: string;
   *       dialog?: boolean;
   *     }} payload
   * @memberof ActionUserResolveGuide
   */
  constructor(
    public payload: {
      guidename: string;
      userGuidename: string;
      base_url: string;
      dialog?: boolean;
    }
  ) {}
}

/**
 * Store action for success resolve guide
 *
 * @export
 * @class ActionUserResolveGuideSuccess
 * @implements {Action}
 */
export class ActionUserResolveGuideSuccess implements Action {
  /**
   * Type of action
   *
   * @memberof ActionUserResolveGuideSuccess
   */
  readonly type = UserActionTypes.RESOLVE_GUIDE_SUCCESS;

  /**
   * Creates an instance of ActionUserResolveGuideSuccess.
   * @param {{ userGuidename: string }} payload
   * @memberof ActionUserResolveGuideSuccess
   */
  constructor(public payload: { userGuidename: string }) {}
}

/**
 * Store action for retrive companies
 *
 * @export
 * @class ActionUserAdminGetCompanies
 * @implements {Action}
 */
export class ActionUserAdminGetCompanies implements Action {
  /**
   * Type of action
   *
   * @memberof ActionUserAdminGetCompanies
   */
  readonly type = UserActionTypes.ADMIN_GET_COMPANIES;

  /**
   * Creates an instance of ActionUserAdminGetCompanies.
   * @param {{
   *       pagesize: number;
   *       query: string;
   *     }} payload
   * @memberof ActionUserAdminGetCompanies
   */
  constructor(
    public payload: {
      pagesize: number;
      query: string;
    }
  ) {}
}

/**
 * Store action for success retrive companies
 *
 * @export
 * @class ActionUserAdminGetCompaniesSuccess
 * @implements {Action}
 */
export class ActionUserAdminGetCompaniesSuccess implements Action {
  /**
   * Type of action
   *
   * @memberof ActionUserAdminGetCompaniesSuccess
   */
  readonly type = UserActionTypes.ADMIN_GET_COMPANIES_SUCCESS;

  /**
   * Creates an instance of ActionUserAdminGetCompaniesSuccess.
   * @param {{
   *       companies: any[];
   *     }} payload
   * @memberof ActionUserAdminGetCompaniesSuccess
   */
  constructor(
    public payload: {
      companies: any[];
    }
  ) {}
}

/**
 * Store action for error retrive companies
 *
 * @export
 * @class ActionUserAdminGetCompaniesError
 * @implements {Action}
 */
export class ActionUserAdminGetCompaniesError implements Action {
  /**
   * Type of action
   *
   * @memberof ActionUserAdminGetCompaniesError
   */
  readonly type = UserActionTypes.ADMIN_GET_COMPANIES_ERROR;

  /**
   * Creates an instance of ActionUserAdminGetCompaniesError.
   * @param {{ error: HttpErrorResponse }} payload
   * @memberof ActionUserAdminGetCompaniesError
   */
  constructor(public payload: { error: HttpErrorResponse }) {}
}

/**
 * Store action for start admin session
 *
 * @export
 * @class ActionUserAdminSupportSessionStart
 * @implements {Action}
 */
export class ActionUserAdminSupportSessionStart implements Action {
  /**
   * Type of action
   *
   * @memberof ActionUserAdminSupportSessionStart
   */
  readonly type = UserActionTypes.ADMIN_SUPPORT_SESSION_START;

  /**
   * Creates an instance of ActionUserAdminSupportSessionStart.
   * @param {{
   *       companyId: string;
   *       userId: string;
   *     }} payload
   * @memberof ActionUserAdminSupportSessionStart
   */
  constructor(
    public payload: {
      companyId: string;
      userId: string;
    }
  ) {}
}

/**
 * Store action for end admin session
 *
 * @export
 * @class ActionUserAdminSupportSessionEnd
 * @implements {Action}
 */
export class ActionUserAdminSupportSessionEnd implements Action {
  /**
   * Type of action
   *
   * @memberof ActionUserAdminSupportSessionEnd
   */
  readonly type = UserActionTypes.ADMIN_SUPPORT_SESSION_END;

  /**
   * Creates an instance of ActionUserAdminSupportSessionEnd.
   * @memberof ActionUserAdminSupportSessionEnd
   */
  constructor() {}
}

/**
 * Store action for success end admin session
 *
 * @export
 * @class ActionUserAdminSupportSessionEndSuccess
 * @implements {Action}
 */
export class ActionUserAdminSupportSessionEndSuccess implements Action {
  /**
   * Type of action
   *
   * @memberof ActionUserAdminSupportSessionEndSuccess
   */
  readonly type = UserActionTypes.ADMIN_SUPPORT_SESSION_END_SUCCESS;

  /**
   * Creates an instance of ActionUserAdminSupportSessionEndSuccess.
   * @memberof ActionUserAdminSupportSessionEndSuccess
   */
  constructor() {}
}

/**
 * Store action for clear updated guides list
 *
 * @export
 * @class ActionClearUpdatedGuides
 * @implements {Action}
 */
export class ActionClearUpdatedGuides implements Action {
  /**
   * Type of action
   *
   * @memberof ActionClearUpdatedGuides
   */
  readonly type = UserActionTypes.CLEAR_UPDATED_GUIDES;

  /**
   * Creates an instance of ActionClearUpdatedGuides.
   * @param {{ userGuidename: string }} payload
   * @memberof ActionClearUpdatedGuides
   */
  constructor(public payload: { userGuidename: string }) {}
}

/**
 * Store action for update user details
 *
 * @export
 * @class ActionUserUpdateDetails
 * @implements {Action}
 */
export class ActionUserUpdateDetails implements Action {
  /**
   * Type of action
   *
   * @memberof ActionUserUpdateDetails
   */
  readonly type = UserActionTypes.USER_UPDATE_DETAILS;

  /**
   * Creates an instance of ActionUserUpdateDetails.
   * @param {{ user: IUser }} payload
   * @memberof ActionUserUpdateDetails
   */
  constructor(public payload: { user: IUser }) {}
}

/**
 * Store action for set dialog status
 *
 * @export
 * @class ActionSetDialogStatus
 * @implements {Action}
 */
export class ActionSetDialogStatus implements Action {
  /**
   * Type of action
   *
   * @memberof ActionSetDialogStatus
   */
  readonly type = UserActionTypes.USER_SET_DIALOG_STATUS;

  /**
   * Creates an instance of ActionSetDialogStatus.
   * @memberof ActionSetDialogStatus
   */
  constructor() {}
}

/**
 * Store action for clear dialog status
 *
 * @export
 * @class ActionClearDialogStatus
 * @implements {Action}
 */
export class ActionClearDialogStatus implements Action {
  /**
   * Type of action
   *
   * @memberof ActionClearDialogStatus
   */
  readonly type = UserActionTypes.USER_CLEAR_DIALOG_STATUS;

  /**
   * Creates an instance of ActionClearDialogStatus.
   * @memberof ActionClearDialogStatus
   */
  constructor() {}
}

/**
 * Store action for remove group after documents verify from questionnaire
 *
 * @export
 * @class ActionRemoveGroupAfterDocumentsVerify
 * @implements {Action}
 */
export class ActionRemoveGroupAfterDocumentsVerify implements Action {
  /**
   * Type of action
   *
   * @memberof ActionRemoveGroupAfterDocumentsVerify
   */
  readonly type = UserActionTypes.USER_REMOVE_GROUP_AFTER_DOCUMENTS_VERIFY;

  /**
   * Creates an instance of ActionRemoveGroupAfterDocumentsVerify.
   * @param {{ deleteIndex: number }} payload
   * @memberof ActionRemoveGroupAfterDocumentsVerify
   */
  constructor(public payload: { deleteIndex: number }) {}
}

/**
 * Store action for SSO user logout
 *
 * @export
 * @class ActionSsoUserLogout
 * @implements {Action}
 */
export class ActionSsoUserLogout implements Action {
  /**
   * Type of action
   *
   * @memberof ActionSsoUserLogout
   */
  readonly type = UserActionTypes.SSO_USER_LOGOUT;

  /**
   * Creates an instance of ActionSsoUserLogout.
   * @param {{}} payload
   * @memberof ActionSsoUserLogout
   */
  constructor(public payload: {}) {}
}

/**
 * Store action for update user active data grid filter
 *
 * @export
 * @class ActionUpdateActiveFilter
 * @implements {Action}
 */
export class ActionUpdateActiveFilter implements Action {
  /**
   * Type of action
   *
   * @memberof ActionUpdateActiveFilter
   */
  readonly type = UserActionTypes.UPDATE_ACTIVE_FILTER;

  /**
   * Creates an instance of ActionUpdateActiveFilter.
   * @param {{ filterData: IDXDataGridFilter }} payload
   * @memberof ActionUpdateActiveFilter
   */
  constructor(public payload: { filterData: IDXDataGridFilter }) {}
}

/**
 * Store action for update user clear data grid filter
 *
 * @export
 * @class ActionClearActiveFilter
 * @implements {Action}
 */
export class ActionClearActiveFilter implements Action {
  /**
   * Type of action
   *
   * @memberof ActionClearActiveFilter
   */
  readonly type = UserActionTypes.CLEAR_ACTIVE_FILTER;

  /**
   * Creates an instance of ActionClearActiveFilter.
   * @param {{ dxGridKey: DxDataGridKey, withoutReload?: boolean }} payload
   * @memberof ActionClearActiveFilter
   */
  constructor(public payload: { dxGridKey: DxDataGridKey; withoutReload?: boolean }) {}
}

export class ActionRejectResponsibility implements Action {
  readonly type = UserActionTypes.REJECT_RESPONSIBILITY;
  constructor(public payload: { rejectUser: IRejectUser; base_url: string }) {}
}

/**
 * Store action for change password
 *
 * @export
 * @class ActionChangePassword
 * @implements {Action}
 */
 export class ActionChangePassword implements Action {
  /**
   * Type of action
   *
   * @memberof ActionChangePassword
   */
  readonly type = UserActionTypes.USER_CHANGE_PASSWORD;

  /**
   * Creates an instance of ActionChangeCompany.
   *
   * @memberof ActionChangeCompany
   */
  constructor(
    public payload: {}
  ) {}
}

/**
 * Store action for success change password
 *
 * @export
 * @class ActionChangePasswordSuccess
 * @implements {Action}
 */
export class ActionChangePasswordSuccess implements Action {
  /**
   * Type of action
   *
   * @memberof ActionChangePasswordSuccess
   */
  readonly type = UserActionTypes.USER_CHANGE_PASSWORD_SUCCESS;

  /**
   * Creates an instance of ActionChangePasswordSuccess.
   * @param {{
   *       result: string;
   *     }} payload
   * @memberof ActionChangePasswordSuccess
   */
  constructor(
    public payload: {
      result: string;
    }
  ) {}
}

/**
 * Store action for error change password
 *
 * @export
 * @class ActionChangePasswordError
 * @implements {Action}
 */
export class ActionChangePasswordError implements Action {
  /**
   * Type of action
   *
   * @memberof ActionChangePasswordError
   */
  readonly type = UserActionTypes.USER_CHANGE_PASSWORD_ERROR;

  /**
   * Creates an instance of ActionChangePasswordError.
   * @param {{ error: HttpErrorResponse }} payload
   * @memberof ActionChangePasswordError
   */
  constructor(public payload: { error: HttpErrorResponse }) {}
}

/**
 * User actions type.
 */
export type UserActions =
  | ActionUserRetrieve
  | ActionUserRetrieveSuccess
  | ActionUserRetrieveError
  | ActionUserUpdate
  | ActionUserUpdateSuccess
  | ActionUserUpdateError
  | ActionChangeCompany
  | ActionChangeCompanySuccess
  | ActionChangeCompanyError
  | ActionClear
  | ActionUserResolveGuide
  | ActionUserResolveGuideSuccess
  | ActionClearUpdatedGuides
  | ActionUserAdminGetCompanies
  | ActionUserAdminGetCompaniesError
  | ActionUserAdminGetCompaniesSuccess
  | ActionUserAdminSupportSessionStart
  | ActionUserAdminSupportSessionEnd
  | ActionUserAdminSupportSessionEndSuccess
  | ActionUserUpdateDetails
  | ActionSetDialogStatus
  | ActionClearDialogStatus
  | ActionRemoveGroupAfterDocumentsVerify
  | ActionUpdateActiveFilter
  | ActionClearActiveFilter
  | ActionRejectResponsibility
  | ActionRejectResponsibilitySuccess
  | ActionUserApprove
  | ActionUserApproveSuccess
  | ActionChangePassword
  | ActionChangePasswordSuccess
  | ActionChangePasswordError;

/**
 * User state selector
 */
export const selectorUserState = (state) => state && state.userState;

/**
 * User feature selector
 */
const featureUserStateSelector = createFeatureSelector<UserState>("userState");

/**
 * User data selector
 */
export const selectorUser = createSelector(featureUserStateSelector, (state) => (state && state.user) || null);

/**
 * User state error
 */
export const selectorError = (state) => state && state.userState.error;

/**
 * Companies list data selector
 */
export const selectorAdminAllCompaniesList = (state) =>
  (!!state && !!state.userState && state.userState.adminAllCompaniesList) || [];

/**
 * Reducer function for user state.
 *
 * @export
 * @param {UserState} [state=initialState]
 * @param {UserActions} action
 * @return {*}  {UserState}
 */
export function userReducer(state: UserState = initialState, action: UserActions): UserState {
  switch (action.type) {
    case UserActionTypes.USER_RETRIEVE:
      return {
        ...state,
        loading: true,
        changeCompanyStatus: null,
        actionCompanyLink: null,
        actionCompanyQueryParams: null,
        error: null
      };
    case UserActionTypes.USER_RETRIEVE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: {
          ...action.payload.user,
          platformTitle: action.payload.user.brand === "KYD" ? action.payload.user.brand : "Distributor Due Diligence platform",
          businessTitle: action.payload.user.brand === "KYD" ? action.payload.user.brand : "Carne",
          guidesUpdated: [],
          dxDataGridFilters: action.payload.user.dxDataGridFilters ? action.payload.user.dxDataGridFilters : []
        },
        error: null
      };
    case UserActionTypes.USER_RETRIEVE_ERROR:
      return {
        ...state,
        loading: false,
        user: null,
        error: action.payload.error
      };
    case UserActionTypes.USER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        changeCompanyStatus: null,
        actionCompanyLink: null,
        actionCompanyQueryParams: null,
        user: {
          ...action.payload.user,
          guidesUpdated: [],
          guides: state && state.user.guides
        },
        error: null
      };
    case UserActionTypes.CHANGE_COMPANY:
      return {
        ...state,
        loading: false,
        changeCompanyStatus: null,
        actionCompanyLink: null,
        actionCompanyQueryParams: null,
        error: null
      };
    case UserActionTypes.CHANGE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        changeCompanyStatus: action.payload.result,
        actionCompanyLink: action.payload.actionLink,
        actionCompanyQueryParams: action.payload.queryParams,
        error: null
      };
    case UserActionTypes.CHANGE_COMPANY_ERROR:
      return {
        ...state,
        loading: false,
        changeCompanyStatus: null,
        error: action.payload.error
      };
    case UserActionTypes.CLEAR_USER_STORE:
      return {
        ...state,
        loading: false,
        changeCompanyStatus: null,
        user: null,
        actionCompanyLink: null,
        actionCompanyQueryParams: null
      };
    case UserActionTypes.ADMIN_GET_COMPANIES_SUCCESS:
      return {
        ...state,
        adminAllCompaniesList: action.payload.companies
      };
    case UserActionTypes.ADMIN_GET_COMPANIES_ERROR:
      return {
        ...state,
        adminAllCompaniesList: null,
        error: action.payload.error
      };
    case UserActionTypes.RESOLVE_GUIDE_SUCCESS:
      return {
        ...state,
        user: state.user
          ? {
              ...state.user,
              guidesUpdated: state.user && state.user.guidesUpdated.concat([action.payload.userGuidename])
            }
          : null
      };
    case UserActionTypes.CLEAR_UPDATED_GUIDES:
      return {
        ...state,
        user:
          state && state.user
            ? {
                ...state.user,
                guides: {
                  ...state.user.guides,
                  [action.payload.userGuidename]: true
                },
                guidesUpdated: []
              }
            : null
      };

    case UserActionTypes.USER_UPDATE_DETAILS:
      return {
        ...state,
        loading: false,
        changeCompanyStatus: null,
        actionCompanyLink: null,
        actionCompanyQueryParams: null,
        user: {
          ...state.user,
          firstName: action.payload && action.payload.user.firstName,
          lastName: action.payload && action.payload.user.lastName,
          phones: action.payload && action.payload.user.phones
        },
        error: null
      };

    case UserActionTypes.USER_SET_DIALOG_STATUS:
      return {
        ...state,
        user: {
          ...state.user,
          needShowReleaseDialog: true
        }
      };

    case UserActionTypes.USER_CLEAR_DIALOG_STATUS:
      return {
        ...state,
        user: {
          ...state.user,
          needShowReleaseDialog: false
        }
      };

    case UserActionTypes.USER_REMOVE_GROUP_AFTER_DOCUMENTS_VERIFY:
      return {
        ...state,
        user:
          state && state.user
            ? {
                ...state.user,
                guides: {
                  ...state.user.guides,
                  documentGuideGroupIds: [...state.user.guides.documentGuideGroupIds]
                    .slice(0, action.payload.deleteIndex)
                    .concat([...state.user.guides.documentGuideGroupIds].slice(action.payload.deleteIndex + 1))
                }
              }
            : null
      };

    case UserActionTypes.UPDATE_ACTIVE_FILTER:
      let isDataGridFilterExist = false;
      state.user?.dxDataGridFilters.forEach((userFilter: IUserDxDataGridFilter) => {
        if (userFilter.key === action.payload.filterData.dxGridKey) {
          isDataGridFilterExist = true;
        }
      });
      return {
        ...state,
        user: {
          ...state.user,
          dxDataGridFilters: isDataGridFilterExist
            ? state.user.dxDataGridFilters.map((userFilter) => {
                if (userFilter.key !== action.payload.filterData.dxGridKey) {
                  return userFilter;
                }
                return {
                  ...userFilter,
                  value: action.payload.filterData.id
                };
              })
            : state.user.dxDataGridFilters.concat([
                {
                  key: action.payload.filterData.dxGridKey,
                  value: action.payload.filterData.id
                }
              ])
        }
      };

    case UserActionTypes.CLEAR_ACTIVE_FILTER:
      let deleteIndex: number;
      state.user?.dxDataGridFilters.forEach((userFilter: IUserDxDataGridFilter, index) => {
        if (userFilter.key === action.payload.dxGridKey) {
          deleteIndex = index;
        }
      });
      return {
        ...state,
        user: {
          ...state.user,
          dxDataGridFilters: [...state.user.dxDataGridFilters]
            .slice(0, deleteIndex)
            .concat([...state.user.dxDataGridFilters].slice(deleteIndex + 1))
        }
      };
    case UserActionTypes.USER_CHANGE_PASSWORD:
      return {
        ...state,
        loading: true,
        changePasswordUrl: null,
        error: null
      };
    case UserActionTypes.USER_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        changePasswordUrl: action.payload.result,
        error: null
      };
    case UserActionTypes.USER_CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        changePasswordUrl: null,
        error: action.payload.error
      };
    default:
      return state;
  }
}

/**
 * Interface for user information
 *
 * @export
 * @interface IUser
 */
export interface IUser {
  /**
   * User email
   *
   * @type {string}
   * @memberof IUser
   */
  email: string;

  /**
   * User first name
   *
   * @type {string}
   * @memberof IUser
   */
  firstName: string;

  /**
   * User last name
   *
   * @type {string}
   * @memberof IUser
   */
  lastName: string;

  /**
   * User title
   *
   * @type {string}
   * @memberof IUser
   */
  title?: string;

  /**
   * User id
   *
   * @type {string}
   * @memberof IUser
   */
  id?: string;

  /**
   * User company brand
   *
   * @type {string}
   * @memberof IUser
   */
  brand?: string;

  /**
   * User roles in current company
   *
   * @type {Array<number>}
   * @memberof IUser
   */
  roles?: Array<number>;

  /**
   * User company types in current company
   *
   * @type {Array<number>}
   * @memberof IUser
   */
  companyTypes?: Array<number>;

  /**
   * User signup status
   *
   * @type {number}
   * @memberof IUser
   */
  signupStatus?: number;

  /**
   * User phones
   *
   * @type {Phone[]}
   * @memberof IUser
   */
  phones?: Phone[];

  /**
   * User job title
   *
   * @type {string}
   * @memberof IUser
   */
  jobTitle?: string;

  /**
   * User departments list
   *
   * @type {string[]}
   * @memberof IUser
   */
  departments?: string[];

  /**
   * User department comment
   *
   * @type {string}
   * @memberof IUser
   */
  departmentComment?: string;

  /**
   * User companies list
   *
   * @type {UserCompany[]}
   * @memberof IUser
   */
  companies?: UserCompany[];

  /**
   * User current company id
   *
   * @type {string}
   * @memberof IUser
   */
  currentCompanyId?: string;

  /**
   * Boolean value to check if is admin session opened
   *
   * @type {boolean}
   * @memberof IUser
   */
  isSessionOpened?: boolean;

  /**
   * User guides list
   *
   * @type {Guides}
   * @memberof IUser
   */
  guides?: Guides;

  /**
   * User updated guides list
   *
   * @type {*}
   * @memberof IUser
   */
  guidesUpdated?: any;

  /**
   * List of static data, for example id's of guides video
   *
   * @type {*}
   * @memberof IUser
   */
  staticData?: any;

  /**
   * Deployment information
   *
   * @type {DeploymentInfo}
   * @memberof IUser
   */
  deploymentInfo?: DeploymentInfo;

  /**
   * Boolean value to check if need show release dialog
   *
   * @type {boolean}
   * @memberof IUser
   */
  needShowReleaseDialog?: boolean;

  /**
   * Boolean value to check if is SSO user
   *
   * @type {boolean}
   * @memberof IUser
   */
   loginProviders?: ExternalProvider[];

  /**
   * Platform title of current company
   *
   * @type {string}
   * @memberof IUser
   */
  platformTitle?: string;

  /**
   * Business title of current company
   *
   * @type {string}
   * @memberof IUser
   */
  businessTitle?: string;

  /**
   * Boolean value to check if is target fund manager
   *
   * @type {boolean}
   * @memberof IUser
   */
  isTargetFundManager?: boolean;

  /**
   * New emails count
   *
   * @type {number}
   * @memberof IUser
   */
  newEmailsCount?: number;

  /**
   * User data grid filters
   *
   * @type {IUserDxDataGridFilter[]}
   * @memberof IUser
   */
  dxDataGridFilters?: IUserDxDataGridFilter[];
}

export interface IRejectUser {
  /**
   * Reject Reason
   *
   * @type {string}
   * @memberof IRejectUser
   */
  rejectReason: string;
}

/**
 * Interface for user company.
 */
export interface UserCompany {
  /**
   * Company ID
   *
   * @type {string}
   * @memberof UserCompany
   */
  companyId: string;

  /**
   * Company name
   *
   * @type {string}
   * @memberof UserCompany
   */
  companyName: string;

  /**
   * Company LEI
   *
   * @type {string}
   * @memberof UserCompany
   */
  lei: string;
}

/**
 * Interface for phone.
 */
export interface Phone {
  /**
   * Phone type
   *
   * @type {PhoneType}
   * @memberof Phone
   */
  type: PhoneType;

  /**
   * Phone number
   *
   * @type {string}
   * @memberof Phone
   */
  number: string;
}

/**
 * Interface for menu items
 *
 * @export
 * @interface MenuItems
 */
export interface MenuItems {
  /**
   * Boolean value of menu item for approve page
   *
   * @type {boolean}
   * @memberof MenuItems
   */
  approve?: boolean;

  /**
   * Boolean value of menu item for assign page
   *
   * @type {boolean}
   * @memberof MenuItems
   */
  assign?: boolean;

  /**
   * Boolean value of menu item for completer page
   *
   * @type {boolean}
   * @memberof MenuItems
   */
  completer?: boolean;

  /**
   * Boolean value of menu item for releaser page
   *
   * @type {boolean}
   * @memberof MenuItems
   */
  releaser?: boolean;

  /**
   * Boolean value of menu item for distributors datagrid page
   *
   * @type {boolean}
   * @memberof MenuItems
   */
  fmDistributors?: boolean;

  /**
   * Boolean value of menu item for manage template page
   *
   * @type {boolean}
   * @memberof MenuItems
   */
  manageTemplate?: boolean;

  /**
   * Boolean value of menu item for review groups page
   *
   * @type {boolean}
   * @memberof MenuItems
   */
  reviewGroups?: boolean;

  /**
   * Boolean value of menu item for help page
   *
   * @type {boolean}
   * @memberof MenuItems
   */
  help?: boolean;

  /**
   * Boolean value of menu item for fm guest page
   *
   * @type {boolean}
   * @memberof MenuItems
   */
  fmguest?: boolean;

  /**
   * Boolean value of menu item for dashboard page
   *
   * @type {boolean}
   * @memberof MenuItems
   */
  dashboard?: boolean;

  /**
   * Boolean value of menu item for fund managers page
   *
   * @type {boolean}
   * @memberof MenuItems
   */
  fundManagers?: boolean;

  /**
   * Boolean value of menu item for admin page
   *
   * @type {boolean}
   * @memberof MenuItems
   */
  admin?: boolean;

  /**
   * Boolean value of menu item for FM admin page
   *
   * @type {boolean}
   * @memberof MenuItems
   */
  fmadmin?: boolean;

  /**
   * Boolean value of menu item for reporting page
   *
   * @type {boolean}
   * @memberof MenuItems
   */
  reporting?: boolean;

  /**
   * Boolean value of menu item for review page
   *
   * @type {boolean}
   * @memberof MenuItems
   */
  review?: boolean;

  /**
   * Boolean value of menu item for admin FM companies page
   *
   * @type {boolean}
   * @memberof MenuItems
   */
  adminFMCompanies?: boolean;

  /**
   * Boolean value of menu item for admin distributor companies page
   *
   * @type {boolean}
   * @memberof MenuItems
   */
  adminDistributorCompanies?: boolean;

  /**
   * Boolean value of menu item for admin users page
   *
   * @type {boolean}
   * @memberof MenuItems
   */
  adminUsers?: boolean;

  /**
   * Boolean value of menu item for admin emails page
   *
   * @type {boolean}
   * @memberof MenuItems
   */
  adminEmails?: boolean;

  /**
   * Boolean value of menu item for all interactions page
   *
   * @type {boolean}
   * @memberof MenuItems
   */
  allinteractions?: boolean;

  /**
   * Boolean value of menu item for all tasks page
   *
   * @type {boolean}
   * @memberof MenuItems
   */
  alltasks?: boolean;

  /**
   * Boolean value of menu item for FM tasks page
   *
   * @type {boolean}
   * @memberof MenuItems
   */
  fmtasks?: boolean;

  /**
   * Boolean value of menu item for analytics page
   *
   * @type {boolean}
   * @memberof MenuItems
   */
  analytics?: boolean;

  /**
   * Boolean value of menu item for documents page
   *
   * @type {boolean}
   * @memberof MenuItems
   */
  documents?: boolean;

  /**
   * Boolean value of menu item for FM fm analytics page
   *
   * @type {boolean}
   * @memberof MenuItems
   */
  fmanalytics?: boolean;
}

/**
 * Interface for guides.
 */
export interface Guides {
  /**
   * Boolean value of guide status for distributor admin page
   *
   * @type {boolean}
   * @memberof Guides
   */
  distributorAdmin: boolean;

  /**
   * Boolean value of guide status for distributor assign page
   *
   * @type {boolean}
   * @memberof Guides
   */
  distributorAssign: boolean;

  /**
   * Boolean value of guide status for distributor completer page
   *
   * @type {boolean}
   * @memberof Guides
   */
  distributorCompleter: boolean;

  /**
   * Boolean value of guide status for distributor dashboard page
   *
   * @type {boolean}
   * @memberof Guides
   */
  distributorDashboard: boolean;

  /**
   * Boolean value of guide status for fund managers page
   *
   * @type {boolean}
   * @memberof Guides
   */
  distributorFoundManagers: boolean;

  /**
   * Boolean value of guide status for distributor releaser page
   *
   * @type {boolean}
   * @memberof Guides
   */
  distributorReleaser: boolean;

  /**
   * Boolean value of guide status for FM admin page
   *
   * @type {boolean}
   * @memberof Guides
   */
  fmAdmin: boolean;

  /**
   * Boolean value of guide status for FM dashboard page
   *
   * @type {boolean}
   * @memberof Guides
   */
  fmDashboard: boolean;

  /**
   * Boolean value of guide status for FM distibutors page
   *
   * @type {boolean}
   * @memberof Guides
   */
  fmDistributors: boolean;

  /**
   * Boolean value of guide status for FM Key Contact bridging page
   *
   * @type {boolean}
   * @memberof Guides
   */
  fmKeyContactBridgingScreen: boolean;

  /**
   * Boolean value of guide status for FM template manager page
   *
   * @type {boolean}
   * @memberof Guides
   */
  fmTemplateManager: boolean;

  /**
   * Boolean value of guide status for FM template managers page
   *
   * @type {boolean}
   * @memberof Guides
   */
  fmTemplateManagers: boolean;

  /**
   * Boolean value of guide status for FM review groups page
   *
   * @type {boolean}
   * @memberof Guides
   */
  fmReviewGroups: boolean;

  /**
   * Boolean value of guide status for FM review page
   *
   * @type {boolean}
   * @memberof Guides
   */
  fmReview: boolean;

  /**
   * Boolean value of guide status for FM approval page
   *
   * @type {boolean}
   * @memberof Guides
   */
  fmApproval: boolean;

  /**
   * Boolean value of guide status for external guest DDQ page
   *
   * @type {boolean}
   * @memberof Guides
   */
  externalGuestDDQ: boolean;

  /**
   * Boolean value of guide status for FM tasks details page
   *
   * @type {*}
   * @memberof Guides
   */
  fmTasksDetails?: any;

  /**
   * Boolean value of guide status for FM tasks page
   *
   * @type {*}
   * @memberof Guides
   */
  fmTasks?: any;

  /**
   * Boolean value of guide status for new cycle completer popup
   *
   * @type {boolean}
   * @memberof Guides
   */
  newCycleCompleterInformation?: boolean;

  /**
   * Boolean value of guide status for document guide group ids status
   *
   * @type {Array<string>}
   * @memberof Guides
   */
  documentGuideGroupIds?: Array<string>;

  /**
   * Boolean value of guide status for processed updated terms popup
   *
   * @type {boolean}
   * @memberof Guides
   */
  processedUpdatedTerms?: boolean;

  /**
   * Boolean value of guide status for updated user information popup
   *
   * @type {boolean}
   * @memberof Guides
   */
  updatedUserInfo?: boolean;

  /**
   * Boolean value of guide status for updated reporting announce popup
   *
   * @type {boolean}
   * @memberof Guides
   */
  processedUpdatedReportingAnnounce: boolean;
}

/**
 * Interface for deployment information.
 *
 * @export
 * @interface DeploymentInfo
 */
export interface DeploymentInfo {
  /**
   * Deploy time
   *
   * @type {number}
   * @memberof DeploymentInfo
   */
  deployTime: number;

  /**
   * Deploy banner duration
   *
   * @type {number}
   * @memberof DeploymentInfo
   */
  bannerDuration: number;
}

/**
 * Interface for data grid filters.
 *
 * @export
 * @interface IUserDxDataGridFilter
 */
export interface IUserDxDataGridFilter {
  key: DxDataGridKey;
  value: string;
}

/**
 * Enum for company type
 *
 * @export
 * @enum {number}
 */
export enum CompanyType {
  FundManager,
  Distributor,
  Intermediary,
  Auditor
}

/**
 * Enum for user role
 *
 * @export
 * @enum {number}
 */
export enum Role {
  KeyContact,
  Completer,
  Releaser,
  Reviewer,
  Approver,
  FMTemplateOwner,
  DDDAdmin,
  RelationshipManager,
  Reporter,
  FMGuest,
  DocumentManager,
  Populator
}

/**
 * Enum for user signup status
 *
 * @export
 * @enum {number}
 */
export enum SignupStatus {
  New,
  Invited,
  PasswordCreated,
  ProfileApproved,
  ProfileRejected,
  Completed,
  CyclePendingConfirmation,
  PendingCompanyConfirmation
}

/**
 * Enum for user system status
 *
 * @export
 * @enum {number}
 */
export enum UserStatus {
  New,
  Invited,
  InvitationRejected,
  InvitationAccepted,
  Enabled,
  Disabled,
  Deleted
}

export enum UserStatusLiteral {
  "New",
  "Invited",
  "Invitation Rejected",
  "Invitation Accepted",
  "Enabled",
  "Disabled",
  "Deleted"
}

/**
 * Enum for phone type
 *
 * @export
 * @enum {number}
 */
export enum PhoneType {
  Mobile,
  LandLine
}

/**
 * Enum for external provider
 *
 * @export
 * @enum {string}
 */
 export enum ExternalProvider {
  Keycloak,
  Carne
}

export const USER_ROLES_LIST = [
  {
    value: Role.KeyContact,
    valueString: "KeyContact",
    label: "Key Contact"
  },
  {
    value: Role.Completer,
    valueString: "Completer",
    label: "Completer"
  },
  {
    value: Role.Releaser,
    valueString: "Releaser",
    label: "Releaser"
  },
  {
    value: Role.Reviewer,
    valueString: "Reviewer",
    label: "Reviewer"
  },
  {
    value: Role.Approver,
    valueString: "Approver",
    label: "Approver"
  },
  {
    value: Role.FMTemplateOwner,
    valueString: "FMTemplateOwner",
    label: "Template Owner"
  },
  {
    value: Role.DDDAdmin,
    valueString: "DDDAdmin",
    label: "Admin"
  },
  {
    value: Role.RelationshipManager,
    valueString: "RelationshipManager",
    label: "Relationship Manager"
  },
  {
    value: Role.Reporter,
    valueString: "Reporter",
    label: "Reporter"
  },
  {
    value: Role.FMGuest,
    valueString: "FMGuest",
    label: "FM Guest"
  },
  {
    value: Role.DocumentManager,
    valueString: "DocumentManager",
    label: "Document Manager"
  },
  {
    value: Role.Populator,
    valueString: "Populator",
    label: "Populator"
  }
];
