import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ViewProgressService } from "@app/modules/view-progress/component/view-progress/services";
import { ActionUploadDocument } from "@app/modules/view-progress/component/view-progress/reducers";
import { selectorUser, IUser } from "app/core/auth/identity/user.reducer";
import * as moment from "moment";
import { ActionToastrError } from "@app/core/toastr/toastr.reducer";
import { NgIf } from "@angular/common";
import { FileDropDirective } from "../../directives/file-drop.directive";
import { FieldErrorShowComponent } from "../../components/field-error-show/field-error-show.component";

const MAX_FILE_SIZE = 30 * 1024 * 1024;

@Component({
    selector: "ddd-upload-keydocument-dialog",
    templateUrl: "./upload-keydocument-dialog.component.html",
    styleUrls: ["./upload-keydocument-dialog.component.scss"],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, FieldErrorShowComponent, FileDropDirective, NgIf]
})
export class UploadKeydocumentDialogComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  public fileListOutput: File;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public user: IUser;

  constructor(
    private readonly store: Store<any>,
    private formBuilder: UntypedFormBuilder,
    public viewProgressService: ViewProgressService,
    public dialogRef: MatDialogRef<UploadKeydocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      description: [null, Validators.required]
    });

    this.store
      .pipe(
        select(selectorUser),
        takeUntil(this.unsubscribe$),
        filter((user: IUser) => !!user)
      )
      .subscribe((user: IUser) => {
        this.user = user;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public isFieldValid(field: string) {
    return !this.form.get(field).valid && (this.form.get(field).touched || this.form.get(field).dirty);
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onSubmit() {
    if (this.form.getRawValue().description) {
      this.form.get("description").setValue(this.form.getRawValue().description.trim());
    }
    if (this.form.valid) {
      this.sentForm();
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  handleDrop(fileList: FileList) {
    if (fileList.length > 0) {
      const inputFile = fileList[0];
      if (inputFile.size > MAX_FILE_SIZE) {
        this.store.dispatch(
          new ActionToastrError({
            error: { message: "File too large. Maximum 30mb." }
          })
        );
        return;
      }

      this.fileListOutput = inputFile;
    }
  }

  handleInputFile($event: any) {
    this.handleDrop($event.target.files);
  }

  sentForm() {
    const description = this.form.getRawValue().description;
    const date = moment().format();
    if (this.fileListOutput) {
      this.store.dispatch(
        new ActionUploadDocument({
          document: this.fileListOutput,
          description: description,
          uploadedBy: this.user,
          uploadedDate: date
        })
      );
      this.dialogRef.close(true);
    }
  }
}
