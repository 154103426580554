import { HttpErrorResponse } from "@angular/common/http";
import { Action } from "@ngrx/store";

import { Country } from "@shared/modules/questions-module-shared/components/country-question/reducers";

/**
 * Company key for local storage use
 */
export const COMPANY_KEY = "COMPANY.QUESTIONS";

/**
 * Company actions types
 */
export enum CompanyActionTypes {
  SAVE = "[Company] Save",
  SAVE_SUCCESS = "[Company] Save Success",
  SAVE_ERROR = "[Company] Save Error",
  COMPANY_RETRIEVE = "[Company] Retrieve",
  COMPANY_RETRIEVE_SUCCESS = "[Company] Retrieve Success",
  COMPANY_RETRIEVE_ERROR = "[Company] Retrieve Error",
  COMPANY_REJECT = "[Company] Reject",
  COMPANY_REJECT_SUCCESS = "[Company] Reject Success"
}

/**
 * Store action for save company information.
 * @export
 * @class ActionCompanySave
 * @implements {Action}
 */
export class ActionCompanySave implements Action {
  /**
   * Action type.
   * @memberof ActionCompanySave
   */
  readonly type = CompanyActionTypes.SAVE;

  /**
   * Creates an instance of ActionCompanySave.
   * @param {Company} payload company save payload
   * @memberof ActionCompanySave
   */
  constructor(public payload: { company: Company }) {}
}

/**
 * Store action for success save company information.
 * @export
 * @class ActionCompanySaveSuccess
 * @implements {Action}
 */
export class ActionCompanySaveSuccess implements Action {
  /**
   * Action type.
   * @memberof ActionCompanySaveSuccess
   */
  readonly type = CompanyActionTypes.SAVE_SUCCESS;

  /**
   * Creates an instance of ActionCompanySaveSuccess.
   * @param {CompaniesStatus} payload company save success payload
   * @memberof ActionCompanySaveSuccess
   */
  constructor(public payload: { companiesStatus: CompaniesStatus }) {}
}

/**
 * Store action for error save company information.
 * @export
 * @class ActionCompanysaveError
 * @implements {Action}
 */
export class ActionCompanysaveError implements Action {
  /**
   * Action type.
   * @memberof ActionCompanysaveError
   */
  readonly type = CompanyActionTypes.SAVE_ERROR;

  /**
   * Creates an instance of ActionCompanysaveError.
   * @param {HttpErrorResponse} payload company save error payload
   * @memberof ActionCompanysaveError
   */
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionCompanyReject implements Action {
  readonly type = CompanyActionTypes.COMPANY_REJECT;
  constructor(public payload: { company: RejectInfo }) {}
}

export class ActionCompanyRejectSuccess implements Action {
  readonly type = CompanyActionTypes.COMPANY_REJECT_SUCCESS;
  constructor(public payload: { companiesStatus: CompaniesStatus }) {}
}

/**
 * Store action for retrieve company information.
 * @export
 * @class ActionCompanyRetrieve
 * @implements {Action}
 */
export class ActionCompanyRetrieve implements Action {
  /**
   * Action type.
   * @memberof ActionCompanyRetrieve
   */
  readonly type = CompanyActionTypes.COMPANY_RETRIEVE;

  /**
   * Creates an instance of ActionCompanyRetrieve.
   * @memberof ActionCompanyRetrieve
   */
  constructor() {}
}

/**
 * Store action for success retrieve company information.
 * @export
 * @class ActionCompanyRetrieveSuccess
 * @implements {Action}
 */
export class ActionCompanyRetrieveSuccess implements Action {
  /**
   * Action type.
   * @memberof ActionCompanyRetrieveSuccess
   */
  readonly type = CompanyActionTypes.COMPANY_RETRIEVE_SUCCESS;

  /**
   * Creates an instance of ActionCompanyRetrieveSuccess.
   * @param {Company} payload company retrieve success payload
   * @memberof ActionCompanyRetrieveSuccess
   */
  constructor(public payload: { company: Company }) {}
}

/**
 * Store action for success retrieve company information.
 * @export
 * @class ActionCompanyRetrieveError
 * @implements {Action}
 */
export class ActionCompanyRetrieveError implements Action {
  /**
   * Action type.
   * @memberof ActionCompanyRetrieveError
   */
  readonly type = CompanyActionTypes.COMPANY_RETRIEVE_ERROR;

  /**
   * Creates an instance of ActionCompanyRetrieveError.
   * @param {HttpErrorResponse} payload company retrieve error payload
   * @memberof ActionCompanyRetrieveError
   */
  constructor(public payload: { error: HttpErrorResponse }) {}
}

/**
 * Company state selector
 */
export const companySelector = (state) => state && state.setup && state.setup.company;
export const companyStatusSelector = (state) => state.setup.companiesStatus;

/**
 * Company action types.
 */
export type CompanyActions =
  | ActionCompanySave
  | ActionCompanySaveSuccess
  | ActionCompanysaveError
  | ActionCompanyRetrieve
  | ActionCompanyRetrieveSuccess
  | ActionCompanyRetrieveError
  | ActionCompanyReject
  | ActionCompanyRejectSuccess;

/**
 * Company setup initial state
 */
export const initialState: CompanyState = {
  loading: false,
  company: {
    legalEntityId: "",
    name: "",
    address: {
      firstAddressLine: "",
      secondAddressLine: "",
      postalCode: "",
      city: "",
      country: {
        key: "",
        name: "",
        alpha2Code: "",
        alpha3Code: "",
        region: "",
        subRegion: ""
      }
    }
  }
};

/**
 * Reducer function for company setup state.
 * @export
 * @param {CompanyState} [state=initialDocumentState]
 * @param {CompanyActions} action
 * @returns {CompanyState}
 */
export function companySetupReducer(state: CompanyState = initialState, action: CompanyActions): CompanyState {
  switch (action.type) {
    case CompanyActionTypes.SAVE:
      return {
        ...state,
        loading: true,
        company: action.payload && action.payload.company,
        error: null
      };

    case CompanyActionTypes.SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        company: null,
        companiesStatus: action.payload.companiesStatus,
        error: null
      };

    case CompanyActionTypes.SAVE_ERROR:
      return {
        ...state,
        loading: false,
        company: null,
        error: action.payload.error
      };

    case CompanyActionTypes.COMPANY_RETRIEVE:
      return { ...state, loading: true, error: null };

    case CompanyActionTypes.COMPANY_RETRIEVE_SUCCESS:
      return {
        ...state,
        loading: false,
        company: action.payload && action.payload.company,
        error: null,
        companiesStatus: null
      };

    case CompanyActionTypes.COMPANY_RETRIEVE_ERROR:
      return {
        ...state,
        loading: false,
        company: null,
        error: action.payload.error
      };

    case CompanyActionTypes.COMPANY_REJECT:
      return {
        ...state,
        loading: true,
        // company: action.payload.company,
        error: null
      };

    case CompanyActionTypes.COMPANY_REJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        companiesStatus: action.payload.companiesStatus,
        error: null
      };

    default:
      return state;
  }
}

/**
 * Interface for company setup state.
 * @export
 * @interface CompanyState
 */
export interface CompanyState {
  /**
   * Loading state status.
   * @type {boolean}
   * @memberof CompanyState
   */
  loading: boolean;

  /**
   * Company information.
   * @type {Company}
   * @memberof CompanyState
   */
  company: Company;

  /**
   * Error.
   * @type {HttpErrorResponse}
   * @memberof CompanyState
   */
  companiesStatus?: CompaniesStatus;
  error?: HttpErrorResponse;
}

/**
 * Interface for company setup address.
 * @export
 * @interface Address
 */
export interface Address {
  /**
   * First address line.
   * @type {string}
   * @memberof Address
   */
  firstAddressLine?: string;

  /**
   * Second address line.
   * @type {string}
   * @memberof Address
   */
  secondAddressLine?: string;

  /**
   * Postal code.
   * @type {string}
   * @memberof Address
   */
  postalCode?: string;

  /**
   * City.
   * @type {string}
   * @memberof Address
   */
  city?: string;

  /**
   * City.
   * @type {Country}
   * @memberof Address
   */
  country: Country;
}

/**
 * Interface for company.
 * @interface Company
 */
export interface Company {
  /**
   * Company name.
   * @type {string}
   * @memberof Company
   */
  name?: string;

  /**
   * List of company types.
   * @type {string[]}
   * @memberof Company
   */
  companyType?: string[];

  /**
   * Company status.
   * @type {string}
   * @memberof Company
   */
  status?: string;

  /**
   * Company legal entity id.
   * @type {string}
   * @memberof Company
   */
  legalEntityId?: string;

  /**
   * Company address.
   * @type {Address}
   * @memberof Company
   */
  address?: Address;
  totalCompanies?: number;
  approvedCompanies?: number;
}

export interface RejectInfo {
  legalEntityId?: string;
  firstName?: string;
  lastName?: string;
  rejectionmessage?: string;
  email: string;
  phones: any;
}

/**
 * Interface for Companies Status.
 * @interface CompaniesStatus
 */
export interface CompaniesStatus {
  /**
   * Total companies count.
   * @type {number}
   * @memberof CompaniesStatus
   */
  totalCompanies?: number;

  /**
   * Approved companies count.
   * @type {number}
   * @memberof CompaniesStatus
   */
  approvedCompanies?: number;
  rejectedCompanies?: number;
}

/**
 * Interface for company details.
 * @export
 * @interface CompanyDetails
 */
export interface CompanyDetails {
  id: string;
  legalEntityId: string;
  firstAddressLine?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  status?: CompanyStatus;
  name?: string;
  address: Address;
  companyType: number[];
  contacts?: Contact[];
  dueDiligenceRelations?: IDueDiligenceRelation[];
  brand: number;
  approvedCompanies: number;
  rejectedCompanies: number;
  totalCompanies: number;
  needUpdateIntermediaryReleaser?: boolean;
  customRelationshipFieldDefinitions?: any[];
  distributionRelations?: any[];
  curatorId: string;
}

export interface IDueDiligenceRelation {
  status: number;
  companyId: string;
  companyName: string;
  legalEntityId: string;
  isTiedRelationship: boolean;
  fmRelationshipMetadata?: any;
}

export interface Contact {
  userId: string;
  isPrimary: boolean;
  status: number;
}

/**
 * Interface for company status.
 * @export
 * @interface CompanyStatus
 */
export enum CompanyStatus {
  New,
  Invited,
  InvitationRejected,
  InvitationAccepted,
  Active,
  Inactive
}

/**
 * Interface for company progress status.
 * @export
 * @interface CompanyProgressStatus
 */
export enum CompanyProgressStatus {
  None,
  FarBehind,
  Overdue,
  BehindSchedule,
  OnTrack,
  PendingCompletionRelease,
  DDQAnswered,
  NoResponseProvided,
  NotInvited
}
