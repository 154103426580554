import { Component, OnInit } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@Component({
    selector: "ddd-spinner-material",
    templateUrl: "./spinner-material.component.html",
    styleUrls: ["./spinner-material.component.scss"],
    standalone: true,
    imports: [MatProgressSpinnerModule]
})
export class SpinnerMaterialComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
