import { combineReducers, Action, compose } from "@ngrx/store";
import {
  SupportCommonActionTypes,
  SupportCommonAction,
  SupportCommonState,
  supportCommonInitialState,
  supportCommonReducer,
  supportCommonStoreName
} from "./support.reducer";
import {
  fmCompaniesStoreName,
  supportFMCopmanyReducer,
  SupportFMCompanyState,
  supportFMCompanyInitialState,
  SupportFMCompanyAction,
  SupportFMCompanyActionTypes
} from "./fm-companies.reducer";
import {
  SupportDistributorCompanyState,
  distributorCompaniesStoreName,
  supportDistributorCompanyInitialState,
  supportDistributorCopmanyReducer,
  SupportDistributorCompanyAction,
  SupportDistributorCompanyActionTypes
} from "./distributor-companies.reducer";
import {
  supportEmailsStoreName,
  supportEmailsReducer,
  SupportEmailsState,
  supportEmailsInitialState,
  SupportEmailsAction,
  SupportEmailsActionTypes
} from "./emails.reducer";

export const SupportActionTypes = Object.assign(
  {},
  SupportCommonActionTypes,
  SupportFMCompanyActionTypes,
  SupportDistributorCompanyActionTypes,
  SupportEmailsActionTypes
);

export type SupportAction =
  | SupportCommonAction
  | SupportFMCompanyAction
  | SupportDistributorCompanyAction
  | SupportEmailsAction;

const initialState: SupportState = {
  [supportCommonStoreName]: supportCommonInitialState,
  [fmCompaniesStoreName]: supportFMCompanyInitialState,
  [distributorCompaniesStoreName]: supportDistributorCompanyInitialState,
  [supportEmailsStoreName]: supportEmailsInitialState
};

export function supportReducer(state: any, action: Action) {
  if (!state) {
    state = initialState;
  }

  if (
    (action as SupportCommonAction) != null ||
    (action as SupportFMCompanyAction) != null ||
    (action as SupportDistributorCompanyAction) != null ||
    (action as SupportEmailsAction) != null
  ) {
    return {
      [supportCommonStoreName]: supportCommonReducer(state[supportCommonStoreName], action as SupportCommonAction),
      [fmCompaniesStoreName]: supportFMCopmanyReducer(state[fmCompaniesStoreName], action as SupportFMCompanyAction),
      [distributorCompaniesStoreName]: supportDistributorCopmanyReducer(
        state[distributorCompaniesStoreName],
        action as SupportDistributorCompanyAction
      ),
      [supportEmailsStoreName]: supportEmailsReducer(state[supportEmailsStoreName], action as SupportEmailsAction)
    };
  }

  return state;
}

export interface SupportState {
  [supportCommonStoreName]: SupportCommonState;
  [fmCompaniesStoreName]: SupportFMCompanyState;
  [distributorCompaniesStoreName]: SupportDistributorCompanyState;
  [supportEmailsStoreName]: SupportEmailsState;
}
