import { Component, OnInit, OnDestroy, NgZone, ViewChild, Input, Inject } from "@angular/core";
import { select, Store, Action } from "@ngrx/store";
import { takeUntil, filter, map } from "rxjs/operators";
import { Actions, ofType } from "@ngrx/effects";
import { Subject } from "rxjs";
import { Location, NgIf, NgClass, NgFor } from "@angular/common";
import { OAuthService } from "angular-oauth2-oidc";
import { selectorLanguage } from "@app/core/i18n/i18n.reducer";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { Router, NavigationEnd, RouterLink, RouterLinkActive } from "@angular/router";

import {
  Role,
  MenuItems,
  CompanyType,
  selectorUser,
  selectorUserState,
  ActionUserRetrieve,
  IUser,
  UserCompany,
  UserState,
  ActionChangeCompany,
  ActionClear,
  ActionUserAdminSupportSessionStart,
  ActionUserAdminSupportSessionEnd,
  ActionUserAdminGetCompanies,
  selectorAdminAllCompaniesList,
  ActionUserResolveGuide,
  ActionClearUpdatedGuides,
  ActionUserAdminGetCompaniesSuccess,
  SignupStatus,
  UserActionTypes,
  ActionChangePassword
} from "@app/core/auth/identity/user.reducer";
import { environment } from "@env/environment";
import * as _ from "lodash";
import {
  ActionSupportGetCompanyUsers,
  supportCompanyUsersSelector
} from "@modules/support/components/support/reducers/support.reducer";
import { ConvertRolePipe } from "@shared/pipes/custom";
import { CountdownComponent } from "ngx-countdown";
import * as moment from "moment";
import { NgSelectModule } from "@ng-select/ng-select";
import { MatMenuModule } from "@angular/material/menu";
import { FormsModule } from "@angular/forms";
import { BroadcastService } from "@app/core";
import { SESSION_BROADCAST_SERVICE } from '@app/core/utils/app.tokens';

@Component({
    selector: "ddd-common-header",
    templateUrl: "./common-header.component.html",
    styleUrls: ["./common-header.component.scss"],
    providers: [ConvertRolePipe],
    standalone: true,
    imports: [NgIf, CountdownComponent, RouterLink, RouterLinkActive, NgClass, FormsModule, MatMenuModule, NgFor, NgSelectModule, TranslateModule]
})
export class CommonHeaderComponent implements OnInit, OnDestroy {
  @Input() loggedUser: IUser;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public pageTitle : string;
  public withMenu: boolean;
  public withCompanySelect: boolean;
  public hideHeader: boolean;
  public currentSetup: CurrentSetup;
  // public loggedUser: IUser;
  public menuItems: MenuItems = {};
  public isFundManager: boolean;
  public isFundManagerReview: boolean;
  public isFundManagerApproval: boolean;
  public isDistributor: boolean;
  public isIntermediary: boolean;
  public menuDropdown: boolean;
  public userCurrentCompany: UserCompany;

  public adminCurrentCompany: any;
  public adminCurrentUser: any;

  public adminAllCompaniesList = [];
  public adminCompanyUsersList = [];
  public isAdmin = false;
  public isSupportSessionStarted = false;
  public userAdminSessionInit = false;
  public userAdminSessionEnded = false;
  private ADMIN_COMPANIES_PAGESIZE = 50;
  private stopAdminCompaniesFetching = false;
  public adminCompaniesListQuery: string;
  public showDeploymentBanner: boolean;
  public timeToDeployment: number;

  public setup: CurrentSetup[] = [
    { link: "/setup/profile-setup", label: "Introduction & Setup", step: "1" },
    { link: "/setup/company-setup", label: "Introduction & Setup", step: "2" }
  ];

  @ViewChild("countdown", { static: true })
  counter: CountdownComponent;

  constructor(
    private oauthService: OAuthService,
    private readonly translate: TranslateService,
    private location: Location,
    private readonly store: Store<any>,
    private zone: NgZone,
    public router: Router,
    public convertRole: ConvertRolePipe,
    private actions$: Actions<Action>,
    @Inject(SESSION_BROADCAST_SERVICE) private sessionBroadCastService: BroadcastService
  ) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.initCommonHeader();
      if (this.loggedUser && this.loggedUser.guidesUpdated.length) {
        this.loggedUser.guidesUpdated.map((item) => {
          this.store.dispatch(new ActionClearUpdatedGuides({ userGuidename: item }));
        });
      }
      if (!this.loggedUser && this.oauthService.hasValidAccessToken()) {
        this.store.dispatch(new ActionUserRetrieve({ base_url: environment.client.base_url }));
      }
    });

    this.storeActions();
  }

  ngOnInit() {}

  public handleDeploymentBanner() {
    const currentTime = moment().utc();
    const deploymentTime = moment(this.loggedUser.deploymentInfo.deployTime * 1000);
    this.timeToDeployment = moment.duration(deploymentTime.diff(currentTime)).as("seconds");
    if (this.timeToDeployment > this.loggedUser.deploymentInfo.bannerDuration * 60) {
      const timeToBanner = this.timeToDeployment - this.loggedUser.deploymentInfo.bannerDuration * 60;
      setTimeout(() => (this.showDeploymentBanner = true), timeToBanner * 1000);
    } else {
      this.showDeploymentBanner = true;
      if (this.timeToDeployment < 0) this.timeToDeployment = 0;
    }

    //setTimeout(() => this.showDeploymentBanner = false, this.timeToDeployment*1000);
  }

  public fetchAdminCompanies() {
    if (!this.stopAdminCompaniesFetching) {
      this.store.dispatch(
        new ActionUserAdminGetCompanies({
          pagesize: this.ADMIN_COMPANIES_PAGESIZE,
          query: this.adminCompaniesListQuery
        })
      );
    }
  }

  public searchAdminCompanies(query) {
    this.adminCompaniesListQuery = query ? query.term : "";
    this.store.dispatch(
      new ActionUserAdminGetCompaniesSuccess({
        companies: []
      })
    );
    this.fetchAdminCompanies();
  }

  /**
   * Workaround to allow searching with ng-select using API instead of list search;
   */
  public customSearchFunction() {
    return true;
  }

  public clearAdminSearch() {
    this.adminCompaniesListQuery = "";
    this.searchAdminCompanies(this.adminCompaniesListQuery);
  }

  public adminCompanySelected() {
    this.adminCompanyUsersList = [];
    if (this.adminCurrentCompany) {
      this.store.dispatch(
        new ActionSupportGetCompanyUsers({
          companyId: this.adminCurrentCompany
        })
      );
    }
  }

  public startAdminSession() {
    if (this.adminCurrentCompany && this.adminCurrentUser) {
      this.userAdminSessionInit = false;
      this.store.dispatch(
        new ActionUserAdminSupportSessionStart({
          companyId: this.adminCurrentCompany,
          userId: this.adminCurrentUser
        })
      );
      this.sessionBroadCastService.publish({
        type: 'session',
        payload: true
      });
    }
  }

  public endAdminSession() {
    this.sessionBroadCastService.publish({
      type: 'session',
      payload: true
    });
    this.router.navigate(["/session-end"], {
      skipLocationChange: true
    });
  }

  public initCommonHeader() {
    if (this.location.path().includes("callback")) {
      this.hideHeader = true;
    } else if (this.location.path().includes("setup")) {
      this.currentSetup = this.setup.find((item) => item.link === this.location.path());
      this.withMenu = false;
    } else {
      this.withMenu = true;
    }
    if (
      this.location.path().includes("profile-setup")
    ) {
      this.withCompanySelect = false;
    } else {
      this.withCompanySelect = true;
    }
    this.menuDropdown = false;
    this.resolveGuides();
  }

  /**
   * Change current company for user
   */
  public changeCompany(id: string): void {
    if (id !== this.loggedUser.currentCompanyId) {
      this.router.navigateByUrl(`/loading-page?action=changeCompany&companyId=${id}`, {
        skipLocationChange: true
      });
    }
  }

  public logout(): void {
    this.menuDropdown = false;
    this.router.navigate(["/logout"]);
  }

  public changePassword(): void {
    this.router.navigateByUrl("/loading-page?action=changePassword", {
      skipLocationChange: true
    });
  }

  public goToProfile(): void {
    this.router.navigate(["/profile"]);
  }

  public roleIncludes(array: Array<Role>, value: number): boolean {
    return array.indexOf(value) > -1;
  }

  public companyTypeIncludes(array: Array<CompanyType>, value: number): boolean {
    return array.indexOf(value) > -1;
  }

  public reload(): any {
    return this.zone.runOutsideAngular(() => {
      location.replace("/");
    });
  }

  /**
   * Resolve user guides
   */
  public resolveGuides(): void {
    if (this.loggedUser && this.loggedUser.guides && this.loggedUser.guidesUpdated.length === 0) {
      if (this.checkGuide("admin", this.isDistributor, "distributorAdmin")) {
        // Don't have guide on template
        this.dispatchResolveGuides("DistributorAdmin", "distributorAdmin");
      } else if (this.checkGuide("assign", this.isDistributor, "distributorAssign")) {
        this.dispatchResolveGuides("DistributorAssign", "distributorAssign");
      } else if (this.checkGuide("complete", this.isDistributor, "distributorCompleter")) {
        this.dispatchResolveGuides("DistributorCompleter", "distributorCompleter");
      } else if (this.checkGuide("dashboard", this.isDistributor, "distributorDashboard")) {
        this.dispatchResolveGuides("DistributorDashboard", "distributorDashboard");
      } else if (this.checkGuide("fundmanagers", this.isDistributor, "distributorFundManagers")) {
        // Don't have guide on template
        this.dispatchResolveGuides("DistributorFundManagers", "distributorFundManagers");
      } else if (this.checkGuide("release", this.isDistributor, "distributorReleaser")) {
        this.dispatchResolveGuides("DistributorReleaser", "distributorReleaser");
      } else if (this.checkGuide("admin", this.isFundManager, "fmAdmin")) {
        // Don't have admin page for fundManager side
        this.dispatchResolveGuides("FMAdmin", "fmAdmin");
      } else if (this.checkGuide("managetemplate", this.isFundManager, "fmTemplateManager")) {
        this.dispatchResolveGuides("FMTemplateManager", "fmTemplateManager");
      } else if (this.checkGuide("distributors", this.isFundManager, "fmDistributors")) {
        this.dispatchResolveGuides("FMDistributors", "fmDistributors");
      } else if (this.checkGuide("dashboard", this.isFundManager, "fmDashboard")) {
        this.dispatchResolveGuides("FMDashboard", "fmDashboard");
      } else if (this.checkGuide("reviewgroups", this.isFundManager, "fmReviewGroups")) {
        this.dispatchResolveGuides("FMReviewGroups", "fmReviewGroups");
      } else if (
        this.checkGuide("review", this.isFundManagerReview, "fmReview") ||
        this.checkGuide("review", this.isFundManagerApproval, "fmApproval")
      ) {
        if (this.isFundManagerApproval) {
          this.dispatchResolveGuides("FMApproval", "fmApproval");
        }
        if (this.isFundManagerReview) {
          this.dispatchResolveGuides("FMReview", "fmReview");
        }
      } else if (this.checkGuide("fmguest", this.isIntermediary, "externalGuestDDQ")) {
        this.dispatchResolveGuides("ExternalGuestDDQ", "externalGuestDDQ");
      } else if (this.checkGuide("fmtasks", this.isFundManager, "fmTasks")) {
        this.dispatchResolveGuides("FMTasks", "fmTasks");
      } else if (this.checkGuide("task-details", this.isFundManager, "fmTasksDetails")) {
        this.dispatchResolveGuides("FMTasksDetails", "fmTasksDetails");
      }
    }
  }

  /**
   * Dispatch user guides
   */
  public dispatchResolveGuides(guidename: string, userGuidename: string): void {
    this.store.dispatch(
      new ActionUserResolveGuide({
        guidename: guidename,
        userGuidename: userGuidename,
        base_url: environment.client.base_url
      })
    );
  }

  /**
   * Check user guide
   */
  public checkGuide(path: string, role: boolean, guidename: string) {
    return this.location.path().includes(path) && role && this.loggedUser.guides[guidename] !== undefined
      ? true
      : false;
  }

  public checkActive() {
    return (
      this.location.path().includes("distributors") ||
      (this.location.path().includes("analytics") && !this.location.path().includes("fmanalytics"))
    );
  }

  public checkReportingActive() {
    return this.location.path().includes("reporting") || this.location.path().includes("fmanalytics");
  }

  public postRedirectAfterAdminSessionStart(user) {
    if (this.location.path().includes("guidance") || this.location.path().includes("documents")) {
      return;
    }
    if (user.signupStatus === SignupStatus.Completed) {
      this.router.navigate(["dashboard"]);
    } else if (user.signupStatus === SignupStatus.ProfileApproved) {
      this.router.navigate(["company-setup"]);
    }
  }

  storeActions() {
    this.store
      .pipe(
        select(supportCompanyUsersSelector),
        filter((users) => !!users),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((currentCompanyUsers) => {
        this.adminCompanyUsersList = currentCompanyUsers.result.map((user) => {
          return {
            ...user,
            disabled:
              user.signupStatus !== SignupStatus.Completed && user.signupStatus !== SignupStatus.ProfileApproved,
            fullname: `${user.firstName} ${user.lastName} [${
              user.roles && user.roles.length > 0
                ? user.roles.map((role) => this.convertRole.transform(role)).join(", ")
                : "NO ROLES"
            }] <${user.email}>`
          };
        });
      });

    this.store
      .pipe(select(selectorAdminAllCompaniesList), takeUntil(this.unsubscribe$))
      .subscribe((adminAllCompaniesList) => {
        this.stopAdminCompaniesFetching =
          adminAllCompaniesList &&
          adminAllCompaniesList.length > 0 &&
          adminAllCompaniesList.length % this.ADMIN_COMPANIES_PAGESIZE !== 0;
        this.adminAllCompaniesList = adminAllCompaniesList.map((adminCompany) => {
          return {
            ...adminCompany,
            fullname: `[${adminCompany.name}], [${adminCompany.legalEntityId}]`
          };
        });
      });

    this.store
      .pipe(
        select(selectorUser),
        filter((user: IUser) => user !== null)
      )
      .subscribe((user: IUser) => {
        this.loggedUser = user;

        if (this.loggedUser.deploymentInfo) {
          this.handleDeploymentBanner();
        }

        this.isAdmin = this.roleIncludes(user.roles, Role.DDDAdmin);
        if (this.isAdmin && !this.loggedUser.isSessionOpened) {
          this.fetchAdminCompanies();
        }

        const userRoles = this.loggedUser.roles;
        const companyTypes = this.loggedUser.companyTypes;
        this.userCurrentCompany = _.find(this.loggedUser.companies, {
          companyId: this.loggedUser.currentCompanyId
        });

        this.isIntermediary = this.companyTypeIncludes(companyTypes, CompanyType.Intermediary);

        this.isFundManager = this.companyTypeIncludes(companyTypes, CompanyType.FundManager);
        this.isFundManagerReview = userRoles.indexOf(Role.Reviewer) > -1;
        this.isFundManagerApproval = userRoles.indexOf(Role.Approver) > -1;

        this.isDistributor = this.companyTypeIncludes(companyTypes, CompanyType.Distributor);

        if (user.roles && user.roles.length === 1 && this.roleIncludes(user.roles, Role.DocumentManager)) {
          this.menuItems = {
            dashboard: true,
            documents: true,
            help: true
          };
        } else if (this.isFundManager) {
          this.menuItems = {
            fmDistributors:
              this.roleIncludes(user.roles, Role.KeyContact) ||
              this.roleIncludes(user.roles, Role.Reviewer) ||
              this.roleIncludes(user.roles, Role.Approver),
            manageTemplate: this.roleIncludes(user.roles, Role.FMTemplateOwner),
            reviewGroups:
              this.roleIncludes(user.roles, Role.Approver) || this.roleIncludes(user.roles, Role.KeyContact),
            help: true,
            reporting: this.roleIncludes(user.roles, Role.Reporter),
            fmadmin: this.roleIncludes(user.roles, Role.KeyContact),
            dashboard: true,
            fmtasks:
              this.roleIncludes(user.roles, Role.KeyContact) ||
              this.roleIncludes(user.roles, Role.Reviewer) ||
              this.roleIncludes(user.roles, Role.Approver) ||
              this.roleIncludes(user.roles, Role.FMTemplateOwner),
            analytics: this.roleIncludes(user.roles, Role.KeyContact),
            documents: this.roleIncludes(user.roles, Role.DocumentManager),
            fmanalytics: this.roleIncludes(user.roles, Role.KeyContact)
          };
        } else if (this.isDistributor) {
          this.menuItems = {
            assign:
              (this.roleIncludes(userRoles, Role.KeyContact) || this.roleIncludes(userRoles, Role.Populator)) &&
              user.signupStatus === SignupStatus.Completed,
            completer:
              (this.roleIncludes(userRoles, Role.Completer) ||
                this.roleIncludes(userRoles, Role.KeyContact) ||
                this.roleIncludes(userRoles, Role.Populator)) &&
              user.signupStatus === SignupStatus.Completed,
            releaser:
              (this.roleIncludes(userRoles, Role.Releaser) || this.roleIncludes(userRoles, Role.KeyContact)) &&
              user.signupStatus === SignupStatus.Completed,
            fundManagers:
              (this.roleIncludes(user.roles, Role.KeyContact) || this.roleIncludes(userRoles, Role.Releaser)) &&
              user.signupStatus === SignupStatus.Completed,
            documents : this.roleIncludes(user.roles, Role.DocumentManager),
            help: true,
            dashboard: true,
            admin: this.roleIncludes(userRoles, Role.KeyContact) && user.signupStatus === SignupStatus.Completed
          };
        } else if (this.isIntermediary) {
          this.menuItems = {
            dashboard: true,
            fmguest: true,
            documents: this.roleIncludes(user.roles, Role.DocumentManager),
            help: true
          };
        } else if (!this.isFundManager && !this.isDistributor && !this.isIntermediary) {
          this.menuItems = {
            dashboard: true,
            help: true
          };
        }
        if (this.isAdmin && !this.loggedUser.isSessionOpened) {
          for (const itemKey in this.menuItems) {
            this.menuItems[itemKey] = false;
          }
          this.menuItems.adminFMCompanies = true;
          this.menuItems.adminDistributorCompanies = true;
          this.menuItems.adminUsers = true;
          this.menuItems.adminEmails = true;
          this.menuItems.allinteractions = true;
          this.menuItems.alltasks = true;
          this.menuItems.documents = this.roleIncludes(user.roles, Role.DocumentManager);
          this.menuItems.reporting = this.roleIncludes(user.roles, Role.Reporter);
        }
        this.initCommonHeader();

        if (this.loggedUser.isSessionOpened && !this.userAdminSessionInit) {
          this.userAdminSessionInit = true;
          if (this.isDistributor || this.isIntermediary) {
            this.postRedirectAfterAdminSessionStart(this.loggedUser);
          } else if (
            (this.isFundManager && !this.location.path().includes("distributors")) ||
            this.location.path().includes("support/distributors")
          ) {
            this.postRedirectAfterAdminSessionStart(this.loggedUser);
          }
        }

        if (this.userAdminSessionEnded) {
          this.userAdminSessionEnded = false;
          this.router.navigate(["/support"]);
        }
      });

    this.store.pipe(select(selectorUserState), takeUntil(this.unsubscribe$)).subscribe((state: UserState) => {
      /**
       * If changed current company
       */
      if (state.changeCompanyStatus) {
        /**
         * Change locatin to action link
         * Otherwise: reload if not have action  link.
         */
        if (state.actionCompanyLink) {
          let query = "";
          if (state.actionCompanyQueryParams) {
            let isFirstProperty = true;
            for (const i in state.actionCompanyQueryParams) {
              if (!isFirstProperty) {
                query += '&';
              }
              query += `${i}=${state.actionCompanyQueryParams[i]}`;
              isFirstProperty = false;
            }
          }

          const link = query == "" ? state.actionCompanyLink : state.actionCompanyLink + "?" + query;

          window.location.href = link;
        } else {
          this.reload();
        }
      }
    });

    this.store.pipe(select(selectorLanguage), takeUntil(this.unsubscribe$)).subscribe((lang) => {
      this.translate.setDefaultLang(lang.currentLanguage);
    });

    this.actions$
      .pipe(
        ofType(UserActionTypes.ADMIN_SUPPORT_SESSION_END_SUCCESS),
        takeUntil(this.unsubscribe$),
        map(() => {
          // Clear data after success end session
          this.adminCompaniesListQuery = "";
          this.adminCurrentCompany = null;
          this.adminCurrentUser = null;
          this.adminCompanyUsersList = [];
          this.searchAdminCompanies("");
          this.userAdminSessionEnded = true;
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

/**
 * Interface for current setup.
 */
export interface CurrentSetup {
  link: string;
  label: string;
  step: string;
}
