import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ExportDdqDialogService } from "@shared/dialogs/export-ddq-dialog/export-ddq-dialog.service";
import { CompanyAdminService } from "@modules/support/components/support/services";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { distinctUntilChanged, debounceTime, switchMap, catchError, map } from "rxjs/operators";
import * as moment from "moment";

import {
  SupportFMCompanyActionTypes,
  ActionSupportFMCompanyGetCompanies,
  ActionSupportFMCompanyGetCompaniesSuccess,
  ActionSupportFMCompanyGetCompaniesError,
  ActionSupportFMCompaniesExport,
  ActionSupportNothingHappends,
  ActionSupportFMCompanySetSearchData,
  ActionFMAddCompany,
  ActionFMAddCompanySuccess,
  FMCompaniesSearchData,
  ActionUpdateUserRoles,
  ActionUpdateUserRolesSuccess,
  ActionSupportFMCompanyGetPaidCompanies,
  ActionSupportFMCompanyGetPaidCompaniesSuccess,
  ActionSupportFMCompanyGetPaidCompaniesError
} from "@modules/support/components/support/reducers/fm-companies.reducer";
import { SupportCompanyListResponse } from "@modules/support/models";
import { ActionToastrSuccess, ActionToastrError } from "@app/core/toastr/toastr.reducer";

@Injectable()
export class SupportFMCompanyEffect {
  constructor(
    private actions$: Actions<Action>,
    private companyService: CompanyAdminService,
    private exportService: ExportDdqDialogService
  ) {}

  getFMCompanies = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportFMCompanyActionTypes.FM_GET_COMPANIES),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportFMCompanyGetCompanies) => {
        return this.companyService
          .getFm(action.payload.page, action.payload.size, {
            ...action.payload.filter
          })
          .pipe(map((response: SupportCompanyListResponse) => new ActionSupportFMCompanyGetCompaniesSuccess(response)));
      }),
      catchError((error) => of(new ActionSupportFMCompanyGetCompaniesError({ error })))
    );
  });

  export = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(SupportFMCompanyActionTypes.FM_EXPORT),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportFMCompaniesExport) => {
        return this.companyService.exportFMCompanies().pipe(
          map((document) => {
            this.exportService.downloadBlobFile(
              document,
              { type: "application/vnd.ms-excel" },
              "fm_companies_" + moment().format("DD-MM-YYYY") + ".xlsx"
            );
            return new ActionSupportNothingHappends();
          }),
          catchError((error) => of(new ActionSupportFMCompanyGetCompaniesError({ error })))
        );
      })
    );
  });

  /**
   * When FM_ADD_COMPANY action is dispatched, call the service to add new fm company.
   * @memberof SupportFMCompanyEffect
   */
  addFMCompany = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportFMCompanyActionTypes.FM_ADD_COMPANY),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionFMAddCompany) =>
        this.companyService.addFMCompany(action.payload.fmCompanyData).pipe(
          switchMap((data) => [
            new ActionToastrSuccess({
              message: "Successfully added fm company."
            }),
            new ActionFMAddCompanySuccess()
          ]),
          catchError((error) =>
            of(
              new ActionToastrError({
                error: {
                  message: "Something went wrong, please try again later."
                }
              })
            )
          )
        )
      )
    );
  });

  storeFilters = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SupportFMCompanyActionTypes.FM_SET_SEARCH_DATA),
        distinctUntilChanged(),
        switchMap((action: ActionSupportFMCompanySetSearchData) => {
          const key = "DDD-FM-FILTER";
          if (action.payload) {
            const data = action.payload;
            const unit: FMCompaniesSearchData = {
              filter: data.filter,
              columns: data.columns
            };
            localStorage.setItem(key, JSON.stringify(unit));
          } else {
            localStorage.removeItem(key);
          }

          return new Observable();
        })
      );
    },
    { dispatch: false }
  );

  /**
   * When FM_UPDATE_USER_ROLES action is dispatched, call the service to update user roles.
   * @memberof SupportFMCompanyEffect
   */
  updateUserRoles = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportFMCompanyActionTypes.FM_UPDATE_USER_ROLES),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionUpdateUserRoles) =>
        this.companyService.updateUserRoles(action.payload.model).pipe(
          switchMap((data) => [
            new ActionToastrSuccess({
              message: "Successfully updated user roles."
            }),
            new ActionUpdateUserRolesSuccess()
          ]),
          catchError((error) =>
            of(
              new ActionToastrError({
                error: {
                  message: "Something went wrong, please try again later."
                }
              })
            )
          )
        )
      )
    );
  });

  getPaidFMCompanies = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportFMCompanyActionTypes.FM_GET_PAID_COMPANIES),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportFMCompanyGetPaidCompanies) => {
        return this.companyService
          .getPaidFm(action.payload.companyAuditorId)
          .pipe(
            map((response: SupportCompanyListResponse) => new ActionSupportFMCompanyGetPaidCompaniesSuccess(response))
          );
      }),
      catchError((error) => of(new ActionSupportFMCompanyGetPaidCompaniesError({ error })))
    );
  });
}
