import { createAction, props } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";

import { IUser } from "@app/core/auth/identity/user.reducer";
import {
  ISearchModel,
  ICompaniesInteractionsResponse,
  ICompaniesInteractionsUsersResponse,
  IDataInteractionsGet,
  IInteractionFormModel
} from "@shared/models/interaction.interfaces";

export const ActionAllCompaniesInteractionsGet = createAction(
    "[AllCompnaiesInteractions] Get",
    props<{
      page: number;
      size: number;
      search: ISearchModel;
    }>()
);

export const ActionAllCompaniesInteractionsGetSuccess = createAction(
    "[AllCompnaiesInteractions] Get Success",
    props<{interactionsData: ICompaniesInteractionsResponse}>()
);

export const ActionAllCompaniesInteractionsGetError = createAction(
    "[AllCompnaiesInteractions] Get Error",
    props<{error: HttpErrorResponse}>()
);

export const ActionAllCompaniesInteractionAdd = createAction(
  "[AllCompnaiesInteractions] Add interaction",
  props<{
    interaction: IInteractionFormModel;
    interactionsGetData: IDataInteractionsGet;
  }>()
);

export const ActionAllCompaniesInteractionSave = createAction(
  "[AllCompnaiesInteractions] Save interaction",
  props<{
    interaction: any;
    interactionsGetData: IDataInteractionsGet;
  }>()
);

export const ActionAllCompaniesInteractionDelete = createAction(
    "[AllCompnaiesInteractions] Delete interaction",
    props<{ id: string; interactionsGetData: IDataInteractionsGet }>()
);

export const ActionAllCompanyUsersGet = createAction(
  "[AllCompnaiesInteractions] Get Company Users",
  props<{companyId: string;}>()
);

export const ActionAllCompanyUsersGetSuccess = createAction(
  "[AllCompnaiesInteractions] Get Company Users Success",
  props<{companyUsers: ICompaniesInteractionsUsersResponse}>()
);

export const ActionAllCompanyUsersGetError = createAction(
  "[AllCompnaiesInteractions] Get Company Users Error",
  props<{error: HttpErrorResponse}>()
);

export const ActionInteractionsExport = createAction("[AllCompnaiesInteractions] Export");