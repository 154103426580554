import { HttpErrorResponse } from "@angular/common/http";
import { Action, createFeatureSelector, createSelector } from "@ngrx/store";
import * as _ from "lodash";

import { IAppStateReviewGroups } from "@modules/review-groups/review-groups.module";
import { IDistributor } from "@shared/models";

export enum AuditorActionTypes {
  AUDITOR_GET_INDEPENDETLY_DISTRIBUTORS_REVIEWED = "[Auditor] Get independetly distributors reviewed",
  AUDITOR_GET_INDEPENDETLY_REVIEWED_DISTRIBUTORS_SUCCESS = "[Auditor] Get independetly reviewed distributors success",
  AUDITORS_GET_INDEPENDETLY_REVIEWED_DISTRIBUTORS_ERROR = "[Auditor] Get independetly reviewed distributors error"
}

export class AuditorGetIndependentlyReviewedDistributors implements Action {
  readonly type = AuditorActionTypes.AUDITOR_GET_INDEPENDETLY_DISTRIBUTORS_REVIEWED;
  constructor(public payload: { fundManagerId: string; page: number; size: number }) {}
}

export class AuditorGetIndependentlyReviewedDistributorsSuccess implements Action {
  readonly type = AuditorActionTypes.AUDITOR_GET_INDEPENDETLY_REVIEWED_DISTRIBUTORS_SUCCESS;
  constructor(public payload: { independentlyReviewedDistributors: IDistributor[] }) {}
}

export class AuditorGetIndependentlyReviewedDistributorsError implements Action {
  readonly type = AuditorActionTypes.AUDITORS_GET_INDEPENDETLY_REVIEWED_DISTRIBUTORS_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export type AuditorActions =
  | AuditorGetIndependentlyReviewedDistributors
  | AuditorGetIndependentlyReviewedDistributorsSuccess
  | AuditorGetIndependentlyReviewedDistributorsError;

export const initialAuditorState: IAuditorState = {
  loading: false,
  saveNeeded: false,
  page: 0,
  size: 20
};

const featureSelector = createFeatureSelector<any>("auditorState");

export const selectorAuditorState = createSelector(featureSelector, (state) => {
  if (state) {
    return state.auditorData;
  }
});

export const selectGroupsState = createSelector(
  selectorAuditorState,
  (state: IAppStateReviewGroups) => state && state.reviewGroups
);
export const selectorListDistributors = createSelector(selectorAuditorState, (state) => state && state.distributors);
export const selectorTargetFmId = createSelector(
  selectorAuditorState,
  (state: IAuditorState) => state.selectedTargetFMId
);

export function auditorReducer(state: IAuditorState = initialAuditorState, action: AuditorActions): IAuditorState {
  switch (action.type) {
    case AuditorActionTypes.AUDITOR_GET_INDEPENDETLY_DISTRIBUTORS_REVIEWED:
      return {
        ...state,
        loading: true,
        selectedTargetFMId: action.payload.fundManagerId
      };
    case AuditorActionTypes.AUDITOR_GET_INDEPENDETLY_REVIEWED_DISTRIBUTORS_SUCCESS:
      return {
        ...state,
        loading: false,
        distributors: action.payload.independentlyReviewedDistributors
      };
    case AuditorActionTypes.AUDITORS_GET_INDEPENDETLY_REVIEWED_DISTRIBUTORS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
}

export interface IAuditorState {
  loading?: boolean;
  distributors?: any;
  selectedTargetFMId?: string;
  saveNeeded?: boolean;
  error?: HttpErrorResponse;
  page: number;
  size: number;
}
