export enum TooltipDialogContentType {
  TEXT = "TEXT",
  LIST = "LIST",
  GRID = "GRID"
}

export interface TooltipDialogOptions {
  title: string;
  content: any;
  flex?: number[];
  type: TooltipDialogContentType;
  buttonText?: string;
}
