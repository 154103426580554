<div class="content">
  <div class="title">
    {{ "EDIT_DISTRIBUTOR_DIALOG.TITLE" | translate }}
  </div>
  <form class="custom-form update-dist-form" [formGroup]="form">
    <div class="control-group">
      <div class="label">
        {{ "EDIT_DISTRIBUTOR_DIALOG.NAME_LABEL" | translate }}
      </div>
      <div class="control-box">
        <input class="input-form" type="text" formControlName="name" aria-label="distributor name" />
        <ddd-field-error-show [displayError]="isFieldValid('name')" errorMsg="Distributor company name is required">
        </ddd-field-error-show>
      </div>
    </div>
    <div class="control-group">
      <div class="label">
        {{ "EDIT_DISTRIBUTOR_DIALOG.LEI" | translate }}
      </div>
      <div class="control-box">
        <input class="input-form" type="text" formControlName="legalEntityId" aria-label="distributor lei" />
        <ddd-field-error-show [displayError]="isFieldValid('legalEntityId')" errorMsg="Legal entity id is required">
        </ddd-field-error-show>
      </div>
    </div>
    <div class="actions">
      <button class="ddd-button" (click)="onClose()">
        {{ "EDIT_DISTRIBUTOR_DIALOG.CANCEL_BUTTON" | translate }}
      </button>
      <button class="ddd-button raised" (click)="onSubmit()">
        {{ "EDIT_DISTRIBUTOR_DIALOG.SAVE_BUTTON" | translate }}
      </button>
    </div>
  </form>
</div>
